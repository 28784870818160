import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import EasyEdit, { Types } from 'react-easy-edit';
// helpers
import { formatDollarsWithCommas } from '../../helpers/Numbers';
import { amplitudeClient } from '../../helpers/Analytics';
import { updatePortfolio } from '../../helpers/Firebase';
// hooks
import useToast from '../../hooks/UseToast';
// components
import Loader from '../global/Loader';

export default function PortfolioCard({
  id,
  portfolio,
  updateName,
  updateGoal,
  updateInvestmentAmount,
  updateRiskTolerance,
  updateRiskHorizon,
  deletePortfolio,
}) {
  // state
  const [loading, setLoading] = useState(true);
  // add toast API for display to the user
  const { addToast } = useToast();

  // destructure portfolio
  const { name, totalAmountInvested, goal, updatedAt, riskTolerance, riskHorizon } = portfolio;
  const isPublic = portfolio.public !== undefined ? portfolio.public.isPublic : false;

  // global cancel for now
  const cancel = () => {
    console.log('Cancelled');
  };

  const riskToleranceOptions = [
    {
      value: 'conservative',
      label: 'Conservative',
    },
    {
      value: 'moderate',
      label: 'Moderate',
    },
    {
      value: 'aggressive',
      label: 'Aggressive',
    },
  ];

  const timeHorizonOptions = [
    {
      value: 'short',
      label: 'Short-Term (9-18 months)',
    },
    {
      value: 'mid',
      label: 'Mid-Term (18 months - 3 years)',
    },
    {
      value: 'long',
      label: 'Long-Term (3 - 4.5 years)',
    },
  ];

  /**
   * Copy shared portfolio link to clipboard
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  const handleGetShareLink = () => {
    const shareLink = `${window.location.origin}/p/${id}`;
    navigator.clipboard.writeText(shareLink);
    amplitudeClient.getInstance().logEvent('action_copy-public-share-link-to-clipboard', {
      link: shareLink,
    });
    window.Appcues?.track('Copy public share link to clipboard');
  };

  /**
   * View the portfolio
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  const handleViewPortfolio = (e) => {
    amplitudeClient.getInstance().logEvent('action_open-portfolio', {
      portfolioId: id,
    });
    window.Appcues?.track('Open portfolio');
  };

  /**
   * Delete the portfolio
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  const handleDeletePortfolio = useCallback((e) => {
    e.preventDefault();
    deletePortfolio(id);
    amplitudeClient.getInstance().logEvent('action_delete-portfolio', {
      portfolioId: id,
    });
    window.Appcues?.track('Delete portfolio');
  }, [deletePortfolio, id]);

  /**
   * Handle undo remove event
   * @author Jusselly Moreno <jusselly.moreno@intechideas.com>
   * @returns - none
   */
  const handleUndoRemoveEvent = useCallback((event) => {
    if (event) {
      updatePortfolio(id, { claimed: true });
    }
  }, []);

  /**
   * Handle remove portfolio alert
   * @author Jusselly Moreno <jusselly.moreno@intechideas.com>
   * @returns - none
   */
  const handleRemovePortfolioAlert = useCallback((event) => {
    addToast(
      `${portfolio.name} has been removed`,
      { type: 'undo', id, handler: handleUndoRemoveEvent },
      'success'
    );

    handleDeletePortfolio(event);
  }, [addToast, handleDeletePortfolio, handleUndoRemoveEvent, id, portfolio]);

  /**
   * Update the portfolio name
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const handleUpdatePortfolioName = (value) => {
    updateName(id, value);
    amplitudeClient.getInstance().logEvent('action_update-portfolio-name', {
      portfolioId: id,
      name: value,
    });
    window.Appcues?.track('Update portfolio name');
  };

  /**
   * Update the portfolio goal
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const handleUpdatePortfolioGoal = (value) => {
    updateGoal(id, value);
    amplitudeClient.getInstance().logEvent('action_update-portfolio-goal', {
      portfolioId: id,
      goal: value,
    });
    window.Appcues?.track('Update portfolio goal');
  };

  /**
   * Update the portfolio investment amount
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const handleUpdateAmountInvested = (value) => {
    updateInvestmentAmount(id, value);
    amplitudeClient.getInstance().logEvent('action_update-portfolio-investment-amount', {
      portfolioId: id,
      investmentAmount: value,
    });
    window.Appcues?.track('Update portfolio investment amount');
  };

  /**
   * Update the portfolio investment amount
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const handleUpdateRiskTolerance = (value) => {
    updateRiskTolerance(id, value);
    amplitudeClient.getInstance().logEvent('action_update-portfolio-risk-tolerance', {
      portfolioId: id,
      riskTolerance: value,
    });
    window.Appcues?.track('Update portfolio risk tolerance');
  };

  /**
   * Update the portfolio investment amount
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const handleUpdateRiskHorizon = (value) => {
    updateRiskHorizon(id, value);
    amplitudeClient.getInstance().logEvent('action_update-portfolio-risk-horizon', {
      portfolioId: id,
      riskHorizon: value,
    });
    window.Appcues?.track('Update portfolio time horizon');
  };

  /**
   * Checks for a portfolio by looking for portfolioList length in profile context
   * This effect runs everytime the portfolioList context value is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Col className="mb-4">
      {loading === true && (
        <Card className="port loading">
          <Card.Body>
            <Loader />
          </Card.Body>
        </Card>
      )}
      {loading === false && (
        id !== null ? (
          <Card id={id} className="port loaded">
            <Card.Body>
              <Card.Title>
                <EasyEdit
                  type={Types.TEXT}
                  value={name}
                  onSave={handleUpdatePortfolioName}
                  onCancel={cancel}
                  saveButtonLabel="Save"
                  cancelButtonLabel="Cancel"
                  attributes={{ className: 'form-control' }}
                  placeholder="Add portfolio name"
                  saveOnBlur={true}
                  allowEdit={true}
                />
              </Card.Title>
              <div style={{ marginLeft: '0.75rem' }}>
                <FontAwesomeIcon
                  className="me-2 fa-fw"
                  icon={['fal', 'bullseye-arrow']}
                  title="Goal"
                />
                <EasyEdit
                  type={Types.TEXT}
                  value={goal === '' ? null : goal}
                  onSave={handleUpdatePortfolioGoal}
                  onCancel={cancel}
                  saveButtonLabel="Save"
                  cancelButtonLabel="Cancel"
                  attributes={{ className: 'form-control' }}
                  placeholder="Add investment goal"
                  saveOnBlur={true}
                  allowEdit={true}
                />
              </div>
              <div style={{ marginLeft: '0.75rem' }}>
                <FontAwesomeIcon
                  className="me-2 fa-fw"
                  icon={['fal', 'badge-dollar']}
                  title="Invested"
                />
                <EasyEdit
                  type={Types.NUMBER}
                  value={formatDollarsWithCommas(totalAmountInvested)}
                  onSave={handleUpdateAmountInvested}
                  onCancel={cancel}
                  saveButtonLabel="Save"
                  cancelButtonLabel="Cancel"
                  attributes={{ className: 'form-control' }}
                  placeholder="Add investment amount"
                  saveOnBlur={true}
                  allowEdit={true}
                />
              </div>
              <div style={{ marginLeft: '0.75rem' }}>
                <FontAwesomeIcon
                  className="me-2 fa-fw"
                  icon={['fal', 'layer-group']}
                  title="Tolerance"
                />
                <EasyEdit
                  type={Types.SELECT}
                  options={riskToleranceOptions}
                  value={riskTolerance}
                  onSave={handleUpdateRiskTolerance}
                  onCancel={cancel}
                  saveButtonLabel="Save"
                  cancelButtonLabel="Cancel"
                  attributes={{ className: 'form-select' }}
                  placeholder="Add risk tolerance"
                  saveOnBlur={true}
                  allowEdit={true}
                />
              </div>
              <div style={{ marginLeft: '0.75rem' }}>
                <FontAwesomeIcon
                  className="me-2 fa-fw"
                  icon={['fal', 'hourglass-half']}
                  title="Horizon"
                />
                <EasyEdit
                  type={Types.SELECT}
                  options={timeHorizonOptions}
                  value={riskHorizon}
                  onSave={handleUpdateRiskHorizon}
                  onCancel={cancel}
                  saveButtonLabel="Save"
                  cancelButtonLabel="Cancel"
                  attributes={{ className: 'form-select' }}
                  placeholder="Add risk horizon"
                  saveOnBlur={true}
                  allowEdit={true}
                />
              </div>
              <Card.Text className="mt-4 mb-2" style={{ marginLeft: '0.75rem' }}>
                <Link
                  to={`/p/${id}`}
                  className="me-4 btn btn-primary"
                  onClick={handleViewPortfolio}
                >
                  View Portfolio
                </Link>
                <Button variant="dark" onClick={handleRemovePortfolioAlert}>
                  Delete Portfolio
                </Button>
              </Card.Text>
              <Card.Text
                className="text-muted fst-italic mt-4 mb-2"
                style={{ marginLeft: '0.75rem' }}
              >
                <small>
                  <FontAwesomeIcon className="me-1" icon={['fal', 'history']} fixedWidth />
                  Last updated
                  {' - '}
                  {updatedAt.toDate().toDateString()}
                  {' @ '}
                  {updatedAt.toDate().toLocaleTimeString('en-US')}
                </small>
              </Card.Text>
              <Card.Text className="text-muted fst-italic" style={{ marginLeft: '0.75rem' }}>
                <small>
                  <FontAwesomeIcon className="me-1" icon={['fal', 'users']} fixedWidth />
                  Shared
                  {' - '}
                  {isPublic === true ? (
                    <span className='public' onClick={handleGetShareLink}>
                      Public
                    </span>
                  ) : (
                    <span>Private</span>
                  )}
                </small>
              </Card.Text>
            </Card.Body>
          </Card>
        ) : (
          <Card className="port no-data">
            <Card.Body>
              <Card.Text className="text-warning">no data</Card.Text>
            </Card.Body>
          </Card>
        )
      )}
    </Col>
  );
}
