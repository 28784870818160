/* eslint-disable camelcase */
import InvestmentDetail from './InvestmentDetail';

export default function InvestmentCards({ accounts, holdings, securities }) {
  const accountList = () =>
    accounts.map(({ account_id, type, name, balances }) => (
      <InvestmentDetail
        key={account_id}
        accountKey={account_id}
        accountType={type}
        name={name}
        available={balances.available}
        current={balances.current}
        holdings={holdings}
        securities={securities}
      />
    ));

  return accountList();
}
