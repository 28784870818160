import React, { useState, useEffect, useCallback } from 'react';
import { getLocalStorageValue, setLocalStorageValue } from '../helpers/LocalStorage';

export const SettingsContext = React.createContext({
  collapsed: null,
  toggled: null,
  muted: null,
  weighting: null,
  momentum: null,
  globalBenchmarks: null,
});

export default function SettingsProvider({ children }) {
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [muted, setMuted] = useState(false);
  const [weighting, setWeighting] = useState('volatility');
  const [momentum, setMomentum] = useState(false);
  const [globalBenchmarks, setBenchmarks] = useState(new Set());

  const handleCollapseSidebar = () => {
    setLocalStorageValue('rs-sidebar-collapsed', !collapsed);
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };

  const handleToggleAudio = () => {
    setLocalStorageValue('rs-audio-muted', !muted);
    setMuted(!muted);
  };

  const handleSetWeighting = (method) => {
    setLocalStorageValue('rs-portfolio-weighting', method);
    setWeighting(method);
  };

  const handleToggleMomentum = () => {
    setLocalStorageValue('rs-filter-momentum', !momentum);
    setMomentum(!momentum);
  };

  const handleSetGlobalBenchmarks = (benchmarks) => {
    setLocalStorageValue('rs-global-benchmarks', benchmarks);
    setBenchmarks(benchmarks);
  };

  useEffect(() => {
    const alreadyMuted = getLocalStorageValue('rs-audio-muted');
    if (alreadyMuted === 'true') {
      setMuted(true);
    } else {
      setMuted(false);
    }
    const alreadyCollapsed = getLocalStorageValue('rs-sidebar-collapsed');
    if (alreadyCollapsed === 'true') {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    const previousWeighting = getLocalStorageValue('rs-portfolio-weighting');
    if (previousWeighting) {
      setWeighting(previousWeighting);
    }
    const previousMomentum = getLocalStorageValue('rs-filter-momentum');
    if (previousMomentum === 'true') {
      setMomentum(true);
    } else {
      setMomentum(false);
    }
    const previousBenchmarks = getLocalStorageValue('rs-global-benchmarks');
    if (previousBenchmarks) {
      setBenchmarks(previousBenchmarks);
    }
    return () => {};
  }, []);

  const contextValue = {
    collapsed,
    toggled,
    muted,
    weighting,
    momentum,
    globalBenchmarks,
    setCollapse: useCallback(() => setCollapsed(false)),
    setToggle: useCallback(() => setToggled(false)),
    collapseSidebar: useCallback(() => handleCollapseSidebar()),
    toggleSidebar: useCallback(() => handleToggleSidebar()),
    toggleMuted: useCallback(() => handleToggleAudio()),
    setWeight: useCallback((method) => handleSetWeighting(method)),
    toggleMomentum: useCallback(() => handleToggleMomentum()),
    setGlobalBenchmarks: useCallback((benchmarks) => handleSetGlobalBenchmarks(benchmarks)),
  };

  return <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>;
}
