// Pinched from https://dev.to/rexebin/build-an-ag-grid-react-component-that-auto-resize-columns-to-fit-container-width-1h5n

import { useState } from 'react';
import useDebounce from './useDebounce';

export default function useContainerWidth(debounce = 100) {
  const [size, setSize] = useState();
  const ref = (element) =>
    element && setSize(element.getBoundingClientRect().width);
  const debounceResize = useDebounce(size, debounce); // TODO, bitching about this, unsure why
  return { width: debounceResize, ref };
}
