import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// components
import Loader from '../global/Loader';
import CollectionToolbarSearch from './CollectionToolbarSearch';
import CollectionToolbarCorrelation from './CollectionToolbarCorrelation';

function CollectionToolbar({ collectionID, collectionSymbols }) {
  // loading flag
  const [loading, setLoading] = useState(true);

  /**
   * One-time load
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (collectionID !== null) {
      setLoading(false);
      return;
    }
    setLoading(true);

    return () => {};
  }, [collectionID]);

  return (
    <div className='toolbar'>
      {loading === true && <Loader message='Loading Toolbar...' />}
      {loading === false && (
        <>
          <CollectionToolbarSearch
            collectionID={collectionID}
            collectionSymbols={collectionSymbols}
          />
          <CollectionToolbarCorrelation collectionID={collectionID} />
        </>
      )}
    </div>
  );
}

CollectionToolbar.propTypes = {
  collectionID: PropTypes.string,
};

CollectionToolbar.defaultProps = {
  collectionID: '',
};

export default CollectionToolbar;
