import React, { setGlobal, useGlobal } from 'reactn';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import useSWR, { SWRConfig } from 'swr';
import { swrDataServicesFetcher } from '../helpers/DataServices';

// context
import ToastProvider from '../contexts/ToastContext';
import ProfileProvider from '../contexts/ProfileContext';
import SettingsProvider from '../contexts/SettingsContext';

// components
import ToastNotification from './global/ToastNotification';
import PrivateRoute from './global/PrivateRoute';
import ShareablePortfolioRoute from './global/ShareablePortfolioRoute';

// routes
import Collections from '../routes/Collections';
import ExploreAsset from '../routes/ExploreAsset';
import ForgotPassword from '../routes/ForgotPassword';
import Institution from '../routes/Institution';
import Investors from '../routes/Investors';
import Login from '../routes/Login';
import NotFound from '../routes/NotFound';
import Onboarding from '../routes/Onboarding';
import Profile from '../routes/Profile';
import Signup from '../routes/Signup';
import Tools from '../routes/Tools';
import AssetInfoModal from './AssetInfoModal';

setGlobal({
  activePortfolio: null,
  collectionAssetList: [],
  currentUser: undefined,
  activeTicker: null,
});

const helmetContext = {};

function App() {
  const [currentUser] = useGlobal('currentUser');
  const [isUserNew] = useGlobal('isUserNew');

  if (currentUser === undefined) {
    return null;
  }

  // Appcues user identity
  if (currentUser?.uid && window.Appcues) {
    window.Appcues?.identify(
      currentUser.uid,
      {
        email: currentUser.email, // Current user's email
        isUserNew,
      }
    );
  }

  return (
    <SWRConfig
      value={{ fetcher: swrDataServicesFetcher }}
    >
      <IntlProvider locale='en'>
        <HelmetProvider context={helmetContext}>
          <Router>
            <DndProvider backend={HTML5Backend}>
              <ProfileProvider>
                <SettingsProvider>
                  <ToastProvider>
                    <Switch>
                      <Route exact path='/'>
                        <Redirect to={'/profile'} />
                      </Route>
                      <Route exact path='/explore/:id' component={ExploreAsset} />
                      <PrivateRoute path='/start' component={Onboarding} />
                      <ShareablePortfolioRoute path='/p/:id' />
                      <PrivateRoute exact path='/c/:id' component={Collections} />
                      <PrivateRoute exact path='/c' component={Collections} />
                      <PrivateRoute path='/investors' component={Investors} />
                      <PrivateRoute path='/profile' component={Profile} />
                      <PrivateRoute path='/i/:id' component={Institution} />
                      <PrivateRoute path='/tools' component={Tools} />
                      <Route path='/signup' component={Signup} />
                      <Route path='/login' component={Login} />
                      <Route path='/forgot-password' component={ForgotPassword} />
                      <Route component={NotFound} />
                    </Switch>
                    <AssetInfoModal />
                    <ToastNotification />
                  </ToastProvider>
                </SettingsProvider>
              </ProfileProvider>
            </DndProvider>
          </Router>
        </HelmetProvider>
      </IntlProvider>
    </SWRConfig>
  );
}

export default App;
