import React, { useGlobal, useState, useEffect } from 'reactn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import { logout } from '../../helpers/Auth';
// hooks
import useSettings from '../../hooks/UseSettings';
import useProfile from '../../hooks/UseProfile';
import useToast from '../../hooks/UseToast';
// helpers
import { getLocalStorageValue } from '../../helpers/LocalStorage';
import { amplitudeClient } from '../../helpers/Analytics';
import { useIsBelowBreakpoint } from '../../hooks/useBreakpoint';

export default function SideNav() {
  const [currentUser] = useGlobal('currentUser');
  // add toast API
  const { addToast } = useToast();
  // get the app level settings context
  const {
    collapseSidebar,
    collapsed,
    muted,
    setToggle,
    toggleMuted,
    toggleSidebar,
    toggled,
  } = useSettings();
  const { isUserAdmin, isAlphaTester, isBetaTester, portfolioList, collectionList } = useProfile();
  // router history
  const history = useHistory();
  // admin or tester?
  const [userIsUserAdmin, setUserIsUserAdmin] = useState(false);
  const [userIsAlphaTester, setUserIsAlphaTester] = useState(false);
  const [userIsBetaTester, setUserIsBetaTester] = useState(false);
  // last portfolio viewed
  const [lastPortfolio, setLastPortfolio] = useState(null);
  // first portfolio in the list
  const firstPortfolio = portfolioList.length !== 0 ? portfolioList[0].id : null;
  // first collection in the list
  const firstCollection = collectionList.length !== 0 ? collectionList[0].id : 'start';
  const isCollapsedOnWidth = useIsBelowBreakpoint('lg');

  /**
   * Handle when a user wants to logout
   * On success: push user to the home page
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleLogout = async () => {
    try {
      await logout().then(() => {
        amplitudeClient.getInstance().logEvent('action_logout');
        amplitudeClient.getInstance().setUserId(null);
        amplitudeClient.getInstance().regenerateDeviceId();
        window.Appcues?.track('Logout');
        history.push('/');
      });
    } catch (err) {
      addToast('LOG_OUT_ERROR', err.message, 'error');
    }
  };

  /**
   * Handle when a user toggles sounds on and off
   * On success: toggle sounds
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleToggleSounds = () => {
    amplitudeClient
      .getInstance()
      .logEvent('action_toggle_sounds', { toggled: muted === true ? 'off' : 'on' });
    toggleMuted();
  };

  /**
   * Handle when a user clicks portfolios nav item
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleSelectPortfolios = (portfolio) => {
    amplitudeClient
      .getInstance()
      .logEvent('action_click-side-nav', { navItem: 'portfolio', portfolioId: portfolio });
    setToggle();
    history.push(`/p/${portfolio}`);
  };

  /**
   * Handle when a user clicks collections nav item
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleSelectCollections = (collection) => {
    amplitudeClient
      .getInstance()
      .logEvent('action_click-side-nav', { navItem: 'collection', collectionId: collection });
    setToggle();
    history.push(`/c/${collection}`);
  };

  /**
   * Handle when a user clicks all other nav items
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleSelectNavItem = (item) => {
    amplitudeClient.getInstance().logEvent('action_click-side-nav', { navItem: item });
    window.Appcues?.track('Click side nav item');
    setToggle();
    history.push(`/${item}`);
  };

  /**
   * Handle when a user collapses the sidebar
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleCollapseSidebar = () => {
    amplitudeClient
      .getInstance()
      .logEvent('action_collapse-side-nav', { collapsed: collapsed === true ? 'open' : 'closed' });
    collapseSidebar();
  };

  /**
   * Handle when a user toggles the sidebar
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleToggleSidebar = () => {
    amplitudeClient.getInstance().logEvent('action_toggle-side-nav', { toggled: 'close' });
    window.Appcues?.track('Toggle side nav');
    toggleSidebar();
  };

  /**
   * Runs once first time component is loaded
   * Checks for local storage value for last portfolio
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // console.log('last portfolio', getLocalStorageValue('rs-last-portfolio'));
    setLastPortfolio(getLocalStorageValue('rs-last-portfolio'));
    return () => {};
  }, []);

  /**
   * Runs each time any of the admin roles is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (isAlphaTester === true) {
      setUserIsAlphaTester(true);
    }
    if (isBetaTester === true) {
      setUserIsBetaTester(true);
    }
    if (isUserAdmin === true) {
      setUserIsUserAdmin(true);
    }
  }, [isAlphaTester, isBetaTester, isUserAdmin]);

  return (
    <div>
      <ProSidebar
        collapsed={collapsed || isCollapsedOnWidth}
        toggled={toggled}
        breakPoint='md'
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <Button className='btn-nav-toggle' onClick={handleToggleSidebar}>
            <FontAwesomeIcon icon={['fal', 'times']} size={'sm'} />
          </Button>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape='square'>
            <MenuItem
              onClick={handleCollapseSidebar}
              icon={<FontAwesomeIcon icon={['fal', 'arrows-h']} size={'lg'} />}
            >
              {/* Collapse */}
            </MenuItem>
            {currentUser && firstPortfolio !== null && (
              <MenuItem
                onClick={() =>
                  handleSelectPortfolios(`${lastPortfolio !== null ? lastPortfolio : firstPortfolio}`)
                }
                icon={<FontAwesomeIcon icon={['fad', 'analytics']} size={'lg'} />}
              >
                Portfolios
              </MenuItem>
            )}
            {currentUser && (
              <MenuItem
                onClick={() => handleSelectCollections(firstCollection)}
                icon={<FontAwesomeIcon icon={['fad', 'list-alt']} size={'lg'} />}
              >
                Collections
              </MenuItem>
            )}
            {currentUser && userIsBetaTester === true && (
              <MenuItem
                onClick={() => handleSelectNavItem('help')}
                icon={<FontAwesomeIcon icon={['fad', 'life-ring']} size={'lg'} />}
              >
                Help
              </MenuItem>
            )}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape='square' popperArrow>
            <MenuItem
              onClick={() => handleSelectNavItem('profile')}
              icon={<FontAwesomeIcon icon={['fad', 'user-cog']} size={'lg'} />}
            >
              Account
            </MenuItem>
            {currentUser && userIsUserAdmin === true && (
              <MenuItem
                onClick={() => handleSelectNavItem('tools')}
                icon={<FontAwesomeIcon icon={['fad', 'tools']} size={'lg'} id='tools' />}
              >
                Tools
              </MenuItem>
            )}
            <MenuItem
              onClick={handleToggleSounds}
              icon={
                <FontAwesomeIcon
                  icon={muted ? ['fad', 'volume-slash'] : ['fad', 'volume-up']}
                  size={'lg'}
                />
              }
            >
              Sounds
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              icon={<FontAwesomeIcon icon={['fad', 'sign-out']} size={'lg'} />}
            >
              Logout
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}
