import React, { useCallback, useEffect, useState } from 'react';
import { Col, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDrag } from 'react-dnd';
// helpers
import { getAssetDetails } from '../../helpers/DataServices';
import { amplitudeClient } from '../../helpers/Analytics';
// components
import Loader from '../global/Loader';
import StockCardBody from './StockCardBody';
import StockCardFooter from './StockCardFooter';
import StockCardError from './StockCardError';

export default function StockCard({
  allocation,
  deleteStock,
  hideShares,
  id,
  isRealPortfolio,
  playerType,
  readOnly,
  shares,
  symbol,
  updateStockShares,
}) {
  // router
  const history = useHistory();
  // state
  const [loading, setLoading] = useState(true);
  const [stockData, setStockData] = useState(null);

  // dnd
  const [{ isDragging }, dragRef] = useDrag({
    type: playerType,
    item: {
      id,
      symbol,
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  /**
   * Update the stock shares
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleUpdateStockShares = useCallback((shareCount) => {
    updateStockShares(id, shareCount);
  }, []);

  /**
   * Delete the stock from portfolio
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleDeleteStock = useCallback(() => {
    deleteStock(id, symbol);
  }, []);

  /**
   * Get stock data
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  // TODO: this is causing a memory leak
  useEffect(async () => {
    const controller = new AbortController();
    let assetDetails;
    try {
      assetDetails = await getAssetDetails(symbol, controller);
      setStockData(assetDetails);
      setLoading(false);
    } catch (e) {
      if (e.message !== 'canceled') {
        assetDetails = { name: symbol };
        setStockData(assetDetails);
        setLoading(false);
      }
    }

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Col className='mb-4'>
      {loading === true && (
        <Card className='stock loading'>
          <Card.Body>
            <Loader />
          </Card.Body>
        </Card>
      )}
      {loading === false && (
        <>
          {stockData ? (
            // <Card id={id} className='stock loaded' ref={dragRef}>
            <Card id={id} className='stock loaded'>
              <StockCardBody
                stockData={stockData}
                symbol={symbol}
                allocation={allocation}
                shares={shares}
                isRealPortfolio={isRealPortfolio}
                updateShareCount={handleUpdateStockShares}
                hideShares={hideShares}
                readOnly={readOnly}
              />
              {!readOnly && (
                <StockCardFooter
                  symbol={symbol}
                  deleteStock={handleDeleteStock}
                />
              )}
            </Card>
          ) : (
            <Card id={id} className='stock no-data'>
              <StockCardError symbol={symbol} />
              {!readOnly && (
                <StockCardFooter
                  symbol={symbol}
                  deleteStock={handleDeleteStock}
                />
              )}
            </Card>
          )}
        </>
      )}
    </Col>
  );
}
