import React, { useEffect, useState } from 'react';
// components
import { getAssetHistory } from '../../helpers/DataServices';
// helpers
import { BENCHMARKS } from '../../helpers/Constants';

const withGlobalBenchmarks = (MultilineChart) => (props) => {
  const [benchmarksWithHistory, setBenchmarksWithHistory] = useState([]);

  useEffect(async () => {
    const controller = new AbortController();
    const { data: benchmarkHistories } = await getAssetHistory({
      symbol: BENCHMARKS.map((b) => b.symbol),
      includeStats: true,
      controller,
    });

    // remap the portfolio equity curve to include daily growth for each benchmark
    // eslint-disable-next-line arrow-body-style
    const mergedBenchmarkData = BENCHMARKS.map((benchmark) => {
      // find the benchmark history for this benchmark
      const benchmarkHistory = benchmarkHistories.find((bH) => bH.symbol === benchmark.symbol);
      // remap the benchmark history to include daily growth and stats
      return {
        ...benchmark,
        history: benchmarkHistory.history.map((h) => ({
          ...h,
          ...h.stats,
          growth: h.closePrice / benchmarkHistory.history[0].closePrice - 1,
        })),
      };
    });

    setBenchmarksWithHistory(mergedBenchmarkData);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div>
      <MultilineChart
        {...props}
        benchmarks={benchmarksWithHistory.filter((b) => props.benchmarks.has(b.symbol))}
      />
    </div>
  );
};

export default withGlobalBenchmarks;
