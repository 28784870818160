import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// hooks
import { removeInvestorFromBullpen } from '../../helpers/Firebase';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// components
import InvestorStat from './InvestorStat';

export default function InvestorListItem({ investor, userID }) {
  const { filerCIK } = investor;
  const [iconColor, setIconColor] = useState('orange');

  // remove the investor from the user's follow list
  const onUnfollow = () => {
    removeInvestorFromBullpen(filerCIK, userID);
    amplitudeClient.getInstance().logEvent('action_remove-investor', {
      investor: investor.name,
      filerCIK,
    });
  };

  /**
   * Runs each time the InvestorItem component is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    const firstChar = investor.name.charAt(0);
    if (/^[a-i0-9]+$/i.test(firstChar)) {
      setIconColor('blue');
    }
    if (/^[j-r]+$/i.test(firstChar)) {
      setIconColor('green');
    }
    if (/^[s-z]+$/i.test(firstChar)) {
      setIconColor('orange');
    }
    return () => {};
  }, []);

  return (
    <Row as={'li'}>
      <Col xs={5}>
        <strong>{investor.name}</strong>
      </Col>
      <Col xs={2}>
        <InvestorStat
          label='' // 3 Year Risk Efficiency
          value={(investor.sortino3yr / Math.sqrt(2)).toFixed(2)}
        />
      </Col>
      <Col xs={2}>
        <InvestorStat
          label='' // Holdings
          value={investor.holdings[0].symbols.length}
        />
      </Col>
      <Col xs={2}>
        <InvestorStat
          label='' // 3 Year Annualized Gains
          value={`${investor.performance3yr}%`}
        />
      </Col>
      <Col xs={1}>
        <Button variant='link' size='sm' onClick={onUnfollow}>
          <FontAwesomeIcon
            icon={['far', 'trash-alt']}
            size={'xs'}
            className='d-inline-block'
            fixedWidth
          />
        </Button>
      </Col>
    </Row>
  );
}
