import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button } from 'react-bootstrap';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// import { getAllTheTickers } from '../../helpers/DataServices';
// components
import Loader from '../global/Loader';
import FolioCombo from './FolioCombo';

export default function MiniFolio({ activeStock }) {
  // state
  const [loading, setLoading] = useState(true);
  const [tickerList, setTickerList] = useState([]);
  const [randomTicker1, setRandomTicker1] = useState(null);
  const [randomTicker2, setRandomTicker2] = useState(null);
  const [randomTicker3, setRandomTicker3] = useState(null);
  const [usedRandomTickers, setUsedRandomTickers] = useState([]);

  const tempTickers = [];

  /**
   * Generate a random ticker from list of symbols
   * @returns - (string) random ticker
   */
  const tickerRandomizer1000 = () => {
    // remove the activeStock from the tickerList
    const filteredTickerList = tickerList.filter((ticker) => ticker !== activeStock);
    // remove values in usedRandomTickers from the filteredTickerList
    const filteredTickerList2 = filteredTickerList.filter(
      (ticker) => !usedRandomTickers.includes(ticker)
    );
    // remove values in tempTickers from the filteredTickerList2
    const finalTickerList = filteredTickerList2.filter((ticker) => !tempTickers.includes(ticker));
    // get a random number based on the finalTickerList array length
    const randomIndex = Math.floor(Math.random() * finalTickerList.length);
    // add the chosen random ticker to the tempTickers array
    tempTickers.push(finalTickerList[randomIndex]);
    // return the chosen random ticker
    return finalTickerList[randomIndex];
  };

  /**
   * Get the full list of symbols from data services
   * @returns - none
   */
  const fetchAllTickers = async () => {
    // const { data: tickers } = await getAllTheTickers();
    const tickers = [
      'AAPL',
      'TSLA',
      'BA',
      'KO',
      'GE',
      'DIS',
      'NFLX',
      'GLD',
      'AMZN',
      'GOOGL',
      'NVDA',
      'JPM',
      'UNH',
      'V',
      'PG',
      'HD',
      'BAC',
      'MA',
      'XOM',
      'PFE',
      'CVX',
      'ABBV',
      'PEP',
      'CSCO',
      'CMCSA',
      'CRM',
      'COST',
      'ADBE',
      'WFC',
      'AVGO',
      'ACN',
      'INTC',
      'MRK',
      'QCOM',
      'LLY',
      'WMT',
      'MCD',
      'NKE',
      'PM',
      'UPS',
      'UNP',
      'LOW',
      'LIN',
      'TXN',
      'BMY',
      'INTU',
      'MS',
      'NEE',
      'RTX',
      'C',
      'SCHW',
      'ORCL',
      'HON',
      'AMGN',
      'AMAT',
      'AXP',
      'GS',
      'NOW',
      'IBM',
      'DE',
      'BLK',
      'GE',
      'CAT',
      'TGT',
    ];
    setTickerList(tickers);
  };

  /**
   * Load three random folio combos
   * @returns - none
   */
  const reloadFolios = async (firstTime = false) => {
    // reset temp array
    tempTickers.length = 0;
    // grab three random tickers
    setRandomTicker1(await tickerRandomizer1000());
    setRandomTicker2(await tickerRandomizer1000());
    setRandomTicker3(await tickerRandomizer1000());
    // add the three random tickers to the usedRandomTickers array
    setUsedRandomTickers([...usedRandomTickers, ...tempTickers]);
    // amplitude tracking
    if (!firstTime) {
      amplitudeClient.getInstance().logEvent('action_single-asset-lander-reload-minifolio', {
        symbol: activeStock,
        random1: tempTickers[0],
        random2: tempTickers[1],
        random3: tempTickers[2],
      });
      window.Appcues?.track('SLA - reload mini-folio');
    }
    // set loading to false
    setLoading(false);
  };

  /**
   * Happens once, grab the list of available tickers from data services
   * @returns - none
   */
  useEffect(() => {
    fetchAllTickers();
    return () => {};
  }, []);

  /**
   * Happens each time the tickerList is updated, should be once
   * @returns - none
   */
  useEffect(() => {
    if (tickerList.length !== 0) {
      const firstTime = true;
      // load the initial folios
      reloadFolios(firstTime);
    }

    return () => {};
  }, [tickerList]);

  return (
    <>
      {loading === true && (
        <div className='loading'>
          <Loader />
        </div>
      )}
      {loading === false && (
        <div className='minifolio'>
          <Container>
            <Row>
              <Col sm={12}>
                <h3>If you made a portfolio from {activeStock} and another asset...</h3>
                <p>
                  By combining assets together in the right way, you can shape your portfolio's
                  performance and risk to suit you exactly as you want. Look at how this asset
                  changes, when only combined with one other asset (in equal amounts). All stats
                  shown for the past year of history.
                </p>
              </Col>
              <FolioCombo ticker={activeStock} rando={randomTicker1} />
              <FolioCombo ticker={activeStock} rando={randomTicker2} />
              <FolioCombo ticker={activeStock} rando={randomTicker3} />
              <Col sm={12}>
                <Button onClick={reloadFolios}>
                  <FontAwesomeIcon icon={['far', 'redo']} size={'sm'} fixedWidth /> Load More Asset
                  Combos
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}
