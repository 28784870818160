import React, { useCallback, useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { get, find } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import { amplitudeClient } from '../helpers/Analytics';
import useProfile from '../hooks/UseProfile';
import { addBlankPortfolio } from '../helpers/Firebase';

function PortfolioDropdown({ value }) {
  const history = useHistory();
  const { portfolioList, userID } = useProfile();
  const onChangePortfolio = useCallback((key) => {
    if (key === 'add-new') {
      // add new blank portfolio go to new portfolio page
      addBlankPortfolio(userID).then((portfolioID) => {
        // amplitude tracking
        amplitudeClient.getInstance().logEvent('action_add-new-portfolio', {
          portfolioId: portfolioID,
        });
        history.push(`/p/${portfolioID}`);
      });
    } else {
      // amplitude tracking
      amplitudeClient.getInstance().logEvent('action_switch-portfolio', {
        portfolioId: key,
      });
      // just nav to the selected portfolio
      history.push(`/p/${key}`);
    }
  }, [history]);

  const portfolioName = useMemo(
    () => get(find(portfolioList, { id: value }), 'name'),
    [portfolioList, value],
  );

  return (
    <Dropdown
      onSelect={onChangePortfolio}
    >
      <Dropdown.Toggle variant="btn btn-link" className="fs-3 fw-bold text-white ps-0">
        {portfolioName}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {portfolioList.map((port) => (
          <Dropdown.Item
            active={port.id === value ? true : undefined}
            key={port.id}
            eventKey={port.id}
          >
            {port.name}
          </Dropdown.Item>
        ))}
        {portfolioList.length > 1 && <Dropdown.Divider />}
        <Dropdown.Item active={false} key="add-new" eventKey="add-new">
          Add New Portfolio
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PortfolioDropdown;
