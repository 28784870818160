import useContainerWidth from './useContainerWidth';

const useDimensions = ({ maxHeight, margin = {}, scaleCoef = 0.5 }) => {
  const { ref, width } = useContainerWidth();
  const hMargin = (margin.left || 0) + (margin.right || 0);
  const vMargin = (margin.top || 0) - (margin.bottom || 0);
  const height = !maxHeight || width * scaleCoef < maxHeight ? width * scaleCoef : maxHeight;
  const innerWidth = width - hMargin;
  const innerHeight = height - vMargin;

  return [
    ref,
    {
      svgWidth: width,
      svgHeight: height,
      width: innerWidth,
      height: innerHeight,
    },
  ];
};

export default useDimensions;
