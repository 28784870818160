module.exports = {
  siteTitle: 'RiskSmith', // Site title for SEO
  siteTitleAlt: 'RiskSmith', // This allows an alternative site title for SEO schema.
  publisher: 'RiskSmith', // Organization name used for SEO schema
  siteDescription:
    "Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.",
  siteKeywords: 'investing, risk management, histograms, risk',
  siteUrl: 'https://app.risksmith.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'RiskSmith', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.risksmith.com', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: 'risksmithhq', // Change for Twitter Cards
  shortTitle: 'RiskSmith', // Used for App manifest e.g. Mobile Home Screen
  shareTitle: 'RiskSmith', // Open Graph Default Title
  shareDescription:
    "Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.", // Open Graph Default Description
  shareImage: '/og-shape-of-risk.png', // Open Graph Default Share Image. 1200x630 is recommended
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 630, // Change to the height of your default share image
  siteLogo: '/logo512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#000000', // Used for Offline Manifest
  themeColor: '#FFFFFF', // Used for Offline Manifest
  copyright: 'Copyright © 2022 RiskSmith', // Copyright string for the RSS feed
  socialMedia: [
    {
      name: 'instagram',
      url: '',
    },
    {
      name: 'twitter',
      url: '',
    },
    {
      name: 'linkedin',
      url: '',
    },
    {
      name: 'gitHub',
      url: '',
    },
    {
      name: 'email',
      url: '',
    },
  ],
};
