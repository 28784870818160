import React from 'react';
// hooks
import useProfile from '../../hooks/UseProfile';
// components
import SectionPreferences from './SectionPreferences';
import SectionPortfolios from './SectionPortfolios';
import SectionInstitutions from './SectionInstitutions';
import SectionNotifications from './SectionNotifications';

export default function UserProfile() {
  // get current user profile context
  const {
    userID,
    userName,
    userEmail,
    userPhone,
    userUpdated,
    portfolioList,
    institutionList,
    isAlphaTester,
  } = useProfile();

  return (
    <>
      <SectionPortfolios portfolioList={portfolioList} />

      <SectionPreferences
        userID={userID}
        userName={userName}
        userEmail={userEmail}
        userPhone={userPhone}
        userUpdated={userUpdated}
      />

      {isAlphaTester && <SectionInstitutions institutionList={institutionList} />}

      <SectionNotifications
        userID={userID}
      />
    </>
  );
}
