const getShares = (portfolioStocks) => {
  const assetShares = {};
  portfolioStocks.forEach((stock) => {
    const { symbol, shares: stockShares } = stock;
    assetShares[symbol] = {
      equal: stockShares || 1,
      weighted: stockShares || 1,
    };
  });
  return assetShares;
};

export default getShares;
