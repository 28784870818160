import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';

export default function BenchmarkBadge({ benchmark, onClickBenchmark, isActive }) {
  const badgeStyles = {
    active: {
      border: `1px solid ${benchmark.color}`,
      backgroundColor: `${benchmark.color}`,
      cursor: 'pointer',
    },
    inactive: {
      border: '1px solid #333',
      backgroundColor: 'rgba(255,255,255,0.1)',
      color: '#777',
      cursor: 'pointer',
    },
  };

  // if the light color, change the text color
  if (benchmark.color === '#f1f1e6') badgeStyles.active.color = '#333333';

  const style = isActive ? badgeStyles.active : badgeStyles.inactive;

  return (
    <Badge pill='true' bg='' onClick={onClickBenchmark} style={style} className='ms-2'>
      {benchmark.name}
    </Badge>
  );
}
