import React, {
  useGlobal,
  useState,
  useEffect,
  useCallback,
} from 'reactn';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import LogRocket from 'logrocket';
import amplitude from 'amplitude-js';
import { isEqual } from 'lodash';
// data
import { db } from '../config/firebase';
// hooks
import { addPortfolioStock } from '../helpers/Firebase';
// helpers
import { SAMPLE_PORTFOLIO_STOCKS } from '../helpers/Constants';
import { setLocalStorageValue } from '../helpers/LocalStorage';

export const ProfileContext = React.createContext({
  collectionList: null,
  currentContextPortfolioID: null,
  institutionCount: 0,
  institutionList: null,
  investorBullpen: null,
  notificationChannelPreferences: null,
  portfolioID: null,
  portfolioName: null,
  portfolioUpdated: null,
  riskHorizon: null,
  riskTolerance: null,
  stockCount: 0,
  stockList: null,
  updateContextPortfolioID: () => {},
  updateWeighting: null,
  userEmail: null,
  userID: null,
  userIsAdmin: null,
  userName: null,
  userPhone: null,
  userUpdated: null,
  weighting: null,
});

export default function ProfileProvider({ children }) {
  // get history API from router
  const history = useHistory();
  const [currentUser, setCurrentUser] = useGlobal('currentUser');
  const [isUserReady, setIsUserReady] = useState(false);
  const [isPortfolioReady, setIsPortfolioReady] = useState(false);

  const [userID, setUserID] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userUpdated, setUserUpdated] = useState('');
  const [userIsAdmin, setUserIsAdmin] = useState('');

  const [isUserAdmin, setIsUserAdmin] = useState();
  const [isAlphaTester, setIsAlphaTester] = useState(false);
  const [isBetaTester, setIsBetaTester] = useState(false);

  const [user, setUser] = useState();
  const [riskTolerance, setRiskTolerance] = useState('');
  const [riskHorizon, setRiskHorizon] = useState('');
  const [investorBullpen, setInvestorBullpen] = useState([]);

  const [currentContextPortfolioID, setCurrentContextPortfolioID] = useState(null);
  const [portfolioList, setPortfolioList] = useState([]);
  const [portfolioCount, setPortfolioCount] = useState(0);

  const [stockList, setStockList] = useState([]);
  const [stockCount, setStockCount] = useState(0);

  const [institutionList, setInstitutionList] = useState([]);
  const [institutionCount, setInstitutionCount] = useState(0);

  const [collectionList, setCollectionList] = useState([]);

  const [weighting, setWeighting] = useState('');

  const contextValue = {
    collectionList,
    currentContextPortfolioID,
    getCurrentPortfolio: useCallback(() => getCurrentPortfolio(), []),
    institutionCount,
    institutionList,
    investorBullpen,
    isAlphaTester,
    isBetaTester,
    isUserAdmin,
    notificationChannelPreferences: (user ? user.notificationChannelPreferences || {} : null),
    portfolioCount,
    portfolioList,
    riskHorizon,
    riskTolerance,
    stockCount,
    stockList,
    updateContextPortfolioID: useCallback((pid) => setCurrentContextPortfolioID(pid), []),
    updateWeighting: useCallback((weight) => setWeighting(weight), []),
    userEmail,
    userID,
    userIsAdmin,
    userName,
    userPhone,
    userUpdated,
    weighting,
  };

  /**
   * Set check in date local storage
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {String} key - local storage key
   * @param {String} ttl - time to life in milliseconds
   * @returns - none
   */
  const setCheckInExpiry = (key, ttl) => {
    const now = new Date();
    // the time when it's supposed to expire
    const expires = now.getTime() + ttl;
    // set data to local storage
    setLocalStorageValue(key, expires);
  };

  // creates a new empty account in database
  const stubNewAccount = (uid) => {
    // stub user doc
    const userDocRef = doc(db, 'users', uid);
    setDoc(userDocRef, {
      name: currentUser.displayName,
      email: currentUser.email,
      phone: currentUser.phoneNumber,
      createdAt: new Date(),
      updatedAt: new Date(),
      investorBullpen: [],
    })
      .then(() => {
        console.log('NEW USER PROFILE CREATED SUCCESSFULLY', userDocRef.id);
        setIsUserReady(true);
        // if (currentUser.email) {
        // currentUser.sendEmailVerification()
        // .then(() => { console.log('verification email sent 🙌'); })
        // .catch((error) => { console.log('verification email NOT sent 👹'); });
        // }
        // stub the intitial portfolio doc
        const batch = writeBatch(db);
        const userPortfolioRef = doc(collection(db, 'portfolios'));

        batch.set(userPortfolioRef, {
          claimed: true,
          owner: uid,
          name: 'Sample Portfolio',
          isReal: false,
          goal: '',
          totalAmountInvested: 10000,
          riskHorizon: '',
          riskTolerance: '',
          createdAt: new Date(),
          updatedAt: new Date(),
        });

        setCheckInExpiry(`RS-${userPortfolioRef.id}`, 600000);
        SAMPLE_PORTFOLIO_STOCKS.forEach((stock) => {
          const newPortfolioStockRef = doc(collection(userPortfolioRef, 'stocks'));

          batch.set(
            newPortfolioStockRef,
            {
              symbol: stock,
              createdAt: new Date(),
              updatedAt: new Date(),
            },
            // merge the data vs overwrite it
            { merge: true }
          );
        });

        batch.commit().then(() => {
          setIsPortfolioReady(true);
        });
      });
  };

  // const updateContextPortfolioID = (pid) => {
  //   setCurrentContextPortfolioID({ pid });
  // };

  // const updateWeighting = (weight) => setWeighting(weight);

  // when we load the first time, get the portfolio
  // useEffect(() => {
  //   if (currentContextPortfolioID !== null) {
  //     console.log('portfolio ready', currentContextPortfolioID);
  //     setIsPortfolioReady(true);
  //   }
  // }, [currentContextPortfolioID]);

  // this effect is run when currentUser is updated
  useEffect(() => {
    if (currentUser !== null) {
      // console.log('USER', currentUser);
      const { uid, email, displayName } = currentUser;
      setUserID(uid);
      setUserEmail(email);

      // This will allow us to search in LogRocket for
      // specific user sessions by name, email and ID
      // only in preview and production environments
      if (process.env.NODE_ENV !== 'development') {
        LogRocket.identify(uid, {
          name: displayName,
          email,
        });
      }

      // add the currentUser ID to Amplitude
      amplitude.getInstance().setUserId(uid);

      currentUser
        .getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.isUserAdmin) {
            // console.log('is user admin?', idTokenResult.claims.isUserAdmin);
            setIsUserAdmin(true);
          } else {
            setIsUserAdmin(false);
          }

          if (idTokenResult.claims.isAlphaTester) {
            // console.log('is alpha tester?', idTokenResult.claims.isAlphaTester);
            setIsAlphaTester(true);
          }
          if (idTokenResult.claims.isBetaTester) {
            // console.log('is beta tester?', idTokenResult.claims.isBetaTester);
            setIsBetaTester(true);
          }
        })
        .catch((error) => {
          console.log('TOKEN ERROR', error);
        });

      // !! setting the database listeners to variables
      // !! so we can dispose of them when the component is removed

      // get and store the User Profile data to state
      const unsubscribeThisUser = onSnapshot(
        doc(db, 'users', uid),
        (userSnapshot) => {
          const nextUser = userSnapshot.data();
          setUser(nextUser);
          // based on snapshot value decide if user is new or returning
          if (nextUser !== undefined) {
            // user is known and returning
            setUserName(nextUser.name);
            setUserPhone(nextUser.phone);
            const userDate = nextUser.updatedAt.toDate().toDateString();
            const userTime = nextUser.updatedAt.toDate().toLocaleTimeString('en-US');
            setUserUpdated(`${userDate} @ ${userTime}`);
            const isAdmin = nextUser.admin === true;
            setUserIsAdmin(isAdmin);
            setRiskTolerance(nextUser.riskTolerance);
            setRiskHorizon(nextUser.riskHorizon);
            setInvestorBullpen(nextUser.investorBullpen);
            //  update user global state
            const globalUser = currentUser;
            globalUser.portfolioViewMode = nextUser.portfolioViewMode;
            setCurrentUser(globalUser);
          } else {
            // user is brand new, stub an empty user collections and documents
            stubNewAccount(uid);
          }
        },
        (err) => {
          console.log('user snapshot listen error', err.message);
        }
      );

      // get and store the portfolio data
      // update current portfolio ID
      const portfoliosQuery = query(
        collection(db, 'portfolios'),
        where('owner', '==', uid),
        where('claimed', '==', true),
        orderBy('name'),
      );
      const unsubscribeThisUserPortfolios = onSnapshot(
        portfoliosQuery,
        (portfoliosSnapshot) => {
          if (portfoliosSnapshot.docs !== undefined) {
            const pList = portfoliosSnapshot.docs.map((portfolio) => ({
              id: portfolio.id,
              ...portfolio.data(),
            }));

            if (!isEqual(pList, portfolioList)) {
              setPortfolioList(pList);
              setPortfolioCount(portfoliosSnapshot.docs.length);
            }
          } else {
            // there are no portfolios yet
            console.log('no portfolios yet');
          }
        },
        (err) => {
          console.log('portfolios snapshot listen error', err.message);
        },
      );

      // get and store the collection data
      // update current collection ID
      const collectionsQuery = query(
        collection(db, 'collections'),
        where('owner', '==', uid),
        where('claimed', '==', true),
        orderBy('name'),
      );
      const unsubscribeThisUserCollections = onSnapshot(
        collectionsQuery,
        (collectionsSnapshot) => {
          if (collectionsSnapshot.docs !== undefined) {
            const cList = collectionsSnapshot.docs.map((d) => ({
              id: d.id,
              ...d.data(),
            }));

            if (!isEqual(cList, collectionList)) {
              setCollectionList(cList);
            }
          } else {
            // there are no collections yet
            console.log('no collections yet');
          }
        },
        (err) => {
          console.log('collections snapshot listen error', err.message);
        }
      );

      // get and store the Plaid institution data
      const unsubscribeThisUserInstitutions = onSnapshot(
        query(
          collection(db, 'institutions'),
          where('owner', '==', uid),
          orderBy('name'),
        ),
        (institutionSnapshot) => {
          if (institutionSnapshot.docs !== undefined) {
            const iList = institutionSnapshot.docs.map((d) => ({
              id: d.id,
              ...d.data(),
            }));
            const iCount = institutionSnapshot.docs.length;

            if (!isEqual(iList, institutionList)) {
              setInstitutionList(iList);
              setInstitutionCount(iCount);
            }
          } else {
            // there are no institutions yet
            console.log('no institutions yet');
          }
        },
        (err) => {
          console.log('institutions snapshot listen error', err);
        }
      );

      // when the component is unloaded, cleanup any listeners to the database
      // otherwise we could have memory leaks, event callbacks will continue to fire
      return () => {
        console.log('unsubscribe user, portfolios, colections, and institutions');
        unsubscribeThisUser();
        unsubscribeThisUserPortfolios();
        unsubscribeThisUserCollections();
        unsubscribeThisUserInstitutions();
      };
    }
  }, [currentUser]);

  // this effect runs when the currentContextPortfolioID is updated
  // refreshes the stockList when that happens
  useEffect(() => {
    if (currentContextPortfolioID !== null) {
      // get the stocks for the portfolio
      const stocksQuery = query(
        doc(db, 'portfolios', currentContextPortfolioID, 'stocks'),
        orderBy('symbol'),
      );
      const unsubscribeTheseStocks = onSnapshot(
        stocksQuery,
        (stocksSnapshot) => {
          if (stocksSnapshot.docs !== undefined) {
            const sList = stocksSnapshot.docs.map((d) => ({
              id: d.id,
              ...d.data(),
            }));
            const sCount = stocksSnapshot.docs.length;

            if (!isEqual(sList, stockList)) {
              setStockList(sList);
              setStockCount(sCount);
            }
          } else {
            // there are no stocks yet
            console.log('no stocks yet');
          }
        },
        (err) => {
          console.log('stocks snapshot listen error', err);
        }
      );

      // when the component is unloaded, cleanup any listeners to the database
      // otherwise we could have memory leaks, event callbacks will continue to fire
      return () => {
        console.log('unsubscribe stocks');
        unsubscribeTheseStocks();
      };
    }
  }, [currentContextPortfolioID]);

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
}
