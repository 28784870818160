import React, { useRef, useState, useEffect } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
// helpers
import { MultiLineDataPropTypes } from '../../helpers/propTypes';
// hooks
import useController from '../../hooks/useController';
import useDimensions from '../../hooks/useDimensions';
// d3 components
import { Line, Axis, GridLine, Overlay, Tooltip, Area } from './components';

const MultilineChart = ({
  data = [],
  dailyHistory = {},
  showNeutral = false,
  showStartingPrice = false,
  yValue = '',
  margin = {},
  portfolioStartingValue = 10000,
  benchmarks,
  yOverrides = null,
  showDScore = false,
  chartName,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const dailyData = [];
  dailyHistory.history.forEach((day) => {
    if (typeof day.date === 'object') dailyData.push({ day });
    if (typeof day.date === 'string') dailyData.push({ ...day, date: new Date(day.date) });
  });

  const end = DateTime.fromJSDate(dailyData[dailyData.length - 1].date);
  const start = DateTime.fromJSDate(dailyData[0].date);
  const diffInMonths = end.diff(start, 'months');
  const numberOfMonths = parseInt(diffInMonths.toObject().months.toFixed(0), 10);

  const isChangePercentLine = yValue === 'changePercent';
  const yDataType = isChangePercentLine ? (d) => d.changePercent * 100 : (d) => d[yValue];
  const normalizeBenchmarkData = (benchmarkHistory) => {
    const percentageAdjustment = yDataType(dailyData[0]) / yDataType(benchmarkHistory[0]);
    const normalizedBenchmarkHistory = benchmarkHistory.map((h) => ({
      ...h,
      closePrice: h.closePrice * percentageAdjustment,
      date: new Date(h.date),
    }));
    // console.log('normalizedBenchmarkHistory', normalizedBenchmarkHistory);
    return normalizedBenchmarkHistory;
  };

  const overlayRef = useRef(null);
  const [containerRef, { svgWidth, svgHeight, width, height }] = useDimensions({
    maxHeight: 200,
    margin,
    // scaleCoef: 1,
  });

  // iterate over benchmarks and add them to the combinedData array
  const combinedData = [...dailyData];
  if (benchmarks) {
    Object.keys(benchmarks).forEach((benchmark) => {
      // console.log('benchmark', benchmarks[benchmark].history);
      combinedData.push(...normalizeBenchmarkData(benchmarks[benchmark].history));
    });
    // console.log('combinedData', combinedData);
  }

  const controller = useController({
    combinedData,
    yValue,
    width,
    height,
    portfolioStartingValue,
    indentX: true,
    yMinOverride: yOverrides !== null ? yOverrides.min : null,
    yMaxOverride: yOverrides !== null ? yOverrides.max : null,
  });
  const { yTickFormat, xTickFormat, xScale, yScale, yScaleForAxis } = controller;

  // console.log('controller', yTickFormat, xTickFormat, xScale, yScale, yScaleForAxis);

  return (
    <div ref={containerRef}>
      <svg
        width={svgWidth}
        height={svgHeight}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onTouchStart={() => setIsHovering(true)}
        onTouchEnd={() => setIsHovering(false)}
      >
        <g transform={`translate(${margin.left},${margin.top})`}>
          <GridLine
            type='vertical'
            scale={xScale}
            ticks={numberOfMonths}
            size={height}
            transform={`translate(0, ${height})`}
            disableAnimation
          />
          <GridLine
            type='horizontal'
            scale={yScaleForAxis}
            ticks={2}
            size={width}
            disableAnimation
          />
          {/* {showNeutral && (
            <GridLine
              type='horizontal'
              className='baseGridLine'
              scale={yScale}
              ticks={1}
              size={width}
              disableAnimation
            />
          )} */}
          {/* {showStartingPrice && (
            <GridLine
              type='horizontal'
              className='baseGridLine'
              scale={yScale}
              ticks={1}
              size={width}
              disableAnimation
            />
          )} */}
          <Line
            key={'portfolio'}
            className={'portfolio'}
            data={dailyData}
            xScale={xScale}
            yScale={yScale}
            yValue={yValue}
            color={'cornflowerblue'}
          />
          <Area
            data={dailyData}
            xScale={xScale}
            yValue={yValue}
            yScale={yScale}
            color='cornflowerblue'
          />
          {showDScore && isHovering && (
            <Line
              key={'dScore'}
              data={dailyData}
              yValue={yValue}
              xScale={xScale}
              yScale={yScale}
              color='#ECC344'
              dScore
            />
          )}
          {/* benchmarks loop */}
          {benchmarks &&
            benchmarks.map((benchmark) => (
              <Line
                key={benchmark.symbol}
                className={benchmark.symbol}
                data={normalizeBenchmarkData(benchmark.history)}
                xScale={xScale}
                yScale={yScale}
                yValue={yValue}
                color={benchmark.color}
              />
            ))}
          {/* {data.map(({ name, items = [], color }) => (
            <Line key={name} data={items} xScale={xScale} yScale={yScale} color={color} />
          ))} */}
          <Axis
            type='left'
            scale={yScaleForAxis}
            transform='translate(0, -10)'
            ticks={3}
            tickFormat={yTickFormat}
            disableAnimation
          />
          {height > 0 && (
            <Overlay ref={overlayRef} width={width} height={height}>
              <Axis
                type='bottom'
                className='axisX'
                anchorEl={overlayRef.current}
                scale={xScale}
                transform={`translate(10, ${height - height / 8})`}
                ticks={numberOfMonths}
                tickFormat={xTickFormat}
                disableAnimation
              />
              <Tooltip
                className='d3Tooltip'
                anchorEl={overlayRef.current}
                width={width}
                height={height}
                margin={margin}
                xScale={xScale}
                yScale={yScale}
                data={dailyData}
                yValue={yValue}
                benchmarks={benchmarks}
                portfolioStartingValue={portfolioStartingValue}
                chartName={chartName}
              />
            </Overlay>
          )}
          {isHovering && showDScore && (
            <text
              fill='#ECC344'
              fontSize={10}
              transform={`translate(${svgWidth / 2}, ${svgHeight - 20})`}
              textAnchor='middle'
            >
              Your dScore
            </text>
          )}
        </g>
      </svg>
    </div>
  );
};

MultilineChart.propTypes = {
  data: PropTypes.array,
  dailyHistory: MultiLineDataPropTypes,
  showNeutral: PropTypes.bool,
  showStartingPrice: PropTypes.bool,
  yValue: PropTypes.string,
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  portfolioStartingValue: PropTypes.number,
};

MultilineChart.defaultProps = {
  data: [],
  dailyHistory: {},
  showNeutral: false,
  showStartingPrice: false,
  yValue: '',
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  portfolioStartingValue: 10000,
};

export default MultilineChart;
