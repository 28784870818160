import { useState } from 'react';
import * as svgToPNG from 'd3-svg-to-png';
import { copyImageToClipboard } from 'copy-image-clipboard';
import * as uuid from 'uuid';
// hooks
import useToast from '../../hooks/UseToast';

const WithCopy = (Histogram) => (props) => {
  const [isHoveringComponent, setIsHoveringComponent] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  // add toast API for display to the user
  const { addToast } = useToast();

  const chartId = uuid.v4();

  // PNG Export
  const exportOptions = {
    download: false,
  };

  const onMouseLeave = () => {
    setIsHoveringComponent(false);
    setIsCopied(false);
  };

  const exportToPNG = () => {
    svgToPNG(`svg[data-uuid='${chartId}']`, 'histogram', exportOptions) // need a better way to target these
      .then((dataUrl) => {
        copyImageToClipboard(dataUrl)
          .then(() => {
            setIsCopied(true);
            addToast('Histogram Copied to Clipboard', '', 'info');
          })
          .catch((err) => {
            addToast('Unable to Copy to Clipboard.', err.message, 'error');
          });
      })
      .catch((err) => {
        console.log('ERR', err);
      });
  };

  const chartProps = {
    ...props,
    dataUUID: chartId,
  };

  return (
    <div
      className='chart-copy-container'
      onMouseEnter={() => setIsHoveringComponent(true)}
      onMouseLeave={onMouseLeave}
    >
      {isHoveringComponent && (
        <div className='chart-copy-overlay' onClick={exportToPNG}>
          {/* <p>{isCopied ? 'Copied!' : 'Click to copy'}</p> */}
        </div>
      )}
      <Histogram {...chartProps} />
    </div>
  );
};

export default WithCopy;
