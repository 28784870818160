import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
// hooks
import useQueryString from '../hooks/UseQueryString';
// media
import riskSmithMark from '../media/risksmith-mark.svg';

export default function NotFound() {
  // query params
  const queryParams = useQueryString();
  // body element class list
  const bodyClassList = document.body.classList;

  /**
   * Sets and removes the class on body element per route path
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    const newBodyClass = 'fourOhFour';

    // set className
    bodyClassList.add(newBodyClass);

    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_404-page', amplitudePayload);
    window.Appcues?.track('404');

    return () => {
      // remove className
      bodyClassList.remove(newBodyClass);
    };
  }, []);

  return (
    <>
      <Container className="d-flex align-items-center justify-content-center h-100">
        <div className='w-100 text-center' style={{ maxWidth: '420px', color: '#fff' }}>
          <h1>🧟‍♂️</h1>
          <p className='lead mb-0'>Page Not Found</p>
          <p className='mb-5'>
            <Link to='/'>Go Home</Link>
          </p>
          <img src={riskSmithMark} alt='RiskSmith Logo' height='32' width='32' className='logo' />
        </div>
      </Container>
    </>
  );
}
