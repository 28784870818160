import { useState, useEffect } from 'react';
import { Col, Card } from 'react-bootstrap';

export default function InvestmentDetail({
  accountKey,
  accountType,
  name,
  available,
  current,
  holdings,
  securities,
}) {
  const [securityData, setSecurityData] = useState([]);

  useEffect(() => {
    // check for holdings data for this account
    const filteredHoldings = holdings.filter((holding) => holding.account_id === accountKey);

    // if this account has holdings
    if (filteredHoldings !== []) {
      // for each loop through each filtered holding
      const filteredSecurities = [];
      filteredHoldings.forEach((holding) => {
        // loop over the securities array comparing security id's of each
        // eslint-disable-next-line no-restricted-syntax
        for (const sec of securities) {
          if (sec.security_id === holding.security_id) {
            filteredSecurities.push(sec);
          }
        }
      });
      // this will result in final array of filtered securities
      if (filteredSecurities !== []) {
        setSecurityData(filteredSecurities);
      }
    }

    return () => {
      // no cleanup
    };
    // fire this only when holdings and securities data is available
  }, [holdings, securities]);

  return (
    <>
      {accountType === 'investment' ? (
        <Col className='pb-4'>
          <Card className='h-100 account' key={accountKey}>
            <Card.Header>
              <Card.Title>{name}</Card.Title>
              <Card.Subtitle className='pb-1 text-muted'>
                {current && <>Current Balance: ${current.toFixed(2)}</>}
                {available && (
                  <>
                    <br />
                    Available Balance: ${available.toFixed(2)}
                  </>
                )}
              </Card.Subtitle>
            </Card.Header>
            <Card.Body>
              {securityData.length !== 0 ? (
                <>
                  {securityData.map((security) => (
                    <Card.Text key={security.security_id} id={security.security_id}>
                      {security.ticker_symbol} (${security.close_price})
                      <small className='text-muted'>
                        <strong>{security.name}</strong>
                      </small>
                    </Card.Text>
                  ))}
                </>
              ) : (
                <Card.Text className='text-muted'>No investment data ☹️</Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      ) : (
        ''
      )}
    </>
  );
}
