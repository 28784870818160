import react, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../media/histogram-loader.json';

export default function AppLoader() {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const stages = [
    'Loading Risksmith...',
    'Analyzing portfolio...',
    'Fetching returns...',
    'Calculating risk...',
    'Building charts...',
    'Almost ready...',
  ];

  const [stage, setStage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (stage < stages.length - 1) {
        setStage(stage + 1);
      }
    }, 4000);

    return () => {
      clearTimeout();
    };
  });

  return (
    <div className='app-loader d-flex w-100 h-100 flex-column align-items-center justify-content-center'>
      <Lottie height={200} width={200} options={lottieOptions} />
      <p>{stages[stage]}</p>
    </div>
  );
}
