import react, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import Axis from './components/Axis';

export default function Histogram({
  dailyHistory,
  dataUUID,
  height,
  onClick,
  onMouseEnter,
  onMouseLeave,
  showYesterday,
  width,
  hideAxis,
}) {
  const [isHovering, setIsHovering] = useState(false);

  // push the data from each extreme into the far end bins
  const data = dailyHistory.history.map((day) => {
    const adjustedDay = { ...day };
    if (day.changePercent > 0.05) adjustedDay.changePercent = 0.051;
    if (day.changePercent < -0.05) adjustedDay.changePercent = -0.051;
    return adjustedDay;
  });

  const dimensions = {
    width: width || 400,
    height: height || 130,
    margins: {
      top: 0,
      right: 10,
      bottom: 30,
      left: 10,
    },
  };

  dimensions.boundedWidth = dimensions.width - dimensions.margins.left - dimensions.margins.right;

  dimensions.boundedHeight = dimensions.height - dimensions.margins.top - dimensions.margins.bottom;

  const parseDate = d3.timeParse('%Y-%m-%d');

  const xAccessor = (d) => d.changePercent * 100; // convert to readable percent
  const yAccessor = (d) => d.length;

  const xScale = d3
    .scaleLinear()
    .domain([-5.5, 5.5]) // Need to set this domain to a fixed range (-5, 5) for the charts to be normalized
    .range([0, dimensions.boundedWidth]);

  const binGenerator = d3
    .bin()
    .domain(xScale.domain())
    .value(xAccessor)
    .thresholds([
      -5, -4.5, -4, -3.5, -3, -2.5, -2, -1.5, -1, -0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5,
    ]);

  const bins = binGenerator(data);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(bins, yAccessor)])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const barPadding = 1;

  const setFillColor = (d) => {
    if (d < 0) return '#F45D48';
    return '#1FBD60';
  };

  const yesterday = dailyHistory.history[dailyHistory.history.length - 1];

  if (!yesterday) {
    return null;
  }

  const yesterdayDate = dailyHistory.history[dailyHistory.history.length - 1].date;
  const todaysBin = bins.find((bin) => bin.find((dailyData) => dailyData.date === yesterdayDate));

  const tickFormatter = (d) => {
    if (d === 5) return '> 5%';
    if (d === -5) return '< -5%';
    return d;
  };

  return (
    <svg
      viewBox={`0 0 ${dimensions.boundedWidth} ${dimensions.height}`}
      className='histogram'
      data-uuid={dataUUID}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <g transform='translate(0, 0)'>
        {bins.map((rectData) => {
          const tintAmt = yAccessor(rectData) / d3.max(bins, yAccessor);
          const fillColor = setFillColor(rectData.x0);
          return (
            <rect
              key={rectData.x0}
              x={xScale(rectData.x0) + barPadding / 2 || 0}
              y={yScale(yAccessor(rectData))}
              rx={3}
              width={d3.max([0, xScale(rectData.x1) - xScale(rectData.x0) - barPadding])}
              height={dimensions.boundedHeight - yScale(yAccessor(rectData))}
              fill={fillColor}
              fillOpacity={tintAmt || 0}
            />
          );
        })}
        {!hideAxis && (
          <>
            <Axis
              type='bottom'
              scale={xScale}
              ticks={10}
              transform={`translate(0, ${dimensions.height - 30})`}
              tickFormat={tickFormatter}
              className='histo-bottom-axis'
            />
            <text
              fill='#666'
              fontSize={8}
              transform={`translate(${dimensions.width / 2}, ${dimensions.height - 2})`}
              textAnchor='middle'
            >
              percent change per day
            </text>
          </>
        )}
        {showYesterday && (
          <circle
            cx={xScale(todaysBin.x0) + (xScale(todaysBin.x1) - xScale(todaysBin.x0)) / 2}
            cy={yScale(2)}
            r={3}
            fill='#f1f1e6'
          />
        )}
      </g>
    </svg>
  );
}
