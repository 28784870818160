import React, { useGlobal, useMemo } from 'reactn';
import PropTypes from 'prop-types';
import EasyEdit, { Types } from 'react-easy-edit';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// hooks
import { updateCollectionName, updateCollectionNote } from '../../helpers/Firebase';
// components
import CollectionToolbar from './CollectionToolbar';
import CollectionAssets from './CollectionAssets';

function Collection({ collection }) {
  const [assetList] = useGlobal('collectionAssetList');
  const collectionSymbols = useMemo(() => assetList.map(({ symbol }) => symbol), [assetList]);

  // global cancel for now
  const cancel = () => {
    console.log('Cancelled');
  };

  /**
   * Update the collection name
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const handleUpdateCollectionName = (value) => {
    console.log('updating name to', value, 'for', collection.id);
    updateCollectionName(collection.id, value);
    amplitudeClient.getInstance().logEvent('action_update-collection-name', {
      collectionId: collection.id,
      name: value,
    });
    window.Appcues?.track('Update collection name');
  };

  /**
   * Update the collection note
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const handleUpdateCollectionNote = (value) => {
    updateCollectionNote(collection.id, value);
    amplitudeClient.getInstance().logEvent('action_update-collection-note', {
      collectionId: collection.id,
      note: value,
    });
    window.Appcues?.track('Update collection note');
  };

  return (
    <div className='collection'>
      <div className='collection__details'>
        <div className='collection__details-name'>
          <EasyEdit
            type={Types.TEXT}
            value={collection.name === '' ? null : collection.name}
            onSave={handleUpdateCollectionName}
            onCancel={cancel}
            saveButtonLabel='Save'
            cancelButtonLabel='Cancel'
            attributes={{ className: 'form-control' }}
            placeholder='Add collection name'
            saveOnBlur={true}
            allowEdit={true}
          />
        </div>
        <div className='collection__details-note'>
          <EasyEdit
            type={Types.TEXTAREA}
            value={collection.note === '' ? null : collection.note}
            onSave={handleUpdateCollectionNote}
            onCancel={cancel}
            saveButtonLabel='Save'
            cancelButtonLabel='Cancel'
            attributes={{ className: 'form-control' }}
            placeholder='Add a note about this collection...'
            saveOnBlur={true}
            allowEdit={true}
          />
        </div>
      </div>
      <CollectionToolbar
        collectionID={collection.id}
        collectionSymbols={collectionSymbols}
      />
      <CollectionAssets
        collectionID={collection.id}
        collectionSymbols={collectionSymbols}
      />
    </div>
  );
}

Collection.propTypes = {
  collection: PropTypes.shape({
    claimed: PropTypes.bool,
    createdAt: PropTypes.shape({
      nanoseconds: PropTypes.number,
      seconds: PropTypes.number,
    }),
    id: PropTypes.string,
    name: PropTypes.string,
    note: PropTypes.string,
    owner: PropTypes.string,
    public: PropTypes.shape({
      isPublic: PropTypes.bool,
    }),
    updatedAt: PropTypes.shape({
      nanoseconds: PropTypes.number,
      seconds: PropTypes.number,
    }),
  }),
};

Collection.defaultProps = {
  collection: {},
};

export default Collection;
