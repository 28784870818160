import React, { useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEmpty } from 'lodash';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import { playSound } from '../../helpers/Sounds';
// hooks
import { removeCollectionAsset, addPortfolioStock } from '../../helpers/Firebase';
import useProfile from '../../hooks/UseProfile';
import useSettings from '../../hooks/UseSettings';
// context
import CollectionContext from '../../contexts/CollectionContext';
// components
import Histogram from '../visuals/Histogram';
import CollectionAssetStat from './CollectionAssetStat';
import { getAssetHistory, getAssetDetails } from '../../helpers/DataServices';

const fetchAssetMeta = async (symbol, controller) => {
  const { data } = await getAssetHistory({ symbol: symbol.toUpperCase(), includeStats: true, controller });
  let yesterday;
  let growth;

  if (data.history) {
    yesterday = data.history[data.history.length - 1];
    if (yesterday) {
      growth = ((yesterday.closePrice / data.history[0].closePrice - 1) * 100).toFixed(2);
    }
  }

  try {
    const assetDetails = await getAssetDetails(symbol, controller);

    if (assetDetails.ticker) {
      return {
        ...data,
        ...assetDetails,
        growth,
        yesterday,
      };
    }
  } catch (e) {
    // NOOP
  }

  return {
    ...data,
    yesterday,
    growth,
  };
};

function CollectionAsset({ collectionID, asset, onUpdate }) {
  const { symbol, id } = asset;
  // get current user profile context
  const { userID, portfolioList } = useProfile();
  // get the app level settings context
  const { muted } = useSettings();
  const { portfolioID, portfolioSymbols } = useContext(CollectionContext);
  const [assetMeta, setAssetMeta] = useState({});

  // check if symbol matches any object.symbol in portfolioSymbols array
  const isItemInPortfolio = portfolioSymbols.find((item) => item.symbol === symbol);
  const updateAssetMeta = useCallback(async (controller) => {
    const nextAssetMeta = await fetchAssetMeta(symbol, controller);

    setAssetMeta(nextAssetMeta);
    onUpdate(symbol, nextAssetMeta);
  }, [onUpdate, symbol]);

  useEffect(() => {
    const controller = new AbortController();

    updateAssetMeta(controller);

    return () => {
      controller.abort();
    };
  }, [symbol, updateAssetMeta]);

  /**
   * Handle remove collection asset
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const onRemoveCollectionAsset = () => {
    if (!muted) {
      playSound('transition_down');
    }
    console.log('remove asset', id, 'from collection', collectionID);
    // amplitude tracking
    amplitudeClient.getInstance().logEvent('action_delete-collection-asset', {
      collectionID,
      asset: id,
      symbol,
    });
    window.Appcues?.track('Delete collection asset');
    // add new collection asset
    removeCollectionAsset(collectionID, id);
  };

  /**
   * Handle remove collection asset
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const onAddToPortfolio = () => {
    if (!muted) {
      playSound('transition_up');
    }
    console.log('add collection item to portfolio', portfolioID);
    // amplitude tracking
    amplitudeClient.getInstance().logEvent('action_add-collection-asset-to-portfolio', {
      portfolioID,
      collectionID,
      asset: id,
      symbol,
    });
    window.Appcues?.track('Add collection asset to portfolio');
    // add new collection asset
    addPortfolioStock(userID, portfolioID, symbol);
  };

  /**
   * Handle amplitude tracking
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const trackAssetViewWebsite = () => {
    amplitudeClient
      .getInstance()
      .logEvent('action_collection-asset-view-website', { collectionId: collectionID, symbol });
  };

  return (
    <>
      <tr className={isItemInPortfolio ? 'asset in-portfolio' : 'asset'}>
        <td className='asset__symbol'>
          <OverlayTrigger placement='top' delay={300} overlay={<Tooltip>Explore Asset</Tooltip>}>
            <Link to={`/explore/${symbol}`} onClick={trackAssetViewWebsite}>
              {symbol}
            </Link>
          </OverlayTrigger>
          <span>
            <small>{asset.name || ''}</small>
          </span>
        </td>
        <CollectionAssetStat
          stat={get(assetMeta, 'yesterday.stats.adjustedSortino', 0).toFixed(2) || 'N/A'}
        />
        <CollectionAssetStat
          showAsPercent={true}
          disableColoring={true}
          stat={(get(assetMeta, 'yesterday.stats.dScore', 0) * 100).toFixed(2) || 'N/A'}
        />
        <CollectionAssetStat
          showAsPercent={true}
          stat={assetMeta.growth || 'N/A'}
        />
        <CollectionAssetStat
          showAsPercent={true}
          stat={asset.correlation === null ? 'N/A' : (asset.correlation * 100).toFixed(2)}
          isWorking={asset.correlation === undefined}
          correlated={true}
        />
        <td className='asset__histo'>
          <Histogram
            dailyHistory={assetMeta.history ? assetMeta : { history: [] }}
            hideAxis
            showYesterday
          />
        </td>
        <td className='asset__delete'>
          <ButtonGroup>
            <OverlayTrigger placement='top' overlay={<Tooltip>Add to Portfolio</Tooltip>}>
              <Button
                className='btn-add'
                variant='link'
                size=''
                onClick={onAddToPortfolio}
                disabled={isItemInPortfolio}
              >
                <FontAwesomeIcon icon={['far', 'plus']} size={'sm'} fixedWidth />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top-end'
              overlay={<Tooltip>Remove from Collection</Tooltip>}
            >
              <Button variant='link' size='' onClick={onRemoveCollectionAsset}>
                <FontAwesomeIcon icon={['far', 'times']} size={'sm'} fixedWidth />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </td>
      </tr>
    </>
  );
}

CollectionAsset.propTypes = {
  collectionID: PropTypes.string,
  asset: PropTypes.shape({
    createdAt: PropTypes.shape({
      nanoseconds: PropTypes.number,
      seconds: PropTypes.number,
    }),
    id: PropTypes.string,
    symbol: PropTypes.string,
    updatedAt: PropTypes.shape({
      nanoseconds: PropTypes.number,
      seconds: PropTypes.number,
    }),
  }),
  onUpdate: PropTypes.func,
};

CollectionAsset.defaultProps = {
  collectionID: '',
  asset: {},
  onUpdate: () => null,
};

export default CollectionAsset;
