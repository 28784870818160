import { arrayOf, shape, string, date as dateProp, number } from 'prop-types';

export const MultiLineDataStatsPropTypes = shape({
  adjustedSortino: number,
  dScore: number,
  sortino: number,
  stdev: number,
});

export const MultiLineDataItemsPropTypes = arrayOf(
  shape({
    change: number,
    changePercent: number,
    closePrice: number,
    date: string,
    stats: MultiLineDataStatsPropTypes,
    symbol: string,
  })
);

export const MultiLineDataPropTypes = shape({
  symbol: string,
  history: MultiLineDataItemsPropTypes,
});
