import React from 'react';
import { buildClearbitURL, buildStockDataLogoURL } from '../../helpers/Clearbit';
import ImageWithFallback from './ImageWithFallback';
import acme from '../../media/rsicon-soft-sm.png';

/**
 * Handles all things company image -- Will prefer an explicitly provided `companyURL` and
 * `companyName` prop, but will otherwrise default to fuzzily finding the next best company
 * name, and company logo from the given sources (`investor`, `searchHit`, and `stockData`).
 *
 * @return Returns an `ImageWithFallback` instance, that will fallback to the global acme image.
 */
const CompanyImage = (props) => {
  const {
    companyURL,
    companyName,
    investor,
    searchHit,
    stockData,
    ...rest
  } = props;

  const nextCompanyName = (
    companyName
    || investor?.name
    || searchHit?.ticker
    || stockData?.name
    || stockData?.ticker
    || 'Company'
  );
  const src = (
    (companyURL && buildClearbitURL(companyURL))
    || (investor && buildClearbitURL(investor.website))
    || (searchHit && buildClearbitURL(searchHit.company_url))
    || (stockData && buildStockDataLogoURL(stockData))
  );

  return (
    <ImageWithFallback
      src={src}
      fallback={acme}
      alt={`${nextCompanyName} logo`}
      {...rest}
    />
  );
};

export default CompanyImage;
