/*
 * returns difference between two numbers in %
 * @param {a} 1 number
 * @param {b} 2 number
 */
export const relDiff = (a, b) => 100 * Math.abs((a - b) / ((a + b) / 2));

/*
 * returns formatted number
 * @param {x} number
 */
export const formatDollarsWithCommas = (x) => {
  if (typeof x !== 'undefined') {
    return x.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
};

export const toFriendlyDScore = (dScore) => (dScore ? (dScore * 100).toFixed(2) : '0.00');

export const fromFriendlyDScore = (dScore) => (+dScore / 100);

export const toFriendlyRiskEfficiency = (riskEfficiency) => (riskEfficiency ? riskEfficiency.toFixed(2) : '0.00');

export const toFriendlyGrowth = (growth) => `${growth.toFixed(2)}%`;
