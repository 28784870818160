/**
 * Builds a Clearbit logo url for valid urls, returns null otherwise
 * @param {url} string - the url of the company
 * @returns - string/null
 */
export const buildClearbitURL = (url) => {
  if (url && url !== '' && url !== 'N/A') {
    return `//logo.clearbit.com/${url}`;
  }

  return null;
};

/**
 * Builds a Clearbit logo url for valid urls, returns null otherwise
 * @param {stockData} object - the `stockData` for which we'll build a logo url
 * @returns - string/null
 */
export const buildStockDataLogoURL = (stockData) => {
  if (!stockData) {
    return null;
  }

  const { logo, company_url: companyURL } = stockData;

  return buildClearbitURL(companyURL) || logo || null;
};
