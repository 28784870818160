/* eslint-disable indent */
import React, { useState, useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EasyEdit, { Types } from 'react-easy-edit';
import NumberFormat from 'react-number-format';
import { DateTime } from 'luxon';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import { getAssetHistory } from '../../helpers/DataServices';
// hooks
import useToggle from '../../hooks/useToggle';
// components
import Loader from '../global/Loader';
import Tip from '../global/Tip';
import VisualCarousel from '../visuals/VisualCarousel';
import CompanyImage from '../global/CompanyImage';

export default function StockCardBody({
  allocation,
  hideShares,
  readOnly,
  isRealPortfolio,
  shares,
  stockData,
  symbol,
  updateShareCount,
}) {
  // state
  const [isActive, setIsActive] = useState(false);
  const [activeAsset, setActiveAsset] = useState({});
  const [isCrypto, setIsCrypto] = useState(stockData.type === 'crypto');
  const [assetLabel, setAssetLabel] = useState('shares');
  const [isMissingHistory, setIsMissingHistory] = useState(false);

  const [iconImgSrc, setIconImgSrc] = useState(null);

  // toggle
  const [shortAssetLength, toggleShortAssetLength] = useToggle(true);

  // global cancel for now
  const cancel = () => {
    console.log('Cancelled');
  };

  /**
   * Update the shares for a stock
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const handleUpdateShares = (value) => {
    // for some reason value is typeof string, even though field type is set as Number
    // updateShareCount(Number(value));
    updateShareCount(value);
    amplitudeClient.getInstance().logEvent('action_update-shares');
    window.Appcues?.track('Update share count on asset');
  };

  // handle amplitude tracking
  const trackAssetClick = () => {
    amplitudeClient
      .getInstance()
      .logEvent('action_shared-portfolio-select-asset', { asset: stockData.TICKER });
      window.Appcues?.track('Click asset on public portfolio');
  };

  /**
   * Get data for charts
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // get asset history
    const controller = new AbortController();
    getAssetHistory({ symbol: symbol.toUpperCase(), includeStats: true, controller })
      .then(({ data }) => {
        setActiveAsset(data);
        setIsActive(true);
        const firstDateInHistory = DateTime.fromISO(data.history[0].date.split('T')[0]).toMillis();
        const lastDateInHistory = DateTime.fromISO(
          data.history[data.history.length - 1].date.split('T')[0]
        ).toMillis();
        const firstExpectedDate = DateTime.fromISO(data.expectedTradingDates.start).toMillis();
        const lastExpectedDate = DateTime.fromISO(data.expectedTradingDates.end).toMillis();

        if (lastDateInHistory < lastExpectedDate) {
          setIsMissingHistory('dead');
          amplitudeClient.getInstance().logEvent('system_missing-history', {
            asset: stockData.ticker?.toUpperCase(),
            type: 'dead',
          });
        }
        if (firstDateInHistory > firstExpectedDate) {
          setIsMissingHistory('young');
          amplitudeClient.getInstance().logEvent('system_missing-history', {
            asset: stockData.ticker?.toUpperCase(),
            type: 'young',
          });
        }
        if (lastDateInHistory < lastExpectedDate && firstDateInHistory > firstExpectedDate) {
          setIsMissingHistory('truncated');
          amplitudeClient.getInstance().logEvent('system_missing-history', {
            asset: stockData.ticker?.toUpperCase(),
            type: 'truncated',
          });
        }
      })
      .catch((err) => console.log('FUCKED IT UP', err));

    return () => {
      controller.abort();
    };
  }, []);

  /**
   * Setup asset label based on crypto vs stock and singular vs plural
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (isCrypto) {
      setAssetLabel(stockData.ticker);
      return;
    }
    if (shares === 1) {
      setAssetLabel('Share');
    } else {
      setAssetLabel('Shares');
    }
  }, [shares]);

  // If we have a history issue, set up the messaging
  let historyMessage;
  switch (isMissingHistory) {
    case 'dead':
      historyMessage = `
        This asset stopped reporting data on 
        ${DateTime.fromISO(
          activeAsset.history[activeAsset.history.length - 1].date.split('T')[0]
        ).toLocaleString(DateTime.DATE_FULL)}
      `;
      break;

    case 'young':
      historyMessage = `
        This asset didn't start reporting data until
        ${DateTime.fromISO(activeAsset.history[0].date.split('T')[0]).toLocaleString(
          DateTime.DATE_FULL
        )}
      `;
      break;

    case 'truncated':
      historyMessage = `
        This asset only reported data from
        ${DateTime.fromISO(activeAsset.history[0].date.split('T')[0]).toLocaleString(
          DateTime.DATE_FULL
        )} to
        ${DateTime.fromISO(
          activeAsset.history[activeAsset.history.length - 1].date.split('T')[0]
        ).toLocaleString(DateTime.DATE_FULL)}
      `;
      break;

    default:
      break;
  }

  return (
    <Card.Body>
      {isMissingHistory && (
        <Tip
          contentfulId='27FGwrKh4gMNlwIOKegQIc'
          icon={['fas', 'history']}
          prefixContent={`${historyMessage}`}
          className='history-alert'
          trigger={['hover', 'focus']}
        />
      )}
      {allocation && (
        <span className='stock-alloc ml-auto p-2'>{allocation.toFixed(2)}%</span>
      )}
      <div className='company-icon-container'>
        <CompanyImage
          stockData={stockData}
          className='company-icon'
        />
      </div>
      {readOnly ? (
        <p className='text-symbol'>
          <Link to={`/explore/${stockData.ticker}`} onClick={trackAssetClick}>
            <strong>{stockData.ticker}</strong>
          </Link>
        </p>
      ) : (
        <p className='text-symbol'>
          <strong>{symbol}</strong>
        </p>
      )}
      <p className='text-name'>{stockData.name}</p>
      {!hideShares && (
        <div className='qty'>
          {isRealPortfolio ? (
            <>
              <EasyEdit
                type={Types.NUMBER}
                value={shares}
                onSave={handleUpdateShares}
                onCancel={cancel}
                saveButtonLabel='Save'
                cancelButtonLabel='Cancel'
                attributes={{ className: 'form-control' }}
                placeholder='Add'
                saveOnBlur={true}
                allowEdit={true}
              />{' '}
              <small className='asset-label'>{assetLabel}</small>
            </>
          ) : (
            <>
              <NumberFormat
                thousandsGroupStyle='thousand'
                value={shares}
                prefix=''
                decimalSeparator='.'
                displayType='text'
                type='text'
                thousandSeparator={true}
                allowNegative={true}
                decimalScale={shortAssetLength ? 4 : 16}
                style={{ cursor: 'pointer' }}
                onClick={toggleShortAssetLength}
              />{' '}
              <small className='asset-label'>{assetLabel}</small>
            </>
          )}
        </div>
      )}
      {isActive && <VisualCarousel asset={activeAsset} />}
      {!isActive && <Loader />}
    </Card.Body>
  );
}
