import React, { useLayoutEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const baseRect = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
};

/**
 * Use ResizeObserver to listen for the changes to the dimensions of an element
 * @params ref - React ref
 * @returns {object} - { width, height, top, left }
 */
const useResize = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  const { current: element } = ref;

  useLayoutEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserver((entries) => {
        // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => {
          entries.forEach((entry) => {
            setDimensions(entry.contentRect);
          });
        });
      });

      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, [element]);

  return dimensions || baseRect;
};

export default useResize;
