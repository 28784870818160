import React from 'react';
import { Button, Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// hooks
import useToast from '../../hooks/UseToast';

export default function ToastNotification() {
  const { toast, removeToast } = useToast();

  let toastIcon = 'exclamation-triangle';
  let toastAutoHide = false;
  let toastCloseButton = true;

  const currentDate = new Date();

  /**
   * Remove toast from context
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleSubmit = () => {
    removeToast();
  };

  if (toast) {
    switch (toast.type) {
      case 'info':
        toastIcon = 'info-circle';
        toastAutoHide = true;
        toastCloseButton = false;
        break;

      case 'success':
        toastIcon = 'badge-check';
        toastAutoHide = true;
        toastCloseButton = false;
        break;

      default:
        toastIcon = 'exclamation-triangle';
        toastAutoHide = false;
        toastCloseButton = true;
        break;
    }
  }

  return (
    <Toast
      style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
      }}
      // animation={false}
      show={!!toast}
      onClose={handleSubmit}
      role="alert"
      autohide={toastAutoHide}
      className={toast ? toast.type : ''}
    >
      {toast && toast.message && (
        <Toast.Header closeButton={toastCloseButton} closeVariant="white">
          <FontAwesomeIcon icon={['fas', toastIcon]} size="'lg" fixedWidth />
          <strong className="ms-2 me-auto">{toast.message}</strong>
          {toast && toast.status?.type === 'undo' && (
            <Button variant="light" onClick={(event) => toast.status.handler(event, toast.status.id)}>
              <FontAwesomeIcon icon={['far', 'undo-alt']} size="lg" fixedWidth />
            </Button>
          )}
          {toast && toast.type === 'error' && <small>{currentDate.toLocaleTimeString()}</small>}
        </Toast.Header>
      )}
      {toast && toast.status?.type !== 'undo' && toast.status && <Toast.Body>{toast.status}</Toast.Body>}
    </Toast>
  );
}
