import React, { useCallback, useEffect, useGlobal, useMemo, useState } from 'reactn';
import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
import { getPortfolioPL, getPortfolioAllocation } from '../helpers/DataServices';
import getShares from '../helpers/getShares';
// hooks
import useProfile from '../hooks/UseProfile';
import useQueryString from '../hooks/UseQueryString';
import useSettings from '../hooks/UseSettings';
// components
import Seo from '../components/global/Seo';
import AppLoader from '../components/global/AppLoader';
import Loader from '../components/global/Loader';
import TopNav from '../components/global/TopNav';
import PortfolioInfo from '../components/portfolio/PortfolioInfo';
import PortfolioToolbar from '../components/portfolio/PortfolioToolbar';
import StockCards from '../components/portfolio/StockCards';
import GetStarted from '../components/explore/GetStarted';
import Footer from '../components/explore/Footer';
import EmptyState from '../components/global/EmptyState';

// document.body.classList.add('portfolio');
// document.body.classList.add('portfolio', 'shared');

export default function SharedPortfolio({ activePortfolio, stockList }) {
  // router location
  const location = useLocation();
  // query params
  const queryParams = useQueryString();
  // get the current user
  const [currentUser] = useGlobal('currentUser');
  // get the app level settings context
  const { weighting } = useSettings();
  const history = useHistory();
  // loading flag
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  // how the allocations are being calculated
  const [allocationMethod, setAllocationMethod] = useState(weighting);
  // whether or not we're updating the PL with fresh data
  const [portfolioPL, setPortfolioPL] = useState([]);
  const [shares, setShares] = useState({});
  const [correlationMatrixData, setCorrelationMatrixData] = useState([]);
  const [assetViewMode, setAssetViewMode] = useState('grid');

  const { id: activePortfolioId, isReal: isActivePortfolioReal } = activePortfolio;
  const portfolioWithStocks = useMemo(() => ({
    ...activePortfolio,
    stocks: stockList,
  }), [activePortfolio, stockList]);
  const isStockListEmpty = stockList.length === 0;

  const bodyClassList = document.body.classList;

  // meta data for SEO
  const metaData = {
    title: 'Shared Portfolio',
    description:
      "Shared Portfolio -- Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.",
  };

  const fetchPL = async (symbols, allocations, stockShares) => {
    setLoading(true);
    setError(false);
    const { data: pl } = await getPortfolioPL({
      symbols,
      weights: allocations,
      shares: stockShares,
    });
    setLoading(false);
    if (pl[0]) {
      setShares(pl[0].shares);
      setPortfolioPL(pl);
    } else {
      setError(true);
    }
  };

  const onSetAllocationMethod = (method) => {
    setAllocationMethod(method);
  };

  // generate dependencies suited for shallow comparison to avoid calling `fetchPL` needlessly
  const { symbolsJSON, userSharesJSON } = useMemo(
    () => ({
      symbolsJSON: JSON.stringify(stockList.map(({ symbol }) => symbol)),
      userSharesJSON: JSON.stringify(getShares(stockList)),
    }),
    [stockList]
  );

  /**
   * Handler for when the user sets the asset view mode
   */
  const handleSetAssetViewMode = useCallback((mode) => setAssetViewMode(mode), [assetViewMode]);

  useEffect(async () => {
    if (!activePortfolioId) return;
    try {
      if (stockList.length === 0) return;
      const symbols = stockList.map((s) => s.symbol);
      const { data: alloc } = await getPortfolioAllocation(stockList.map((s) => s.symbol));
      if (isActivePortfolioReal) {
        fetchPL(symbols, null, getShares(stockList));
      } else {
        fetchPL(symbols, alloc);
      }
    } catch (err) {
      console.log('isActivePortfolioReal/stockList useEffect', err);
    }
  }, [activePortfolioId, symbolsJSON, userSharesJSON]);

  /**
   * Amplitude tracking
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    amplitudePayload.type = 'shared';
    amplitudePayload.portfolioId = activePortfolioId;

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_portfolio-page', amplitudePayload);
    window.Appcues?.track('View shared profile');
    // set className
    bodyClassList.add('portfolio', 'shared');

    return () => {
      // remove className
      bodyClassList.remove('portfolio', 'shared');
    };
  }, []);

  if (!portfolioPL) return <AppLoader />;

  // right now, this is fine, but later, may need shares by day passed in'
  let { totalAmountInvested } = activePortfolio;
  if (isActivePortfolioReal && portfolioPL[0]) {
    totalAmountInvested = portfolioPL[portfolioPL.length - 1].equal.closePrice;
  }

  return (
    <>
      {(!activePortfolio.public?.isPublic) && (
        <Redirect to='/404' />
      )}
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      <Container fluid>
        <Row>
          <TopNav cpid={activePortfolioId} promo={true} />
          <Col>
            {loading === true && (
              <Loader
                height='380px'
                message='Loading your portfolio stats, this can take a few seconds'
              />
            )}
            {error && (
              <EmptyState
                height='380px'
                iconName={['fas', 'exclamation-triangle']}
                message='We were unable to analyze your portfolio'
                submessage='Please try again later'
              />
            )}
            {!isStockListEmpty && portfolioPL[0] && loading === false && (
              <Container>
                <PortfolioInfo
                  portfolio={activePortfolio}
                  totalInvested={totalAmountInvested}
                  portfolioPL={portfolioPL}
                  allocationMethod={allocationMethod}
                  correlationMatrixData={correlationMatrixData}
                  readOnly
                />
              </Container>
            )}
            {!isStockListEmpty && portfolioPL.length > 0 && (
              <Container>
                <PortfolioToolbar
                  portfolio={activePortfolio}
                  onSetPortfolioWeighting={(method) => onSetAllocationMethod(method)}
                  portfolioPL={portfolioPL}
                  totalInvested={0}
                  stockList={stockList}
                  startingDate={portfolioPL[Math.ceil(portfolioPL.length / 2)].date}
                  hideModeToggle
                  hideAllocationDropdown={isActivePortfolioReal}
                  onSetAssetViewMode={handleSetAssetViewMode}
                  viewMode={assetViewMode}
                />
              </Container>
            )}
            <Container>
              <StockCards
                cpid={activePortfolioId}
                shares={shares}
                isWeighted={allocationMethod === 'volatility'}
                portfolio={portfolioWithStocks}
                readOnly
                hideShares
                viewMode={assetViewMode}
              />
              <GetStarted />
            </Container>
            <div className='footer'>
              <Container>
                <Footer />
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
