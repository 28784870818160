import react, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

import useWindowSize from '../../hooks/UseWindowSize';

export default function CorrelationMatrix({ data, width, height }) {
  const numRows = data.length;

  const fontScale = d3.scaleLinear().domain([1, 10]).range([14, 10]);

  const leftMarginScale = d3.scaleLinear().domain([10, 14]).range([60, 90]);

  const labelFontSize = fontScale(numRows);
  const leftMargin = leftMarginScale(labelFontSize);

  const dimensions = {
    width: width || 400,
    height: height || 400,
    margins: {
      top: 30,
      right: 0,
      bottom: 0,
      left: leftMargin,
    },
  };

  const symbols = data[0].map(({ symbol }) => symbol);
  const chartScaleX = d3.scaleBand().domain(symbols).range([0, dimensions.width]);

  const chartScaleY = d3.scaleBand().domain(symbols).range([0, dimensions.height]);

  const topAxis = d3.axisTop(chartScaleX);
  const leftAxis = d3.axisLeft(chartScaleY);

  dimensions.boundedWidth = dimensions.width - dimensions.margins.left - dimensions.margins.right;
  dimensions.boundedHeight = dimensions.height - dimensions.margins.top - dimensions.margins.bottom;

  const HorizontalLabels = ({ symbolsForChart }) => (
    <g>
      {symbolsForChart.map((symbol, i) => (
        <text
          key={`horizontal-label-${i}`}
          x={dimensions.margins.left + chartScaleX(symbol) + chartScaleX.bandwidth() / 2}
          y={20}
          textAnchor='middle'
          fontSize={labelFontSize}
          fontWeight='bold'
          fill='white'
        >
          {symbol}
        </text>
      ))}
    </g>
  );

  const VerticalLabels = ({ symbolsForChart }) => (
    <g>
      {symbolsForChart.map((symbol, i) => (
        <text
          key={`horizontal-label-${i}`}
          x={dimensions.margins.left - 10}
          y={5 + dimensions.margins.top + chartScaleY(symbol) + chartScaleY.bandwidth() / 2}
          fontSize={labelFontSize}
          fontWeight='bold'
          fill='white'
          textAnchor='end'
        >
          {symbol}
        </text>
      ))}
    </g>
  );

  return (
    <svg
      height={dimensions.height + dimensions.margins.top}
      width={dimensions.width + dimensions.margins.left}
    >
      <HorizontalLabels symbolsForChart={symbols} />
      <VerticalLabels symbolsForChart={symbols} />
      <g transform={`translate(${dimensions.margins.left}, 30)`}>
        {data.map((row, rowIndex) =>
          row.map(({ symbol, correlation }, columnIndex) => {
            const color = '#1FBD60';
            return (
              <g key={`g-${rowIndex}-${columnIndex}`}>
                <rect
                  key={`rect-${rowIndex}-${columnIndex}`}
                  x={chartScaleX(symbol)}
                  y={rowIndex * chartScaleY.bandwidth()}
                  width={chartScaleX.bandwidth()}
                  height={chartScaleY.bandwidth()}
                  fill={color}
                  fillOpacity={correlation / 1}
                />
                <text
                  key={`text-${rowIndex}-${columnIndex}`}
                  x={chartScaleX(symbol) + chartScaleX.bandwidth() / 2}
                  y={5 + rowIndex * chartScaleY.bandwidth() + chartScaleY.bandwidth() / 2}
                  textAnchor='middle'
                  fontSize={labelFontSize}
                  fill='white'
                >
                  {correlation === 1
                    ? `${(correlation * 100).toFixed(0)}%`
                    : `${(correlation * 100).toFixed(1)}%`}
                </text>
              </g>
            );
          })
        )}
        ;
      </g>
    </svg>
  );
}
