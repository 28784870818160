import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import { playSound } from '../../helpers/Sounds';
// hooks
import { addCollectionAsset } from '../../helpers/Firebase';
import useSettings from '../../hooks/UseSettings';
// components
import CollectionToolbarSearchItemStat from './CollectionToolbarSearchItemStat';

const assetTypeLabelByType = {
  stock: 'Stock',
  crypto: 'Crypto',
};

function CollectionToolbarSearchItem({ hit, components, collectionID, collectionSymbols }) {
  // get the app level settings context
  const { muted } = useSettings();

  // check if hit.ticker matches any of the items in collectionSymbols
  const symbolInCollection = collectionSymbols.includes(hit.ticker);

  // format data and text for display
  const [growth, setGrowth] = useState(false);
  const [dScore, setDScore] = useState(false);
  const [riskEfficiency, setRiskEfficiency] = useState(false);
  const assetType = assetTypeLabelByType[hit.type] || 'N/A';

  useEffect(() => {
    if (hit.riskEfficiency !== null) {
      setRiskEfficiency(hit.riskEfficiency.toFixed(2));
    }
    if (hit.dScore !== null) {
      setDScore((hit.dScore * 100).toFixed(2));
    }

    // if (hit.growth !== null) {
    //   setGrowth((hit.growth * 100).toFixed(2));
    // }

    return () => {};
  }, [hit]);

  /**
   * Add new asset to collection
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const addAsset = async (e) => {
    e.preventDefault();
    if (!muted) {
      playSound('button');
    }
    // add new collection
    await addCollectionAsset(collectionID, hit.ticker).then((newCollectionAssetID) => {
      // amplitude tracking
      amplitudeClient.getInstance().logEvent('action_add-new-collection-asset', {
        collectionId: collectionID,
        asset: newCollectionAssetID,
        symbol: hit.ticker,
      });
      window.Appcues?.track('Add new asset to collection');
      console.log('collection asset created', newCollectionAssetID);
    });
  };

  if (symbolInCollection) {
    return (
      <div className='aa-ItemWrapper in-collection'>
        <div className='aa-ItemContent'>
          <div className='aa-ItemContentBody'>
            <div className='aa-ItemContentTitle'>
              {hit.name}
              {hit.ticker && <strong> ({hit.ticker})</strong>}
            </div>
          </div>
        </div>
        {symbolInCollection && <div className='aa-ItemActions'>{assetType} added</div>}
      </div>
    );
  }

  return (
    <a href='#' className='aa-ItemLink' onClick={addAsset}>
      <div className='aa-ItemContent'>
        <div className='aa-ItemContentBody'>
          <div className='aa-ItemContentTitle'>
            {/* {components.Highlight({ hit, attribute: 'name' })}
            {hit.name && ' ('}
            {components.Highlight({ hit, attribute: 'ticker' })}
            {hit.name && ')'} */}
            {hit.name}
            {hit.ticker && <strong> ({hit.ticker})</strong>}
          </div>
          <div className='aa-ItemContentSubtitle'>
            {riskEfficiency && (
              <CollectionToolbarSearchItemStat
                label='RE'
                stat={riskEfficiency}
                showAsPercent={false}
                disableColoring={false}
              />
            )}
            {dScore && (
              <CollectionToolbarSearchItemStat
                label='DS'
                stat={dScore}
                showAsPercent={true}
                disableColoring={true}
              />
            )}
            {growth && (
              <CollectionToolbarSearchItemStat
                label='G'
                stat={growth}
                showAsPercent={true}
                disableColoring={false}
              />
            )}
          </div>
          {/* <div className='aa-ItemContentSubtitle'>
            {assetType}
            {hit.sector && ` - ${hit.sector}`}
          </div>
          <div className='aa-ItemContentDescription'>
            RE: {riskEfficiency || '--'} | DS: {`${dScore}%` || '--'}
          </div> */}
        </div>
      </div>
      {symbolInCollection && <div className='aa-ItemActions'>{assetType} added</div>}
    </a>
  );
}

CollectionToolbarSearchItem.propTypes = {
  hit: PropTypes.object,
  components: PropTypes.object,
  collectionID: PropTypes.string,
  collectionSymbols: PropTypes.array,
};

CollectionToolbarSearchItem.defaultProps = {
  hit: {},
  components: {},
  collectionID: '',
  collectionSymbols: [],
};

export default CollectionToolbarSearchItem;
