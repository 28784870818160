import React, { useEffect } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Redirect, useLocation } from 'react-router-dom';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
// hooks
import useProfile from '../hooks/UseProfile';
import useQueryString from '../hooks/UseQueryString';
// components
import Seo from '../components/global/Seo';
import SideNav from '../components/global/SideNav';
import Layout from '../components/Layout';
import UserManagement from '../components/tools/UserManagement';

export default function toolsDashboard() {
  const location = useLocation();
  const queryParams = useQueryString();
  const { isUserAdmin, userEmail } = useProfile();
  // meta data for SEO
  const metaData = {
    title: 'Tools',
    description:
      "Tools -- Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.",
  };
  const isReady = (userEmail && isUserAdmin !== undefined);
  const isAuthorized = (
    isUserAdmin === true
    || (userEmail && userEmail.endsWith('risksmith.com'))
  );

  /**
   * Amplitude tracking
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_tools-page', amplitudePayload);
    window.Appcues?.track('View tools');

    return () => {};
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <>
      {isReady && !isAuthorized && <Redirect to="/profile" />}
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO noIndex />
      <Layout>
        <Container fluid>
          <Row>
            <Col sm={12}>
              <UserManagement />
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
