import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Hooks
import useProfile from '../../hooks/UseProfile';
import { setPortfolioMode } from '../../helpers/Firebase';
// Helpers
import { formatDollarsWithCommas } from '../../helpers/Numbers';
import { amplitudeClient } from '../../helpers/Analytics';

export default function PortfolioTypeToggle({ portfolio, amountInvested, startingDate }) {
  const { userID } = useProfile();
  const intl = useIntl();

  const [isReal, setIsReal] = useState(portfolio && portfolio.isReal);

  const mockText = `${formatDollarsWithCommas(amountInvested)} since ${intl.formatDate(
    startingDate
  )}`;
  const realText = `${formatDollarsWithCommas(amountInvested)} since ${intl.formatDate(
    startingDate
  )}`;
  // const realText = 'Based on your actual portfolio holdings';

  const switchLabel = isReal ? 'MANAGED PORTFOLIO' : 'SIMULATED PORTFOLIO';

  const onChangePortfolioType = () => {
    setIsReal(!isReal);
    setPortfolioMode(userID, portfolio.id, !isReal);
    amplitudeClient.getInstance().logEvent('action_toggle-portfolio-type', {
      toggle: isReal ? 'simulated' : 'managed',
      portfolioId: portfolio.id,
    });
    window.Appcues?.track('Toggle portfolio type', { state: isReal ? 'simulated' : 'managed' });
  };

  const subtext = isReal ? realText : mockText;

  useEffect(() => {
    setIsReal(portfolio && portfolio.isReal);
  }, [portfolio]);

  return (
    <div className='d-flex align-items-center'>
      <Switch
        type='switch'
        id='portfolio-mode-switch'
        checked={isReal}
        onChange={onChangePortfolioType}
        uncheckedIcon={<FontAwesomeIcon icon={['fad', 'user-robot']} className='ms-2 mt-2' />}
        checkedIcon={<FontAwesomeIcon icon={['fad', 'user-cowboy']} className='ms-2 mt-2' />}
        height={33}
        width={63}
        handleDiameter={33}
        offColor='#2b333e'
        onColor='#2b333e'
        offHandleColor='#3EA6FF'
        onHandleColor='#1FBD60'
        // boxShadow='0px 0px 1px 1px #3EA6FF'
        // activeBoxShadow='0px 0px 1px 1px #1FBD60'
      />
      <span className='switch-label'>
        {switchLabel}{' '}
      </span>
    </div>
  );
}
