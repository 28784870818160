import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button } from 'react-bootstrap';
// helpers
import { INVESTOR_FOLLOW_LIMIT } from '../../helpers/Constants';
import { amplitudeClient } from '../../helpers/Analytics';
import { getInvestors, removeAllInvestorsFromBullpen } from '../../helpers/Firebase';
// components
import InvestorListItem from './InvestorListItem';

export default function InvestorSearch({
  investorBullpen,
  userID,
  followCount = 0,
  followLimit = INVESTOR_FOLLOW_LIMIT,
}) {
  if (!investorBullpen) return null;
  // investors followed by the user
  const [bullpenInvestors, setBullpenInvestors] = useState([investorBullpen]);
  // all of the investors in the database
  const [allInvestors, setAllInvestors] = useState([]);
  // filtered investor data for display
  const [filteredInvestors, setFilteredInvestors] = useState([]);

  const onClickClearAll = () => {
    removeAllInvestorsFromBullpen(userID);
    amplitudeClient.getInstance().logEvent('action_remove-all-investors');
  };

  // gets all the investors from the database on first time component load
  useEffect(async () => {
    const inv = await getInvestors();
    setAllInvestors(inv);
  }, []);

  // update list of investors followed by the user (user.investorBullpen)
  useEffect(() => {
    setBullpenInvestors(investorBullpen);
  }, [investorBullpen]);

  // filters the allInvestors array to be only the data we need
  useEffect(() => {
    if ((allInvestors.length !== 0 || undefined) && (bullpenInvestors.length !== 0 || undefined)) {
      // console.log(
      //   'filtering investors from ',
      //   allInvestors.length,
      //   'to',
      //   bullpenInvestors.length
      // );
      const filteredArray = allInvestors.filter((e) => bullpenInvestors.includes(e.filerCIK));
      setFilteredInvestors(filteredArray);
    }
  }, [allInvestors, bullpenInvestors]);

  return (
    <div className='investor-search'>
      {bullpenInvestors.length === 0 ? (
        <>
          <div className='teaser mb-4'>
            <p className='lead'>
              <FontAwesomeIcon icon={['fal', 'lightbulb-on']} size={'lg'} className='me-2' /> Start
              following investors to get ideas!
            </p>
            <p>
              Follow investors that invest like you, and steal their ideas to put into your
              portfolio.
            </p>
          </div>
        </>
      ) : (
        <div className='my-investors'>
          <p className='investors-title'>
            Your Investors
            <span>
              ({followCount} of {followLimit})
            </span>
            <Button variant='link' size='sm' onClick={onClickClearAll}>
              <small>Clear All</small>
            </Button>
          </p>
          <Row className='investors-list__labels'>
            <Col xs={5}>Investor</Col>
            <Col xs={2}>Risk Effcy</Col>
            <Col xs={2}>Holdings</Col>
            <Col xs={2}>1yr Growth</Col>
            <Col xs={1}></Col>
          </Row>
          <ul className='investors-list'>
            {filteredInvestors.map((investor) => (
              <InvestorListItem key={investor.filerCIK} investor={investor} userID={userID} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
