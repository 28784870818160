import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
// hooks
import useToast from '../hooks/UseToast';
import useProfile from '../hooks/UseProfile';
import useQueryString from '../hooks/UseQueryString';
// data config
import { db } from '../config/firebase';
// components
import Seo from '../components/global/Seo';
import SideNav from '../components/global/SideNav';
import Loader from '../components/global/Loader';
import InvestmentsContainer from '../components/plaid/InvestmentsContainer';

export default function Institution(props) {
  // router location
  const location = useLocation();
  // query params
  const queryParams = useQueryString();
  // add toast API for display to the user
  const { addToast } = useToast();
  // current institution name
  const [institution, setInstitution] = useState(null);
  // get the current user profile API context
  const { userID } = useProfile();
  // loading
  const [loading, setLoading] = useState(true);
  // meta data for SEO
  const metaData = {
    title: 'Institutions',
    description:
      "Institutions -- Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.",
  };

  // testing -- runs each time setInstitution is updated
  useEffect(() => {
    if (institution !== null) {
      setLoading(false);
    }
  }, [institution]);

  // this effect is only run once on load
  useEffect(() => {
    // get institutionID from URL path
    const path = props.location.pathname.split('/');
    const institutionID = path[2];

    // get the institution data from the database
    if (userID && institutionID !== '') {
      // setting the database listener to a variable so we can then dispose of it when component is removed
      const docRef = doc(db, 'institutions', institutionID);
      const unsubscribeThisInstitution = onSnapshot(
        docRef,
        (institutionSnapshot) => {
          const nextInstitution = institutionSnapshot.data();

          if (!isEqual(nextInstitution, institution)) {
            setInstitution(nextInstitution);
          }
        },
        (err) => {
          console.log('institution snapshot listen error', err.message);
        }
      );
      // when the component is unloaded, cleanup any listeners to the database
      // otherwise we could have memory leaks, event callbacks will continue to fire
      return () => {
        unsubscribeThisInstitution();
      };
    }
  }, [userID]);

  /**
   * Amplitude tracking
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // get institutionID from URL path
    const path = props.location.pathname.split('/');
    const institutionID = path[2];
    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    amplitudePayload.instituionId = institutionID;

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_plaid-institution-page', amplitudePayload);
    window.Appcues?.track('View plaid institution page');
    return () => {};
  }, []);

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      <SideNav />
      <Container fluid>
        <Row className="h-100">
          <Col className='main' sm={12}>
            <div className='pt-5 pb-5'>
              {loading === true && <Loader />}
              {loading === false && (
                <InvestmentsContainer
                  accessToken={institution.accessToken}
                  institution={institution.name}
                />
              )}
              <Link to='/profile' className='mt-5 btn btn-secondary'>
                Back To Profile
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
