import useWindowSize from './UseWindowSize';

// window size cutoff for allowing list view
const listCutoff = 1000;

const useCanViewList = () => {
  const { width: windowWidth } = useWindowSize();
  const canViewList = windowWidth > listCutoff;

  return canViewList;
};

export default useCanViewList;
