import React, { useCallback } from 'react';
import { map } from 'lodash';
import AssetClassButton from './AssetClassButton';

const AssetClassOptions = [
  {
    label: 'Stocks',
    value: 'stock',
  },
  {
    label: 'Crypto',
    value: 'crypto',
  },
  // {
  //   label: 'ETFs',
  //   value: 'etfs',
  // },
  // {
  //   label: 'Futures',
  //   value: 'futures',
  // },
  // {
  //   label: 'Shoes',
  //   value: 'shoes',
  // },
  // {
  //   label: 'Handbags',
  //   value: 'handbags',
  // },
];

function AssetClassPicker({
  value = AssetClassOptions,
  onChange,
}) {
  const hasSelectedAll = value.length === AssetClassOptions.length;
  const handleClickSelectAll = useCallback(() => {
    onChange(map(AssetClassOptions, 'value'));
  }, [onChange]);

  const handleClick = useCallback((nextValue) => {
    onChange([nextValue]);
  }, [onChange]);

  return (
    <div className="p-2 mb-3 d-flex overflow-auto">
      <AssetClassButton
        className="mr-2"
        selected={hasSelectedAll}
        value="all"
        label="Everything"
        onClick={handleClickSelectAll}
      />
      {map(AssetClassOptions, (option) => (
        <AssetClassButton
          className="mr-2"
          selected={!hasSelectedAll && value.includes(option.value)}
          key={option.value}
          label={option.label}
          onClick={handleClick}
          value={option.value}
        />
      ))}
    </div>
  );
}

export default AssetClassPicker;
