/** MultilineChart.controller.js */
import { useMemo } from 'react';
import * as d3 from 'd3';

const useController = ({
  combinedData,
  yValue,
  width,
  height,
  portfolioStartingValue,
  indentX,
  yMinOverride,
  yMaxOverride,
}) => {
  const xMin = useMemo(() => d3.min(combinedData, ({ date }) => date), [combinedData]);
  const xMax = useMemo(() => d3.max(combinedData, ({ date }) => date), [combinedData]);

  const xScale = useMemo(() => {
    const indention = (xMax - xMin) * 0.1;
    return (
      d3
        .scaleTime()
        .domain([indentX ? xMin - indention : xMin, indentX ? xMax - -indention / 2 : xMax])
        // .domain([xMin, xMax])
        .range([0, width])
    );
  }, [xMin, xMax, width]);

  const isChangePercentLine = yValue === 'changePercent';

  const yMin =
    yMinOverride ||
    useMemo(() =>
      d3.min(combinedData, isChangePercentLine ? (d) => d.changePercent * 100 : (d) => d[yValue], [
        combinedData,
      ])
    );

  let yMean;
  if (yMinOverride && yMaxOverride) {
    yMean = (yMinOverride + yMaxOverride) / 2;
  } else {
    yMean =
      (yMinOverride + yMaxOverride) / 2 ||
      useMemo(() =>
        d3.mean(
          combinedData,
          isChangePercentLine ? (d) => d.changePercent * 100 : (d) => d[yValue],
          [combinedData]
        )
      );
  }

  const yMax =
    yMaxOverride ||
    useMemo(() =>
      d3.max(combinedData, isChangePercentLine ? (d) => d.changePercent * 100 : (d) => d[yValue], [
        combinedData,
      ])
    );

  const yScale = useMemo(() => {
    const indention = (yMax - yMin) * 0.25;
    return d3
      .scaleLinear()
      .domain([yMin - indention, yMax + indention])
      .range([height, 0]);
  }, [height, yMin, yMax]);

  const yScaleForAxis = useMemo(
    () => d3.scaleBand().domain([yMin, yMean, yMax]).range([height, 0]),
    [height, yMin, yMax, yMean]
  );

  let yTickFormat = (d) => d.toString();

  if (yValue === 'changePercent') {
    yTickFormat = (d) => `${parseFloat(d) > 0 ? '+' : ''}${d3.format('.0f')(d)}`;
  }
  if (yValue === 'closePrice') {
    yTickFormat = (d) => `${d3.format('$.0f')(d)}`;
  }
  if (yValue === 'sortino' || yValue === 'adjustedSortino') {
    yTickFormat = (d) => `${parseFloat(d) > 0 ? '+' : ''}${d3.format('.2f')(d)}`;
  }
  if (yValue === 'dScore') {
    yTickFormat = (d) => `${parseFloat(d) > 0 ? '+' : ''}${d3.format('.2%')(d)}`;
  }
  // if (yValue === 'dScore') {
  //   yTickFormat = (d) =>
  //     `${parseFloat(d) > 0 ? '+' : ''}${d3.format('$.2f')(d * portfolioStartingValue)}`;
  // }
  if (yValue === 'stdev' || yValue === 'growth') {
    yTickFormat = (d) => `${parseFloat(d) > 0 ? '+' : ''}${d3.format('.2%')(d)}`;
  }

  const xTickFormat = (d) => {
    if (d3.timeFormat('%b')(d) === 'Jan') {
      return d3.timeFormat('%Y')(d);
    }
    return d3.timeFormat('%b')(d);
  };

  // console.log({
  // xMin,
  // xMax,
  // yMin,
  // yMean,
  // yMax,
  // xScale,
  // yScale,
  // yScaleForAxis,
  // xTickFormat,
  // yTickFormat,
  // });

  return {
    yTickFormat,
    xScale,
    yScale,
    yScaleForAxis,
    xTickFormat,
  };
};

export default useController;
