import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import { playSound } from '../../helpers/Sounds';
// hooks
import useSettings from '../../hooks/UseSettings';
// components
import Loader from '../global/Loader';

function CollectionListItem({ id, name, removeCollection, isActive }) {
  // router history
  const history = useHistory();
  // get the app level settings context
  const { muted } = useSettings();
  // loading flag
  const [loading, setLoading] = useState(true);

  /**
   * Handle select collection
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const onSelectCollection = () => {
    console.groupCollapsed('COLLECTION SELECTED');
    console.log('Collection ID', id);
    console.groupEnd();

    if (!muted) {
      playSound('tap');
    }
    amplitudeClient.getInstance().logEvent('action_select-collection', {
      collectionId: id,
    });
    window.Appcues?.track('Select collection');
    // redirect to selected collection
    history.push(`/c/${id}`);
  };

  /**
   * One-time load
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (id !== null) {
      // console.log('collection', collection);
      setLoading(false);
      return;
    }
    setLoading(true);

    return () => {};
  }, [id]);

  return (
    <>
      {loading === true && <Loader message='Loading collection...' />}
      {loading === false && (
        <ListGroup.Item as={'li'} key={id} action onClick={onSelectCollection} active={isActive}>
          <div className='collection'>
            <div className='collection__name'>{name}</div>
          </div>
          <Button
            variant='link'
            onClick={() => {
              removeCollection(id);
            }}
          >
            <FontAwesomeIcon icon={['far', 'trash-alt']} size={'sm'} fixedWidth />
          </Button>
        </ListGroup.Item>
      )}
    </>
  );
}

CollectionListItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  removeCollection: PropTypes.func,
  isActive: PropTypes.bool,
};

CollectionListItem.defaultProps = {
  id: '',
  name: '',
  removeCollection: () => {},
  isActive: false,
};

export default CollectionListItem;
