import React, { useEffect, useState } from 'react';
import { Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// helpers
import { INVESTOR_FOLLOW_LIMIT } from '../../helpers/Constants';
import { amplitudeClient } from '../../helpers/Analytics';
import { getInvestors } from '../../helpers/Firebase';
// components
import InvestorCard from './InvestorCard';

export default function InvestorCatalog({
  portfolio = {},
  investorBullpen,
  userID,
  followCount = 0,
  followLimit = INVESTOR_FOLLOW_LIMIT,
}) {
  const riskToleranceOptions = [
    {
      key: 'conservative',
      label: 'Conservative',
    },
    {
      key: 'moderate',
      label: 'Moderate',
    },
    {
      key: 'aggressive',
      label: 'Aggressive',
    },
  ];

  const timeHorizonOptions = [
    {
      key: 'short',
      label: 'Short-Term (9-18 months)',
    },
    {
      key: 'mid',
      label: 'Mid-Term (18 months - 3 years)',
    },
    {
      key: 'long',
      label: 'Long-Term (3 - 4.5 years)',
    },
  ];

  const [investors, setInvestors] = useState([]);
  const [filteredInvestors, setFilteredInvestors] = useState([]);
  const [riskTolerance, setRiskTolerance] = useState({});
  const [timeHorizon, setTimeHorizon] = useState({});

  const findRiskTolerance = (key) => riskToleranceOptions.find((opt) => opt.key === key);
  const findTimeHorizon = (key) => timeHorizonOptions.find((opt) => opt.key === key);

  useEffect(async () => {
    setRiskTolerance(findRiskTolerance(portfolio.riskTolerance || 'conservative'));
    setTimeHorizon(findTimeHorizon(portfolio.riskHorizon || 'long'));
    const inv = await getInvestors();
    setInvestors(inv);
  }, []);

  const findInvestorsByRisk = (key) => {
    let investorsByRisk = [];

    if (key === 'conservative') {
      investorsByRisk = investors.filter((i) => i.performance3yr > 0 && i.performance3yr < 15);
    }

    if (key === 'moderate') {
      investorsByRisk = investors.filter((i) => i.performance3yr > 15 && i.performance3yr < 30);
    }

    if (key === 'aggressive') {
      investorsByRisk = investors.filter((i) => i.performance3yr > 30);
    }

    return investorsByRisk;
  };

  const findInvestorsByTime = (key) => {
    let investorsByTime = [];

    if (key === 'short') {
      investorsByTime = investors.filter((i) => i.avgTimeHeld >= 3 && i.avgTimeHeld < 6);
    }

    if (key === 'mid') {
      investorsByTime = investors.filter((i) => i.avgTimeHeld >= 6 && i.avgTimeHeld < 12);
    }

    if (key === 'long') {
      investorsByTime = investors.filter((i) => i.avgTimeHeld >= 12 && i.avgTimeHeld <= 18);
    }

    return investorsByTime;
  };

  const filterInvestors = () => {
    const dedupedFiltered = [];

    const investorsByRisk = findInvestorsByRisk(riskTolerance.key);
    const investorsByTime = findInvestorsByTime(timeHorizon.key);

    const filteredArray = investorsByRisk.filter((riskInvestor) =>
      investorsByTime.some((investor) => investor.name === riskInvestor.name)
    );

    setFilteredInvestors(filteredArray);
  };

  const onSelectRiskTolerance = (value) => {
    setRiskTolerance(findRiskTolerance(value));
    amplitudeClient.getInstance().logEvent('action_select-risk-tolerance', {
      value,
    });
  };

  const onSelectTimeHorizon = (value) => {
    setTimeHorizon(findTimeHorizon(value));
    amplitudeClient.getInstance().logEvent('action_select-time-horizon', {
      value,
    });
  };

  useEffect(() => {
    filterInvestors();
  }, [investors, riskTolerance, timeHorizon]);

  return (
    <>
      <div className='investor-info'>
        <p className='lead'>Follow Successful Investors</p>
        <hr />
        <p>
          Follow investors that you like or that invest like you, and we'll help you find the same
          things that they invest in!
        </p>
      </div>
      <div className='investor-toolbar'>
        {riskTolerance.label && (
          <DropdownButton
            title={riskTolerance.label}
            variant='dark'
            className='me-2'
            onSelect={onSelectRiskTolerance}
          >
            {riskToleranceOptions.map((option) => (
              <Dropdown.Item key={option.key} eventKey={option.key}>
                {option.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
        {timeHorizon.label && (
          <DropdownButton title={timeHorizon.label} variant='dark' onSelect={onSelectTimeHorizon}>
            {timeHorizonOptions.map((option) => (
              <Dropdown.Item key={option.key} eventKey={option.key}>
                {option.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </div>
      <div className='investor-cards'>
        <Row xs={1} sm={1} md={2} lg={3} xl={3}>
          {filteredInvestors.length !== 0 &&
            filteredInvestors.map((investor) => {
              const isFavorite = investorBullpen && investorBullpen.includes(investor.filerCIK);

              return (
                <InvestorCard
                  key={investor.filerCIK}
                  investor={investor}
                  userID={userID}
                  isFavorite={isFavorite}
                  followCount={followCount}
                  followLimit={followLimit}
                />
              );
            })}
        </Row>
      </div>
    </>
  );
}
