import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// helpers
import { INVESTOR_FOLLOW_LIMIT } from '../helpers/Constants';
import { amplitudeClient } from '../helpers/Analytics';
// hooks
import useToast from '../hooks/UseToast';
import useProfile from '../hooks/UseProfile';
import useQueryString from '../hooks/UseQueryString';
// components
import Seo from '../components/global/Seo';
import Loader from '../components/global/Loader';
import TopNav from '../components/global/TopNav';
import SideNav from '../components/global/SideNav';
import InvestorCatalog from '../components/investors/InvestorCatalog';
import InvestorSidebar from '../components/investors/InvestorSidebar';

export default function Investors() {
  // router location
  const location = useLocation();
  // query params
  const queryParams = useQueryString();
  // add toast API
  const { addToast } = useToast();
  // get the current user profile API context
  const { investorBullpen, userID } = useProfile();
  // loading flag
  const [loading, setLoading] = useState(true);
  // follower count
  const [followCount, setFollowCount] = useState(0);
  const followLimit = INVESTOR_FOLLOW_LIMIT;
  // meta data for SEO
  const metaData = {
    title: 'Investors',
    description:
      "Investors -- Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.",
  };

  /**
   * Amplitude tracking
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_investors-page', amplitudePayload);

    return () => {};
  }, []);

  /**
   * Runs each time currentContextPortfolioID is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (investorBullpen) {
      setFollowCount(investorBullpen.length);
      setLoading(false);
    }
  }, [investorBullpen]);

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      <SideNav />
      <Container fluid>
        <Row>
          <TopNav pageTitle='Investor Hub' />
          <Col className='main' sm={7} xl={8}>
            {loading === false ? (
              <InvestorCatalog
                investorBullpen={investorBullpen}
                userID={userID}
                followCount={followCount}
                followLimit={followLimit}
              />
            ) : (
              <Loader
                height='380px'
                message='Loading your investors, this can take a few seconds'
              />
            )}
          </Col>
          <Col sm={5} xl={4} className='sidebar'>
            {loading === false ? (
              <InvestorSidebar
                investorBullpen={investorBullpen}
                userID={userID}
                followCount={followCount}
                followLimit={followLimit}
              />
            ) : (
              <div className='investor-search'>
                <div className='teaser mb-4'>
                  <p className='lead'>
                    <FontAwesomeIcon icon={['fal', 'lightbulb-on']} size={'lg'} className='me-2' />{' '}
                    Choose a Portfolio
                  </p>
                  <p>We'll need a little messaging for when there is no portfolio choosen yet.</p>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
