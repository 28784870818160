import React, {
  useEffect,
  useGlobal,
  useMemo,
  useState,
} from 'reactn';
import { Navbar, Nav } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// hooks
import useProfile from '../../hooks/UseProfile';
import useSettings from '../../hooks/UseSettings';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// components
import InfoOverview from './InfoOverview';
import InfoRisk from './InfoRisk';
import InfoGrowth from './InfoGrowth';
import InfoVolatility from './InfoVolatility';
import PortfolioBenchmarks from './PortfolioBenchmarks';
import Tip from '../global/Tip';
import PortfolioDropdown from '../PortfolioDropdown';
import EmptyState from '../global/EmptyState';

function ResponsiveMenu({ activeSection, onSelect, readOnly }) {
  return (
    <Navbar expand='xl' variant='dark'>
      <Navbar.Toggle aria-controls='portfolio-info-nav' />
      <Navbar.Collapse id='portfolio-info-nav'>
        <Nav
          className='portfolio-info-nav'
          variant='pills'
          activeKey={activeSection}
          onSelect={onSelect}
        >
          <Nav.Item>
            <Nav.Link eventKey='overview' title='Overview'>
              Overview
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='risk' title='Risk Efficiency'>
              Risk Efficiency
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='growth' title='Growth'>
              Growth
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='volatility' title='Volatility'>
              Volatility
            </Nav.Link>
          </Nav.Item>
          {!readOnly && (
            <Nav.Item>
              <Nav.Link eventKey='edit-portfolio' title='Edit'>
                <FontAwesomeIcon icon="fa-regular fa-gear" />
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

// takes the PL and cleans out dates that have no stats (weekends, holidays)
const clearDaysWithNoStats = (pl) => pl.filter((d) => d.stats);

// these two methods take the base portfolio, which has both weighted and equal
// values in the `stats` key for each day, and remaps it to a new array with only
// the weighted or equal values in stats, so everything else sees it as the same

// NOTE: this is legacy, and will go away when the shape of the mulitAssetPL response's shape
// changes to only return the PL for a single allocation method
const buildWeightedPL = (pl) => clearDaysWithNoStats(pl).map((day) => ({
  ...day.weighted,
  date: day.date,
  ...day.stats.weighted,
}));

export default function PortfolioInfo({
  error,
  portfolio = {},
  totalInvested = 10000,
  portfolioPL = [],
  allocationMethod,
  readOnly,
}) {
  // get the app level settings context
  const { globalBenchmarks, setGlobalBenchmarks } = useSettings();

  // console.log('Portfolio Info', globalBenchmarks);

  const [activeSection, setActiveSection] = useState('overview');
  const [portfolioEquityCurve, setPortfolioEquityCurve] = useState([]);
  const [benchmarks, setBenchmarks] = useState();
  const [isEditingPortfolio, setIsEditingPortfolio] = useGlobal('isEditingPortfolio');
  const { updateWeighting } = useProfile();
  const { isReal } = portfolio;

  // this function reacts to changing the allocation method between equal and
  // weighted, and takes the full PL from the database, and runs it through the
  // functions above to process it to an adjustedPL based on allocation type
  const adjustedPortfolioPL = useMemo(() => {
    if (!portfolioPL) return;

    return buildWeightedPL(portfolioPL);
  }, [portfolioPL]);

  const handleSelectNav = (eventKey) => {
    if (eventKey === 'edit-portfolio') {
      setIsEditingPortfolio(true);
    } else {
      setActiveSection(eventKey);
      amplitudeClient.getInstance().logEvent('action_info-nav-click', { section: eventKey });
      window.Appcues?.track('Click on portfolio info sub-nav', { tab: eventKey });
    }
  };

  // this function is responsible for taking the adjusted PL, and building both
  // equity (dollar growth over time) and risk curves (sortino, adjusted sortingo,
  // standard deviation) so that we can chart it and display it

  const createEquityRiskCurve = async (adjustedPL) => {
    setPortfolioEquityCurve(
      adjustedPL.map((day) => ({
        date: day.date,
        closePrice: day.closePrice,
        stdev: day.stdev,
        sortino: day.sortino,
        adjustedSortino: day.adjustedSortino,
        dScore: day.dScore,
      }))
    );
  };

  useEffect(() => {
    createEquityRiskCurve(adjustedPortfolioPL);
  }, [adjustedPortfolioPL]);

  useEffect(() => {
    if (Object.keys(globalBenchmarks).length === 0) {
      setBenchmarks(new Set());
      return;
    }
    if (Object.keys(globalBenchmarks).length !== 0) {
      console.log('Portfolio Info', globalBenchmarks);
      setBenchmarks(new Set([...globalBenchmarks.split(' ')]));
    }
  }, [globalBenchmarks]);

  return (
    <div className="portfolio-info">
      <div className="d-block d-xl-flex justify-content-between align-items-center mb-5">
        <div className="order-2">
          <ResponsiveMenu
            activeSection={activeSection}
            readOnly={readOnly}
            onSelect={handleSelectNav}
          />
        </div>
        <div className="order-1">
          {readOnly && <h2>{portfolio.name}</h2>}
          {!readOnly && (
            <PortfolioDropdown
              value={portfolio.id}
            />
          )}
          {adjustedPortfolioPL.length > 0 && activeSection === 'overview' && (
            <span className="small text-muted">
              Analysis from{' '}
              {DateTime.fromISO(adjustedPortfolioPL[0].date).toUTC().toFormat('MMM d, yyyy')}
              {' - '}
              {DateTime.fromISO(adjustedPortfolioPL[adjustedPortfolioPL.length - 1].date)
                .toUTC()
                .toFormat('MMM d, yyyy')}
              <span className='ms-2'>
                <Tip contentfulId='4MjbIp9EogUvrWR7WV0neS' />
              </span>
            </span>
          )}
        </div>
      </div>
      <div className='d-flex align-items-center'>
        {!error && activeSection !== 'overview' && (
          <PortfolioBenchmarks
            benchmarks={benchmarks}
            updateSelectedBenchmarks={(obj) => setGlobalBenchmarks([...obj].join(' '))}
          />
        )}
      </div>
      {error && (
        <EmptyState
          height='380px'
          iconName={['fas', 'exclamation-triangle']}
          message='We were unable to analyze your portfolio'
          submessage='Please try again later'
        />
      )}
      {!error && activeSection === 'overview' && (
        <InfoOverview
          portfolioStartingValue={totalInvested}
          portfolioChangeHistory={adjustedPortfolioPL}
          portfolioEquityCurve={portfolioEquityCurve}
          portfolioId={portfolio.id}
          readOnly={readOnly}
          error={error}
        />
      )}
      {!error && activeSection === 'risk' && (
        <InfoRisk
          portfolioStartingValue={totalInvested}
          portfolioEquityCurve={portfolioEquityCurve}
          selectedBenchmarks={benchmarks}
          portfolioName={portfolio.name}
        />
      )}
      {!error && activeSection === 'growth' && (
        <InfoGrowth
          portfolioEquityCurve={portfolioEquityCurve}
          portfolioStartingValue={totalInvested}
          portfolioChangeHistory={adjustedPortfolioPL}
          selectedBenchmarks={benchmarks}
          readOnly={readOnly}
          portfolioName={portfolio.name}
        />
      )}
      {!error && activeSection === 'volatility' && (
        <InfoVolatility
          allocationMethod={allocationMethod}
          portfolioChangeHistory={adjustedPortfolioPL}
          portfolioEquityCurve={portfolioEquityCurve}
          selectedBenchmarks={benchmarks}
          portfolioStartingValue={totalInvested}
          portfolioName={portfolio.name}
        />
      )}
    </div>
  );
}
