import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createNullCache } from '@algolia/cache-common';
import algoliasearch from 'algoliasearch/lite';
import { OverlayTrigger } from 'react-bootstrap';
import {
  Configure,
  InstantSearch,
  SearchBox,
  useHits,
} from 'react-instantsearch-hooks-web';
import AgSearchResults, { AssetSearchColIds } from './AssetSearchResults';
import useWindowSize from '../hooks/UseWindowSize';
import AssetClassPicker from './AssetClassPicker';
import './AssetSearch.scss';

const {
  REACT_APP_ALGOLIA_INDEX_NAME = 'production',
  REACT_APP_ALGOLIA_APP_ID = '',
  REACT_APP_ALGOLIA_APIKEY = '',
} = process.env;

const searchIndex = REACT_APP_ALGOLIA_INDEX_NAME;
const algoliaClient = algoliasearch(
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_APIKEY,
  {
    responsesCache: createNullCache(),
    requestsCache: createNullCache(),
  },
);
algoliaClient.initIndex(searchIndex);

const globalSearchColIds = {
  0: ['symbolWithName', 'riskEfficiency', 'dScore', 'growth1yr'],
};

function AssetSearchHits(props) {
  const { onSelectHit } = props;
  const { hits } = useHits();

  return (
    <AgSearchResults
      colIds={globalSearchColIds}
      rowData={hits}
      onSelectHit={onSelectHit}
    />
  );
}

const baseRect = {
  left: 0,
  x: 0,
  top: 0,
  y: 0,
  width: 0,
  height: 0,
};

function AssetSearch({
  stockList = [],
}) {
  const target = useRef(null);
  const searchBoxRef = useRef(null);
  const { width: windowWidth = 0 } = useWindowSize();
  const [classes, setClasses] = useState();
  const [isShowing, setIsShowing] = useState(false);
  const searchFilters = (classes || []).map((type) => `type:${type}`).join(' OR ');

  // NOTE: intentionally using window width in the dependency array to trip
  // this memo to get the latest bounding client rect
  const targetBoundingRect = useMemo(() => {
    if (target.current) {
      return target.current.getBoundingClientRect();
    }

    return baseRect;
  }, [isShowing, target.current, windowWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  const overlay = () => (
    <div
      className="AssetSearchPopover d-flex justify-content-center align-items-start position-absolute"
      style={{
        top: `${targetBoundingRect.height - 8}px`,
        left: `${targetBoundingRect.left}px`,
        width: `${targetBoundingRect.width}px`,
        zIndex: 100000,
      }}
    >
      <div
        className="text-white w-100"
      >
        <AssetClassPicker
          value={classes}
          onChange={setClasses}
        />
        <AssetSearchHits />
      </div>
    </div>
  );

  const renderSearchBox = useCallback(({ ref, ...props }) => (
    <SearchBox
      placeholder="Search stocks or cryptos"
      className="mb-3 mx-auto"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ), []);

  return (
    <div className="AssetSearch d-flex flex-column flex-grow-1">
      <InstantSearch
        searchClient={algoliaClient}
        indexName={searchIndex}
      >
        <Configure
          filters={searchFilters}
          hitsPerPage={12}
        />
        <div ref={target}>
          <OverlayTrigger
            placement="bottom"
            overlay={overlay}
            trigger={['focus']}
            onToggle={setIsShowing}
          >
            {renderSearchBox}
          </OverlayTrigger>
        </div>
      </InstantSearch>
    </div>
  );
}

export default AssetSearch;
