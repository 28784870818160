import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InvestorStat = ({ label, value, includeDivider }) => (
  <div className='investor-stat'>
    <span className='investor-stat__value'>{value}</span>
    <span className='investor-stat__label'>{label}</span>
    {includeDivider && (
      <span className='investor-stat__divider'>
        <FontAwesomeIcon icon={['fas', 'circle']} size={'xs'} className='' />
      </span>
    )}
  </div>
);

export default InvestorStat;
