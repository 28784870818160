import React, { useCallback, useMemo, useState } from 'react';

const Context = React.createContext();

export const CollectionContextProvider = (props) => {
  const [sortOption, setSortOption] = useState('');
  const [filterOption, setFilterOption] = useState(null);
  const [industryOption, setIndustryOption] = useState(null);
  const [portfolioPL, setPortfolioPL] = useState([]);
  const [portfolioID, setPortfolioID] = useState('');
  const [portfolioSymbols, setPortfolioSymbols] = useState([]);

  const setSort = useCallback((method) => {
    setSortOption(method);
  }, []);

  const setFilter = useCallback((method) => {
    setFilterOption(method);
  }, []);

  const setIndustry = useCallback((method) => {
    setIndustryOption(method);
  }, []);

  const setCurrentPortfolioPL = useCallback((pl) => {
    setPortfolioPL(pl);
  }, []);

  const setCurrentPortfolioID = useCallback((id) => {
    setPortfolioID(id);
  }, []);

  const setCurrentPortfolioSymbols = useCallback((symbols) => {
    setPortfolioSymbols(symbols);
  }, []);

  // React.useEffect(() => {
  //   setTotalCartPrice(
  //     shoppingCart
  //       .map((item) => item.pricePerUnit)
  //       .reduce((prev, curr) => prev + curr, 0)
  //   );
  // }, [shoppingCart]);

  const providerValue = useMemo(
    () => ({
      sortOption,
      filterOption,
      industryOption,
      portfolioPL,
      portfolioID,
      portfolioSymbols,
      actions: {
        setSort,
        setFilter,
        setIndustry,
        setCurrentPortfolioPL,
        setCurrentPortfolioID,
        setCurrentPortfolioSymbols,
      },
    }),
    [
      sortOption,
      filterOption,
      industryOption,
      portfolioPL,
      portfolioID,
      portfolioSymbols,
      setSort,
      setFilter,
      setIndustry,
      setCurrentPortfolioPL,
      setCurrentPortfolioID,
      setCurrentPortfolioSymbols,
    ]
  );

  return <Context.Provider value={providerValue}>{props.children}</Context.Provider>;
};
export default Context;
