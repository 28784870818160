import { useState } from 'react';

export default function useToggle(defaultValue) {
  const [value, setValue] = useState(defaultValue);

  // eslint-disable-next-line no-shadow
  function toggleValue(value) {
    setValue((currentValue) => (typeof value === 'boolean' ? value : !currentValue));
  }

  return [value, toggleValue];
}
