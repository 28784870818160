import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  collection,
  doc,
  getDocs,
  query,
} from 'firebase/firestore';
import {
  compact,
  keys,
  mapValues,
  map,
  reduce,
  zipObject,
  values,
} from 'lodash';
import { amplitudeClient } from '../helpers/Analytics';
import {
  addPortfolioStock,
  removePortfolioStockByTicker,
  addCollectionAsset,
  removeCollectionAssetByTicker,
} from '../helpers/Firebase';
import { db } from '../config/firebase';
import useProfile from '../hooks/UseProfile';
// components
import Loader from './global/Loader';
import Histogram from './visuals/Histogram';
import MultilineChart from './visuals/MultilineChart';
import StockStats from './stock/StockStats';
import withCopy from './visuals/withCopy';
import withBenchmarks from './visuals/withBenchmarks';
import MultiSelect from './MultiSelect';
// helpers
import { getAssetHistory, getAssetDetails } from '../helpers/DataServices';
import { D3_VOLATILITY_OVERRIDES } from '../helpers/Constants';

const pProps = async (shape) => {
  const fns = values(shape);
  const results = await Promise.all(fns.map((fn) => fn()));
  const props = keys(shape);

  return zipObject(props, results);
};

const MultilineChartWithBenchmarks = withBenchmarks(MultilineChart);
const HistogramWithCopy = withCopy(Histogram);

const fetchStocks = async () => {
  const stocksQuery = query(
    doc(db, 'portfolios', currentContextPortfolioID, 'stocks'),
    orderBy('symbol'),
  );
  const unsubscribeTheseStocks = onSnapshot(
    stocksQuery,
    (stocksSnapshot) => {
      if (stocksSnapshot.docs !== undefined) {
        const sList = stocksSnapshot.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        }));
        const sCount = stocksSnapshot.docs.length;

        if (!isEqual(sList, stockList)) {
          setStockList(sList);
          setStockCount(sCount);
        }
      } else {
        // there are no stocks yet
        console.log('no stocks yet');
      }
    },
    (err) => {
      console.log('stocks snapshot listen error', err);
    }
  );
};

function AddToPortfolioButton(props) {
  const { ticker, ...rest } = props;
  const { portfolioList, userID } = useProfile();
  const [assetList, setAssetList] = useState();
  const [tickersByPortfolioId, setTickersByPortfolioId] = useState({});

  const shape = useMemo(() => mapValues(zipObject(map(portfolioList, 'id')), (_, portfolioId) => async () => {
    const snapshot = await getDocs(collection(db, 'portfolios', portfolioId, 'stocks'));
    const assets = [];
    snapshot.forEach((ref) => assets.push({ id: ref.id, ...ref.data() }));

    return compact(map(assets, 'symbol'));
  }), [portfolioList]);

  const updateAssetList = useCallback(async () => {
    const nextTickersByPortfolioId = await pProps(shape);

    setTickersByPortfolioId(nextTickersByPortfolioId);
  }, [shape]);

  const handleChange = useCallback(async (_nextPortfolioIds, addedPortfolioId, removedPortfolioId) => {
    if (addedPortfolioId) {
      await addPortfolioStock(userID, addedPortfolioId, ticker);
    } else if (removedPortfolioId) {
      await removePortfolioStockByTicker(removedPortfolioId, ticker);
    }

    await updateAssetList();

    // amplitudeClient.getInstance().logEvent('action_add-asset-to-portfolio-drop-menu', {
    //   symbol: ticker.toUpperCase(),
    //   portfolioId: cpid,
    // });
  }, [updateAssetList, userID, ticker]);

  useEffect(() => {
    updateAssetList();
  }, [updateAssetList]);

  const options = useMemo(() => portfolioList.map((portfolio) => ({
    label: portfolio.name,
    value: portfolio.id,
  })), [portfolioList]);

  const value = useMemo(() => {
    const nextValue = reduce(tickersByPortfolioId, (memo, tickers, portfolioId) => {
      if (tickers.includes(ticker)) {
        memo.push(portfolioId);
      }

      return memo;
    }, []);

    return nextValue;
  }, [ticker, tickersByPortfolioId]);

  return (
    <MultiSelect
      onChange={handleChange}
      options={options}
      value={value}
      variant="success text-white"
      {...rest}
    >
      Add to portfolio
    </MultiSelect>
  );
}

function AddToCollectionButton(props) {
  const { ticker, ...rest } = props;
  const { collectionList } = useProfile();
  const [assetList, setAssetList] = useState();
  const [tickersByCollectionId, setTickersByCollectionId] = useState({});

  const shape = useMemo(() => mapValues(zipObject(map(collectionList, 'id')), (_, collectionId) => async () => {
    const snapshot = await getDocs(collection(db, 'collections', collectionId, 'assets'));
    const assets = [];
    snapshot.forEach((ref) => assets.push({ id: ref.id, ...ref.data() }));

    return compact(map(assets, 'symbol'));
  }), [collectionList]);

  const updateAssetList = useCallback(async () => {
    const nextTickersByPortfolioId = await pProps(shape);

    setTickersByCollectionId(nextTickersByPortfolioId);
  }, [shape]);

  const handleChange = useCallback(async (_, addedCollectionId, removedCollectionId) => {
    if (addedCollectionId) {
      await addCollectionAsset(addedCollectionId, ticker);
    } else if (removedCollectionId) {
      await removeCollectionAssetByTicker(removedCollectionId, ticker);
    }

    await updateAssetList();

    // amplitudeClient.getInstance().logEvent('action_add-asset-to-portfolio-drop-menu', {
    //   symbol: ticker.toUpperCase(),
    //   portfolioId: cpid,
    // });
  }, [updateAssetList, ticker]);

  useEffect(() => {
    updateAssetList();
  }, [updateAssetList]);

  const options = useMemo(() => collectionList.map((coll) => ({
    label: coll.name,
    value: coll.id,
  })), [collectionList]);

  const value = useMemo(() => {
    const nextValue = reduce(tickersByCollectionId, (memo, tickers, collectionId) => {
      if (tickers.includes(ticker)) {
        memo.push(collectionId);
      }

      return memo;
    }, []);

    return nextValue;
  }, [ticker, tickersByCollectionId]);

  return (
    <MultiSelect
      onChange={handleChange}
      options={options}
      value={value}
      variant="info text-white"
      {...rest}
    >
      Add to collection
    </MultiSelect>
  );
}

function AssetInfo({
  cpid,
  ticker,
  activeSidebar,
  totalInvested = 10000,
}) {
  // state
  const [loading, setLoading] = useState(true);
  const [stockData, setStockData] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [activeAsset, setActiveAsset] = useState({});
  const [loadingError, setLoadingError] = useState(false);
  const [formattedWebsite, setFormattedWebsite] = useState(null);
  const [linkableWebsite, setLinkableWebsite] = useState(null);

  // get the current user profile context
  const { userID } = useProfile();
  const intl = useIntl();

  const [selectedItems, setSelectedItems] = useState([]);
  // const legendData = [portfolioData, schcData, vcitData];
  // const chartData = [
  //   portfolioData,
  //   ...[schcData, vcitData].filter((d) => selectedItems.includes(d.name)),
  // ];

  // console.log('chartData', chartData);

  const onChangeSelection = (name) => {
    const newSelectedItems = selectedItems.includes(name)
      ? selectedItems.filter((item) => item !== name)
      : [...selectedItems, name];
    setSelectedItems(newSelectedItems);
  };

  // handle amplitude tracking
  const trackAssetClick = () => {
    amplitudeClient
      .getInstance()
      .logEvent('action_portfolio-sidebar-select-asset', { asset: stockData.TICKER });
  };

  /**
   * Get data for charts
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (!ticker) {
      return;
    }

    // get asset history
    const controller = new AbortController();
    getAssetHistory({ symbol: ticker.toUpperCase(), includeStats: true, controller })
      .then(({ data }) => {
        setActiveAsset(data);
        setIsActive(true);
        amplitudeClient.getInstance().logEvent('view_asset-details', {
          symbol: ticker.toUpperCase(),
        });
      })
      .catch((err) => {
        setLoadingError(true);
      });

    return () => {
      controller.abort();
    };
  }, [ticker]);

  /**
   * Get stock data from roster
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(async () => {
    if (ticker === '') return;
    // get company data from roster
    const controller = new AbortController();
    try {
      const assetDetails = await getAssetDetails(ticker, controller);
      if (assetDetails) {
        setStockData(assetDetails);
        setLoading(false);
      }
    } catch (e) {
      if (e.message !== 'canceled') {
        setStockData({
          name: ticker,
        });
        setLoading(false);
      }
    }

    return () => {
      controller.abort();
    };
  }, [ticker]);

  useEffect(() => {
    if (stockData) {
      let url = null;
      let furl = null;
      let lurl = null;

      // check all the spots for a valid website
      // eslint-disable-next-line camelcase
      if ('company_url' in stockData) {
        url = stockData.company_url;
      } else if ('websites' in stockData) {
        // eslint-disable-next-line prefer-destructuring
        url = stockData.websites[0];
      }

      // if there is a url found
      if (url !== null) {
        // then make sure it is formatted properly to be a link and remove trailing slash
        furl = url.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '');
        // if the url does not start with http or https, add it
        if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
          lurl = url;
        } else {
          lurl = `https://${url}`;
        }
      }

      setFormattedWebsite(furl);
      setLinkableWebsite(lurl);
    }

    return () => {};
  }, [stockData]);

  let yesterday;
  let growth;

  const marginOveride = {
    top: 10,
    right: 0,
    bottom: 15,
    left: 0,
  };

  if (!activeAsset.history) return null;

  if (activeAsset.history) {
    yesterday = activeAsset.history[activeAsset.history.length - 1];
    growth = ((yesterday.closePrice / activeAsset.history[0].closePrice - 1) * 100).toFixed(2);
  }

  const historyStartDate = intl.formatDate(activeAsset.history[0].date);
  const historyEndDate = intl.formatDate(activeAsset.history[activeAsset.history.length - 1].date);

  return (
    <div className="mb-4 mt-4">
      {loading === true && (
        <div className="Stock loading...">
          <Loader />
        </div>
      )}
      {loading === false && loadingError && (
        <div className="text-center mt-5">
          <h4>Sorry, we couldn't load this asset</h4>
          <p>Give this a try later, and we'll also look into it on our end.</p>
        </div>
      )}
      {loading === false && !loadingError && (
        <>
          {stockData !== null ? (
            <div id={ticker} className='stock loaded'>
              <h4>
                <Link to={`/explore/${ticker}`} onClick={trackAssetClick} className="text-white text-decoration-none">
                  {stockData.name}
                </Link>
              </h4>
              <span className="badge rounded-pill bg-success">{ticker}</span>
              <div className="mb-3">
                <small>
                  <a href={linkableWebsite} target='_blank' rel='noopener noreferrer' className="text-white">
                    {formattedWebsite}
                  </a>
                </small>
              </div>
              <p>
                <small>{stockData.short_description || stockData.long_description}</small>
              </p>
              <div className="d-grid gap-2 d-sm-block mb-3">
                <AddToPortfolioButton
                  className="me-sm-2"
                  ticker={ticker}
                />
                <AddToCollectionButton
                  ticker={ticker}
                />
              </div>
              <StockStats
                dScore={(yesterday.stats.dScore * 100).toFixed(2) || 'N/A'}
                riskEfficiency={yesterday.stats.adjustedSortino?.toFixed(2) || 'N/A'}
                growth={growth || 'N/A'}
                growthStartDate={historyStartDate}
              />
              <hr />
              {isActive && (
                <>
                  <h6 className='mb-3'>Return Distribution</h6>
                  <div>
                    <HistogramWithCopy dailyHistory={activeAsset} showYesterday />
                  </div>
                  <h6 className='mt-5 mb-3'>
                    Volatility ({historyStartDate} - {historyEndDate})
                  </h6>
                  <div className='new-multi-chart'>
                    <MultilineChartWithBenchmarks
                      dailyHistory={activeAsset}
                      showNeutral
                      yValue='changePercent'
                      margin={marginOveride}
                      yOverrides={D3_VOLATILITY_OVERRIDES}
                      chartName={ticker}
                    />
                  </div>
                  <h6 className='mt-5 mb-3'>
                    Growth ({historyStartDate} - {historyEndDate})
                  </h6>
                  <div className='new-multi-chart'>
                    <MultilineChartWithBenchmarks
                      dailyHistory={activeAsset}
                      yValue='closePrice'
                      margin={marginOveride}
                      chartName={ticker}
                    />
                  </div>
                </>
              )}
              {!isActive && <Loader />}
            </div>
          ) : (
            <div className='stock no-data'>
              <p className='text-warning'>no data</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AssetInfo;
