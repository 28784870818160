import {
  useGlobal,
  useRef,
  useEffect,
  useState,
} from 'reactn';
import { Col, Container, Form, Button, Card, Popover, Row } from 'react-bootstrap';
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom';
import {
  GoogleLoginButton,
  TwitterLoginButton,
  FacebookLoginButton,
} from 'react-social-login-buttons';
import queryString from 'query-string';
import { signup, loginWithGoogle, loginWithTwitter, loginWithFacebook } from '../helpers/Auth';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
// hooks
import useToast from '../hooks/UseToast';
import useQueryString from '../hooks/UseQueryString';
// components
import Seo from '../components/global/Seo';
// media
import finiacLogo from '../media/finiac-logo-on-dark.png';

export default function Signup() {
  // add toast API
  const { addToast } = useToast();
  // reference to email input
  const emailRef = useRef();
  // reference to password input
  const passwordRef = useRef();
  // reference to password confirm input
  const passwordConfirmRef = useRef();
  // loader
  const [loading, setLoading] = useState(false);
  // router
  const history = useHistory();
  // router location
  const location = useLocation();
  // current path from location
  const { pathname } = location;
  // query params
  const queryParams = useQueryString();
  const [currentUser] = useGlobal('currentUser');
  // body element class list
  const bodyClassList = document.body.classList;
  const { referralSource } = queryString.parse(location.search);
  // meta data for SEO
  const metaData = {
    title: 'Signup for Finiac',
    description:
      'Signup for Finiac and boost confidence in your portfolio! Finiac lets you design portfolios, evaluate risk, and invest smarter.',
  };

  /**
   * Sets and removes the class on body element per route path
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // split the path into an array [0] seems to always =''
    const pathArray = pathname.split('/');
    const pathArrayLength = pathArray.length;
    const newBodyClass = pathArray[pathArrayLength - 1];

    // set className
    bodyClassList.add(newBodyClass);

    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_signup-page', amplitudePayload);
    window.Appcues?.track('View signup');

    return () => {
      // remove className
      bodyClassList.remove(newBodyClass);
    };
  }, []);

  /**
   * Handle signup form submit
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      amplitudeClient
        .getInstance()
        .logEvent('action_signup', { method: 'email', source: referralSource });
      setLoading(false);
      history.push('/profile');
    } catch (err) {
      addToast('SIGN UP ERROR', err.message, 'error');
      setLoading(false);
    }
  }

  async function handleGoogleSignin() {
    try {
      await loginWithGoogle();
      amplitudeClient
        .getInstance()
        .logEvent('action_signup', { method: 'google', source: referralSource });
      history.push('/profile');
    } catch (err) {
      addToast('GOOGLE SIGNUP ERROR', err.message, 'error');
    }
  }

  async function handleTwitterSignin() {
    try {
      await loginWithTwitter();
      amplitudeClient
        .getInstance()
        .logEvent('action_signup', { method: 'twitter', source: referralSource });
      history.push('/profile');
    } catch (err) {
      addToast('TWITTER SIGNUP ERROR', err.message, 'error');
    }
  }

  async function handleFacebookSignin() {
    try {
      await loginWithFacebook();
      amplitudeClient
        .getInstance()
        .logEvent('action_signup', { method: 'facebook', source: referralSource });
      history.push('/profile');
    } catch (err) {
      addToast('FACEBOOK SIGNUP ERROR', err.message, 'error');
    }
  }

  // Dynamically change copy for headline
  let headlineCopy;
  switch (referralSource) {
    case 'waitlist':
      headlineCopy = 'The wait is over! Start using Finiac now, for free!';
      break;
    default:
      headlineCopy = 'Sign up for Finiac, free!';
      break;
  }

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      {currentUser !== null && <Redirect to='/profile' />}
      <Container fluid className="d-flex align-items-center justify-content-center h-100">
        <Col md={6} className='px-5'>
          <img src={finiacLogo} alt='Finiac logo' className='auth-logo' />
          <h4 className='my-5'>{headlineCopy}</h4>
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' id='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email' ref={emailRef} required />
            </Form.Group>
            <Form.Group className='mb-3' id='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                ref={passwordRef}
                aria-describedby='passwordHelpBlock'
                required
              />
              <Form.Text id='passwordHelpBlock' muted>
                a minimum of 6 characters required
              </Form.Text>
            </Form.Group>
            <Button disabled={loading} type='submit' size='lg' className='my-3'>
              Sign Up
            </Button>
          </Form>
          <hr />
          <GoogleLoginButton onClick={handleGoogleSignin} className='GoogleLoginButton'>
            <span>Sign Up w/ Google</span>
          </GoogleLoginButton>
          <TwitterLoginButton onClick={handleTwitterSignin} className='TwitterLoginButton'>
            <span>Sign Up w/ Twitter</span>
          </TwitterLoginButton>
          <FacebookLoginButton onClick={handleFacebookSignin} className='FacebookLoginButton'>
            <span>Sign Up w/ Facebook</span>
          </FacebookLoginButton>
          <div className='mt-5'>
            Already have an account? <Link to='/login'>Log In</Link>
          </div>
        </Col>
        <Col
          md={6}
          className='d-none d-md-flex h-100 auth-sidebar justify-content-center flex-column px-5'
        >
          <h3 className='sidebar-quote mb-4'>
            The common thing all great investors share is a commitment to risk management.
          </h3>
          <p>
            Jack Schwager, author of <em>Market Wizards</em>
          </p>
        </Col>
      </Container>
    </>
  );
}
