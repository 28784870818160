import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
// helpers
import { MultiLineDataPropTypes } from '../../helpers/propTypes';
// hooks
import useController from '../../hooks/useController';
// d3 components
import { Line, Axis, GridLine, Overlay, Tooltip, Area } from './components';

const LineChart = ({
  data = [],
  dailyHistory = {},
  showNeutral = false,
  showStartingPrice = false,
  yValue = '',
  margin = {},
  portfolioStartingValue = 10000,
  benchmarks,
  thiccness,
  color,
  sparks,
  yOverrides = null,
  containerClasses,
}) => {
  const dailyData = [];
  const width = 190;
  const height = 65;
  dailyHistory.history.forEach((day) => {
    if (typeof day.date === 'object') dailyData.push({ day });
    if (typeof day.date === 'string') dailyData.push({ ...day, date: new Date(day.date) });
  });

  const isChangePercentLine = yValue === 'changePercent';
  const yDataType = isChangePercentLine ? (d) => d.changePercent * 100 : (d) => d[yValue];
  const normalizeBenchmarkData = (benchmarkHistory) => {
    const percentageAdjustment = yDataType(dailyData[0]) / yDataType(benchmarkHistory[0]);
    const normalizedBenchmarkHistory = benchmarkHistory.map((h) => ({
      ...h,
      closePrice: h.closePrice * percentageAdjustment,
      date: new Date(h.date),
    }));
    return normalizedBenchmarkHistory;
  };

  // iterate over benchmarks and add them to the combinedData array
  const combinedData = [...dailyData];
  if (benchmarks) {
    Object.keys(benchmarks).forEach((benchmark) => {
      // console.log('benchmark', benchmarks[benchmark].history);
      combinedData.push(...normalizeBenchmarkData(benchmarks[benchmark].history));
    });
    // console.log('combinedData', combinedData);
  }

  const controller = useController({
    combinedData,
    yValue,
    width,
    height,
    portfolioStartingValue,
    indentX: false,
    yMinOverride: yOverrides !== null ? yOverrides.min : null,
    yMaxOverride: yOverrides !== null ? yOverrides.max : null,
  });
  const { yTickFormat, xTickFormat, xScale, yScale, yScaleForAxis } = controller;

  return (
    <div className={containerClasses}>
      <svg viewBox={`0 0 ${width} ${height}`}>
        <g>
          <Line
            key={'portfolio'}
            className={'portfolio'}
            data={dailyData}
            xScale={xScale}
            yScale={yScale}
            yValue={yValue}
            color={color}
            stroke={thiccness}
            sparkline={sparks}
          />
        </g>
      </svg>
    </div>
  );
};

LineChart.propTypes = {
  data: PropTypes.array,
  dailyHistory: MultiLineDataPropTypes,
  showNeutral: PropTypes.bool,
  showStartingPrice: PropTypes.bool,
  yValue: PropTypes.string,
  thiccness: PropTypes.number,
  color: PropTypes.string,
  sparks: PropTypes.bool,
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  portfolioStartingValue: PropTypes.number,
};

LineChart.defaultProps = {
  data: [],
  dailyHistory: {},
  showNeutral: false,
  showStartingPrice: false,
  yValue: '',
  thiccness: 1,
  color: 'cornflowerblue',
  sparks: false,
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  portfolioStartingValue: 10000,
};

export default LineChart;
