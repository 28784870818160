import React, {
  useCallback,
  useGlobal,
} from 'reactn';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PublicToggle from './portfolio/PublicToggle';
import { updatePortfolio } from '../helpers/Firebase';

import './PortfolioEditPane.scss';

function PortfolioEditPane() {
  const [activePortfolio] = useGlobal('activePortfolio');
  const [, setIsEditingPortfolio] = useGlobal('isEditingPortfolio');

  const handleBlurName = useCallback((e) => {
    updatePortfolio(
      activePortfolio.id,
      { name: e.target.value },
    );
  }, [activePortfolio.id]);

  const handleBlurDescription = useCallback((e) => {
    updatePortfolio(
      activePortfolio.id,
      { description: e.target.value },
    );
  }, [activePortfolio.id]);

  const handleClickClose = useCallback(() => {
    setIsEditingPortfolio(false);
  }, [setIsEditingPortfolio]);

  return (
    <div className='PortfolioEditPane'>
      <div className="text-end">
        <Button variant="link text-white" onClick={handleClickClose}>
          <FontAwesomeIcon icon={['far', 'times']} size="sm" fixedWidth />
        </Button>
      </div>

      <label htmlFor="portfolio-name">Name</label>
      <div className="input-group mb-4">
        <input
          className="control w-100"
          defaultValue={activePortfolio.name}
          id="portfolio-name"
          onBlur={handleBlurName}
        />
      </div>

      <label htmlFor="portfolio-description">What is this portfolio for?</label>
      <div className="input-group mb-4">
        <textarea
          onBlur={handleBlurDescription}
          defaultValue={activePortfolio.description}
          className="control w-100"
          id="portfolio-description"
        />
      </div>

      <PublicToggle portfolio={activePortfolio} />
    </div>
  );
}

export default PortfolioEditPane;
