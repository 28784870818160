import React, { useEffect, useState } from 'react';
import { Col, ButtonToolbar, ButtonGroup, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// components
import Loader from '../global/Loader';
import InvestmentsContainer from './InvestmentsContainer';

export default function PlaidCard({
  id,
  token,
  name,
  created,
  deleteInstitution,
  selectInstitution,
}) {
  // state
  const [loading, setLoading] = useState(true);
  const [statsIcon, setStatsIcon] = useState({
    line: 'd-inline-block',
    fill: 'd-none',
  });
  const [deleteIcon, setDeleteIcon] = useState({
    line: 'd-inline-block',
    fill: 'd-none',
  });

  // select the institution page
  const handleSelectInstitution = (e) => {
    e.preventDefault();
    selectInstitution(id);
  };

  // delete the institution
  const handleDeleteInstitution = (e) => {
    e.preventDefault();
    deleteInstitution(id);
  };

  useEffect(() => {
    setLoading(false);

    return () => {
      // cleanup
    };
  }, []);

  return (
    <Col className='mb-4'>
      {loading === true && (
        <Card className='institution loading'>
          <Card.Body>
            <Loader />
          </Card.Body>
        </Card>
      )}
      {loading === false && (
        <Card id={id} className='institution loaded'>
          <Card.Body>
            <Card.Text>
              <strong>{name}</strong>
            </Card.Text>
            <Card.Text>
              <small>
                Institution ID:
                <br />
                {id}
              </small>
            </Card.Text>
            <Card.Text className='mb-0'>
              <small>
                Access Token:
                <br />
                {token}
              </small>
            </Card.Text>
            <Card.Text className='mt-4 mb-2' style={{ marginLeft: '0.75rem' }}>
              <Button variant='primary' className='me-4' onClick={handleSelectInstitution}>
                View Holdings
              </Button>
              <Button variant='secondary' onClick={handleDeleteInstitution}>
                Delete Institution
              </Button>
            </Card.Text>
            <Card.Text className='text-muted mt-3'>
              <small>
                <FontAwesomeIcon className='me-1' icon={['fal', 'history']} /> Created
                {' - '}
                {created.toDate().toDateString()}
                {' @ '}
                {created.toDate().toLocaleTimeString('en-US')}
              </small>
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </Col>
  );
}
