import React, {
  useContext,
  useEffect,
  useGlobal,
  useState,
} from 'reactn';
import PropTypes from 'prop-types';
import { DropdownButton, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { find, get, isEqual } from 'lodash';
import {
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';
// data config
import { db } from '../../config/firebase';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import { playSound } from '../../helpers/Sounds';
import { getLocalStorageValue } from '../../helpers/LocalStorage';
import { getPortfolioPL, getPortfolioAllocation } from '../../helpers/DataServices';
// hooks
import useProfile from '../../hooks/UseProfile';
import useSettings from '../../hooks/UseSettings';
// context
import CollectionContext from '../../contexts/CollectionContext';
import Tip from '../global/Tip';

function CollectionToolbarCorrelation({ collectionID }) {
  const [loading, setLoading] = useState(true);
  const [currentPortfolioName, setCurrentPortfolioName] = useState('');
  const [currentPortfolioID, setCurrentPortfolioID] = useGlobal('currentPortfolioId');
  const [activePortfolio, setActivePortfolio] = useState({});
  const [portfolioPL, setPortfolioPL] = useState([]);
  const [isRealPortfolio, setIsRealPortfolio] = useState(false);
  const [shares, setShares] = useState({});
  const { portfolioSymbols, actions } = useContext(CollectionContext);
  // get current user profile context
  const { portfolioList } = useProfile();
  // get the app level settings context
  const { muted } = useSettings();

  // first portfolio in the list
  const firstPortfolio = portfolioList.length !== 0 ? portfolioList[0].id : null;

  // handles switching when sort dropdown is switched
  const onChangeSortPortfolio = (portfolioID) => {
    if (!muted) {
      playSound('tap');
    }
    // find the name of portfolio in the portfolioList
    const portfolioName = portfolioList.find((portfolio) => portfolio.id === portfolioID).name;
    setCurrentPortfolioName(portfolioName);
    // set a state variable to keep the selected portfolio ID
    // this will kick off the rest
    actions.setCurrentPortfolioID(portfolioID);
    setCurrentPortfolioID(portfolioID);
    // track the event
    amplitudeClient.getInstance().logEvent('action_change-collection-sort-portfolio', {
      portfolioId: portfolioID,
      collectionId: collectionID,
    });
    window.Appcues?.track('Change collection sort portfolio');
  };

  const getShares = (portfolioStocks) => {
    const assetShares = {};
    portfolioStocks.forEach((stock) => {
      const { symbol, shares: stockShares } = stock;
      assetShares[symbol] = {
        equal: stockShares || 1,
        weighted: stockShares || 1,
      };
    });
    return assetShares;
  };

  const fetchPL = async (symbols, allocations, stockShares) => {
    setLoading(true);
    const { data: pl } = await getPortfolioPL({
      symbols,
      weights: allocations,
      shares: stockShares,
    });
    setLoading(false);
    if (pl[0]) {
      setShares(pl[0].shares);
      // setPortfolioPL(pl);
      actions.setCurrentPortfolioPL(pl);
    }
  };

  /**
   * Handle amplitude tracking
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const trackAssetViewPortfolio = () => {
    amplitudeClient.getInstance().logEvent('action_collection-view-portfolio', {
      portfolioId: currentPortfolioID,
      collectionId: collectionID,
    });
    window.Appcues?.track('View portfolio from collection');
  };

  /**
   * updates when current portfolio id changes
   * gets the portfolio data from firebase based on the portfolio id
   * gets the portfolio assets from firebase based on the portfolio id
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(async () => {
    if (!currentPortfolioID) {
      return;
    }

    setActivePortfolio({});
    actions.setCurrentPortfolioSymbols([]);
    const unsubscribeThisPortfolio = onSnapshot(
      doc(db, 'portfolios', currentPortfolioID),
      (portfolio) => {
        const nextActivePortfolio = {
          ...portfolio.data(),
          id: portfolio.id,
        };

        if (!isEqual(nextActivePortfolio, activePortfolio)) {
          setActivePortfolio(nextActivePortfolio);
        }

        setIsRealPortfolio(portfolio.isReal);
        setLoading(false);
      });

    const unsubscribeTheseStocks = onSnapshot(
      collection(doc(db, 'portfolios', currentPortfolioID), 'stocks'),
      (snapshot) => {
        const assets = snapshot.docs
          .filter((d) => d.data().symbol)
          .map((d) => ({
            ...d.data(),
            id: d.id,
          }));

        if (!isEqual(assets, portfolioSymbols)) {
          actions.setCurrentPortfolioSymbols(assets);
        }
      });

    return () => {
      unsubscribeThisPortfolio();
      unsubscribeTheseStocks();
    };
  }, [currentPortfolioID]);

  /**
   * updates when activePortfolio or portfolioSymbols changes
   * gets the portfolio PL from data services
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(async () => {
    if (Object.keys(activePortfolio).length > 0 && portfolioSymbols.length > 0) {
      console.groupCollapsed('PORTFOLIO SELECTED');
      console.log('activePortfolio', activePortfolio);
      console.log('portfolioSymbols', portfolioSymbols);
      console.groupEnd();
    }
    if (!activePortfolio.id) return;
    try {
      if (portfolioSymbols.length === 0) return setLoading(false);
      const symbols = portfolioSymbols.map(({ symbol }) => symbol);
      const { data: alloc } = await getPortfolioAllocation(symbols);
      if (!activePortfolio.isReal) {
        fetchPL(symbols, alloc);
      }
      if (activePortfolio.isReal) {
        fetchPL(symbols, null, getShares(portfolioSymbols));
      }
    } catch (err) {
      console.log('isRealPortfolio/portfolioSymbols useEffect', err);
    }
  }, [activePortfolio, portfolioSymbols]);

  // useEffect(() => {
  //   // new collection selected, reset the dropdown
  //   setCurrentPortfolioName('');
  //   return () => {};
  // }, [collectionID]);

  /**
   * Runs once first time component is loaded
   * Checks for local storage value for last portfolio
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // try and get the last portfolio vieweed from local storage
    const lastPortfolioID = getLocalStorageValue('rs-last-portfolio');
    // if there is a last portfolio...
    if (lastPortfolioID !== null) {
      const lastPortfolioName = get(find(portfolioList, { id: lastPortfolioID }), 'name');
      // set the curent portfolio ID
      actions.setCurrentPortfolioID(lastPortfolioID);
      setCurrentPortfolioID(lastPortfolioID);
      // set the current portfolio name
      setCurrentPortfolioName(lastPortfolioName);
    }
    // if null... then use the first portfolio in the users list
    if (lastPortfolioID === null) {
      // set the curent portfolio ID
      actions.setCurrentPortfolioID(portfolioList.length !== 0 ? portfolioList[0].id : null);
      setCurrentPortfolioID(portfolioList.length !== 0 ? portfolioList[0].id : null);
      // set the current portfolio name
      setCurrentPortfolioName(portfolioList.length !== 0 ? portfolioList[0].name : null);
    }

    return () => {};
  }, []);

  return (
    <div className='toolbar__actions'>
      <small className='me-3'>
        Risksmith calculates the correlation of assets to your portfolios to help you reduce risk.
        Correlation displayed calculated for{' '}
        <OverlayTrigger placement='top' delay={300} overlay={<Tooltip>View Portfolio</Tooltip>}>
          <Link to={`/p/${currentPortfolioID}`} onClick={trackAssetViewPortfolio}>
            <strong>{currentPortfolioName}</strong>
          </Link>
        </OverlayTrigger>
        . <Tip contentfulId='3mmMxBtnAShWeSPfjcMq18' className='ms-1' />
      </small>
      {portfolioList.length > 1 && (
        <DropdownButton
          className='toolbar__actions-sort-portfolio'
          onSelect={onChangeSortPortfolio}
          // title={
          //   currentPortfolioName === '' ? 'Sort by Correlation to a Portfolio' : currentPortfolioName
          // }
          title='Change Portfolio'
          align='start'
          disabled={false}
          variant='dark'
        >
          {portfolioList.map(
            (portfolio) =>
              portfolio.id !== currentPortfolioID && (
                <Dropdown.Item
                  key={portfolio.id}
                  eventKey={portfolio.id}
                  disabled={portfolio.id === currentPortfolioID}
                >
                  {portfolio.name}
                </Dropdown.Item>
              )
          )}
        </DropdownButton>
      )}
    </div>
  );
}

CollectionToolbarCorrelation.propTypes = {
  collectionID: PropTypes.string,
};

CollectionToolbarCorrelation.defaultProps = {
  collectionID: '',
};

export default CollectionToolbarCorrelation;
