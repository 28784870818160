// import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';
import { logEvent } from 'firebase/analytics';
import { initAuth } from './helpers/Auth';

// analytics
import { analytics } from './config/firebase';

// icons
import './helpers/Icons';
// styles
import './styles/style.scss';

import App from './components/App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

// know thy environment
const isDevEnv = process.env.NODE_ENV === 'development';

// always fire sentry, we filter alerts by env
if (['production', 'staging'].includes(process.env.REACT_APP_SENTRY_ENV)) {
  // adjust sample rate per env
  const tracesSampleRate = isDevEnv ? 1.0 : 0.5;

  Sentry.init({
    tracesSampleRate,
    dsn: 'https://6b5ff3a5c17148c6bcb05fe24c55e23d@o575601.ingest.sentry.io/5728055',
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
    environment: process.env.REACT_APP_SENTRY_ENV,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

// only use logrocket in preview and production links
if (!isDevEnv) {
  // Init LogRocket
  LogRocket.init('obfnuv/my12tocks');

  // Access a LogRocket session for every Drift support request.
  LogRocket.getSessionURL((sessionURL) => {
    drift.track('LogRocket', { sessionURL });
  });

  // Add a LogRocket session URL to Google Analytics as a custom variable.
  LogRocket.getSessionURL((sessionURL) => {
    logEvent(analytics, 'LogRocket', { session_url: sessionURL });
  });

  // Every crash report will have a LogRocket session URL.
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

if (process.env.NODE_ENV === 'development') {
  const rootEl = document.getElementById('root');
  let changeCount = 0;

  const observer = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      if (mutation.type === 'attributes') {
        changeCount += 1;
      } else if (mutation.type === 'childList') {
        changeCount += mutation.addedNodes.length;
        changeCount += mutation.removedNodes.length;
      }
    });

    changeCount += 1;
  });

  // eslint-disable-next-line no-underscore-dangle
  window.__getChangeCount = () => changeCount;

  // eslint-disable-next-line no-underscore-dangle
  window.__resetChangeCount = () => { changeCount = 0; };

  observer.observe(rootEl, {
    childList: true,
    attributes: true,
    subtree: true,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log());

initAuth();
