import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DotPulse } from '@uiball/loaders';

function CollectionAssetStat({
  correlated,
  disableColoring,
  isWorking = false,
  showAsPercent,
  stat,
}) {
  const statClasses = classNames({
    'asset__stat stat-positive': stat > 0,
    'asset__stat stat-negative': stat < 0,
    'stat-correlated': correlated === true,
  });

  if (isWorking) {
    return (
      <td className='asset__stat'>
        <span className='asset__stat-loader'>
          <DotPulse size={20} speed={1.3} color='#757575' />
        </span>
      </td>
    );
  }

  let displayStat = stat;

  if (stat === 'NaN' || stat === null) {
    displayStat = '--';
  } else if (showAsPercent && stat !== 'N/A') {
    displayStat = `${stat}%`;
  }

  return (
    <td className={!disableColoring ? statClasses : 'asset__stat'}>
      {displayStat}
    </td>
  );
}

CollectionAssetStat.propTypes = {
  stat: PropTypes.string,
  disableColoring: PropTypes.bool,
  showAsPercent: PropTypes.bool,
  isWorking: PropTypes.bool,
  correlated: PropTypes.bool,
};

CollectionAssetStat.defaultProps = {
  stat: '',
  disableColoring: false,
  showAsPercent: false,
  isWorking: false,
  correlated: false,
};

export default CollectionAssetStat;
