import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import useProfile from '../../hooks/UseProfile';
import { addBlankCollection } from '../../helpers/Firebase';
// components
import Loader from '../global/Loader';

function CollectionStart() {
  // router history
  const history = useHistory();
  // get the current user profile API context
  const { userID } = useProfile();

  /**
   * Handle adding new collection
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const onAddCollection = async () => {
    // add new collection
    await addBlankCollection(userID).then((collectionID) => {
      // track event
      amplitudeClient.getInstance().logEvent('action_add-new-collection', {
        collectionId: collectionID,
      });
      window.Appcues?.track('Create new collection');
      // redirect to new collection
      history.push(`/c/${collectionID}`);
    });
  };

  return (
    <div className='collection-start'>
      <div className='mt-3 mb-3'>No collections yet...</div>
      <Button className='mt-1' variant='primary' onClick={onAddCollection}>
        <FontAwesomeIcon icon={['far', 'file-alt']} fixedWidth /> Add Your First Collection
      </Button>
    </div>
  );
}

CollectionStart.propTypes = {};

CollectionStart.defaultProps = {};

export default CollectionStart;
