import React from 'react';
import { Card } from 'react-bootstrap';
// Images
import acme from '../../media/rsicon-soft-sm.png';

export default function StockCardError({ symbol }) {
  return (
    <Card.Body>
      <div>
        <img
          src={acme}
          alt={`${symbol} logo`}
          className='company-icon'
        />
      </div>
      <p className='text-symbol'>
        <strong>{symbol}</strong>
      </p>
      <p className='text-danger'>no data for {symbol}</p>
    </Card.Body>
  );
}
