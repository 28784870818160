import React, { useGlobal } from 'reactn';
import { useRouteMatch } from 'react-router-dom';
import TopNav from './global/TopNav';
import SideNav from './global/SideNav';
import { useIsBelowBreakpoint } from '../hooks/useBreakpoint';
import BlogPane from './BlogPane';
import PortfolioEditPane from './PortfolioEditPane';
import GlobalSidebar from './GlobalSidebar';

function Layout(props) {
  const { children } = props;
  const match = useRouteMatch({
    path: '/p/:portfolioId',
  });
  const isBlogSidebarHidden = useIsBelowBreakpoint('xl') || !match;
  const [isEditingPortfolio] = useGlobal('isEditingPortfolio');

  return (
    <div className="d-flex h-100">
      <div className="d-flex flex-column flex-grow-1 h-100">
        <TopNav />
        <div className="d-flex flex-grow-1 overflow-hidden">
          <SideNav />
          <div className="flex-grow-1 overflow-auto">
            {children}
          </div>
        </div>
      </div>
      {!isBlogSidebarHidden && !isEditingPortfolio && (
        <GlobalSidebar>
          <BlogPane />
        </GlobalSidebar>
      )}
      {isEditingPortfolio && (
        <GlobalSidebar>
          <PortfolioEditPane />
        </GlobalSidebar>
      )}
    </div>
  );
}

export default Layout;
