import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const Tooltip = ({
  xScale,
  yScale,
  yValue,
  width,
  height,
  data,
  margin,
  anchorEl,
  children,
  benchmarks,
  portfolioStartingValue,
  chartName = 'Asset',
  ...props
}) => {
  // console.log('benchmarks', benchmarks);
  const ref = useRef(null);
  const drawLine = useCallback(
    (x) => {
      d3.select(ref.current)
        .select('.tooltipLine')
        .attr('x1', x)
        .attr('x2', x)
        .attr('y1', -margin.top)
        .attr('y2', height);
    },
    [ref, height, margin]
  );

  const drawContent = useCallback(
    (x) => {
      const tooltipContent = d3.select(ref.current).select('.tooltipContent');
      tooltipContent.attr('transform', (cur, i, nodes) => {
        const nodeWidth = nodes[i]?.getBoundingClientRect()?.width || 0;
        const translateX = nodeWidth + x > width ? x - nodeWidth - 12 : x + 8;
        return `translate(${translateX}, ${-margin.top})`;
      });
      tooltipContent.select('.contentTitle').text(d3.timeFormat('%b %d, %Y')(xScale.invert(x)));
    },
    [xScale, margin, width]
  );

  const drawBackground = useCallback(() => {
    // reset background size to defaults
    const contentBackground = d3.select(ref.current).select('.contentBackground');
    contentBackground.attr('width', 125).attr('height', 40);

    // calculate new background size
    const tooltipContentElement = d3.select(ref.current).select('.tooltipContent').node();
    if (!tooltipContentElement) return;

    const contentSize = tooltipContentElement.getBoundingClientRect();
    contentBackground.attr('width', contentSize.width + 6).attr('height', contentSize.height + 6);
  }, []);

  const onChangePosition = useCallback(
    (d, bhi, isVisible) => {
      let yValueFormatted = d[yValue];

      // format portfolio data for tooltip
      switch (yValue) {
        case 'changePercent':
          yValueFormatted = `${parseFloat(d[yValue]) > 0 ? '+' : ''}${d3.format('.2f')(
            d[yValue] * 100
          )}`;
          break;

        case 'closePrice':
          yValueFormatted = `${d3.format('$.0f')(d[yValue])}`;
          break;

        case 'sortino':
        case 'adjustedSortino':
          yValueFormatted = `${parseFloat(d[yValue]) > 0 ? '+' : ''}${d3.format('.2f')(d[yValue])}`;
          break;

        case 'dScore':
        case 'stdev':
        case 'growth':
          yValueFormatted = `${parseFloat(d[yValue]) > 0 ? '+' : ''}${d3.format('.2%')(d[yValue])}`;
          break;

        default:
          break;
      }

      // console.log('yValueFormatted', yValueFormatted);

      // add formatted portfolio data to tooltip
      d3.selectAll('.performanceItemValue.PORT').text(isVisible ? yValueFormatted : 'No Data');

      // if benchmarks are available
      // if (benchmarks.length >= 1) {
      // format each benchmark for tooltip
      benchmarks.forEach((benchmark) => {
        const benchmarkSymbol = benchmark.symbol;
        let b = benchmark.history[bhi - 1];
        try {
          b = benchmark.history[bhi - 1];
        } catch (error) {
          b = benchmark.history[benchmark.history.length - 1];
        }
        let bValueFormatted;
        // format benchmark data for tooltip
        switch (yValue) {
          case 'changePercent':
            bValueFormatted = `${parseFloat(b[yValue]) > 0 ? '+' : ''}${d3.format('.2f')(
              b[yValue] * 100
            )}`;
            break;

          case 'closePrice':
            bValueFormatted = `${d3.format('$.0f')(b[yValue])}`;
            break;

          case 'sortino':
          case 'adjustedSortino':
            bValueFormatted = `${parseFloat(b[yValue]) > 0 ? '+' : ''}${d3.format('.2f')(
              b[yValue]
            )}`;
            break;

          case 'dScore':
          case 'stdev':
          case 'growth':
            bValueFormatted = `${parseFloat(b[yValue]) > 0 ? '+' : ''}${d3.format('.2%')(
              b[yValue]
            )}`;
            break;

          default:
            break;
        }

        // console.log(benchmarkSymbol, 'bValueFormatted', bValueFormatted);

        // add formatted benchmark data to tooltip
        d3.selectAll(`.performanceItemValue.${benchmarkSymbol}`).text(
          isVisible ? bValueFormatted : 'No Data'
        );
      });
      // }

      // position all tooltip elements
      d3.selectAll('.performanceItemValue').attr(
        'transform',
        (datum, index, nodes) =>
          `translate(${nodes[index].previousSibling.getBoundingClientRect().width + 14},4)`
      );
    },
    [benchmarks]
  );

  const isChangePercentLine = yValue === 'changePercent';

  const followPoints = useCallback(
    (event) => {
      const [x] = d3.pointer(event);
      const xDate = xScale.invert(x);
      const bisectDate = d3.bisector((d) => d.date).left;
      let baseXPos = 0;

      // draw circles on line
      d3.select(ref.current)
        .selectAll('.tooltipLinePoint')
        .attr('transform', (cur, i) => {
          const index = bisectDate(data, xDate, 1);
          const d0 = data[index - 1];
          const d1 = data[index];
          const d = xDate - (d0?.date || 0) > (d1?.date || 0) - xDate ? d1 : d0;
          if (!d?.date && !d?.changePercent) {
            // move point out of container
            return 'translate(-100,-100)';
          }
          const xPos = xScale(d.date);
          if (i === 0) {
            baseXPos = xPos;
          }

          let isVisible = true;
          if (xPos !== baseXPos) {
            isVisible = false;
          }
          const yPos = yScale(isChangePercentLine ? d.changePercent * 100 : d[yValue]);

          onChangePosition(d, index, isVisible);

          return isVisible ? `translate(${xPos}, ${yPos})` : 'translate(-100,-100)';
        });

      drawLine(baseXPos);
      drawContent(baseXPos);
      drawBackground();
    },
    [anchorEl, drawLine, drawContent, drawBackground, xScale, yScale, data, onChangePosition]
  );

  useEffect(() => {
    d3.select(anchorEl)
      .on('mouseout.tooltip', (event) => {
        d3.select(ref.current).attr('opacity', 0);
      })
      .on('mouseover.tooltip', (event) => {
        d3.select(ref.current).attr('opacity', 1);
      })
      .on('mousemove.tooltip', (event) => {
        d3.select(ref.current).selectAll('.tooltipLinePoint').attr('opacity', 1);
        followPoints(event);
      });
  }, [anchorEl, followPoints]);

  if (!data.length) return null;

  return (
    <g ref={ref} opacity={1} {...props}>
      <line className='tooltipLine' />
      <g className='tooltipContent'>
        <rect className='contentBackground' rx={4} ry={4} opacity={0.8} />
        <text className='contentTitle' transform='translate(8,16)' fontSize={14} />
        <g className='content' transform='translate(8,34)'>
          <g key='portfolio' transform={'translate(6,0)'}>
            <circle r={6} fill='cornflowerblue' />
            <text className='performanceItemName PORT' transform='translate(10,4)'>
              {chartName}
            </text>
            <text className='performanceItemValue PORT' opacity={0.65} fontSize={10} />
          </g>
          {benchmarks.map(({ name, color, symbol }, i) => (
            <g key={name} transform={`translate(6,${20 * (i + 1)})`}>
              <circle r={6} fill={color} />
              <text className={`performanceItemName ${symbol}`} transform='translate(10,4)'>
                {name}
              </text>
              <text className={`performanceItemValue ${symbol}`} opacity={0.65} fontSize={10} />
            </g>
          ))}
        </g>
      </g>
      <circle
        className='tooltipLinePoint'
        fill='cornflowerblue'
        r={6}
        key='portfolio'
        opacity={0}
      />
      {/* {benchmarks.map(({ name, color }) => (
        <circle className='tooltipLinePoint' fill={color} r={6} key={name} opacity={0} />
      ))} */}
    </g>
  );
};

Tooltip.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      change: PropTypes.number,
      changePercent: PropTypes.number,
      closePrice: PropTypes.number,
      date: PropTypes.date,
      stats: PropTypes.shape({
        adjustedSortino: PropTypes.number,
        dScore: PropTypes.number,
        sortino: PropTypes.number,
        stdev: PropTypes.number,
      }),
      symbol: PropTypes.string,
    })
  ),
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
};

Tooltip.defaultProps = {
  width: 0,
  height: 0,
  margin: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  data: [],
  anchorEl: null,
};

export default Tooltip;
