import React from 'react';

function AssetSearchSectionHeader({ label }) {
  return (
    <div className="AssetSearchSectionHeader px-4 py-1">
      <small className="text-uppercase">
        {label}
      </small>
    </div>
  );
}

export default AssetSearchSectionHeader;
