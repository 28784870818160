import { library } from '@fortawesome/fontawesome-svg-core';

// regular icons
import {
  faAlarmExclamation,
  faAnalytics,
  faBookOpen,
  faBriefcase,
  faChartArea,
  faCheck,
  faClock,
  faCommentAltLines,
  faCubes,
  faEllipsisV,
  faExternalLinkSquareAlt,
  faFileAlt,
  faFilePlus,
  faGrid2,
  faGripLines,
  faHeart,
  faLandmark,
  faListUl,
  faLongArrowLeft,
  faLongArrowRight,
  faPencilAlt,
  faPlus,
  faRedo,
  faSlidersHSquare,
  faSyncAlt,
  faTimes,
  faToggleOff,
  faToggleOn,
  faToiletPaper,
  faTrashAlt,
  faUndoAlt,
  faUserCheck,
  faUserRobot,
  faUserTimes,
  faVoteNay,
  faGear,
} from '@fortawesome/pro-regular-svg-icons';

// light icons
import {
  faAlarmExclamation as faAlarmExclamationLight,
  faArrowsH as faArrowsHLight,
  faBadgeDollar as faBadgeDollarLight,
  faBookOpen as faBookOpenLight,
  faBullseyeArrow as faBullseyeArrowLight,
  faCabinetFiling as faCabinetFilingLight,
  faClock as faClockLight,
  faCog as faCogLight,
  faCommentAltLines as faCommentAltLinesLight,
  faCommentsAltDollar as faCommentsAltDollarLight,
  faCopy as faCopyLight,
  faHistory as faHistoryLight,
  faHourglassHalf as faHourglassHalfLight,
  faInfoCircle as faInfoCircleLight,
  faLayerGroup as faLayerGroupLight,
  faLightbulbOn as faLightbulbOnLight,
  faPlus as faPlusLight,
  faPlusHexagon as faPlusHexagonLight,
  faSignOutAlt as faSignOutAltLight,
  faSlidersH as faSlidersHLight,
  faTimes as faTimesLight,
  faTimesSquare as faTimesSquareLight,
  faTrashAlt as faTrashAltLight,
  faUserRobot as faUserRobotLight,
  faUsers as faUsersLight,
  faWandMagic as faWandMagicLight,
  faArrowCircleRight as faCircleArrowRightLight,
} from '@fortawesome/pro-light-svg-icons';

// solid icons
import {
  faAlarmExclamation as faAlarmExclamationSolid,
  faAnalytics as faAnalyticsSolid,
  faBadgeCheck as faBadgeCheckSolid,
  faBars as faBarsSolid,
  faBell as faBellSolid,
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
  faCaretUp as faCaretUpSolid,
  faChartArea as faChartAreaSolid,
  faChevronDown as faChevronDownSolid,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faChevronUp as faChevronUpSolid,
  faCircle as faCircleSolid,
  faClock as faClockSolid,
  faCommentAltLines as faCommentAltLinesSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faInfoCircle as faInfoCircleSolid,
  faHistory as faHistorySolid,
  faPencilAlt as faPencilAltSolid,
  faSortDown as faSortDownSolid,
  faSyncAlt as faSyncAltSolid,
  faTrashAlt as faTrashAltSolid,
  faUserCowboy as faUserCowboySolid,
  faUserRobot as faUserRobotSolid,
  faVoteNay as faVoteNaySolid,
} from '@fortawesome/pro-solid-svg-icons';

// duotone icons
import {
  faAnalytics as faAnalyticsDuotone,
  faListAlt as faListAltDuotone,
  faHomeLgAlt as faHomeLgAltDuotone,
  faLandmark as faLandmarkDuotone,
  faLifeRing as faLifeRingDuotone,
  faPlusHexagon as faPlusHexagonDuotone,
  faSearchDollar as faSearchDollarDuotone,
  faSignOut as faSignOutDuotone,
  faSlidersHSquare as faSlidersHSquareDuotone,
  faSortUp as faSortUpDuotone,
  faSortDown as faSortDownDuotone,
  faTimesSquare as faTimesSquareDuotone,
  faTools as faToolsDuotone,
  faUserAstronaut as faUserAstronautDuotone,
  faUserCog as faUserCogDuotone,
  faUserCowboy as faUserCowboyDuotone,
  faUserFriends as faUserFriendsDuotone,
  faUserRobot as faUserRobotDuotone,
  faEye as faEyeDuotone,
  faEyeSlash as faEyeSlashDuotone,
  faVolumeSlash as faVolumeSlashDuotone,
  faVolumeUp as faVolumeUpDuotone,
} from '@fortawesome/pro-duotone-svg-icons';

// brand icons
import { faDiscord as faDiscordBrand } from '@fortawesome/free-brands-svg-icons';

library.add(
  faAlarmExclamation,
  faAlarmExclamationLight,
  faAlarmExclamationSolid,
  faAnalytics,
  faAnalyticsDuotone,
  faAnalyticsSolid,
  faArrowsHLight,
  faBadgeCheckSolid,
  faBadgeDollarLight,
  faBarsSolid,
  faBellSolid,
  faBookOpen,
  faBookOpenLight,
  faBriefcase,
  faBullseyeArrowLight,
  faCabinetFilingLight,
  faCaretDownSolid,
  faCaretRightSolid,
  faCaretUpSolid,
  faChartArea,
  faChartAreaSolid,
  faCheck,
  faChevronDownSolid,
  faChevronLeftSolid,
  faChevronRightSolid,
  faChevronUpSolid,
  faCircleArrowRightLight,
  faClock,
  faClockLight,
  faClockSolid,
  faCogLight,
  faCommentAltLines,
  faCommentAltLinesLight,
  faCommentAltLinesSolid,
  faCommentsAltDollarLight,
  faCopyLight,
  faCubes,
  faDiscordBrand,
  faEllipsisV,
  faExclamationTriangleSolid,
  faExternalLinkSquareAlt,
  faEyeDuotone,
  faEyeSlashDuotone,
  faFileAlt,
  faFilePlus,
  faGear,
  faGrid2,
  faGripLines,
  faHeart,
  faHistoryLight,
  faHistorySolid,
  faHomeLgAltDuotone,
  faHourglassHalfLight,
  faInfoCircleLight,
  faInfoCircleSolid,
  faLandmark,
  faLandmarkDuotone,
  faLayerGroupLight,
  faLifeRingDuotone,
  faLightbulbOnLight,
  faListAltDuotone,
  faListUl,
  faLongArrowLeft,
  faLongArrowRight,
  faPencilAlt,
  faPencilAltSolid,
  faPlus,
  faPlusHexagonDuotone,
  faPlusHexagonLight,
  faPlusLight,
  faRedo,
  faSearchDollarDuotone,
  faSignOutAltLight,
  faSignOutDuotone,
  faSlidersHLight,
  faSlidersHSquare,
  faSlidersHSquareDuotone,
  faSortDownDuotone,
  faSortDownSolid,
  faSortUpDuotone,
  faSyncAlt,
  faSyncAltSolid,
  faTimes,
  faTimesLight,
  faTimesSquareDuotone,
  faTimesSquareLight,
  faToggleOff,
  faToggleOn,
  faToiletPaper,
  faToolsDuotone,
  faTrashAlt,
  faTrashAltLight,
  faTrashAltSolid,
  faUndoAlt,
  faUserAstronautDuotone,
  faUserCheck,
  faUndoAlt,
  faUserCogDuotone,
  faUserCowboyDuotone,
  faUserCowboySolid,
  faUserFriendsDuotone,
  faUserRobot,
  faUserRobotDuotone,
  faUserRobotLight,
  faUserRobotSolid,
  faUserTimes,
  faUsersLight,
  faVolumeSlashDuotone,
  faVolumeUpDuotone,
  faVoteNay,
  faVoteNaySolid,
  faWandMagicLight
);
