import React, { useState } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { httpsCallable } from 'firebase/functions';
// hooks
import useToast from '../../hooks/UseToast';
// config
import { functions } from '../../config/firebase';

export default function ManageAdminRoles() {
  // add toast API for display to the user
  const { addToast } = useToast();
  // store input field to state
  const [userID, setUserID] = useState('');
  // store role field to state
  const [userRole, setUserRole] = useState('');

  const addUserCustomClaim = httpsCallable(functions, 'addUserCustomClaim');
  const removeUserCustomClaims = httpsCallable(functions, 'removeUserCustomClaims');

  /**
   * Update state as user types
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onChange event
   * @returns - none
   */
  const handleUserFieldChange = (e) => {
    setUserID(e.target.value);
    console.log(e.target.value);
  };

  /**
   * Update state as user chooses
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onChange event
   * @returns - none
   */
  const handleRoleFieldSelect = (e) => {
    setUserRole(e.target.value);
    console.log(e.target.value);
  };

  /**
   * Handle updating the user's role
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - error toast if stockSymbol is empty
   */
  const handleUpdateRoles = (e) => {
    e.preventDefault();
    // check if the values are empty
    if (userID === '') {
      return addToast('ERROR SAVING USER', 'ID cannot be empty...', 'error');
    }
    if (userRole === '') {
      return addToast('ERROR SAVING USER', 'Role cannot be empty...', 'error');
    }

    // !! do the stuff to the user role
    // set users custom claim
    addUserCustomClaim({
      uid: userID,
      role: userRole,
    });

    // clear the state after submit
    setUserID('');
  };

  /**
   * Handle adding stock to database
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - error toast if stockSymbol is empty
   */
  const handleRemoveRoles = (e) => {
    e.preventDefault();
    // check if the values are empty
    if (userID === '') {
      return addToast('ERROR SAVING USER', 'ID cannot be empty...', 'error');
    }

    // !! do the stuff to the user role
    // remove users custom claim
    removeUserCustomClaims({
      uid: userID,
    });

    // clear the state after submit
    setUserID('');
  };

  return (
    <>
      <h5 className='mb-4'>Admin Roles</h5>
      <Row>
        <Form.Group className='col-md-6' controlId='addUserID'>
          <Form.Label>
            <strong>User ID</strong>
          </Form.Label>
          <Form.Control
            type='text'
            required
            placeholder='Enter User ID'
            value={userID || ''}
            onChange={handleUserFieldChange}
          />
        </Form.Group>
        <Form.Group className='col-md-6' controlId='chooseRole'>
          <Form.Label>
            <strong>Role to Assign</strong>
          </Form.Label>
          <Form.Control as='select' required onChange={handleRoleFieldSelect}>
            <option value=''>Choose Role</option>
            <option value='isUserAdmin'>User Admin</option>
            <option value='isAlphaTester'>Alpha Tester</option>
            <option value='isBetaTester'>Beta Tester</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className='col-md-12' controlId='submit'>
          <Button className='mt-4' variant='primary' type='submit' onClick={handleUpdateRoles}>
            Update User Role <FontAwesomeIcon icon={['far', 'user-check']} className='ms-2' />
          </Button>
          <Button className='mt-4 ms-3' variant='secondary' onClick={handleRemoveRoles}>
            Remove All Roles <FontAwesomeIcon icon={['far', 'user-times']} className='ms-2' />
          </Button>
        </Form.Group>
      </Row>
    </>
  );
}
