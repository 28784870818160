import React from 'react';
import { useIntl } from 'react-intl';
// helpers
import { D3_VOLATILITY_OVERRIDES } from '../../helpers/Constants';
// components
import Loader from '../global/Loader';
import withGlobalBenchmarks from '../visuals/withGlobalBenchmarks';
import MultilineChart from '../visuals/MultilineChart';
import Tip from '../global/Tip';

const MultilineChartWithGlobalBenchmarks = withGlobalBenchmarks(MultilineChart);

const marginOveride = {
  top: 20,
  right: 0,
  bottom: 15,
  left: 0,
};

export default function InfoVolatility({
  portfolioEquityCurve,
  portfolioChangeHistory,
  portfolioStartingValue,
  selectedBenchmarks,
  portfolioName,
}) {
  if (portfolioEquityCurve.length === 0) return <Loader message='Loading all the goodies...' />;

  const intl = useIntl();
  const portfolioStartingDate = intl.formatDate(portfolioEquityCurve[0].date);
  const portfolioEndingDate = intl.formatDate(
    portfolioEquityCurve[portfolioEquityCurve.length - 1].date
  );

  const dScore =
    (portfolioChangeHistory[portfolioChangeHistory.length - 1].dScore * portfolioStartingValue) /
    100;

  return (
    <section className='portfolio-info-section'>
      {portfolioEquityCurve.length > 0 && (
        <>
          <div className='d-flex'>
            <div className='w-50 pe-3'>
              {portfolioChangeHistory.length > 0 && (
                <>
                  <div className='multi-line-chart'>
                    <MultilineChartWithGlobalBenchmarks
                      dailyHistory={{ history: portfolioChangeHistory }}
                      yValue='changePercent'
                      showNeutral
                      margin={marginOveride}
                      benchmarks={selectedBenchmarks}
                      yOverrides={D3_VOLATILITY_OVERRIDES}
                      showDScore
                      chartName={portfolioName}
                    />
                  </div>
                </>
              )}
              <p className='mb-0 mt-5 text-center'>
                <small>
                  Volatility ({portfolioStartingDate} - {portfolioEndingDate})
                  <Tip contentfulId='WLkpIaG0TRZCKnTIhvXxv' className='ms-2' />
                </small>
              </p>
            </div>
            <div className='w-50 ps-3'>
              <div className='multi-line-chart'>
                <MultilineChartWithGlobalBenchmarks
                  dailyHistory={{ history: portfolioEquityCurve }}
                  yValue='stdev'
                  showStartingPrice
                  margin={marginOveride}
                  benchmarks={selectedBenchmarks}
                  chartName={portfolioName}
                />
              </div>
              <p className='mb-0 mt-5 text-center'>
                <small>
                  Standard Deviation ({portfolioStartingDate} - {portfolioEndingDate})
                  <Tip contentfulId='2jGHRvd8Dh5hlfGmO3t9Ch' className='ms-2' />
                </small>
              </p>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
