import React, {
  useCallback,
  useEffect,
  useGlobal,
  useMemo,
  useState,
} from 'reactn';
import {
  Container,
  Modal,
} from 'react-bootstrap';
import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import {
  get,
  keyBy,
  map,
  mapValues,
} from 'lodash';
import queryString from 'query-string';
import useQueryString from '../hooks/UseQueryString';
import useSettings from '../hooks/UseSettings';
// data config
import { functions } from '../config/firebase';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
import {
  getLocalStorageValue,
  getSessionStorageValue,
  setLocalStorageValue,
} from '../helpers/LocalStorage';
// components
import Seo from '../components/global/Seo';
import AppLoader from '../components/global/AppLoader';
import Loader from '../components/global/Loader';
import PortfolioInfo from '../components/portfolio/PortfolioInfo';
import PortfolioToolbar from '../components/portfolio/PortfolioToolbar';
import StockCards from '../components/portfolio/StockCards';
import Layout from '../components/Layout';
import { updatePortfolio } from '../helpers/Firebase';

// meta data for SEO
const metaData = {
  title: 'Portfolio',
  description:
    "Portfolio -- Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.",
};

// update allocations for the portfolio
const updatePortfolioAllocation = httpsCallable(functions, 'updatePortfolioAllocation');

export default function PrivatePortfolio({ activePortfolio, stockList }) {
  // router location
  const location = useLocation();
  // query params
  const queryParams = useQueryString();
  // get the current user
  const [currentUser] = useGlobal('currentUser');
  // get the app level settings context
  const { weighting } = useSettings();
  // to show or hide the modal
  // which sidebar is currently in view to the user
  const [activeSidebar, setActiveSidebar] = useState('search');
  // which sidebar is currently in view to the user
  const [activeStock, setActiveStock] = useState('');
  // which sidebar is currently in view to the user
  const [checkInData, setCheckInData] = useState({});
  // how the allocations are being calculated
  const [allocationMethod, setAllocationMethod] = useState(weighting);
  const [isEditingPortfolio] = useGlobal('isEditingPortfolio');
  const { id: activePortfolioId, isReal: isActivePortfolioReal } = activePortfolio;
  const portfolioWithStocks = useMemo(() => ({
    ...activePortfolio,
    stocks: stockList,
  }), [activePortfolio, stockList]);
  const isStockListEmpty = stockList.length === 0;
  const { portfolioViewMode = 'grid' } = currentUser || {};
  const symbols = stockList.map(({ symbol }) => symbol);
  const assetQuantities = mapValues(keyBy(stockList, 'symbol'), ({ shares }) => shares || 1);
  const determineAllocationsURL = useMemo(() => {
    if (symbols.length === 0) {
      return null;
    }

    const qs = queryString.stringify({
      symbols,
      allocationMethod: (isActivePortfolioReal ? 'fixed-quantities' : allocationMethod),
      assetQuantities: JSON.stringify(assetQuantities),
    }, { arrayFormat: 'bracket', });
    return `/determineAllocations?${qs}`;
  }, [allocationMethod, assetQuantities, isActivePortfolioReal, symbols]);
  const { data: allocationsResponse } = useSWR(determineAllocationsURL);
  const { allocations } = allocationsResponse || {};
  const weights = useMemo(() => map(allocations, (memo, symbol) => ({
    symbol,
    allocation: allocations[symbol],
  })), [allocations]);
  const multiAssetPLOpts = useMemo(() => {
    if (symbols.length === weights.length) {
      return ['/multiAssetPL', { symbols, weights }];
    }
    return null;
  }, [symbols, weights]);
  const { data, error } = useSWR(multiAssetPLOpts);
  const portfolioPL = data && Array.isArray(data) ? data : [];
  const plError = error || (data && data.error);
  const shares = get(portfolioPL, '0.shares', {});

  /**
   * Don't show disclaimer if user had already closed it
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - {boolean}
   */
  const hasNOTBeenClosed = getSessionStorageValue('rs-disclaimer-closed') !== 'true';

  /**
   * When a user closes the info sidebar
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleCloseInfo = useCallback(() => {
    setActiveSidebar('search');
  }, []);

  /**
   * When a user opens the info sidebar
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {String} stock - stock symbol
   * @returns - none
   */
  const handleOpenInfo = useCallback((stock) => {
    setActiveStock(stock);
    setActiveSidebar('info');
  }, []);

  /**
   * Get last check in date from local storage
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {String} key - local storage key
   * @returns - value for the local storage key provided
   */
  const getCheckInExpiry = (key) => {
    const expired = getLocalStorageValue(key);
    // if the item doesn't exist, return null
    if (!expired) {
      return null;
    }
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > expired) {
      // If the item is expired, delete the item from storage and return null
      localStorage.removeItem(key);
      return null;
    }
    return expired;
  };

  const onChangePortfolioType = (type) => setPortfolioType(type);

  const onSetAllocationMethod = useCallback((method) => {
    setAllocationMethod(method);

    updatePortfolio(activePortfolioId, {
      weighting: method,
    });
  }, [activePortfolioId]);

  /**
   * Hanlder for setting the allocation method
   */
  const handleSetPortfolioWeighting = useCallback((method) => onSetAllocationMethod(method), []);

  // check if activeStock is in the stockList
  const isStockInStockList = stockList.find((stock) => stock.symbol === activeStock);

  /**
   * Amplitude tracking
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    amplitudePayload.type = 'private';
    amplitudePayload.portfolioId = activePortfolioId;

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_portfolio-page', amplitudePayload);
    window.Appcues?.track('View portfolio');

    return () => {};
  }, []);

  if (!portfolioPL || !activePortfolioId) return <AppLoader />;

  // right now, this is fine, but later, may need shares by day passed in
  let { totalAmountInvested } = activePortfolio;
  if (isActivePortfolioReal && portfolioPL[0]) {
    totalAmountInvested = portfolioPL[portfolioPL.length - 1].equal.closePrice;
  }

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO noIndex />
      <Layout>
        <Container fluid className="d-flex">
          <div className="w-100">
            {!portfolioPL && (
              <Loader
                height="380px"
                message="Loading your portfolio stats, this can take a few seconds"
              />
            )}
            {!isStockListEmpty && (
              <PortfolioInfo
                portfolio={activePortfolio}
                totalInvested={totalAmountInvested}
                portfolioPL={portfolioPL}
                allocationMethod={allocationMethod}
                error={plError}
              />
            )}
            {!isStockListEmpty && portfolioPL.length > 0 && (
              <PortfolioToolbar
                portfolio={activePortfolio}
                onSetPortfolioWeighting={handleSetPortfolioWeighting}
                portfolioPL={portfolioPL}
                totalInvested={totalAmountInvested}
                stockList={stockList}
                startingDate={portfolioPL[Math.ceil(portfolioPL.length / 2)].date}
                onSetAssetViewMode={() => {}}
                viewMode={portfolioViewMode}
              />
            )}
            {activePortfolioId && (
              <StockCards
                allocations={allocations}
                cpid={activePortfolioId}
                openInfoSidebar={handleOpenInfo}
                shares={shares}
                isWeighted={allocationMethod === 'volatility'}
                portfolio={portfolioWithStocks}
                viewMode={portfolioViewMode}
              />
            )}
          </div>
        </Container>
      </Layout>
    </>
  );
}
