/*
 * Gets a local storage value by key
 * @param {string} key
 */
export const getLocalStorageValue = (key) => {
  const keyData = localStorage.getItem(key);
  if (!keyData) {
    console.log('local storage data is null for', key);
    return null;
  }
  return keyData;
};

/*
 * Set a local storage value by key
 * @param {string} key
 * @param {string} value
 */
export const setLocalStorageValue = (key, val) => {
  if (!key) {
    console.log('returning, no key');
    return null;
  }
  localStorage.setItem(key, val);
};

/*
 * Gets a session storage value by key
 * @param {string} key
 */
export const getSessionStorageValue = (key) => {
  const keyData = sessionStorage.getItem(key);
  if (!keyData) {
    return null;
  }
  return keyData;
};

/*
 * Set a session storage value by key
 * @param {string} key
 * @param {string} value
 */
export const setSessionStorageValue = (key, val) => {
  if (!key) {
    console.log('returning, no key');
    return null;
  }
  sessionStorage.setItem(key, val);
};
