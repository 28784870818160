import StockStat from './StockStat';

const StockStats = ({ dScore, riskEfficiency, growth, growthStartDate }) => (
  <div className='d-flex p-2 stock-stats'>
    <StockStat label='Risk Efficiency' stat={riskEfficiency} />
    <StockStat label='dScore' stat={dScore} formattedStat={`${dScore}%`} disableColoring />
    <StockStat
      label={`Growth since ${growthStartDate}`}
      stat={growth}
      formattedStat={`${growth}%`}
    />
  </div>
);

export default StockStats;
