import { useCallback, useEffect, useState } from 'react';
import * as contentful from 'contentful';

export const contentfulClient = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const useContentfulEntry = (contentfulId) => {
  const [entry, setEntry] = useState();
  const loadContentfulEntry = useCallback(async () => {
    const nextEntry = await contentfulClient.getEntry(contentfulId);

    setEntry(nextEntry);
  }, [contentfulId]);

  useEffect(loadContentfulEntry, [loadContentfulEntry]);

  return entry;
};

export const useContentfulEntries = (query) => {
  const [entries, setEntries] = useState();
  const loadContentfulEntries = useCallback(async () => {
    const nextEntries = await contentfulClient.getEntries(query);

    setEntries(nextEntries);
  }, [query]);

  useEffect(loadContentfulEntries, [loadContentfulEntries]);

  return entries;
};

export default useContentfulEntry;
