/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip, Popover, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import { playSound } from '../../helpers/Sounds';
// hooks
import useSettings from '../../hooks/UseSettings';
import useProfile from '../../hooks/UseProfile';
// context
import CollectionContext from '../../contexts/CollectionContext';
// components
import Autocomplete from '../global/Autocomplete';
import CollectionToolbarSearchItem from './CollectionToolbarSearchItem';
import Ranger from '../global/Ranger';
import Tip from '../global/Tip';

function CollectionToolbarSearch({ collectionID, collectionSymbols }) {
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_APIKEY
  );

  const initRangeVals = [
    [0, 5],
    [0, 10],
    [0, 200],
  ];

  const d = new Date();
  const dateSearchFilter = `lastTradingDate > ${Math.floor(d.setDate(d.getDate() - 7))}`;
  const fullSearch = `(type:stock OR type:crypto) AND ${dateSearchFilter}`;

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [searchFilter, setSearchFilter] = useState(fullSearch);
  const [typeStock, setTypeStock] = useState(true);
  const [typeCrypto, setTypeCrypto] = useState(true);
  const [rangeRiskEffciency, setRangeRiskEffciency] = useState([
    initRangeVals[0][0],
    initRangeVals[0][1],
  ]);
  const [rangeDScore, setRangeDScore] = useState([initRangeVals[1][0], initRangeVals[1][1]]);
  const [rangeGrowth, setRangeGrowth] = useState([initRangeVals[2][0], initRangeVals[2][1]]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const { muted } = useSettings();
  const { isAlphaTester } = useProfile();

  const searchRef = useRef(null);
  const selectInputRef = useRef();

  const animatedComponents = makeAnimated();

  const industryOptions = [
    { value: 'Aerospace/Defense', label: 'Aerospace & Defense' },
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Banking', label: 'Banking' },
    { value: 'Chemicals', label: 'Chemicals' },
    {
      value: 'Computer Software & Services',
      label: 'Computer Software & Services',
    },
    { value: 'Computer Hardware', label: 'Computer Hardware' },
    { value: 'Conglomerates', label: 'Conglomerates' },
    { value: 'Consumer Durables', label: 'Consumer Durables' },
    { value: 'Consumer NonDurables', label: 'Consumer NonDurables' },
    { value: 'Diversified Services', label: 'Diversified Services' },
    { value: 'Drugs', label: 'Drugs' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Energy', label: 'Energy' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Food & Beverage', label: 'Food & Beverage' },
    { value: 'Health Services', label: 'Health Services' },
    { value: 'Industrial Goods', label: 'Industrial Goods' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'Internet', label: 'Internet' },
    { value: 'Leisure', label: 'Leisure' },
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'Materials & Construction', label: 'Materials & Construction' },
    { value: 'Media', label: 'Media' },
    { value: 'Metals & Mining', label: 'Metals & Mining' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Retail', label: 'Retail' },
    { value: 'Specialty Retail', label: 'Specialty Retail' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Telecommunications', label: 'Telecommunications' },
    { value: 'Tobacco', label: 'Tobacco' },
    { value: 'Transportation', label: 'Transportation' },
    { value: 'Utilities', label: 'Utilities' },
    { value: 'Wholesale', label: 'Wholesale' },
  ];

  const customSelectStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#1A2430',
      borderColor: '#1A2430',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#1A2430',
      borderColor: '#1A2430',
      width: '100%',
      maxWidth: '325px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#40afff' : '',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: '#40afff',
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#ffffff',
    }),
    container: (provided, state) => ({
      ...provided,
      marginBottom: '20px',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#ffffff',
    }),
  };

  const joiner = (filterString, option) => {
    if (
      filterString !== '' &&
      filterString.slice(-3) !== ' OR' &&
      filterString.slice(-4) !== ' AND'
    ) {
      return ` ${option} `;
    }
    return '';
  };

  /**
   * Handles updating the search filter state
   * Triggered when the filter popover is hidden and shown
   * @returns - none
   */
  const onToggleFilters = () => {
    // UI is closing
    if (showFilterOptions === true) {
      console.group('APPLY FILTERS');
      console.log('Stocks?', typeStock);
      console.log('Crypto?', typeCrypto);
      console.log(
        'Industries:',
        selectedIndustries.map((i) => i.label)
      );
      console.log('RE:', rangeRiskEffciency);
      console.log('DS:', rangeDScore);
      console.log('G:', rangeGrowth);
      console.groupEnd();
      // if the user has not muted sounds
      if (!muted) {
        // play the toggle sound
        playSound('toggle_off');
      }

      // TODO update the searchFilter
      let filterString = '';
      let assetString = '';
      let industryString = '';
      let riskString = '';
      let dScoreString = '';
      let growthString = '';

      // ASSET TYPES
      if (typeStock) {
        assetString += joiner(assetString, 'OR');
        assetString += 'type:stock';
      }
      if (!typeStock) {
        assetString += joiner(assetString, 'AND');
        assetString += 'NOT type:stock';
      }
      if (typeCrypto) {
        assetString += joiner(assetString, 'OR');
        assetString += 'type:crypto';
      }
      if (!typeCrypto) {
        assetString += joiner(assetString, 'AND');
        assetString += 'NOT type:crypto';
      }

      // INDUSTRIES
      if (typeStock && !typeCrypto) {
        if (selectedIndustries.length !== 0) {
          selectedIndustries.forEach((industry, index) => {
            industryString += `industry_category:"${industry.value}"`;
            if (index !== selectedIndustries.length - 1) {
              industryString += ' OR ';
            }
          });
        }
      }

      // RISK EFFICIENCY
      if (rangeRiskEffciency[0] !== 0 || rangeRiskEffciency[1] !== 5) {
        riskString += `riskEfficiency:${rangeRiskEffciency[0]} TO ${rangeRiskEffciency[1]}`;
      }

      // D-SCORE
      if (rangeDScore[0] !== 0 || rangeDScore[1] !== 10) {
        dScoreString += `dScore:${rangeDScore[0] / 100} TO ${rangeDScore[1] / 100}`;
      }

      // GROWTH
      if (rangeGrowth[0] !== 0 || rangeGrowth[1] !== 200) {
        growthString += `growth:${rangeGrowth[0]} TO ${rangeGrowth[1]}`;
      }

      // LETS JAM
      filterString = assetString === '' ? '' : `(${assetString})`;
      filterString +=
        industryString === '' ? '' : `${joiner(filterString, 'AND')}(${industryString})`;
      filterString += riskString === '' ? '' : `${joiner(filterString, 'AND')}(${riskString})`;
      filterString += dScoreString === '' ? '' : `${joiner(filterString, 'AND')}(${dScoreString})`;
      filterString += growthString === '' ? '' : `${joiner(filterString, 'AND')}(${growthString})`;

      console.log(filterString);
      setSearchFilter(filterString);

      // send amplitude tracking
      amplitudeClient.getInstance().logEvent('action_toggle-search-filters', { toggled: 'open' });
      window.Appcues?.track('Toggle collection search filters');
      // set showFilterOptions flag
      setShowFilterOptions(false);
      return;
    }

    // UI is opening
    if (showFilterOptions === false) {
      // if the user has not muted sounds
      if (!muted) {
        // play the toggle sound
        playSound('toggle_on');
      }
      // reset the search filter
      setSearchFilter('');
      // send amplitude tracking
      amplitudeClient.getInstance().logEvent('action_toggle-search-filters', { toggled: 'closed' });
      window.Appcues?.track('Toggle collection search filters');
      // set showFilterOptions flag
      setShowFilterOptions(true);
    }
  };

  /**
   * Handles clearing filter options.
   * Triggered from button in the filter popover
   * @returns - none
   */
  const onClearFilters = () => {
    if (typeStock && !typeCrypto) {
      selectInputRef.current.clearValue();
    }
    setTypeStock(true);
    setTypeCrypto(true);
    setRangeRiskEffciency([initRangeVals[0][0], initRangeVals[0][1]]);
    setRangeDScore([initRangeVals[1][0], initRangeVals[1][1]]);
    setRangeGrowth([initRangeVals[2][0], initRangeVals[2][1]]);

    // if the user has not muted sounds
    if (!muted) {
      // play the toggle sound
      playSound('toggle_off');
    }

    // reset the search filter
    setSearchFilter(fullSearch);

    // set showFilterOptions flag
    setShowFilterOptions(false);

    amplitudeClient.getInstance().logEvent('action_clear-search-filters');
    window.Appcues?.track('Clear collection sort filters');
  };

  /**
   * Filter Popover Component
   * Triggered from button in the filter popover
   * @returns - Popover
   */
  const filtersPopover = (
    <Popover className='filters-popover'>
      <Popover.Body>
        <div>
          <p>Asset Type</p>
          <Form>
            <Form.Check
              inline
              checked={typeStock}
              label='Stocks'
              name='stocks'
              type='checkbox'
              id={'stocks-filter'}
              onChange={() => {
                setTypeStock(!typeStock);
              }}
            />
            <Form.Check
              inline
              checked={typeCrypto}
              label='Cryptos'
              name='cryptos'
              type='checkbox'
              id={'cryptos-filter'}
              onChange={() => {
                setTypeCrypto(!typeCrypto);
              }}
            />
          </Form>
          {typeStock && !typeCrypto && (
            <>
              <p>
                Industries <Tip contentfulId='72EyzItkj7D4ARDVl97tX3' className='ms-1' />
              </p>
              <Select
                ref={selectInputRef}
                defaultValue={selectedIndustries}
                styles={customSelectStyles}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={industryOptions}
                closeMenuOnScroll={true}
                hideSelectedOptions={true}
                isClearable={true}
                onChange={setSelectedIndustries}
              />
            </>
          )}
          <p>
            Risk Efficiency <Tip contentfulId='3sM2BLaigl38NrbGmki5Lg' className='ms-1' />
          </p>
          <Ranger
            vals={rangeRiskEffciency}
            min={initRangeVals[0][0]}
            max={initRangeVals[0][1]}
            stepSize={0.25}
            tickSize={1}
            setRange={setRangeRiskEffciency}
          />
          <p>
            dScore % <Tip contentfulId='3K520A54XxISWJjqRNATAP' className='ms-1' />
          </p>
          <Ranger
            vals={rangeDScore}
            min={initRangeVals[1][0]}
            max={initRangeVals[1][1]}
            stepSize={0.25}
            tickSize={1}
            setRange={setRangeDScore}
          />
          {/* <p>1 Yr Growth % </p>
          <Ranger
            vals={rangeGrowth}
            min={initRangeVals[2][0]}
            max={initRangeVals[2][1]}
            stepSize={1}
            tickSize={25}
            setRange={setRangeGrowth}
          /> */}
          <div className='filters-popover__actions'>
            <Button
              className='filters-popover__actions--clear'
              variant='dark'
              size=''
              onClick={onClearFilters}
            >
              Clear
            </Button>
            <Button
              className='filters-popover__actions--save'
              variant='dark'
              size=''
              onClick={onToggleFilters}
            >
              Save Filters
            </Button>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (typeStock && !typeCrypto) {
      selectInputRef.current.clearValue();
      return () => {};
    }
  }, [typeStock, typeCrypto]);

  useEffect(() => {
    console.log('searchFilter', searchFilter);
  }, [searchFilter]);

  const getSources = useCallback(({ query }) => [
    {
      sourceId: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
      getItems() {
        return getAlgoliaResults({
          searchClient,
          queries: [
            {
              indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
              query,
              params: {
                hitsPerPage: 8,
              },
              filters: searchFilter,
            },
          ],
        });
      },
      templates: {
        item({ item, components }) {
          return (
            <CollectionToolbarSearchItem
              hit={item}
              components={components}
              collectionID={collectionID}
              collectionSymbols={collectionSymbols}
            />
          );
        },
      },
    },
  ], [collectionID, collectionSymbols, searchClient, searchFilter]);

  return (
    <>
      <div className='toolbar__search' ref={searchRef}>
        <Autocomplete
          openOnFocus={true}
          placeholder='Search by name or symbol...'
          autoFocus={false}
          getSources={getSources}
        />
        {isAlphaTester && (
          <OverlayTrigger
            // eslint-disable-next-line no-return-assign
            show={showFilterOptions}
            trigger='click'
            container={searchRef}
            onToggle={onToggleFilters}
            placement='bottom-end'
            overlay={filtersPopover}
          >
            <span className='d-inline-block'>
              <OverlayTrigger placement='top' overlay={<Tooltip>Filter Search Results</Tooltip>}>
                <Button
                  className='btn-filters'
                  variant='dark'
                  size=''
                  active={showFilterOptions || searchFilter !== fullSearch}
                >
                  <FontAwesomeIcon icon={['fal', 'sliders-h']} size={'lg'} fixedWidth />
                </Button>
              </OverlayTrigger>
            </span>
          </OverlayTrigger>
        )}
      </div>
    </>
  );
}

CollectionToolbarSearch.propTypes = {
  collectionID: PropTypes.string,
};

CollectionToolbarSearch.defaultProps = {
  collectionID: '',
};

export default CollectionToolbarSearch;
