import classNames from 'classnames';

const AssetStat = ({ formattedStat, stat, label, description, disableColoring }) => {
  const statClasses = classNames({
    'stat-positive': !disableColoring && stat > 0,
    'stat-negative': !disableColoring && stat < 0,
  });

  return (
    <div className='asset-stat'>
      <h3 className={statClasses}>{formattedStat || stat}</h3>
      <h4>{label}</h4>
      <p>{description}</p>
    </div>
  );
};

export default AssetStat;
