import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import useSWR from 'swr';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
import { swrDataServicesFetcher } from '../helpers/DataServices';
// hooks
import useQueryString from '../hooks/UseQueryString';
// components
import Seo from '../components/global/Seo';
import AppLoader from '../components/global/Loader';
import SideNav from '../components/global/SideNav';
import TopNav from '../components/global/TopNav';
import AssetInfo from '../components/explore/AssetInfo';
import Minifolio from '../components/explore/Minifolio';
import GetStarted from '../components/explore/GetStarted';
import Footer from '../components/explore/Footer';

export default function ExploreAsset() {
  // router location
  const location = useLocation();
  // query params
  const queryParams = useQueryString();
  // body element class list
  const bodyClassList = document.body.classList;
  // get the id from the browser URL /a/:id
  const { id } = useParams();
  // user flag
  const [user, setUser] = useState(null);
  // asset ID to look up
  const [assetId, setAssetId] = useState(null);
  // meta data for SEO
  const metaData = {
    title: `Explore ${id.toUpperCase()}`,
    description: `Explore ${id.toUpperCase()} -- Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.`,
  };

  const { data: assetHistory, error: assetHistoryError } = useSWR(`/asset-history?symbol=${id.toUpperCase()}&includeStats=true`, swrDataServicesFetcher);
  const { data: assetDetails, error: assetDetailsError } = useSWR(`/asset/${id.toUpperCase()}`, swrDataServicesFetcher);

  /**
   * Happens once, gets URL path, sets body class
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // set className on body
    bodyClassList.add('asset-detail');
    // set id as assetId state
    setAssetId(id.toUpperCase());

    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    amplitudePayload.symbol = id.toUpperCase();

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_explore-single-asset-page', amplitudePayload);
    window.Appcues?.track('Explore single asset page');
    return () => {
      // remove className
      bodyClassList.remove('asset-detail');
    };
  }, []);

  if (!assetHistory) return <AppLoader />;

  if (assetHistory?.history.length === 0) {
    return (
      <Container className="h-100">
        <Row className="h-100">
          <Col sm={{ span: 12 }} className=" h-100 d-flex align-items-center justify-content-center">
            <h3>Sorry, we don't have that symbol</h3>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      {user !== null && <SideNav />}
      <Container fluid>
        <Row className="h-100">
          <TopNav pageTitle="Risk Smith" allowSearch />
          <div>
            <>
              <AssetInfo
                assetHistory={assetHistory?.history}
                assetDetails={assetDetails}
                symbol={id.toUpperCase()}
              />
              <Minifolio activeStock={id.toUpperCase()} />
              <GetStarted activeStock={id.toUpperCase()} />
            </>
            <div className='footer'>
              <Container>
                <Footer activeStock={id.toUpperCase()} />
              </Container>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
