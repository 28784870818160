import { useCallback, useGlobal } from 'reactn';
import { Modal } from 'react-bootstrap';
import AssetInfo from './AssetInfo';
import './AssetInfoModal.scss';

function AssetInfoModal(props) {
  const [activeTicker, setActiveTicker] = useGlobal('activeTicker');

  const handleHide = useCallback(() => {
    setActiveTicker(null);
  }, [setActiveTicker]);

  // amplitudeClient.getInstance().logEvent('action_open-asset-details', {
  //   symbol,
  // });

  return (
    <Modal
      show={!!activeTicker}
      onHide={handleHide}
      dialogClassName="AssetInfoModal"
    >
      <Modal.Header
        closeVariant="white"
        closeButton
      >
        <Modal.Title>Asset Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AssetInfo
          ticker={activeTicker}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AssetInfoModal;
