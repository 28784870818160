export const ItemTypes = {
  TEAM: 'team',
  PLAYER: 'player',
};

export const D3_VOLATILITY_OVERRIDES = { min: -15, max: 15 };

export const SAMPLE_PORTFOLIO_STOCKS = ['AAPL', 'BTC-USD', 'TSLA'];

export const CRYPTO_SYMBOLS = [
  'BTC-USD',
  'ETH-USD',
  'ADA-USD',
  'USDT-USD',
  'BCH-USD',
  'LTC-USD',
  'UNI-USD',
  'LINK-USD',
  'XLM-USD',
  'ETC-USD',
  'EOS-USD',
  'DOGE-USD',
  'SOL-USD',
];

export const ICON_COLORS = ['green', 'blue', 'orange'];

export const INVESTOR_FOLLOW_LIMIT = 9;

export const BENCHMARKS = [
  {
    symbol: 'SPY',
    color: '#F45D48',
    name: 'S&P500',
    show: false,
  },
  {
    symbol: 'DIA',
    color: '#f1f1e6',
    name: 'Dow Jones',
    show: false,
  },
  {
    symbol: 'QQQ',
    color: '#1FBD60',
    name: 'Nasdaq 100',
    show: false,
  },
];

export const SUPPORTED_EXCHANGES = [
  {
    name: 'US Equities Exchange',
    symbols: ['BATS', 'CBOE', 'BZX'],
  },
  {
    name: 'Chicago Stock Exchange',
    symbols: ['XCHI'],
  },
  {
    name: '',
    symbols: ['FINR', 'FINRA', 'TRACE'],
  },
  {
    name: 'Investors Exchange',
    symbols: ['IEXG'],
  },
  {
    name: 'NASDAQ Stock Market',
    symbols: ['XNAS', 'NASDAQ'],
  },
  {
    name: 'National Stock Exchange (USA)',
    symbols: ['XCIS'],
  },
  {
    name: 'New York Stock Exchange',
    symbols: ['XNYS'],
  },
  {
    name: 'NYSE American',
    symbols: ['XASE'],
  },
  {
    name: 'NYSE Arca',
    symbols: ['ARCX'],
  },
];
