import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { isEqual } from 'lodash';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
// data config
import { db } from '../config/firebase';
// context
import { CollectionContextProvider } from '../contexts/CollectionContext';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
// hooks
import useProfile from '../hooks/UseProfile';
import useQueryString from '../hooks/UseQueryString';
import useToast from '../hooks/UseToast';
// components
import Seo from '../components/global/Seo';
import AppLoader from '../components/global/AppLoader';
import Loader from '../components/global/Loader';
import SideNav from '../components/global/SideNav';
import Layout from '../components/Layout';
import CollectionList from '../components/collections/CollectionList';
import Collection from '../components/collections/Collection';
import CollectionStart from '../components/collections/CollectionStart';

export default function Collections() {
  // router location
  const location = useLocation();
  // router params
  const { id } = useParams();
  // query params
  const queryParams = useQueryString();
  // get profile API from context
  const { userID } = useProfile();
  // toast API
  const { addToast } = useToast();
  // loading flag
  const [loading, setLoading] = useState(true);
  const [collectionList, setCollectionList] = useState([]);
  const [activeCollectionID, setActiveCollectionID] = useState(id);

  // meta data for SEO
  const metaData = {
    title: 'Collections',
    description: `Collections -- Creating a great portfolio takes work. We'll help
      you get started, by guiding you through creating a team of stocks that's 
      optimized to win, and then giving you the tools to manage it for the long haul.`,
  };

  /**
   * Get the active collection from collectionList
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const activeCollection = useMemo(
    () => collectionList.find((c) => c.id === activeCollectionID),
    [collectionList, activeCollectionID]
  );

  /**
   * Amplitude tracking, runs only once
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    console.log('WELCOME TO COLLECTIONS');
    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_collection-page', amplitudePayload);
    window.Appcues?.track('View collection');
    return () => {};
  }, []);

  /**
   * Runs each time userID changes, should be once
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (userID) {
      // console.log('GETTING ALL COLLECTIONS');
      const unsubscribeTheseCollections = onSnapshot(
        query(
          collection(db, 'collections'),
          where('owner', '==', userID),
          where('claimed', '==', true),
          orderBy('name'),
        ),
        (snapshot) => {
          const collections = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          if (!isEqual(collections, collectionList)) {
            setCollectionList(collections);
          }

          setLoading(false);
        },
        (err) => {
          console.log('collection snapshot listen error', err);
          addToast('ERROR GETTING COLLECTIONS', err.message, 'error');
          setLoading(true);
        }
      );

      return () => {
        unsubscribeTheseCollections();
      };
    }
  }, [userID]);

  // /**
  //  * Runs each time the params id changes, should be everytime URL string changes
  //  * @author Ryan Srofe <rsrofe@gmail.com>
  //  * @returns - none
  //  */
  useEffect(() => {
    // SET THE COLLECTION BASED ON ID
    // console.log('SETTING COLLECTION', id);
    if (id === 'start') {
      setLoading(false);
      return;
    }

    setActiveCollectionID(id);

    return () => {};
  }, [id]);

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      <Layout>
        <Row className="">
          <CollectionContextProvider>
            <Col className='sidebar' sm={4}>
              <div className='collections'>
                {loading === true && <Loader />}
                {loading === false && (
                  <CollectionList collections={collectionList} urlParamId={id} />
                )}
              </div>
            </Col>
            <Col sm={8}>
              {collectionList.length === 0 && <CollectionStart />}
              {collectionList.length > 0 && <Collection collection={activeCollection} />}
            </Col>
          </CollectionContextProvider>
        </Row>
      </Layout>
    </>
  );
}
