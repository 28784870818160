import React from 'react';
import { Button, Card } from 'react-bootstrap';

export default function InvestorCardFooter({
  investorID,
  isFavorite,
  selectInvestor,
  deleteInvestor,
  atFollowLimit,
}) {
  /**
   * View the stock page
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  const handleSelectInvestor = (e) => {
    e.preventDefault();
    selectInvestor(investorID);
  };

  /**
   * Delete the stock
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  const handleDeleteInvestor = (e) => {
    e.preventDefault();
    deleteInvestor(investorID);
  };

  return (
    <Card.Footer>
      <div className='d-flex align-items-center p-3'>
        {!isFavorite && (
          <Button
            variant='dark'
            className='follow-btn'
            onClick={handleSelectInvestor}
            disabled={atFollowLimit}
          >
            Follow
          </Button>
        )}
        {isFavorite && (
          <Button variant='dark' className='follow-btn following' onClick={handleDeleteInvestor}>
            Unfollow
          </Button>
        )}
      </div>
    </Card.Footer>
  );
}
