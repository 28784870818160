import React, { useMemo } from 'react';
import readingTime from 'reading-time';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useContentfulEntries } from '../hooks/useContentful';

const query = {
  content_type: 'risksmithblog',
  limit: 3,
  order: '-sys.createdAt',
};

const htmlToText = (html) => {
  const d = document.createElement('div');
  d.innerHTML = html;

  return d.textContent || d.innerText || '';
};

function BlogPane() {
  const entries = useContentfulEntries(query);
  const posts = useMemo(() => {
    if (entries) {
      return entries.items.map(({ fields }) => {
        const bodyText = htmlToText(documentToHtmlString(fields.body));

        return {
          link: `https://finiac.com/blog/${fields.slug}`,
          title: htmlToText(fields.title),
          blurb: `${bodyText.substr(0, 200)}...`,
          readTime: readingTime(bodyText).text,
        };
      });
    }

    return [];
  }, [entries]);

  return (
    <>
      {posts.map(({ blurb, link, readTime, title }) => (
        <div className="card bg-dark mb-3" key={title}>
          <div className="card-body">
            <div className="badge bg-info rounded-pill float-end">From our blog</div>
            <a href={link} target="_blank" rel="noreferrer" className="text-white text-decoration-none">
              <h5 className="card-title" dangerouslySetInnerHTML={{ __html: title }} />
            </a>
            <h6 className="card-subtitle mb-2 text-muted fw-normal" style={{ fontSize: '0.75em' }}>{readTime}</h6>
            <p className="card-text" dangerouslySetInnerHTML={{ __html: blurb }} style={{ fontSize: '0.825em' }} />
          </div>
        </div>
      ))}
    </>
  );
}

export default BlogPane;
