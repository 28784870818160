import { useContext } from 'react';
import { SettingsContext } from '../contexts/SettingsContext';

export default function UseSettings() {
  const {
    collapsed,
    toggled,
    muted,
    weighting,
    momentum,
    globalBenchmarks,
    setCollapse,
    setToggle,
    collapseSidebar,
    toggleSidebar,
    toggleMuted,
    setWeight,
    toggleMomentum,
    setGlobalBenchmarks,
  } = useContext(SettingsContext);
  return {
    collapsed,
    toggled,
    muted,
    weighting,
    momentum,
    globalBenchmarks,
    setCollapse,
    setToggle,
    collapseSidebar,
    toggleSidebar,
    toggleMuted,
    setWeight,
    toggleMomentum,
    setGlobalBenchmarks,
  };
}
