import axios from 'axios';

const rootURL = process.env.REACT_APP_DATA_SERVICES_URL;

export async function getAssetHistory({ symbol, limit, includeStats, controller }) {
  const url = `${rootURL}/asset-history`;
  const history = await axios.get(
    url,
    { params: { symbol, limit, includeStats } },
    { signal: controller?.signal }
  );
  return history;
}

export async function getPortfolioPL({ symbols, weights, shares }) {
  const url = `${rootURL}/multiAssetPL`;
  const pl = await axios.post(url, { symbols, weights, shares });
  return pl;
}

export async function getPortfolioAllocation(symbols) {
  const url = `${rootURL}/portfolioAllocation`;
  const alloc = await axios.get(url, { params: { symbols } });
  return alloc;
}

export async function getCovarianceMatrix(symbols) {
  const url = `${rootURL}/covarianceMatrix`;
  const matrix = await axios.get(url, { params: { symbols } });
  return matrix;
}

export async function sortAssetsByCorrelation(startingPL, bullpenOfSymbols, weighting, controller) {
  const url = `${rootURL}/sortByCorrelation`;
  const { data: matrix } = await axios.post(
    url,
    {
      startingPL,
      bullpenOfSymbols,
      weighting,
    },
    { signal: controller.signal }
  );
  return matrix;
}

export async function getAssetDetails(symbol, controller) {
  const url = `${rootURL}/asset/${symbol}`;
  const { data: details } = await axios.get(url, { signal: controller?.signal });
  return details;
}

export async function getAllTheTickers() {
  const url = 'https://staging-data.risksmith.com/symbols'; // TODO: this is hardcoded to staging for testing, switch to prod once ready
  const { data: tickers } = await axios.get(url);
  return tickers;
}

// Used for the SWR hook
const swrRequest = axios.create({
  baseURL: rootURL,
});

export const swrDataServicesFetcher = async (url, payload) => {
  if (payload) {
    const res = await swrRequest.post(url, payload);

    return res.data;
  }

  const res = await swrRequest.get(url);

  return res.data;
};
