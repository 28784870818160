// Pinched from https://dev.to/rexebin/build-an-ag-grid-react-component-that-auto-resize-columns-to-fit-container-width-1h5n

import { useEffect, useState } from 'react';

export default function useDebounce(value, delay = 150) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
