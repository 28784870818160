/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { isSupported } from 'firebase/messaging';
// hooks
import useToast from '../../hooks/UseToast';
import useProfile from '../../hooks/UseProfile';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// data config
// import { messaging } from '../../config/firebase';
// components
import Loader from './Loader';

export default function Notifications() {
  // loading flag
  const [loading, setLoading] = useState(true);
  // add toast API for display to the user
  const { addToast } = useToast();
  // get the current user profile API context
  const { userID } = useProfile();
  // const { messaging } = useFirebase();

  // we may need to put the following functions in useFirebase()
  // https://github.com/firebase/snippets-web/blob/1c4c6834f310bf53a98b3fa3c2e2191396cacd69/messaging/index.js#L6-L6
  // async function receiveMessage() {
  //   // [START messaging_receive_message]
  //   // Handle incoming messages. Called when:
  //   // - a message is received while the app has focus
  //   // - the user clicks on an app notification created by a service worker
  //   //   `messaging.onBackgroundMessage` handler.
  //   try {
  //     if (await isSupported()) {
  //       messaging.onMessage((payload) => {
  //         console.log('Message received. ', payload);
  //         // ...
  //       });
  //     }
  //   } catch (error) {
  //     console.log('receiveMessage', error);
  //   }
  //   // [END messaging_receive_message]
  // }

  // async function requestPermission() {
  //   // [START messaging_request_permission]
  //   try {
  //     if (await isSupported()) {
  //       Notification.requestPermission().then((permission) => {
  //         if (permission === 'granted') {
  //           console.log('Notification permission granted.');
  //           // TODO(developer): Retrieve a registration token for use with FCM.
  //           // ...
  //         } else {
  //           console.log('Unable to get permission to notify.');
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.log('messaging error', error);
  //   }
  //   // [END messaging_request_permission]
  // }

  // async function getToken() {
  //   // [START messaging_get_token]
  //   // Get registration token. Initially this makes a network call, once retrieved
  //   // subsequent calls to getToken will return from cache.
  //   try {
  //     if (await isSupported()) {
  //       messaging
  //         .getToken({ vapidKey: '<YOUR_PUBLIC_VAPID_KEY_HERE>' })
  //         .then((currentToken) => {
  //           if (currentToken) {
  //             console.log('currentToken', currentToken);
  //             // Send the token to your server and update the UI if necessary
  //           } else {
  //             // Show permission request UI
  //             console.log('No registration token available. Request permission to generate one.');
  //             requestPermission();
  //           }
  //         })
  //         .catch((err) => {
  //           console.log('An error occurred while retrieving token. ', err);
  //           // ...
  //         });
  //     }
  //   } catch (error) {
  //     console.log('getToken', error);
  //   }
  //   // [END messaging_get_token]
  // }

  // async function deleteToken() {
  //   // [START messaging_delete_token]
  //   try {
  //     if (await isSupported()) {
  //       messaging
  //         .deleteToken()
  //         .then(() => {
  //           console.log('Token deleted.');
  //           // ...
  //         })
  //         .catch((err) => {
  //           console.log('Unable to delete token. ', err);
  //         });
  //     }
  //   } catch (error) {
  //     console.log('deleteToken', error);
  //   }
  //   // [END messaging_delete_token]
  // }

  const dismissMessage = (id) => {
    console.log('delete', id);
    amplitudeClient.getInstance().logEvent('action_delete-notification', { notificationID: id });
    window.Appcues?.track('Delete notification');
  };

  // this effect is only run once on load
  useEffect(async () => {
    setLoading(false);
    // try {
    //   if (await isSupported()) {
    //     requestPermission();
    //   }
    // } catch (error) {
    //   console.log('messaging error', error);
    // }
    // setLoading('done');
    return () => {};
  }, []);

  return (
    <>
      <h6>Notifications</h6>
      {loading === 'done' && (
        <div className='ftu'>
          <p>We may need to ask users for permission to send notifications</p>
        </div>
      )}
      {loading === true && <Loader />}
      {loading === false && (
        <ListGroup as='ol'>
          <ListGroup.Item key='notification01' as='li'>
            <div className='message'>
              <div className='message__title'>Est sit amet facilisis magna etiam tempor orci</div>
              <div className='message__body'>
                Auctor neque vitae tempus quam pellentesque nec nam aliquam sem. Lacinia quis vel
                eros donec ac. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt
                ornare massa.
              </div>
              <div className='message__date'>Today</div>
            </div>
            <div className='action'>
              <Button size='sm' variant='link' onClick={() => dismissMessage('notification 01')}>
                <FontAwesomeIcon icon={['fal', 'times']} fixedWidth />
              </Button>
            </div>
          </ListGroup.Item>
          <ListGroup.Item key='notification02' as='li'>
            <div className='message'>
              <div className='message__title'>Posuere sollicitudin aliquam</div>
              <div className='message__body'>
                Tincidunt arcu non sodales neque sodales ut etiam sit amet. Quam id leo in vitae.
                Enim nec dui nunc mattis.
              </div>
              <div className='message__date'>Yesterday</div>
            </div>
            <div className='action'>
              <Button size='sm' variant='link' onClick={() => dismissMessage('notification 02')}>
                <FontAwesomeIcon icon={['fal', 'times']} fixedWidth />
              </Button>
            </div>
          </ListGroup.Item>
          <ListGroup.Item key='notification03' as='li'>
            <div className='message'>
              <div className='message__title'>Viverra orci sagittis eu volutpat odio facilisis</div>
              <div className='message__body'>
                Volutpat consequat mauris nunc congue. Metus vulputate eu scelerisque felis
                imperdiet proin fermentum leo vel. Lorem ipsum dolor sit amet consectetur adipiscing
                elit ut aliquam.
              </div>
              <div className='message__date'>January 18, 2022</div>
            </div>
            <div className='action'>
              <Button size='sm' variant='link' onClick={() => dismissMessage('notification 03')}>
                <FontAwesomeIcon icon={['fal', 'times']} fixedWidth />
              </Button>
            </div>
          </ListGroup.Item>
        </ListGroup>
      )}
    </>
  );
}
