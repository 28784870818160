import React, { useCallback, useState } from 'react';

const ImageWithFallback = ({ fallback, src, ...props }) => {
  const [isFallingBack, setIsFallingBack] = useState(false);
  const handleError = useCallback(() => setIsFallingBack(true), []);

  if (isFallingBack || !src) {
    return (
      <img
        src={fallback}
        {...props}
      />
    );
  }

  return (
    <img
      src={src}
      onError={handleError}
      {...props}
    />
  );
};

export default ImageWithFallback;
