import React, {
  useCallback,
  useGlobal,
  useEffect,
  useRef,
  useState,
} from 'reactn';
import {
  Button,
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useHistory } from 'react-router-dom';
import AssetSearch from '../AssetSearch';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// hooks
import useProfile from '../../hooks/UseProfile';
import useSettings from '../../hooks/UseSettings';
import useToast from '../../hooks/UseToast';
import { addBlankPortfolio } from '../../helpers/Firebase';
// components
import Notifications from './Notifications';
// media
import riskSmithMark from '../../media/risksmith-mark.svg';

const NavbarBrand = ({ readOnly }) => {
  const { toggleSidebar } = useSettings();

  const handleToggleSidebar = useCallback(() => {
    amplitudeClient.getInstance().logEvent('action_toggle-side-nav', { toggled: 'open' });
    toggleSidebar();
  }, [toggleSidebar]);

  return (
    <>
      {!readOnly && (
        <Button
          className="nav-link toggle-sidebar"
          variant="primary"
          onClick={handleToggleSidebar}
        >
          <FontAwesomeIcon icon={['fas', 'bars']} fixedWidth size="lg" />
        </Button>
      )}
      <Navbar.Brand>
        <img src={riskSmithMark} alt="RiskSmith Logo" height="25" width="25" />
      </Navbar.Brand>
    </>
  );
};

export default function TopNav({
  cpid,
  pageTitle,
  promo,
  allowSearch,
}) {
  // add toast API
  const { addToast } = useToast();
  // current user auth
  const [currentUser] = useGlobal('currentUser');
  // get the current user profile API context
  const { userID, userEmail, portfolioList, isAlphaTester } = useProfile();
  // router location
  const location = useLocation();
  // router history
  const history = useHistory();
  // current path from location
  const { pathname } = location;
  // body element class list
  const bodyClassList = document.body.classList;
  const [portfolio, setPortfolio] = useGlobal('activePortfolio');

  function keepLastIndex(obj) {
    if (window.getSelection) {
      // ie11 10 9 ff safari
      obj.focus(); // Solve the problem that ff cannot locate the focus without getting the focus
      const range = window.getSelection(); // Create range
      range.selectAllChildren(obj); // range selects all child content under obj
      range.collapseToEnd(); // Move the cursor to the end
    } else if (document.selection) {
      // ie10 9 8 7 6 5
      const range = document.selection.createRange(); // Create selection object
      // const range = document.body.createTextRange();
      range.moveToElementText(obj); // range is positioned to obj
      range.collapse(false); // Move the cursor to the end
      range.select();
    }
  }

  // handle amplitude tracking
  const trackSignUp = () => {
    amplitudeClient.getInstance().logEvent('action_topbar-sign-up');
    window.Appcues?.track('Sign up from topbar');
  };
  const trackLogIn = () => {
    amplitudeClient.getInstance().logEvent('action_topbar-log-in');
    window.Appcues?.track('Log in from topbar');
  };
  const trackSharedSignup = () => {
    amplitudeClient.getInstance().logEvent('action_shared-portfolio-signup-cta', {
      portfolioId: cpid,
    });
    window.Appcues?.track('Signup from shared portfolio CTA');
  };

  /**
   * Handle selecting a portfolio or adding a new one
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none, however user is taken to the new portfolio route
   */
  const onAddPortfolio = async () => {
    // add new blank portfolio and go to new portfolio page
    await addBlankPortfolio(userID).then((portfolioID) => {
      // amplitude tracking
      amplitudeClient.getInstance().logEvent('action_add-new-portfolio', {
        portfolioId: portfolioID,
      });
      window.Appcues?.track('Add new portfolio');
      history.push(`/p/${portfolioID}`);
    });
  };

  /**
   * Handle toggling of notifications
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const onToggleNotifications = (show) => {
    if (show === true) {
      amplitudeClient.getInstance().logEvent('action_toggle-notifications', { toggled: 'open' });
      window.Appcues?.track('Toggle notifications open');
      return;
    }
    if (show === false) {
      amplitudeClient.getInstance().logEvent('action_toggle-notifications', { toggled: 'closed' });
      window.Appcues?.track('Toggle notifications closed');
    }
  };

  const popover = (
    <Popover id="notifications">
      {/* <Popover.Header as='h3'>Popover right</Popover.Header> */}
      <Popover.Body>
        <Notifications />
      </Popover.Body>
    </Popover>
  );

  /**
   * Sets and removes the class on body element per route path
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // split the path into an array [0] seems to always =''
    const pathArray = pathname.split('/');
    const pathArrayLength = pathArray.length;
    let newBodyClass;
    switch (pathArray[pathArrayLength - 2]) {
      case 'p':
        newBodyClass = 'portfolio';
        break;

      case 'i':
        newBodyClass = 'institution';
        break;

      case 'c':
        newBodyClass = 'collections';
        break;

      case 'explore':
        newBodyClass = 'explore';
        break;

      default:
        newBodyClass = pathArray[pathArrayLength - 1];
        break;
    }

    // set className
    bodyClassList.add(newBodyClass);

    return () => {
      // remove className
      bodyClassList.remove(newBodyClass);
    };
  }, [bodyClassList, pathname]);

  const renderPrivateNavbarContainer = () => (
    <Container fluid className="px-3">
      <>
        <NavbarBrand />
        <Nav className="flex-grow-1">
          <AssetSearch />
        </Nav>
        <Nav className='ms-3'>
          {portfolioList.length === 0 && (
            <Button variant='warning' onClick={onAddPortfolio}>
              Start
            </Button>
          )}
        </Nav>
        {isAlphaTester && (
          <Nav className="ms-auto">
            <OverlayTrigger
              trigger="click"
              onToggle={onToggleNotifications}
              placement="bottom-end"
              overlay={popover}
              rootClose
            >
              <Button className="m-0">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={['fas', 'bell']} />
                  <span className="fa-layers-counter" style={{ background: 'Tomato' }} />
                </span>
              </Button>
            </OverlayTrigger>
          </Nav>
        )}
        {!isAlphaTester && (
          <div className="ms-auto" />
        )}
      </>
    </Container>
  );

  const renderPublicNavbarContainer = () => (
    <Container>
      <NavbarBrand readOnly />
      <Navbar.Text>
        <h4 className='portfolio-name'>{pageTitle || 'RiskSmith'}</h4>
      </Navbar.Text>
      {promo ? (
        <Nav className='ms-auto'>
          <Link
            to={`/signup?utm_campaign=shared-portfolio&utm_source=${cpid}`}
            className='nav-link share-cta'
            onClick={trackSharedSignup}
          >
            Track your own portfolio, free!{' '}
            <FontAwesomeIcon icon={['fal', 'arrow-circle-right']} fixedWidth className='ms-2' />
          </Link>
        </Nav>
      ) : (
        <Nav className="flex-grow-1">
          <AssetSearch />
          <Link to='/signup' className='nav-link' onClick={trackSignUp}>
            Sign Up
          </Link>
          {/* <Link to='/login' className='nav-link' onClick={trackLogIn}>
            Log In
          </Link> */}
        </Nav>
      )}
    </Container>
  );

  const navbarContainer = (currentUser && userEmail)
    ? renderPrivateNavbarContainer()
    : renderPublicNavbarContainer();

  return (
    <Navbar as='header' variant='dark py-0' sticky={promo ? '' : 'top'}>
      {navbarContainer}
    </Navbar>
  );
}
