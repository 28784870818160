import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
// components
import Loader from '../global/Loader';
import InvestorAcronym from './InvestorAcronym';
import CompanyImage from '../global/CompanyImage';
import InvestorStat from './InvestorStat';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import { buildClearbitURL } from '../../helpers/Clearbit';

export default function InvestorCardBody({ investor, isFavorite }) {
  // state
  const [iconColor, setIconColor] = useState('orange');
  /**
   * Cleans up the various URL's so they consistent when displaying
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - The cleaned up URL
   */
  const cleanUpWebsite = (website) => {
    let cleanedWebsite = '';
    const splitAtSlash = website.split('//');
    // First, get rid of the protocol
    if (splitAtSlash.length === 1) cleanedWebsite = splitAtSlash[0]; //eslint-disable-line
    if (splitAtSlash.length === 2) cleanedWebsite = splitAtSlash[1]; //eslint-disable-line

    // Now, get rid of any trailing slashes
    const lastChar = cleanedWebsite[cleanedWebsite.length - 1];
    if (lastChar === '/') {
      cleanedWebsite = cleanedWebsite.substring(0, cleanedWebsite.length - 1);
    }

    // And finally, get rid of any www
    if (cleanedWebsite.split('www.').length > 1) {
      return cleanedWebsite.split('www.')[1];
    }

    return cleanedWebsite;
  };

  const onClickWebsite = (e) => {
    console.log('clicked', e.target.href, e.target.name);
    amplitudeClient.getInstance().logEvent('action_visit-investor-website', {
      investor: e.target.name,
      website: e.target.href,
    });
  };

  /**
   * Runs each time the InvestorCardBody component is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    const firstChar = investor.name.charAt(0);
    if (/^[a-i0-9]+$/i.test(firstChar)) {
      setIconColor('blue');
    }
    if (/^[j-r]+$/i.test(firstChar)) {
      setIconColor('green');
    }
    if (/^[s-z]+$/i.test(firstChar)) {
      setIconColor('orange');
    }
    return () => {};
  }, []);

  return (
    <Card.Body>
      {/* <InvestorAcronym color={iconColor} name={investor.name} /> */}
      <div className='icon-container'>
        <CompanyImage
          investor={investor}
          className='investor-icon'
        />
      </div>
      {investor.name && <p className='investor-name'>{investor.name}</p>}
      {investor.website && (
        <p className='investor-url'>
          <a
            href={investor.website}
            target='_blank'
            className='investor-url'
            onClick={onClickWebsite}
            name={investor.name}
          >
            {cleanUpWebsite(investor.website)}
          </a>
        </p>
      )}
      <div className={'investor-stats d-flex'}>
        <div className='w-50'>
          <InvestorStat
            label='3 Year Risk Efficiency'
            value={(investor.sortino3yr / Math.sqrt(2)).toFixed(2)}
          />
        </div>
        <div className='w-50'>
          <InvestorStat label='3 Year Annualized Gains' value={`${investor.performance3yr}%`} />
        </div>
      </div>
      <div className={'investor-holdings'}>
        <InvestorStat label='Holdings' value={investor.holdings[0].symbols.length} />
      </div>
    </Card.Body>
  );
}
