import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
// helpers
// components
import Loader from '../global/Loader';
import Histogram from '../visuals/Histogram';
import AssetStats from './AssetStats';
import CompanyImage from '../global/CompanyImage';
import withCopy from '../visuals/withCopy';

const HistogramWithCopy = withCopy(Histogram);

export default function AssetInfo({
  assetHistory,
  assetDetails,
  symbol,
}) {
  const [open, setOpen] = useState(false);

  // dates
  const intl = useIntl();

  if (!assetHistory) return <Loader />;

  const yesterday = assetHistory[assetHistory.length - 1];
  const growth = ((yesterday.closePrice / assetHistory[0].closePrice - 1) * 100).toFixed(2);
  const historyStartDate = intl.formatDate(assetHistory[0].date);

  return (
    <>
      <Container>
        <Row as='section' id={symbol} className='asset-info'>
          <Col sm={{ span: 8, offset: 2 }}>
            <div className='company-icon-container'>
              <a href={assetDetails?.company_url} target='_blank' rel='noopener noreferrer'>
                <CompanyImage
                  assetDetails={assetDetails}
                  className='company-icon'
                />
              </a>
            </div>
            <h1>{assetDetails?.name || symbol}</h1>
            <h5>{symbol}</h5>
            <p className={open ? 'open' : ''}>
              {assetDetails?.short_description ? (
                <>
                  <small>
                    {open
                      ? assetDetails?.short_description
                      : assetDetails?.short_description.slice(0, 420)}
                  </small>
                  <Button
                    onClick={() => setOpen(!open)}
                    aria-controls='example-collapse-text'
                    aria-expanded={open}
                  >
                    <FontAwesomeIcon
                      icon={['fas', `chevron-${open ? 'up' : 'down'}`]}
                      size={'lg'}
                      fixedWidth
                    />
                  </Button>
                </>
              ) : null}
            </p>
            <p className='lead'>How {symbol}'s 1-year returns are distributed</p>
            <div className='d3-chart'>
              <HistogramWithCopy dailyHistory={{ history: assetHistory }} />
            </div>
          </Col>
          <AssetStats
            dScore={(yesterday.stats.dScore * 100).toFixed(2)}
            riskEfficiency={yesterday.stats.adjustedSortino.toFixed(2)}
            growth={growth}
            growthStartDate={historyStartDate}
          />
        </Row>
      </Container>
    </>
  );
}
