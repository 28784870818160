import React, {
  useCallback,
  useGlobal,
  useEffect,
  useState,
} from 'reactn';
import { useParams } from 'react-router-dom';
import {
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { isEqual } from 'lodash';
// data config
import { db } from '../config/firebase';
// components
import AppLoader from '../components/global/AppLoader';
import PrivatePortfolio from './PrivatePortfolio';
import SharedPortfolio from './SharedPortfolio';
import { verifyPortfolioOwner } from '../helpers/Firebase';

export default function Portfolio() {
  const [activePortfolio, setActivePortfolio] = useGlobal('activePortfolio');
  const [portfolioPL, setPortfolioPL] = useState([]);
  const [isRealPortfolio, setIsRealPortfolio] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [hasLoadedPortfolioOwner, setHasLoadedPortfolioOwner] = useState(false);
  const [isPortfolioOwner, setIsPortfolioOwner] = useState(false);
  const { id } = useParams();
  const [currentUser] = useGlobal('currentUser');

  const updateDataForLocation = useCallback(async () => {
    setStockList([]);
    const unsubscribeThisPortfolio = onSnapshot(
      doc(db, 'portfolios', id),
      (portfolio) => {
        const nextActivePortfolio = {
          ...portfolio.data(),
          id: portfolio.id,
        };

        if (!isEqual(nextActivePortfolio, activePortfolio)) {
          setActivePortfolio(nextActivePortfolio);
        }

        setIsRealPortfolio(portfolio.isReal);
      });

    const unsubscribeTheseStocks = onSnapshot(
      collection(doc(db, 'portfolios', id), 'stocks'),
      (snapshot) => {
        const assets = snapshot.docs
          .filter((d) => d.data().symbol)
          .map((d) => ({
            ...d.data(),
            id: d.id,
          }));

        if (!isEqual(assets, stockList)) {
          setStockList(assets);
        }
      });

    let ownsPortfolio = false;
    if (currentUser) {
      ownsPortfolio = await verifyPortfolioOwner(id, currentUser.uid);
    }
    setIsPortfolioOwner(ownsPortfolio);
    setHasLoadedPortfolioOwner(true);

    return () => {
      unsubscribeThisPortfolio();
      unsubscribeTheseStocks();
    };
  }, [id]);

  /**
   * Reload portfolio data when the location changes
   */
  useEffect(() => {
    updateDataForLocation();
  }, [updateDataForLocation]);

  if (!portfolioPL || !activePortfolio) return <AppLoader />;

  // right now, this is fine, but later, may need shares by day passed in'
  let { totalAmountInvested } = activePortfolio;
  if (isRealPortfolio && portfolioPL[0]) {
    totalAmountInvested = portfolioPL[0].equal.closePrice;
  }

  if (!hasLoadedPortfolioOwner) return <AppLoader />;

  if (!isPortfolioOwner) {
    return <SharedPortfolio activePortfolio={activePortfolio} stockList={stockList} />;
  }

  return <PrivatePortfolio activePortfolio={activePortfolio} stockList={stockList} />;
}
