import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button } from 'react-bootstrap';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// components
import Loader from '../global/Loader';

export default function Footer({ activeStock }) {
  // state
  const [loading, setLoading] = useState(true);

  /**
   * Handle the onclick event for the button
   * @returns - none
   */
  const handleJoinDiscord = () => {
    // amplitude tracking
    amplitudeClient.getInstance().logEvent('action_join-discord', {
      symbol: activeStock,
    });
    window.Appcues?.track('Click join Discord link');
    // send the user to the discord server
    window.location = 'https://discord.gg/qau4UWnfdF';
  };

  /**
   * Happens once
   * @returns - none
   */
  useEffect(() => {
    // set loading to false
    setLoading(false);

    return () => {};
  }, []);

  return (
    <>
      {loading === true && (
        <div className='loading'>
          <Loader />
        </div>
      )}
      {loading === false && (
        <Row>
          <Col sm='6'>
            <h4>Latest from @risksmithhq</h4>
            <TwitterTimelineEmbed
              autoHeight={true}
              linkColor='#1fbd60'
              noBorders={true}
              noFooter={true}
              noHeader={true}
              noScrollbar={true}
              screenName='risksmithhq'
              sourceType='profile'
              theme='dark'
              transparent={true}
            />
          </Col>
          <Col sm={{ span: 5, offset: 1 }}>
            <h4>Chat stats with us on Discord</h4>
            <p>
              Interested in these histograms and stats? Curious about building portfolios and
              designing risk yourself? Join our Discord and connect with the Risksmith team and a
              community of folks that think just like you.
            </p>
            <Button onClick={handleJoinDiscord}>
              <FontAwesomeIcon icon={['fab', 'discord']} size={'lg'} fixedWidth /> Join our Discord
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}
