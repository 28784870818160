import { useState } from 'react';
import useWindowSize from './UseWindowSize';

const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export function useIsBelowBreakpoint(bp = 'xs') {
  const { width } = useWindowSize();

  return (
    (bp === 'xs' && width < breakpoints.xs)
    || (bp === 'sm' && width < breakpoints.sm)
    || (bp === 'md' && width < breakpoints.md)
    || (bp === 'lg' && width < breakpoints.lg)
    || (bp === 'xl' && width < breakpoints.xl)
    || (bp === 'xxl' && width < breakpoints.xxl)
  );
}

export default useIsBelowBreakpoint;
