import React, { useState, useCallback } from 'react';

export const ToastContext = React.createContext({
  toast: null,
  addToast: () => {},
  removeToast: () => {},
});

export default function ToastProvider({ children }) {
  const [toast, setToast] = useState(null);

  const removeToast = () => setToast(null);

  const addToast = (message, status, type) => setToast({ message, status, type });

  const contextValue = {
    toast,
    addToast: useCallback((message, status, type) => addToast(message, status, type), []),
    removeToast: useCallback(() => removeToast(), []),
  };

  return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
}
