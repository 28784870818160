import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// helpers
import { getPortfolioAllocation, getPortfolioPL } from '../../helpers/DataServices';
// components
import Loader from '../global/Loader';
import Histogram from '../visuals/Histogram';
import EmptyState from '../global/EmptyState';

export default function FolioCombo({ ticker, rando }) {
  // state
  const [loading, setLoading] = useState(true);
  const [portfolioPL, setPortfolioPL] = useState([]);
  const [error, setError] = useState();
  const [symbolCombo, setSymbolCombo] = useState([]);
  const [histogramData, setHistogramData] = useState([]);
  const [dScore, setDScore] = useState(0);
  const [annualReturn, setAnnualReturn] = useState(0);
  const [riskEfficiency, setRiskEfficiency] = useState(0);

  /**
   * Get the combined PL for the two assets
   * @returns - none
   */
  const fetchPL = async (symbols) => {
    setLoading(true);
    const { data: alloc } = await getPortfolioAllocation(symbols);
    const { data: pl } = await getPortfolioPL({
      symbols,
      weights: alloc,
    });

    if (Array.isArray(pl)) {
      setPortfolioPL(pl);
    } else {
      setError(true);
    }

    setLoading(false);
  };

  /**
   * Happens each time the ticker and rando props changes
   * @returns - none
   */
  useEffect(() => {
    if (ticker !== undefined && rando !== undefined) {
      setSymbolCombo([ticker, rando]);
      // get the combined PL for the two assets
      fetchPL([ticker, rando]);
    }
    return () => {};
  }, [ticker, rando]);

  /**
   * Happens each time the portfolioPL changes
   * @returns - none
   */
  useEffect(() => {
    if (portfolioPL.length !== 0) {
      const lastDayOfPL = portfolioPL[portfolioPL.length - 1];
      const firstDayOfPL = portfolioPL[0];
      if (portfolioPL !== 'Weights and symbols must match') {
        setHistogramData(portfolioPL.map((day) => day.equal));
        setDScore(lastDayOfPL.stats.equal.dScore);
        setAnnualReturn(lastDayOfPL.equal.closePrice / firstDayOfPL.equal.closePrice - 1);
        setRiskEfficiency(lastDayOfPL.stats.equal.adjustedSortino);
      } else {
        setLoading(true);
      }
    }
    return () => {};
  }, [portfolioPL]);

  return (
    <Col sm={4}>
      {loading === true && (
        <Loader
          height='350px'
          message={`Loading the shape of risk for ${ticker} & ${rando}, this can take a few seconds`}
        />
      )}
      {loading === false && error && (
        <EmptyState
          height='350px'
          iconName={['fas', 'exclamation-triangle']}
          message={'Unable to load asset combo'}
          submessage='Please try again later'
        />
      )}
      {loading === false && !error && (
        <>
          {symbolCombo.length > 0 && portfolioPL.length > 0 && (
            <div className='folio-combo'>
              <strong>
                {ticker} + {rando}
              </strong>
              <div className='histogram'>
                <Histogram dailyHistory={{ history: histogramData }} />
              </div>
              <Row className='folio-stats'>
                <Col xs={4} className='folio-stat'>
                  <strong>{riskEfficiency.toFixed(2)}</strong>
                  <small>Risk Efficiency</small>
                </Col>
                <Col xs={4} className='folio-stat'>
                  <strong>{(dScore * 100).toFixed(2)}%</strong>
                  <small>dScore</small>
                </Col>
                <Col xs={4} className='folio-stat'>
                  <strong>{(annualReturn * 100).toFixed(2)}%</strong>
                  <small>Growth</small>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </Col>
  );
}
