import classNames from 'classnames';

const StockStat = ({ formattedStat, stat, label, disableColoring }) => {
  const statClasses = classNames({
    'stat-positive': !disableColoring && stat > 0,
    'stat-negative': !disableColoring && stat < 0,
  });

  return (
    <div className="stock-stat">
      <span className={statClasses}>{ formattedStat || stat }</span>
      <span>{ label }</span>
    </div>
  );
};

export default StockStat;
