import React, { useGlobal } from 'reactn';
import { Route } from 'react-router-dom';
import Portfolio from '../../routes/Portfolio';

export default function ShareablePortfolioRoute({ ...rest }) {
  const [currentUser] = useGlobal('currentUser');
  const portfolioId = rest.computedMatch.params.id;
  // TODO: get the portfolioID from the route, grab it from firebase, see if this person owns it

  return (
    <Route
      { ...rest }
      render={(props) => <Portfolio {...props} />}
    />
  );
}
