import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
// hooks
import useProfile from '../hooks/UseProfile';
import useQueryString from '../hooks/UseQueryString';
// components
import Seo from '../components/global/Seo';
import Loader from '../components/global/Loader';
import Layout from '../components/Layout';
import UserProfile from '../components/profile/UserProfile';

export default function Profile() {
  // router location
  const location = useLocation();
  // query params
  const queryParams = useQueryString();
  // get profile API from context
  const { userID, portfolioList } = useProfile();
  // loading flag
  const [loading, setLoading] = useState(true);
  // meta data for SEO
  const metaData = {
    title: 'Profile',
    description:
      "Profile -- Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.",
  };

  /**
   * Amplitude tracking
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_profile-page', amplitudePayload);
    window.Appcues?.track('View profile');
    return () => {};
  }, []);

  /**
   * Checks for both a user and a list of portfolios
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // check for user
    if (userID !== '') {
      // console.log('found user ID', userID, 'with', portfolioList.length, 'portfolios');
      setLoading(false);
    }

    return () => {};
  }, [userID, portfolioList]);

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      <Layout>
        <Row>
          <Col className='main' sm={12}>
            {loading === true && <Loader />}
            {loading === false && <UserProfile />}
          </Col>
        </Row>
      </Layout>
    </>
  );
}
