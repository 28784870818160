import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// hooks
import useToast from '../../hooks/UseToast';
import useProfile from '../../hooks/UseProfile';
import { addBlankCollection, removeCollection, updateCollection } from '../../helpers/Firebase';
// components
import CollectionListItem from './CollectionListItem';

function CollectionsList({ collections, urlParamId }) {
  // router history
  const history = useHistory();
  // loading flag
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  // get the current user profile API context
  const { userID } = useProfile();
  // add toast API for display to the user
  const { addToast } = useToast();

  /**
   * Handle adding new collection
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const onAddCollection = async () => {
    // add new collection
    await addBlankCollection(userID).then((collectionID) => {
      // track event
      amplitudeClient.getInstance().logEvent('action_add-new-collection', {
        collectionId: collectionID,
      });
      window.Appcues?.track('Add new collection');
      // redirect to new collection
      history.push(`/c/${collectionID}`);
    });
  };

  /**
   * Handle remove collection
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const onRemoveCollection = useCallback((rid) => {
    console.log('remove collection', rid, 'from', collections);
    // track event
    amplitudeClient.getInstance().logEvent('action_delete-collection', {
      collectionId: rid,
    });
    window.Appcues?.track('Delete collection');
    // // remove collection by ID
    setIsDeleting(true);
    removeCollection(rid);
  }, [collections]);

  /**
   * Handle undo remove event
   * @author Jusselly Moreno <jusselly.moreno@intechideas.com>
   * @returns - none
   */
  const handleUndoRemoveEvent = useCallback((event, rid) => {
    if (event) {
      updateCollection(rid, { claimed: true });
    }
  }, []);

  /**
   * Handle remove collection alert
   * @author Jusselly Moreno <jusselly.moreno@intechideas.com>
   * @returns - none
   */
  const onRemoveCollectionAlert = useCallback((rid) => {
    const collectionName = collections.find((collection) => collection.id === rid)?.name;

    addToast(
      `${collectionName} has been removed`,
      { type: 'undo', id: rid, handler: handleUndoRemoveEvent },
      'success'
    );

    onRemoveCollection(rid);
  }, [addToast, collections, handleUndoRemoveEvent, onRemoveCollection]);

  /**
   * One-time load
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (collections.length === 0) {
      setIsFirstTimeUser(true);
      // redirect to start
      history.push('/c/start');
    }

    if (collections.length > 0) {
      if (isDeleting) {
        history.push(`/c/${collections[0].id}`);
        setIsDeleting(false);
      }
      setIsFirstTimeUser(false);
      return;
    }

    return () => {};
  }, [collections, history, isDeleting]);

  return (
    <>
      {isFirstTimeUser === true && (
        <>
          <p className="mt-4">
            <small>
              Don't lose track of things you might want to invest in. Create collections, monitor
              those assets, and find the next perfect asset for your portfolio.
            </small>
          </p>
          <Button className="mt-1" variant="primary" onClick={onAddCollection}>
            <FontAwesomeIcon icon={['far', 'file-alt']} fixedWidth />
            Add Your First Collection
          </Button>
        </>
      )}
      {isFirstTimeUser === false && (
        <>
          <p className="mt-4">
            <small>
              Don't lose track of things you might want to invest in. Create collections, monitor
              those assets, and find the next perfect asset for your portfolio.
            </small>
          </p>
          <Button className="mt-1" variant="primary" onClick={onAddCollection}>
            <FontAwesomeIcon icon={['far', 'file-alt']} fixedWidth />
            New Collection
          </Button>
          {/* <Button disabled className='mt-1' variant='primary' onClick={onAddCollection}>
            <FontAwesomeIcon icon={['far', 'file-alt']} fixedWidth /> New Smart Collection
          </Button> */}
          <ListGroup as="ul" variant="flush">
            {collections.length !== 0 &&
              collections.map((collection) => (
                <CollectionListItem
                  key={collection.id}
                  id={collection.id}
                  name={collection.name}
                  removeCollection={onRemoveCollectionAlert}
                  isActive={collection.id === urlParamId}
                />
              ))}
          </ListGroup>
        </>
      )}
    </>
  );
}

CollectionsList.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      claimed: PropTypes.bool,
      createdAt: PropTypes.shape({
        nanoseconds: PropTypes.number,
        seconds: PropTypes.number,
      }),
      id: PropTypes.string,
      name: PropTypes.string,
      note: PropTypes.string,
      owner: PropTypes.string,
      public: PropTypes.shape({
        isPublic: PropTypes.bool,
      }),
      updatedAt: PropTypes.shape({
        nanoseconds: PropTypes.number,
        seconds: PropTypes.number,
      }),
    })
  ),
  urlParamId: PropTypes.string,
};

CollectionsList.defaultProps = {
  collections: [],
  urlParamId: '',
};

export default CollectionsList;
