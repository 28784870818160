import React from 'react';
import { useIntl } from 'react-intl';
import Loader from '../global/Loader';
import withGlobalBenchmarks from '../visuals/withGlobalBenchmarks';
import MultilineChart from '../visuals/MultilineChart';
import Tip from '../global/Tip';

export default function InfoRisk({
  portfolioEquityCurve,
  selectedBenchmarks,
  portfolioStartingValue,
  portfolioName,
}) {
  if (portfolioEquityCurve.length === 0) return <Loader message='Loading all the goodies...' />;

  const intl = useIntl();
  const portfolioStartingDate = intl.formatDate(portfolioEquityCurve[0].date);
  const portfolioEndingDate = intl.formatDate(
    portfolioEquityCurve[portfolioEquityCurve.length - 1].date
  );

  const MultilineChartWithGlobalBenchmarks = withGlobalBenchmarks(MultilineChart);

  const marginOveride = {
    top: 20,
    right: 0,
    bottom: 15,
    left: 0,
  };

  return (
    <section className='portfolio-info-section'>
      {portfolioEquityCurve.length > 0 && (
        <>
          <div className='d-flex'>
            <div className='w-50'>
              <div className='multi-line-chart'>
                <MultilineChartWithGlobalBenchmarks
                  dailyHistory={{ history: portfolioEquityCurve }}
                  yValue='adjustedSortino'
                  showStartingPrice
                  margin={marginOveride}
                  benchmarks={selectedBenchmarks}
                  chartName={portfolioName}
                />
              </div>
              <p className='mb-0 mt-5 text-center'>
                <small>
                  Risk Efficiency ({portfolioStartingDate} - {portfolioEndingDate})
                  <Tip contentfulId='5qZaeE7nAwSqieJpysOkVl' className='ms-2' />
                </small>
              </p>
            </div>
            <div className='w-50 ps-5'>
              <div className='multi-line-chart'>
                <MultilineChartWithGlobalBenchmarks
                  dailyHistory={{ history: portfolioEquityCurve }}
                  yValue='dScore'
                  showStartingPrice
                  margin={marginOveride}
                  portfolioStartingValue={portfolioStartingValue}
                  benchmarks={selectedBenchmarks}
                  chartName={portfolioName}
                />
              </div>
              <p className='mb-0 mt-5 text-center'>
                <small>
                  dScore ({portfolioStartingDate} to {portfolioEndingDate})
                  <Tip contentfulId='HifiXugUfrEhOkGZNIBvo' className='ms-2' />
                </small>
              </p>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
