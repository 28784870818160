import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// components
import Loader from '../global/Loader';

export default function GetStarted({ activeStock }) {
  // router history
  const history = useHistory();
  // state
  const [loading, setLoading] = useState(true);

  /**
   * Handle the onclick event for the button
   * @returns - none
   */
  const handleGetStarted = () => {
    // amplitude tracking
    amplitudeClient.getInstance().logEvent('action_get-started', {
      symbol: activeStock,
    });
    window.Appcues?.track('Get started');
    // send to the signup page with UTMs
    history.push(
      `/signup?utm_campaign=${activeStock}&utm_medium=web-mobile&utm_source=single-lander`
    );
  };

  /**
   * Happens once
   * @returns - none
   */
  useEffect(() => {
    // set loading to false
    setLoading(false);

    return () => {};
  }, []);

  return (
    <>
      {loading === true && (
        <div className='loading'>
          <Loader />
        </div>
      )}
      {loading === false && (
        <Container className='get-started'>
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h2>
                Start designing your own portfolio and shape your performance and risk to fit you.
              </h2>
              <p>
                Get your personalized portfolio stats and start designing your own performance and
                risk for free at Risksmith. Choose from over 35,000 different assets and build the
                portfolio you've dreamed of managing.
              </p>
              <Button onClick={handleGetStarted}>Get Started Free</Button>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
