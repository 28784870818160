import React, { useCallback } from 'react';
import { Button, Overlay } from 'react-bootstrap';

function AssetClassButton({
  label,
  onClick,
  selected,
  value,
  ...rest
}) {
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    onClick(value);
  }, [onClick, value]);

  const bsPrefix = selected
    ? 'btn btn-link fw-bold text-white'
    : 'btn btn-link text-white';

  return (
    <Button
      onMouseDown={handleMouseDown}
      bsPrefix={bsPrefix}
      size="sm"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {label}
    </Button>
  );
}

export default AssetClassButton;
