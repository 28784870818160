/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
// hooks
import useToast from '../../hooks/UseToast';
import useProfile from '../../hooks/UseProfile';
import { addPlaidInstitution, removePlaidInstitution } from '../../helpers/Firebase';
// components
import Loader from '../global/Loader';
import PlaidButton from '../plaid/PlaidButton';
import PlaidCards from '../plaid/PlaidCards';
import PlaidCard from '../plaid/PlaidCard';

export default function SectionInstitutions({ institutionList }) {
  // router
  const history = useHistory();
  // loading flag
  const [loading, setLoading] = useState(true);
  // add toast API for display to the user
  const { addToast } = useToast();
  // current link token (we don't actually need to store this)
  const [linkToken, setLinkToken] = useState(null);
  // get the current user profile API context
  const { userID, institutionCount } = useProfile();

  // Saves the AccessToken and Institution Name to the DB
  const saveInstitution = (uat, name) => {
    addPlaidInstitution(userID, uat, name);
  };

  /**
   * Connects to Plaid to create temporary link token
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @success - Set the link token to state (we dont need to keep this token)
   * @error - log the error detail to console and set error state to display to user
   */
  const createLinkToken = async () => {
    try {
      const res = await axios.post(
        'https://us-central1-my-12-stocks.cloudfunctions.net/plaidAPI-plaidAPI/create_link_token'
      );
      const data = res.data.link_token;
      setLinkToken(data);
    } catch (err) {
      addToast('FAILED TO CREATE LINK TOKEN', err.message, 'error');
    }
  };

  /**
   * Connects to Plaid to get a permanent access token
   * If link token is successfully created, use it to get a public token to exchange for an access token
   * User clicks the button within the Link component and successfully consents to an institution
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {String} publicToken - public token to be exchanged for permanent access code
   * @param {String} institutionName - name of the institution connected
   * @success - set the access token and institution to state (kicks off UI display)
   * @error - log the error detail to console and set error state to display to user
   */
  const getAccessToken = async (publicToken, institutionName) => {
    // the public token and institution name are passed as variables from success callback
    try {
      const res = await axios.post(
        'https://us-central1-my-12-stocks.cloudfunctions.net/plaidAPI-plaidAPI/exchange_public_token',
        {
          publicToken,
        }
      );
      const data = res.data.access_token;
      saveInstitution(data, institutionName);
    } catch (err) {
      addToast('FAILED TO CREATE ACCESS TOKEN', err.message, 'error');
    }
  };

  /**
   * removes an institution for a specified user
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {String} institutionID - the institution ID
   * @returns - none
   */
  const deleteInstitution = (institutionID) => {
    removePlaidInstitution(userID, institutionID);
  };

  /**
   * view the institution page
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {String} institutionID - the institution ID
   * @returns - none
   */
  const selectInstitution = (institutionID) => {
    // set the current institution context
    // setCurrentInstitution(id);
    // navigate to the page
    history.push(`/i/${institutionID}`);
  };

  // this effect is only run once on load
  useEffect(() => {
    // init Plaid Link by getting a link token, expires after 30 min
    // Plaid will NOT work without this being called before a user tries to connect
    createLinkToken();

    return () => {};
  }, []);

  /**
   * Checks for a portfolio by looking for portfolioList length in profile context
   * This effect runs everytime the portfolioList context value is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (institutionList.length !== 0) {
      setLoading(false);
    } else {
      setLoading('done');
    }
  }, [institutionList]);

  return (
    <section className='user-institutions'>
      <p className='lead'>
        {/* <FontAwesomeIcon icon={['fad', 'sliders-h-square']} className='me-3' /> */}
        Plaid Accounts
      </p>
      <hr />
      <p className='mb-4'>
        You can link your finanical accounts with RiskSmith using Plaid and monitor information
        about those account including 💸 and ⚖️. Connecting an account allows RiskSmith to provide
        insights into your financial fitness.
      </p>
      {loading === 'done' && (
        <div className='ftu'>
          <PlaidButton token={linkToken} getAccessToken={getAccessToken} />
        </div>
      )}
      {loading === true && <Loader />}
      {loading === false && (
        <>
          <Row xs={1} sm={1} md={2} lg={2} xl={3}>
            {institutionList.length !== 0 ? (
              institutionList.map((institution) => (
                <PlaidCard
                  key={institution.id}
                  id={institution.id}
                  token={institution.accessToken}
                  name={institution.name}
                  created={institution.createdAt}
                  deleteInstitution={deleteInstitution}
                  selectInstitution={selectInstitution}
                />
              ))
            ) : (
              <p className='col text-danger'>🧟‍♂️ You haven't added any institutions yet!</p>
            )}
          </Row>
        </>
      )}
    </section>
  );
}
