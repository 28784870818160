import React, {
  useCallback,
  useEffect,
  useGlobal,
} from 'reactn';
import { reject } from 'lodash';
import useProfile from './UseProfile';
import {
  addNotificationSubscription,
  getNotificationSubscriptions,
  removeNotificationSubscription,
} from '../helpers/Firebase';

const useSubscriptions = () => {
  const { userID } = useProfile();
  const [subscriptions, setSubscriptions] = useGlobal('subscriptions');

  const subscribe = useCallback(async (topic, subscription) => {
    const nextSubscriptions = [
      ...subscriptions,
      { topic, ...subscription },
    ];

    setSubscriptions(nextSubscriptions);

    addNotificationSubscription(
      userID,
      topic,
      subscription,
    );
  }, [addNotificationSubscription, setSubscriptions, subscriptions, userID]);

  const unsubscribe = useCallback(async (id) => {
    const nextSubscriptions = reject(subscriptions, { id });

    setSubscriptions(nextSubscriptions);
    removeNotificationSubscription(id);
  }, [removeNotificationSubscription, setSubscriptions, subscriptions]);

  const init = useCallback(async () => {
    const nextSubscriptions = await getNotificationSubscriptions(userID);

    setSubscriptions(nextSubscriptions);
  }, [getNotificationSubscriptions, setSubscriptions, userID]);

  useEffect(init, []);

  return {
    subscriptions,
    subscribe,
    unsubscribe,
  };
};

export default useSubscriptions;
