import React from 'react';
import { PlaidLink } from 'react-plaid-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PlaidButton({ token, getAccessToken }) {
  // lots of events happen during the Link process, console loggin for now
  const onLoad = () => {
    // console.log('Link module has loaded...');
  };
  const onExit = (error, metadata) => {
    // console.log('onExit', error, metadata);
  };
  const onEvent = (eventName, metadata) => {
    // console.log('onEvent', eventName, metadata);
  };
  // push access token and institution name up to getAccessToken in parent component
  const onSuccess = (publicToken, metadata) => {
    // console.log('onSuccess', publicToken, metadata);
    getAccessToken(publicToken, metadata.institution.name);
  };

  return (
    <>
      <PlaidLink
        className='plaid-btn mb-3'
        style={{ cursor: 'pointer' }}
        token={token || ''}
        onLoad={onLoad}
        onExit={onExit}
        onEvent={onEvent}
        onSuccess={onSuccess}
      >
        Connect Account <FontAwesomeIcon icon={['fal', 'plus']} className='ms-1' />
      </PlaidLink>
    </>
  );
}
