import React, { useEffect, useState } from 'react';
// components
import BenchmarkBadge from '../global/BenchmarkBadge';
// helpers
import { BENCHMARKS } from '../../helpers/Constants';

export default function PortfolioBenchmarks({ benchmarks, updateSelectedBenchmarks }) {
  const [selectedBenchmarks, setSelectedBenchmarks] = useState(benchmarks);

  /**
   * Handle clicking a benchmark
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Object} benchmark - a single benchmark
   * @returns - none
   */
  const onClickBenchmark = (benchmark) => {
    // check if selected benchmark is in the list of selected
    const isCurrentlyActive = selectedBenchmarks.has(benchmark.symbol);
    // not in the list, add it
    if (!isCurrentlyActive) {
      setSelectedBenchmarks(new Set(selectedBenchmarks.add(benchmark.symbol)));
    }
    // already in the list, remove it
    if (isCurrentlyActive) {
      const newBenchmarks = new Set([...selectedBenchmarks]);
      newBenchmarks.delete(benchmark.symbol);
      setSelectedBenchmarks(newBenchmarks);
    }
  };

  /**
   * Each time selectedBenchmarks is updated
   * Send new values to parent state
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    updateSelectedBenchmarks(selectedBenchmarks);
  }, [selectedBenchmarks]);

  return (
    <div className='benchmarks d-flex align-items-center mt-2 ms-auto flex-wrap'>
      <span
        className='me-2'
        style={{
          fontSize: '.875em',
          color: '#636363',
          fontWeight: 600,
        }}
      >
        Compare to:
      </span>
      {BENCHMARKS.map((benchmark) => (
        <BenchmarkBadge
          key={benchmark.symbol}
          benchmark={benchmark}
          onClickBenchmark={() => onClickBenchmark(benchmark)}
          isActive={selectedBenchmarks.has(benchmark.symbol)}
        />
      ))}
    </div>
  );
}
