import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const Area = ({ xScale, yScale, yValue, color, data, disableAnimation, ...props }) => {
  // console.log('AreaData', data);
  const ref = useRef(null);

  useEffect(() => {
    if (disableAnimation) {
      d3.select(ref.current).attr('opacity', 1);
      return;
    }
    // eslint-disable-next-line newline-per-chained-call
    d3.select(ref.current).transition().duration(750).ease(d3.easeBackIn).attr('opacity', 1);
  }, [disableAnimation]);

  const isChangePercentLine = yValue === 'changePercent';

  const d = useMemo(() => {
    const area = d3
      .area()
      .x(({ date }) => xScale(date))
      .y1((dd) => yScale(isChangePercentLine ? dd.changePercent * 100 : dd[yValue]))
      .y0(() => yScale(yScale.domain()[0]));
    return area(data);
  }, [xScale, yScale, data]);

  return (
    <>
      <path ref={ref} d={d} fill={`url(#gradient-${color})`} opacity={0} {...props} />
      <defs>
        <linearGradient id={`gradient-${color}`} x1='0%' x2='0%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor={color} stopOpacity={0.1} />
          <stop offset='75%' stopColor={color} stopOpacity={0} />
        </linearGradient>
      </defs>
    </>
  );
};

Area.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.date,
      changePercent: PropTypes.number,
    })
  ),
  color: PropTypes.string,
  disableAnimation: PropTypes.bool,
};

Area.defaultProps = {
  data: [],
  color: 'white',
  disableAnimation: false,
};

export default Area;
