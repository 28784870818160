import Snd from 'snd-lib';

const snd = new Snd();

/**
 * SND01 -- Sine
 * The simple sound kit for designing a variety of interactions. All sounds are designed based on sine waves; the smallest
 * pure element of sound. It is designed to be as simple as possible with a small file size, focusing on a frequency range
 * that can be reproduced even by speakers with relatively low playback capabilities
 *
 * SND02 -- Piano
 * All sounds are played with a Steinway grand piano. Not only by usual techniques, but also experimental techniques such as
 * hammering and scratching surface. Simple but with rich lustrous sound asset that only live instruments can provide.
 */
snd.load(Snd.KITS.SND01).then(() => {
  // console.log('SOUNDS LOADED', Snd.SOUNDS);
});

/**
 * UX Sounds
 * https://snd.dev/
 *
 * @param {button} - Unlike Tap sound, this sound is played to clearly indicate to the user that they have pressed a UI element that performs a specific function, such as a button.
 * @param {caution} - This sound is played to indicate to the user that something is wrong, has a slightly more negative sound than Notification.
 * @param {celebration} - This is a sound to celebrate the moment when user have achieved the biggest goal of each application.
 * @param {disabled} - This sound is played when you press a disabled button.
 * @param {notification} - This sound is played to alert the user when there is some kind of notification from the app.
 * @param {progress_loop} - This sound is played to clearly indicate that some task is being processed, such as loading, dragging, etc.
 * @param {ringtone_loop} - This is the sound with the highest level of alert compared to Notification and Caution.
 * @param {select} - This sound is played when a specific UI element is selected such as a checkbox, radio button, choice chip, or input form.
 * @param {swipe} - This sound is played when a user swipes or presses the pager button to make a horizontal-transition.
 * @param {tap} - This sound is played to indicate to the user that the program recognized the user's action such as tapping or clicking on the screen.
 * @param {toggle_off} - This sound is played when a UI that clearly indicates two states, "ON" and "OFF", such as a toggle switch, is activated.
 * @param {toggle_on} - This sound is played when a UI that clearly indicates two states, "ON" and "OFF", such as a toggle switch, is activated.
 * @param {transition_down} - Unlike Swipe sound which is for horizontal-transition within the same hierarchy, this is a sound is played when a hierarchical-transition is made by a modal window, etc.
 * @param {transition_up} - Unlike Swipe sound which is for horizontal-transition within the same hierarchy, this is a sound is played when a hierarchical-transition is made by a modal window, etc.
 * @param {type} - This sound is played to provide feedback to the user when typing text.
 */

// eslint-disable-next-line import/prefer-default-export
export const playSound = (name) => {
  switch (name) {
    case 'button':
      snd.play(Snd.SOUNDS.BUTTON);
      break;

    case 'caution':
      snd.play(Snd.SOUNDS.CAUTION);
      break;

    case 'celebration':
      snd.play(Snd.SOUNDS.CELEBRATION);
      break;

    case 'disabled':
      snd.play(Snd.SOUNDS.DISABLED);
      break;

    case 'notification':
      snd.play(Snd.SOUNDS.NOTIFICATION);
      break;

    case 'progress_loop':
      snd.play(Snd.SOUNDS.PROGRESS_LOOP);
      break;

    case 'ringtone_loop':
      snd.play(Snd.SOUNDS.RINGTONE_LOOP);
      break;

    case 'select':
      snd.play(Snd.SOUNDS.SELECT);
      break;

    case 'swipe':
      snd.play(Snd.SOUNDS.SWIPE);
      break;

    case 'tap':
      snd.play(Snd.SOUNDS.TAP);
      break;

    case 'toggle_off':
      snd.play(Snd.SOUNDS.TOGGLE_OFF);
      break;

    case 'toggle_on':
      snd.play(Snd.SOUNDS.TOGGLE_ON);
      break;

    case 'transition_down':
      snd.play(Snd.SOUNDS.TRANSITION_DOWN);
      break;

    case 'transition_up':
      snd.play(Snd.SOUNDS.TRANSITION_UP);
      break;

    case 'type':
      snd.play(Snd.SOUNDS.TYPE);
      break;

    default:
      break;
  }
};
