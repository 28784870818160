import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { without } from 'lodash';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

import './MultiSelect.scss';

function MultiSelectRow(props) {
  const {
    label,
    value,
    onClick,
    selected,
  } = props;

  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  if (selected) {
    return (
      <button
        className="list-group-item list-group-item-dark list-group-item-action bg-transparent cursor-pointer"
        onClick={handleClick}
        type="button"
      >
        <FontAwesomeIcon
          className="text-success me-2"
          icon={faCircleCheck}
          fixedWidth
        />
        <span className="text-white">
          {label}
        </span>
      </button>
    );
  }

  return (
    <button
      className="list-group-item list-group-item-dark list-group-item-action bg-transparent cursor-pointer"
      onClick={handleClick}
      type="button"
    >
      <FontAwesomeIcon
        className="text-success me-2"
        icon={faCirclePlus}
        fixedWidth
      />
      <span className="text-white">
        {label}
      </span>
    </button>
  );
}

function MultiSelect(props) {
  const {
    children,
    onChange,
    options,
    value,
    ...rest
  } = props;

  const handleClickRow = useCallback((nextValue) => {
    if (value.includes(nextValue)) {
      const nextValues = without(value, nextValue);

      onChange(nextValues, null, nextValue);
    } else {
      const nextValues = [...value, nextValue];

      onChange(nextValues, nextValue, null);
    }
  }, [onChange, value]);

  const popover = (
    <Popover className="MultiSelectPopover bg-black border-dark">
      <Popover.Body className="p-0 fs-6">
        <ul className="list-group list-group-flush bg-transparent">
          {options.map((option) => (
            <MultiSelectRow
              key={option.value}
              onClick={handleClickRow}
              label={option.label}
              value={option.value}
              selected={value.includes(option.value)}
            />
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose
      >
        <Button {...rest}>
          {children}
        </Button>
      </OverlayTrigger>
    </>
  );
}

export default MultiSelect;
