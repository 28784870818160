import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { get } from 'lodash';
import { getAllocationBySharePrice } from '../../helpers/Math';
import { getAssetHistory } from '../../helpers/DataServices';
import AgSearchResults, { PortfolioListColIds } from '../AssetSearchResults';

export default function StockList({
  allocations,
  dropArea,
  portfolioAssets,
  isRealPortfolio,
  isWeighted,
}) {
  const [mappedData, setMappedData] = useState([]);
  const [rowData, setRowData] = useState([]);

  const load = useCallback(async (abortController) => {
    let { data: portfolioAssetsWithStats } = await getAssetHistory({
      symbol: portfolioAssets.map((asset) => asset.symbol),
      includeStats: true,
      abortController,
    });

    if (!Array.isArray(portfolioAssetsWithStats)) portfolioAssetsWithStats = [portfolioAssetsWithStats];

    const assetsWithStats = [];

    portfolioAssetsWithStats.forEach((assetWithStats) => {
      const mostRecentDayOfHistory = assetWithStats.history[assetWithStats.history.length - 1];
      const portfolioAsset = portfolioAssets.find((asset) => asset.symbol === assetWithStats.symbol);

      if (assetWithStats && mostRecentDayOfHistory) {
        assetsWithStats.push({
          ...assetWithStats,
          riskEfficiency: mostRecentDayOfHistory.stats.adjustedSortino,
          dScore: mostRecentDayOfHistory.stats.dScore,
          growth1yr: mostRecentDayOfHistory.closePrice / assetWithStats.history[0].closePrice - 1,
          lastClose: mostRecentDayOfHistory.closePrice,
          history: assetWithStats.history,
          id: portfolioAssets.find((asset) => asset.symbol === assetWithStats.symbol).id,
        });
      }
    });
    setMappedData(assetsWithStats);
    setRowData(assetsWithStats);
  }, [portfolioAssets]);

  useEffect(() => {
    // go get stats for each asset in the portfolio
    const abortController = new AbortController();

    load(abortController);

    return () => {
      abortController.abort();
    };
  }, [load, portfolioAssets]);

  useEffect(() => {
    if (mappedData.length === 0) return;
    const assetsWithNewAllocations = mappedData.map((asset) => ({
      ...asset,
      allocation: get(allocations, asset.symbol),
    }));
    setRowData(assetsWithNewAllocations);
  }, [allocations, isRealPortfolio, mappedData]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ overflow: 'hidden', flexGrow: '1' }}>
        <div className="portfolio-list ag-theme-alpine mt-3 w-100" ref={dropArea}>
          <AgSearchResults
            colIds={PortfolioListColIds}
            rowData={rowData}
          />
        </div>
      </div>
    </div>
  );
}
