import React from 'react';
import { useIntl } from 'react-intl';
import Loader from '../global/Loader';
import withGlobalBenchmarks from '../visuals/withGlobalBenchmarks';
import LineChart from '../visuals/LineChart';
import MultilineChart from '../visuals/MultilineChart';
import Tip from '../global/Tip';
// helpers
import { formatDollarsWithCommas } from '../../helpers/Numbers';

export default function InfoGrowth({
  portfolioEquityCurve,
  portfolioChangeHistory,
  selectedBenchmarks,
  readOnly,
  portfolioName,
}) {
  if (portfolioEquityCurve.length === 0) return <Loader message='Loading all the goodies...' />;

  const intl = useIntl();
  const portfolioStartingDate = intl.formatDate(portfolioEquityCurve[0].date);
  const portfolioEndingDate = intl.formatDate(
    portfolioEquityCurve[portfolioEquityCurve.length - 1].date
  );

  const MultilineChartWithGlobalBenchmarks = withGlobalBenchmarks(MultilineChart);

  const firstDay = portfolioChangeHistory[0];
  const lastDay = portfolioChangeHistory[portfolioChangeHistory.length - 1];

  const marginOveride = {
    top: 20,
    right: 0,
    bottom: 15,
    left: 0,
  };

  // remap the portfolio equity curve to include daily growth
  const portfolioEquityCurveWithGrowth = portfolioEquityCurve.map((day) => ({
    ...day,
    growth: day.closePrice / portfolioEquityCurve[0].closePrice - 1,
  }));

  return (
    <section className='portfolio-info-section'>
      {portfolioEquityCurve.length > 0 && (
        <>
          <div className='d-flex'>
            <div className='w-50 growth'>
              <h3 className={lastDay.adjustedSortino > 0 ? 'stat-positive' : 'stat-negative'}>
                {(((lastDay.closePrice - firstDay.closePrice) / firstDay.closePrice) * 100).toFixed(
                  2
                )}
                %
                <div className='growth-line-chart'>
                  <LineChart
                    dailyHistory={{ history: portfolioEquityCurve }}
                    yValue='closePrice'
                    thiccness={3}
                    color={lastDay.adjustedSortino > 0 ? '#62BD37' : '#F45D48'}
                    sparks={true}
                  />
                </div>
              </h3>
              {!readOnly && (
                <>
                  <h4 className={lastDay.adjustedSortino > 0 ? 'stat-positive' : 'stat-negative'}>
                    {formatDollarsWithCommas(
                      portfolioEquityCurve[0].closePrice *
                        ((lastDay.closePrice - firstDay.closePrice) / firstDay.closePrice)
                    )}
                  </h4>
                  <p className="text-muted small">
                    (Based on investing ${portfolioEquityCurve[0].closePrice.toFixed(2)} in this portfolio a year ago)
                  </p>
                </>
              )}
              <p className='lead'>
                {portfolioStartingDate} - {portfolioEndingDate}
              </p>
            </div>
            <div className='w-50 ps-5'>
              <div className='multi-line-chart'>
                <MultilineChartWithGlobalBenchmarks
                  dailyHistory={{ history: portfolioEquityCurveWithGrowth }}
                  yValue='growth'
                  showStartingPrice
                  margin={marginOveride}
                  benchmarks={selectedBenchmarks}
                  chartName={portfolioName}
                />
              </div>
              <p className='mb-0 mt-5 text-center'>
                <small>
                  Growth ({portfolioStartingDate} - {portfolioEndingDate})
                  <Tip contentfulId='3z4V2nNhOiytid7bJ3UjUI' className='ms-2' />
                </small>
              </p>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
