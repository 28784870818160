import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import useToast from '../../hooks/UseToast';
import InvestmentList from './InvestmentList';

export default function InvestmentsContainer({ accessToken, institution }) {
  // add toast API
  const { addToast } = useToast();
  // various data
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userAccounts, setUserAccounts] = useState([]);
  const [userHoldings, setUserHoldings] = useState([]);
  const [userSecurities, setUserSecurities] = useState([]);

  const getInvestments = async () => {
    // reset
    setActive(true);
    setLoading(true);
    // give er a go
    try {
      const res = await axios.post(
        'https://us-central1-my-12-stocks.cloudfunctions.net/plaidAPI-plaidAPI/holdings',
        {
          accessToken,
        }
      );

      const { accounts, holdings, securities } = res.data.holdings;

      setUserAccounts(accounts);
      setUserHoldings(holdings);
      setUserSecurities(securities);

      setActive(true);
      setLoading(false);
    } catch (err) {
      // addToast('FAILED TO GET DATA', err.message, 'error');
      setActive(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvestments();
  }, [accessToken, institution]);

  return (
    <>
      <Row xs={1}>
        <Col>
          <h2 className='pt-2 pb-4 mb-5 border-bottom'>{institution}</h2>
        </Col>
      </Row>
      {active === true ? (
        <>
          {loading === true ? (
            <Row xs={1}>
              <Col>
                <p>loading data...</p>
              </Col>
            </Row>
          ) : (
            <Row xs={1} md={2} xl={2}>
              <InvestmentList
                accounts={userAccounts}
                holdings={userHoldings}
                securities={userSecurities}
              />
            </Row>
          )}
        </>
      ) : (
        <Row xs={1}>
          <Col>
            <p>no financial data found</p>
          </Col>
        </Row>
      )}
    </>
  );
}
