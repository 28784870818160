/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EasyEdit, { Types } from 'react-easy-edit';
// hooks
import { updateUserName, updateUserPhone } from '../../helpers/Firebase';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// components
import Loader from '../global/Loader';

export default function SectionPreferences({
  userID,
  userName,
  userEmail,
  userPhone,
  userUpdated,
}) {
  // loading flag
  const [loading, setLoading] = useState(true);

  // global cancel for now
  const cancel = () => {
    console.log('Cancelled');
  };

  // update the user name value
  const handleUpdateUserName = (newUserName) => {
    // console.log('new value', newUserName);
    // empty - do nothing
    if (newUserName === null || undefined) {
      return;
    }
    // whitespace only - reset to null in DB
    if (!newUserName || /^\s*$/.test(newUserName)) {
      updateUserName(userID, null);
      return;
    }
    console.log('update name to', newUserName);
    updateUserName(userID, newUserName);
    amplitudeClient.getInstance().logEvent('action_update-profile-name', {
      name: newUserName,
    });
    window.Appcues?.track('Update portfolio name');
  };

  // update the user phone value
  const handleUpdateUserPhone = (newUserPhone) => {
    console.log('new value', newUserPhone);
    // empty - do nothing
    if (newUserPhone === null || undefined) {
      return;
    }
    // whitespace only - reset to null in DB
    if (!newUserPhone || !newUserPhone.trim()) {
      updateUserPhone(userID, null);
      return;
    }
    updateUserPhone(userID, newUserPhone);
    amplitudeClient.getInstance().logEvent('action_update-profile-phone', {
      phone: newUserPhone,
    });
    window.Appcues?.track('Update phone number');
  };

  /**
   * Sets loading when data is ready
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (userEmail !== '' && userUpdated !== '') {
      setLoading(false);
    }
  }, [userEmail, userUpdated]);

  return (
    <section className='user-preferences'>
      <p className='lead'>
        {/* <FontAwesomeIcon icon={['fad', 'sliders-h-square']} className='me-3' /> */}
        Profile
      </p>
      <hr />
      {loading === true && <Loader />}
      {loading === false && (
        <>
          <Row as='dl'>
            <Col as='dt' xs={4} sm={2}>
              Name
            </Col>
            <Col as='dd' xs={8} sm={10}>
              <EasyEdit
                type={Types.TEXT}
                value={userName}
                onSave={handleUpdateUserName}
                onCancel={cancel}
                saveButtonLabel='Save'
                cancelButtonLabel='Cancel'
                attributes={{ className: 'form-control' }}
                placeholder='add your name or a username...'
                saveOnBlur={true}
                allowEdit={true}
              />
            </Col>
            <Col as='dt' xs={4} sm={2}>
              Email
            </Col>
            <Col as='dd' xs={8} sm={10}>
              <EasyEdit
                type={Types.TEXT}
                value={userEmail}
                onSave={cancel}
                onCancel={cancel}
                attributes={{ className: 'form-control' }}
                viewAttributes={{ className: 'field-no-edit' }}
                placeholder='No Email?'
                saveOnBlur={false}
                allowEdit={false}
              />
              <span className='profile-link'>
                <Link to='/update-profile' className=''>
                  <FontAwesomeIcon icon={['far', 'external-link-square-alt']} className='' />
                </Link>
              </span>
            </Col>
            <Col as='dt' xs={4} sm={2}>
              Password
            </Col>
            <Col as='dd' xs={8} sm={10}>
              <EasyEdit
                type={Types.TEXT}
                value={'******'}
                onSave={cancel}
                onCancel={cancel}
                attributes={{ className: 'form-control' }}
                viewAttributes={{ className: 'field-no-edit' }}
                placeholder='******'
                saveOnBlur={false}
                allowEdit={false}
              />
              <span className='profile-link'>
                <Link to='/update-profile' className=''>
                  <FontAwesomeIcon icon={['far', 'external-link-square-alt']} className='' />
                </Link>
              </span>
            </Col>
            <Col as='dt' xs={4} sm={2}>
              Phone
            </Col>
            <Col as='dd' xs={8} sm={10}>
              <EasyEdit
                type={Types.TEXT}
                value={userPhone}
                onSave={handleUpdateUserPhone}
                onCancel={cancel}
                saveButtonLabel='Save'
                cancelButtonLabel='Cancel'
                attributes={{ className: 'form-control' }}
                placeholder='add phone number...'
                saveOnBlur={true}
                allowEdit={true}
              />
            </Col>
          </Row>
          <p className='text-muted'>
            <small>
              User ID - <span className='fst-italic'>{userID}</span>
              <br />
              <FontAwesomeIcon className='me-1' icon={['fal', 'history']} /> Last Updated -{' '}
              {userUpdated}
            </small>
          </p>
        </>
      )}
    </section>
  );
}
