import React, {
  useCallback,
  useEffect,
  useGlobal,
  useMemo,
  useRef,
  useState,
} from 'reactn';
import { AgGridReact } from 'ag-grid-react';
import { filter, reduce } from 'lodash';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LineChart from './visuals/LineChart';
import Histogram from './visuals/Histogram';
import { toFriendlyRiskEfficiency, toFriendlyDScore } from '../helpers/Numbers';
import { getAssetHistory, getAssetDetails } from '../helpers/DataServices';
import useResize from '../hooks/useResize';
import { removePortfolioStock } from '../helpers/Firebase';
import AssetSearchSectionHeader from './AssetSearchSectionHeader';

const fetchAssetMeta = async (symbol, controller) => {
  const { data } = await getAssetHistory({ symbol: symbol.toUpperCase(), includeStats: true, controller });
  let yesterday;
  let growth;

  if (data.history) {
    yesterday = data.history[data.history.length - 1];
    if (yesterday) {
      growth = ((yesterday.closePrice / data.history[0].closePrice - 1) * 100).toFixed(2);
    }
  }

  try {
    const assetDetails = await getAssetDetails(symbol, controller);

    if (assetDetails.ticker) {
      return {
        ...data,
        ...assetDetails,
        growth,
        yesterday,
      };
    }
  } catch (e) {
    // NOOP
  }

  return {
    ...data,
    yesterday,
    growth,
  };
};

const AssetHistogram = (props) => {
  const { symbol } = props;
  const [assetMeta, setAssetMeta] = useState({});
  const updateAssetMeta = useCallback(async (controller) => {
    if (!symbol) {
      return;
    }

    const nextAssetMeta = await fetchAssetMeta(symbol, controller);

    setAssetMeta(nextAssetMeta);
  }, [symbol]);

  useEffect(() => {
    const controller = new AbortController();

    updateAssetMeta(controller);

    return () => {
      controller.abort();
    };
  }, [symbol, updateAssetMeta]);

  return (
    <Histogram
      dailyHistory={assetMeta.history ? assetMeta : { history: [] }}
      hideAxis
      showYesterday
    />
  );
};

const AssetNameCellRenderer = ({ data, isShowingAssetName }) => (
  <div className="pe-3">
    <h6 className="m-0">{data.symbol || data.ticker}</h6>
    {isShowingAssetName && (
      <div className="text-muted small">{data.name || 'N/A'}</div>
    )}
  </div>
);

const StatWithSparklineRenderer = (props) => {
  const {
    data,
    showAsPercentage,
    stat,
    value,
  } = props;
  if (!data.history) return null;

  const flattenedHistory = props.data.history.map((day) => ({
    ...day,
    adjustedSortino: day.stats.adjustedSortino,
    dScore: day.stats.dScore,
  }))
    .splice(-20);

  const slope = (flattenedHistory[flattenedHistory.length - 1][stat] - flattenedHistory[0][stat]) / (flattenedHistory.length - 1);
  let lineColor;

  switch (stat) {
    case 'adjustedSortino':
    case 'growth1yr':
      lineColor = slope > 0 ? '#62BD37' : '#F45D48';
      break;

    case 'dScore':
      lineColor = slope < 0 ? '#62BD37' : '#F45D48';
      break;

    default:
      break;
  }

  const formattedValue = stat === 'dScore'
    ? toFriendlyDScore(value)
    : toFriendlyRiskEfficiency(value);

  return (
    <span className="d-flex">
      {!showAsPercentage && formattedValue}
      {showAsPercentage && `${formattedValue}%`}
      <span className="ms-2 grid-visual">
        <LineChart
          dailyHistory={{ history: flattenedHistory }}
          yValue={stat}
          thiccness={2}
          color={lineColor}
          sparks
        />
      </span>
    </span>
  );
};

const HistogramRenderer = (props) => {
  const { data: { ticker } } = props;

  return (
    <AssetHistogram
      symbol={ticker}
    />
  );
};

const ActionsRenderer = (props) => {
  const [currentUser] = useGlobal('currentUser');
  const [activePortfolio] = useGlobal('activePortfolio');
  const { uid: userId } = currentUser;
  const { id: portfolioId } = activePortfolio;
  const { id: stockId } = props.data;

  const handleDeleteStock = useCallback(() => {
    removePortfolioStock(userId, portfolioId, stockId);
  }, [portfolioId, stockId, userId]);

  return (
    <div className='list-actions'>
      <Button variant='link' onClick={handleDeleteStock}>
        <FontAwesomeIcon icon={['far', 'trash-alt']} className='bi bi-bar-trash' fixedWidth />
      </Button>
    </div>
  );
};

const cellColorStyler = (params) => {
  if (params.value > 0) return 'stat-positive';
  if (params.value < 0) return 'stat-negative';
  return '';
};

const availableTableColumns = [
  {
    field: 'symbol',
    headerName: 'Asset',
    sortable: true,
    sort: 'asc',
    cellRenderer: AssetNameCellRenderer,
  },
  {
    colId: 'symbolWithName',
    field: 'symbol',
    headerName: 'Asset',
    sortable: true,
    sort: 'asc',
    cellRenderer: AssetNameCellRenderer,
    cellRendererParams: {
      isShowingAssetName: true,
    },
  },
  {
    field: 'allocation',
    headerName: 'Allocation (%)',
    sortable: true,
    valueFormatter: (params) => (params.value === Infinity ? '100' : params.value?.toFixed(2)),
  },
  {
    colId: 'riskEfficiencyHistory',
    field: 'riskEfficiency',
    headerName: 'Risk Efficiency',
    sortable: true,
    cellRenderer: StatWithSparklineRenderer,
    cellRendererParams: {
      stat: 'adjustedSortino',
      showAsPercentage: false,
    },
    cellClass: (params) => cellColorStyler(params),
  },
  {
    field: 'riskEfficiency',
    headerName: 'Risk Efficiency',
    sortable: true,
    cellClass: (params) => cellColorStyler(params),
    valueFormatter: (params) => toFriendlyRiskEfficiency(params.value),
  },
  {
    colId: 'dScoreHistory',
    field: 'dScore',
    headerName: 'dScore',
    sortable: true,
    cellRenderer: StatWithSparklineRenderer,
    cellRendererParams: {
      showAsPercentage: true,
      stat: 'dScore',
    },
  },
  {
    field: 'dScore',
    headerName: 'dScore',
    sortable: true,
    cellClass: (params) => cellColorStyler(params),
    valueFormatter: (params) => toFriendlyDScore(params.value),
  },
  {
    field: 'growth1yr',
    headerName: 'Growth (1 yr)',
    sortable: true,
    valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%`,
    cellClass: (params) => cellColorStyler(params),
  },
  {
    field: 'histogram',
    headerName: '',
    sortable: false,
    cellRenderer: HistogramRenderer,
  },
  {
    field: 'actions',
    headerName: ' ',
    sortable: true,
    cellRenderer: ActionsRenderer,
    suppressSizeToFit: true,
    width: 70,
  },
];

export const PortfolioListColIds = {
  0: [
    'symbol',
    'allocation',
    'riskEfficiencyHistory',
    'dScoreHistory',
    'growth1yr',
    'actions',
  ],
};

export const AssetSearchColIds = {
  0: ['symbolWithName', 'riskEfficiency', 'dScore'],
  400: ['symbolWithName', 'riskEfficiency', 'dScore', 'histogram'],
  600: ['symbolWithName', 'riskEfficiency', 'dScore', 'growth1yr', 'histogram'],
};

const getColIdsForWidth = (colIds, width) => {
  if (Array.isArray(colIds)) {
    return colIds;
  }

  return reduce(colIds, (memo, value, breakpoint) => (
    width > breakpoint ? value : memo
  ), []);
};

const AgSearchResults = ({
  colIds,
  onSelectHit,
  rowData,
}) => {
  const gridRef = useRef();
  const wrapperRef = useRef();
  const [activeTicker, setActiveTicker] = useGlobal('activeTicker');
  const { width } = useResize(wrapperRef);
  const colIdsForWidth = useMemo(() => getColIdsForWidth(colIds, width), [colIds, width]);
  const columnDefs = useMemo(() => availableTableColumns.filter((tableColumn) => (
    colIdsForWidth.includes(tableColumn.colId || tableColumn.field)
  )), [colIdsForWidth]);
  const handleGridSizeChanged = useCallback((params) => params.api.sizeColumnsToFit(), []);
  const handleGridReady = useCallback((params) => { gridRef.current = params.api; }, []);
  const handleSelectionChanged = useCallback((e) => {
    const {
      api: {
        selectionService: {
          lastSelectedNode,
        },
      },
    } = e;
    const { data } = lastSelectedNode;
    const { ticker } = data;

    if (lastSelectedNode.isSelected()) {
      setActiveTicker(ticker);

      if (onSelectHit) {
        onSelectHit(data);
      }

      lastSelectedNode.setSelected(false);
    }
  }, [onSelectHit, setActiveTicker]);

  return (
    <div ref={wrapperRef}>
      <AgGridReact
        onSelectionChanged={handleSelectionChanged}
        columnDefs={columnDefs}
        domLayout="autoHeight"
        onGridReady={handleGridReady}
        onGridSizeChanged={handleGridSizeChanged}
        ref={gridRef}
        rowData={rowData}
        rowHeight={60}
        rowSelection="single"
        animateRows
        suppressNoRowsOverlay
      />
    </div>
  );
};

export default AgSearchResults;
