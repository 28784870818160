import React, { useGlobal } from 'reactn';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ component: Component, ...rest }) {
  const [currentUser] = useGlobal('currentUser');
  return (
    <Route
      { ...rest }
      render={ (props) => (currentUser ? <Component { ...props } /> : <Redirect to='/login' />)}
    ></Route>
  );
}
