import { getGlobal, setGlobal } from 'reactn';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
// config
import { auth, db } from '../config/firebase';

export function loginAnonymously() {
  // Anonymous log-in will be persisted with session persistence only
  setPersistence(auth, browserSessionPersistence)
    .then(() => signInAnonymously(auth))
    .catch(({ code, message }) => {
      console.log('ERROR', code, message);
    });
}

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function loginWithGoogle() {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
}

export function loginWithTwitter() {
  const provider = new TwitterAuthProvider();
  return signInWithPopup(auth, provider);
}

export function loginWithFacebook() {
  const provider = new FacebookAuthProvider();
  return signInWithPopup(auth, provider);
}

export function logout() {
  return signOut(auth);
}

export function resetPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

export function updateEmail(email) {
  return getGlobal().currentUser.updateEmail(email);
}

export function updatePassword(password) {
  return getGlobal().currentUser.updatePassword(password);
}

export function signup(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export const initAuth = () => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userProfile = await getDoc(doc(db, 'users', user.uid));
      const cutoff = new Date();
      cutoff.setDate(cutoff.getDate() - 1); // check to see if the user is less than a day old
      setGlobal({ isUserNew: userProfile?.data()?.createdAt?.toDate() > cutoff });
    }
    setGlobal({ currentUser: user });
  });
};
