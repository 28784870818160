import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { simplifiedLine } from '@tomgp/line-simplification';

const Line = ({
  xScale,
  yScale,
  yValue,
  color,
  stroke,
  sparkline,
  data,
  isSmooth,
  animation,
  dScore,
  ...props
}) => {
  const ref = useRef(null);
  // Define different types of animation that we can use
  const animateLeft = useCallback(() => {
    // NOTE: for some reason getTotalLength() doesn't work in tests
    // in this codesandbox so we added default value just for tests
    const totalLength = ref.current.getTotalLength ? ref.current.getTotalLength() : 400;
    d3.select(ref.current)
      .attr('opacity', 1)
      .attr('stroke-dasharray', `${totalLength},${totalLength}`)
      .attr('stroke-dashoffset', totalLength)
      .transition()
      .duration(750)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);
  }, []);
  const animateFadeIn = useCallback(() => {
    // eslint-disable-next-line newline-per-chained-call
    d3.select(ref.current).transition().duration(750).ease(d3.easeLinear).attr('opacity', 1);
  }, []);
  const noneAnimation = useCallback(() => {
    d3.select(ref.current).attr('opacity', 1);
  }, []);

  useEffect(() => {
    switch (animation) {
      case 'left':
        animateLeft();
        break;
      case 'fadeIn':
        animateFadeIn();
        break;
      case 'none':
      default:
        noneAnimation();
        break;
    }
  }, [animateLeft, animateFadeIn, noneAnimation, animation]);

  // Recalculate line length if scale has changed
  useEffect(() => {
    if (animation === 'left') {
      const totalLength = ref.current.getTotalLength ? ref.current.getTotalLength() : 400;
      d3.select(ref.current).attr('stroke-dasharray', `${totalLength},${totalLength}`);
    }
  }, [xScale, yScale, animation]);

  const parseDate = d3.utcParse('%Y-%m-%dT%H:%M:%S.%L%Z');
  const isChangePercentLine = yValue === 'changePercent';

  const line = d3
    .line()
    .x((d) => xScale(d.date))
    .y((d) => yScale(isChangePercentLine ? d.changePercent * 100 : d[yValue]));

  const sparkLine = simplifiedLine()
    .tolerance(1)
    .x((d) => xScale(d.date))
    .y((d) => yScale(isChangePercentLine ? d.changePercent * 100 : d[yValue]));

  const dScoreLine = d3
    .line()
    .x((d) => xScale(d.date))
    .y((d) => yScale(0 - d.dScore * 100));

  let d = line(data);
  if (sparkline) d = sparkLine(data);
  if (dScore) d = dScoreLine(data);

  return (
    <path
      ref={ref}
      d={d}
      stroke={color}
      strokeWidth={stroke}
      fill='none'
      opacity={0}
      {...props}
    />
  );
};

Line.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      change: PropTypes.number,
      changePercent: PropTypes.number,
      closePrice: PropTypes.number,
      date: PropTypes.date,
      stats: PropTypes.shape({
        adjustedSortino: PropTypes.number,
        dScore: PropTypes.number,
        sortino: PropTypes.number,
        stdev: PropTypes.number,
      }),
      symbol: PropTypes.string,
    })
  ),
  color: PropTypes.string,
  stroke: PropTypes.number,
  isSmooth: PropTypes.bool,
  animation: PropTypes.oneOf(['left', 'fadeIn', 'none']),
  sparkline: PropTypes.bool,
};

Line.defaultProps = {
  data: [],
  color: 'white',
  stroke: 1,
  isSmooth: false,
  animation: 'left',
  sparkline: false,
};

export default Line;
