import React, {
  useCallback,
  useEffect,
  useGlobal,
  useState,
} from 'reactn';
import { ButtonToolbar, ButtonGroup, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StockCardFooter({ symbol, selectStock, deleteStock }) {
  const [, setActiveTicker] = useGlobal('activeTicker');

  /**
   * View the stock page
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  const handleSelectStock = useCallback((e) => {
    e.preventDefault();
    setActiveTicker(symbol);
  }, [setActiveTicker, symbol]);

  /**
   * Delete the stock
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  const handleDeleteStock = useCallback((e) => {
    e.preventDefault();
    deleteStock(symbol);
  }, []);

  return (
    <Card.Footer>
      <ButtonToolbar className='justify-content-between' aria-label='Stock Card Toolbar'>
        <ButtonGroup aria-label='View Stats'>
          <Button
            variant='link'
            onClick={handleSelectStock}
          >
            <FontAwesomeIcon icon={['far', 'analytics']} className='d-inline-block' fixedWidth />
          </Button>
        </ButtonGroup>
        <ButtonGroup aria-label='Delete Stock'>
          <Button
            variant='link'
            onClick={handleDeleteStock}
          >
            <FontAwesomeIcon icon={['far', 'trash-alt']} className='bi bi-bar-trash' fixedWidth />
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Card.Footer>
  );
}
