import {
  useGlobal,
  useRef,
  useEffect,
  useState,
} from 'reactn';
import { Container, Form, Button, Col, Alert } from 'react-bootstrap';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { resetPassword } from '../helpers/Auth';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
// hooks
import useToast from '../hooks/UseToast';
import useQueryString from '../hooks/UseQueryString';
// components
import Seo from '../components/global/Seo';

import risksmithLogo from '../media/risksmith-logo-on-dark.png';

// meta data for SEO
const metaData = {
  title: 'Forgot Password',
  description: 'Forgot Password',
};

export default function ForgotPassword() {
  // add toast API
  const { addToast } = useToast();
  // reference to email input
  const emailRef = useRef();
  // success messaging
  const [message, setMessage] = useState('');
  // loader
  const [loading, setLoading] = useState(false);
  // router location
  const location = useLocation();
  // current path from location
  const { pathname } = location;
  // query params
  const queryParams = useQueryString();
  const [currentUser] = useGlobal('currentUser');
  // body element class list
  const bodyClassList = document.body.classList;

  /**
   * Sets and removes the class on body element per route path
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // split the path into an array [0] seems to always =''
    const pathArray = pathname.split('/');
    const pathArrayLength = pathArray.length;
    const newBodyClass = pathArray[pathArrayLength - 1];

    // set className
    bodyClassList.add(newBodyClass);

    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      console.log(key);
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_forgot-password-page', amplitudePayload);
    window.Appcues?.track('View forgot password page');

    return () => {
      // remove className
      bodyClassList.remove(newBodyClass);
    };
  }, []);

  /**
   * Handle forgot password form submit
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage('');
      setLoading(true);
      await resetPassword(emailRef.current.value);
      amplitudeClient
        .getInstance()
        .logEvent('action_reset-password', { email: emailRef.current.value });
      setMessage('Check your inbox for further instructions');
    } catch (err) {
      addToast('Failed to reset password', err.message, 'error');
    }
    setLoading(false);
  }

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      {currentUser !== null && <Redirect to='/profile' />}
      <Container fluid className="d-flex align-items-center justify-content-center h-100">
        <Col md={6} className='px-5'>
          <img src={risksmithLogo} alt='RiskSmith logos' className='auth-logo' />
          <h4 className='my-5'>Reset your RiskSmith password</h4>
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' id='email'>
              <Form.Label>Enter the email you registered at RiskSmith with:</Form.Label>
              <Form.Control type='email' ref={emailRef} required />
            </Form.Group>
            {message && <Alert variant='success'>{message}</Alert>}
            <Button disabled={loading} type='submit' size='lg' className='my-3'>
              Reset Password
            </Button>
          </Form>
          <hr />
          <div className='mt-5'>
            Ready to log in? <Link to='/login'>Head back to login.</Link>
          </div>
        </Col>
        <Col
          md={6}
          className='d-none d-md-flex h-100 auth-sidebar justify-content-center flex-column px-5'
        ></Col>
      </Container>
    </>
  );
}
