/* eslint-disable jsx-quotes, react/prop-types, react/jsx-filename-extension, react/function-component-definition */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { getToken } from 'firebase/messaging';
import {
  getNotificationSettings,
  setNotificationChannelPreferences,
  ensurePushNotificationToken,
} from '../../helpers/Firebase';
import { fetchMessaging } from '../../config/firebase';
import useProfile from '../../hooks/UseProfile';

const channelOptions = [
  { title: 'Push', value: 'push' },
  { title: 'Email', value: 'email' },
];

const CheckboxButtonGroup = ({
  enabledValues,
  name,
  onChange,
  options,
  values,
}) => {
  const handleChange = useCallback((e) => {
    if (e.target.checked && !values.includes(e.target.value)) {
      onChange([...values, e.target.value]);
    } else if (!e.target.checked && values.includes(e.target.value)) {
      onChange(values.filter((v) => v !== e.target.value));
    }
  }, [onChange, values]);

  return (
    <div className='btn-group' role='group'>
      {options.map(({ title, value }) => (
        <Fragment key={value}>
          <input
            autoComplete='off'
            checked={values.includes(value)}
            className='btn-check'
            disabled={!enabledValues.includes(value)}
            id={`${name}:${value}`}
            onChange={handleChange}
            type='checkbox'
            value={value}
          />
          <label
            className='btn btn-outline-secondary'
            htmlFor={`${name}:${value}`}
          >
            {title}
          </label>
        </Fragment>
      ))}
    </div>
  );
};

const NotificationType = ({
  slug,
  title,
  description,
  availableChannels = [],
  channels = [],
  onChange,
}) => {
  const handleChangeChannels = useCallback((nextChannels) => {
    onChange(slug, nextChannels);
  }, [onChange, slug]);

  return (
    <div
      className='mb-5 d-block d-sm-flex align-items-start justify-content-between'
      id={slug}
    >
      <div className='me-3'>
        <strong>{title}</strong>
        <p>{description}</p>
      </div>
      <CheckboxButtonGroup
        enabledValues={availableChannels}
        options={channelOptions}
        values={channels}
        name={`${slug}[]`}
        onChange={handleChangeChannels}
      />
    </div>
  );
};

const fetchNotificationToken = async () => {
  // NOTE: we currently don't have service workers set up on localhost
  if (window.location.hostname === 'localhost') {
    console.error('No service worker available on localhost');
    return null;
  }

  const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration('./service-worker');
  const messaging = await fetchMessaging();

  if (messaging) {
    const token = await getToken(messaging, {
      serviceWorkerRegistration,
      vapidKey: process.env.kEACT_APP_FIREBASE_MESSAGING_VAPIDKEY,
    });

    return token;
  }

  return null;
};

const NotificationRequestAlert = ({ onClick, userID }) => {
  if (typeof Notification === 'undefined') {
    return null;
  }

  if (['defined', 'default'].includes(Notification.permission)) {
    return (
      <div className='alert alert-info d-flex justify-content-between align-items-center'>
        You have notifications turned off on this device.
        <button
          className='btn alert-link'
          onClick={onClick}
          type='button'
        >
          Enable Notifications
        </button>
      </div>
    );
  }

  return null;
};

const SectionNotifications = ({ userID }) => {
  const { isAlphaTester, notificationChannelPreferences } = useProfile();
  const [notificationSettings, setNotificationSettings] = useState();
  const [controlledNotificationChannelPreferences, setControlledNotificationChannelPreferences] = useState();

  /**
   * Initialize notification data from db
   */
  const initialize = useCallback(async () => {
    const res = await getNotificationSettings();
    setNotificationSettings(res);

    if (typeof Notification === 'undefined') {
      return null;
    }

    if (Notification.permission === 'granted') {
      const notificationToken = await fetchNotificationToken();

      ensurePushNotificationToken(userID, notificationToken);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // FIXME: ^ adding the exhaustive deps here causes infinite calls of the side effect, and can't
  // be fixed until useFirebase is _actually_ a hook, or those fns are moved outside of the useFirebase function

  // NOTE: this isn't ideal, but somewhat necessary with how we're providing this data from the
  // profile context
  useEffect(() => {
    const hasLoadedNotificationPreferences = (
      !controlledNotificationChannelPreferences
      && notificationChannelPreferences
    );

    if (hasLoadedNotificationPreferences) {
      setControlledNotificationChannelPreferences(notificationChannelPreferences);
    }
  }, [
    notificationChannelPreferences,
    controlledNotificationChannelPreferences,
    setControlledNotificationChannelPreferences,
  ]);

  const handleChangeChannels = async (notificationSlug, channels) => {
    const nextNotificationPreferences = {
      ...controlledNotificationChannelPreferences,
      [notificationSlug]: channels,
    };

    setControlledNotificationChannelPreferences(nextNotificationPreferences);
    setNotificationChannelPreferences(userID, nextNotificationPreferences);
  };

  const handleClickUpdateNotificationToken = async () => {
    const notificationToken = await fetchNotificationToken();

    ensurePushNotificationToken(userID, notificationToken);
  };

  useEffect(initialize, [initialize]);

  return (
    <section className='user-preferences'>
      <p className='lead'>
        Notifications
      </p>
      <hr />

      <NotificationRequestAlert
        userID={userID}
        onClick={handleClickUpdateNotificationToken}
      />

      {notificationSettings && notificationSettings.map((notificationSetting) => (
        <NotificationType
          slug={notificationSetting.id}
          title={notificationSetting.title}
          description={notificationSetting.description}
          availableChannels={notificationSetting.availableChannels}
          channels={controlledNotificationChannelPreferences && (
            controlledNotificationChannelPreferences[notificationSetting.id]
            || notificationSetting.defaultChannels
          )}
          onChange={handleChangeChannels}
          key={notificationSetting.id}
        />
      ))}

    </section>
  );
};

export default SectionNotifications;
