import React, {
  forwardRef,
  useState,
  useMemo,
} from 'reactn';
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { filter, find, groupBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  fromFriendlyDScore,
  toFriendlyDScore,
} from '../../helpers/Numbers';

// components
import Histogram from '../visuals/Histogram';
import LineChart from '../visuals/LineChart';
import Loader from '../global/Loader';
import Tip from '../global/Tip';
import withCopy from '../visuals/withCopy';
import useSubscriptions from '../../hooks/useSubscriptions';
import useProfile from '../../hooks/UseProfile';

const HistogramWithCopy = withCopy(Histogram);

const thresholdSubscriptionOperatorOptions = [
  { value: '>', label: 'Rises above' },
  { value: '<', label: 'Drops below' },
];

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#1A2430',
    borderColor: '#1A2430',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#1A2430',
    borderColor: '#1A2430',
    width: '100%',
    maxWidth: '325px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#40afff' : '',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#40afff',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#ffffff',
  }),
  container: (provided, state) => ({
    ...provided,
    marginBottom: '20px',
  }),
  input: (provided, state) => ({
    ...provided,
    color: '#ffffff',
  }),
};

function ThresholdSubscriptionForm({
  defaultOperator,
  defaultValue,
  onSubmit = () => null,
  onRemove = () => null,
  subject,
  subscription,
  topic,
  title,
  toFriendly = (v) => v,
  fromFriendly = (v) => v,
}) {
  const [operator, setOperator] = useState(subscription ? subscription.filter.value[0].numeric[0] : defaultOperator);
  const [value, setValue] = useState(toFriendly(subscription ? subscription.filter.value[0].numeric[1] : defaultValue));
  const [error, setError] = useState();
  const { subscribe, unsubscribe } = useSubscriptions();
  const { userID } = useProfile();

  const handleChangeOperator = (option) => {
    setOperator(option.value);
  };

  const handleChangeThreshold = (e) => {
    setValue(e.target.value);

    if (Number.isNaN(+e.target.value)) {
      setError('Threshold should be a valid number');
    } else {
      setError(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!error) {
      subscribe(topic, {
        id: `${topic}:${subject}:${userID}`,
        filter: {
          subject: [subject],
          value: [{ numeric: [operator, fromFriendly(value)] }],
        },
        meta: {
          friendlyThreshold: value,
        },
      });

      onSubmit();
    }
  };

  const handleClickRemove = () => {
    unsubscribe(subscription.id);

    onRemove();
  };

  const defaultOperatorOption = thresholdSubscriptionOperatorOptions.find((option) => option.value === operator);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Form
      validated={!error}
      onSubmit={handleSubmit}
      noValidate
    >
      <Row>
        <Form.Group as={Col} controlId="operator">
          <Form.Label>
            {`When ${title}`}
          </Form.Label>
          <Select
            defaultValue={defaultOperatorOption}
            styles={customSelectStyles}
            options={thresholdSubscriptionOperatorOptions}
            onChange={handleChangeOperator}
            closeMenuOnSelect
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} className="mb-3" controlId="threshold">
          <Form.Label>Threshold</Form.Label>
          <Form.Control
            type="text"
            placeholder="2.0"
            value={value}
            onChange={handleChangeThreshold}
            isInvalid={error}
          />
          <Form.Control.Feedback type="invalid">
            {error}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      {subscription && (
        <div className="d-flex justify-content-between">
          <Button variant="danger" type="button" onClick={handleClickRemove}>
            Remove
          </Button>
          <Button variant="primary" type="submit" disabled={!!error}>
            Save
          </Button>
        </div>
      )}

      {!subscription && (
        <Button variant="primary" type="submit" disabled={!!error}>
          Create Alarm
        </Button>
      )}
    </Form>
  );
}

const ThresholdSubscriptionPopover = forwardRef(({
  defaultOperator,
  defaultValue,
  subject,
  subscription,
  title,
  topic,
  toFriendly,
  fromFriendly,
  ...rest
}, ref) => (
  <Popover id={topic} ref={ref} {...rest}>
    <Popover.Header as="h3">
      {subscription ? 'Edit Alarm' : 'Add Alarm'}
    </Popover.Header>
    <Popover.Body>
      <ThresholdSubscriptionForm
        defaultOperator={defaultOperator}
        defaultValue={defaultValue}
        fromFriendly={fromFriendly}
        onSubmit={() => document.body.click()}
        onRemove={() => document.body.click()}
        subject={subject}
        subscription={subscription}
        title={title}
        toFriendly={toFriendly}
        topic={topic}
      />
    </Popover.Body>
  </Popover>
));

ThresholdSubscriptionPopover.displayName = 'ThresholdSubscriptionPopover';

/**
 * Builds a comparator function that returns true if the given subject is the subject of the
 * provided subscription
 */
const buildIsThresholdSubjectComparator = (subject) => (
  (subscription) => subscription?.filter?.subject?.[0] === subject
);

const dScoreTopic = 'dscore-threshold';
const riskEfficiencyTopic = 'risk-efficiency-threshold';

export default function InfoOverview({
  portfolioChangeHistory,
  portfolioStartingValue,
  portfolioEquityCurve,
  portfolioId,
  readOnly
}) {
  const {
    subscriptions,
    unsubscribe,
  } = useSubscriptions();
  const isThresholdSubject = useMemo(() => buildIsThresholdSubjectComparator(portfolioId), [portfolioId]);
  const subscriptionsForPortfolio = useMemo(
    () => filter(subscriptions, isThresholdSubject),
    [isThresholdSubject, subscriptions],
  );
  const dScoreSubscription = useMemo(
    () => find(subscriptionsForPortfolio, { topic: dScoreTopic }),
    [subscriptionsForPortfolio],
  );
  const riskEfficiencySubscription = useMemo(
    () => find(subscriptionsForPortfolio, { topic: riskEfficiencyTopic }),
    [subscriptionsForPortfolio],
  );

  if (portfolioChangeHistory.length === 0) {
    return <Loader message='Doing some math, this might take a few!' />;
  }
  if (portfolioEquityCurve.length === 0) {
    return <Loader message='Loading all the numbers...' />;
  }

  const firstDay = portfolioChangeHistory[0];
  const lastDay = portfolioChangeHistory[portfolioChangeHistory.length - 1];
  const { dScore, adjustedSortino } = portfolioChangeHistory[portfolioChangeHistory.length - 1];
  const sensibleDScoreThreshold = (dScore * 1.1).toFixed(2);
  const sensibleRiskEfficiencyThreshold = (adjustedSortino * 0.9).toFixed(2);

  return (
    <section className='portfolio-info-section'>
      <Row>
        <div className='col-md-6'>
          <Row>
            <div className='col-7 d-flex'>
              <div className='riskEff'>
                <h3 className={lastDay.adjustedSortino > 0 ? 'stat-positive' : 'stat-negative'}>
                  {lastDay.adjustedSortino.toFixed(2)}
                </h3>
                <p className='lead'>
                  Risk Efficiency
                  <Tip contentfulId='3sM2BLaigl38NrbGmki5Lg' className='ms-2' />
                  {!readOnly && (
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom-end"
                      overlay={(
                        <ThresholdSubscriptionPopover
                          defaultOperator="<"
                          defaultValue={sensibleRiskEfficiencyThreshold}
                          subject={portfolioId}
                          subscription={riskEfficiencySubscription}
                          title="Risk Efficiency"
                          topic={riskEfficiencyTopic}
                        />
                      )}
                      rootClose
                    >
                      <Button>
                        <span className='fa-layers fa-fw'>
                          <FontAwesomeIcon icon={['fas', 'bell']} />
                          {riskEfficiencySubscription && (
                            <span className='fa-layers-counter' style={{ background: 'Tomato' }}></span>
                          )}
                        </span>
                      </Button>
                    </OverlayTrigger>
                  )}
                </p>
                <div className='overview-line-chart'>
                  <LineChart
                    dailyHistory={{ history: portfolioEquityCurve }}
                    yValue='adjustedSortino'
                    thiccness={3}
                    color={lastDay.adjustedSortino > 0 ? '#62BD37' : '#F45D48'}
                    sparks={true}
                  />
                </div>
              </div>
            </div>
            {/* <div className='col-4'>
              <div className='acme-chart text-center'>Dials</div>
            </div> */}
            <div className='col-5 d-flex'>
              <div className='dScore'>
                <h3 className='text-white'>{(dScore * 100).toFixed(2)}%</h3>
                <p className='lead'>
                  dScore
                  <Tip contentfulId='3K520A54XxISWJjqRNATAP' className='ms-2' />
                  {!readOnly && (
                    <OverlayTrigger
                      trigger='click'
                      placement='bottom-end'
                      overlay={(
                        <ThresholdSubscriptionPopover
                          defaultOperator=">"
                          defaultValue={sensibleDScoreThreshold}
                          fromFriendly={fromFriendlyDScore}
                          subject={portfolioId}
                          subscription={dScoreSubscription}
                          title="dScore"
                          toFriendly={toFriendlyDScore}
                          topic={dScoreTopic}
                        />
                      )}
                      rootClose
                    >
                      <Button>
                        <span className='fa-layers fa-fw'>
                          <FontAwesomeIcon icon={['fas', 'bell']} />
                          {dScoreSubscription && (
                            <span className='fa-layers-counter' style={{ background: 'Tomato' }}></span>
                          )}
                        </span>
                      </Button>
                    </OverlayTrigger>
                  )}
                </p>
                <div className='overview-line-chart'>
                  <LineChart
                    dailyHistory={{ history: portfolioEquityCurve }}
                    yValue='dScore'
                    thiccness={3}
                    color={dScore > 0 ? '#059B42' : '#F45D48'}
                    sparks={true}
                  />
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <p className='mb-0 mt-5 text-center'>
              <small>
                How well you’re using risk to <strong>win</strong>
              </small>
            </p>
          </Row>
        </div>
        <div className='col-md-6'>
          {portfolioChangeHistory.length > 0 && (
            <HistogramWithCopy dailyHistory={{ history: portfolioChangeHistory }} showYesterday />
          )}
          <p className='mb-0 mt-5 text-center'>
            <small>How your returns are distributed</small>
            <Tip contentfulId='38yn0sOyTLwwxnjMpvYBVL' className='ms-2' />
          </p>
        </div>
      </Row>
    </section>
  );
}
