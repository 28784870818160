import React, { useState, useEffect } from 'react';
import { Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// hooks
import useProfile from '../../hooks/UseProfile';
import {
  addBlankPortfolio,
  removePortfolio,
  updatePortfolioName,
  updatePortfolioGoal,
  updatePortfolioInvestmentAmount,
  updatePortfolioRiskTolerance,
  updatePortfolioRiskHorizon,
} from '../../helpers/Firebase';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// components
import Loader from '../global/Loader';
import PortfolioCard from './PortfolioCard';

import { getLocalStorageValue, setLocalStorageValue } from '../../helpers/LocalStorage';

export default function SectionPortfolios({ portfolioList }) {
  // get history API from router
  const history = useHistory();
  // get profile API from context
  const { userID } = useProfile();
  // loading flag
  const [loading, setLoading] = useState(true);

  /**
   * Add a portfolio
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none, however user is taken to the new portfolio route
   */
  const addNewPortfolio = () => {
    console.log('add portfolio');
    // save a blank portfolio
    addBlankPortfolio(userID).then((portfolioID) => {
      amplitudeClient.getInstance().logEvent('action_add-new-portfolio', {
        portfolioId: portfolioID,
      });
      window.Appcues?.track('Create new portfolio');
      // go to new portfolio page
      history.push(`/p/${portfolioID}`);
    });
  };

  /**
   * Delete a portfolio
   * @author Ryan Srofe <rsrofe@gmail.com>
   */
  const deletePortfolio = (pid) => {
    // console.log('delete portfolio', pid);
    removePortfolio(pid);
  };

  /**
   * Update the portfolio name
   * @author Ryan Srofe <rsrofe@gmail.com>
   */
  const updateName = (pid, val) => {
    // console.log('update portfolio name', val);
    updatePortfolioName(pid, val);
  };

  /**
   * Update the portfolio goal
   * @author Ryan Srofe <rsrofe@gmail.com>
   */
  const updateGoal = (pid, val) => {
    // console.log('update portfolio goal', val);
    updatePortfolioGoal(pid, val);
  };

  /**
   * Update the portfolio investment amount
   * @author Ryan Srofe <rsrofe@gmail.com>
   */
  const updateInvestmentAmount = (pid, val) => {
    // console.log('update portfolio investment', val);
    updatePortfolioInvestmentAmount(pid, val);
  };

  /**
   * Update the risk tolerance value
   * @author Ryan Srofe <rsrofe@gmail.com>
   */
  const updateRiskTolerance = (pid, val) => {
    // console.log('value to update', val);
    updatePortfolioRiskTolerance(pid, val);
  };

  /**
   * Update the risk horizon value
   * @author Ryan Srofe <rsrofe@gmail.com>
   */
  const updateRiskHorizon = (pid, val) => {
    // console.log('value to update', val);
    updatePortfolioRiskHorizon(pid, val);
  };

  /**
   * This checks to ensure that the portfolio stored in local storage belongs to this user. If not,
   * default to the first portfolio in the list.
   */
  const ensureLocalStorageLastPortfolioValid = (userPortfolioList) => {
    const localStorageLastPortfolio = getLocalStorageValue('rs-last-portfolio');
    const portfolioExists = userPortfolioList.find(
      (portfolio) => portfolio.id === localStorageLastPortfolio
    );
    if (!portfolioExists) {
      setLocalStorageValue('rs-last-portfolio', userPortfolioList[0].id);
    }
  };

  /**
   * Checks for a portfolio by looking for portfolioList length in profile context
   * This effect runs everytime the portfolioList context value is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (portfolioList.length !== 0) {
      ensureLocalStorageLastPortfolioValid(portfolioList);
      setLoading(false);
    } else {
      setLoading('done');
    }
  }, [portfolioList]);

  return (
    <section className='user-portfolios'>
      <p className='lead'>
        {/* <FontAwesomeIcon icon={['fad', 'analytics']} className='me-3' /> */}
        Portfolios
      </p>
      <hr />
      {loading === 'done' && (
        <div className='ftu'>
          <Button variant='warning' onClick={addNewPortfolio}>
            Add New Portfolio
          </Button>
        </div>
      )}
      {loading === true && <Loader />}
      {loading === false && (
        <>
          <Row xs={1} sm={1} md={2} lg={2} xl={3}>
            {portfolioList.length !== 0 &&
              portfolioList.map((portfolio) => (
                <PortfolioCard
                  key={portfolio.id}
                  id={portfolio.id}
                  portfolio={portfolio}
                  updateName={updateName}
                  updateGoal={updateGoal}
                  updateInvestmentAmount={updateInvestmentAmount}
                  updateRiskTolerance={updateRiskTolerance}
                  updateRiskHorizon={updateRiskHorizon}
                  deletePortfolio={deletePortfolio}
                />
              ))}
          </Row>
        </>
      )}
    </section>
  );
}
