/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRanger } from 'react-ranger';

function Ranger({
  handleBackgroundColor,
  handleTextColor,
  trackBackgroundColor,
  trackHighlightColor,
  vals,
  min,
  max,
  stepSize,
  tickSize,
  setRange,
  disabled,
}) {
  const [values, setValues] = useState(vals);

  const disabledHandleBackgroundColor = 'DimGrey';
  const disabledHandleTextColor = 'Black';
  const disabledTrackBackgroundColor = 'DimGrey';
  const disabledTrackHighlightColor = 'DimGrey';

  /**
   * React-Ranger component
   * @param {number} min - minimum range value
   * @param {number} max - maximum range value
   * @param {number} stepSize - step size user can stop on
   * @param {number} tickSize - tick marks for reference
   * @param {object} values - current range values
   * @param {func} setValues - set state values
   * https://github.com/tannerlinsley/react-ranger
   * @returns - getTrackProps: func - A function that takes optional props and returns the combined necessary props for the track component.
   *            ticks: array - Ticks to be rendered. Each tick has the following props:
   *            segments: array - Segments to be rendered. Each segment has the following props:
   *            handles: array - Handles to be rendered. Each handle has the following props:
   */
  const { getTrackProps, ticks, segments, handles } = useRanger({
    min,
    max,
    stepSize,
    tickSize,
    values,
    onChange: setValues,
  });

  /**
   * Lifts up the values state to the parent component
   * @returns - none
   */
  useEffect(() => {
    setRange(values);
  }, [values]);

  /**
   * Resets the 'values' state to the new(initial) values
   * @returns - none
   */
  useEffect(() => {
    setValues(vals);
  }, [vals]);

  return (
    <div className={disabled ? 'ranger disabled' : 'ranger'}>
      <div {...getTrackProps()} className='ranger-track'>
        {ticks.map(({ value, getTickProps }) => (
          <div {...getTickProps()} className='ranger-tick'>
            <div className='ranger-tick-label'>{value}</div>
          </div>
        ))}
        {segments.map(({ getSegmentProps }, i) => (
          <div
            {...getSegmentProps({
              style: {
                background:
                  // prettier-ignore
                  i === 0
                    ? (disabled ? disabledTrackBackgroundColor : trackBackgroundColor)
                    : i === 1
                      ? (disabled ? disabledTrackHighlightColor : trackHighlightColor)
                      : i === 2
                        ? (disabled ? disabledTrackBackgroundColor : trackBackgroundColor)
                        : (disabled ? disabledTrackBackgroundColor : trackBackgroundColor),
              },
            })}
            className='ranger-segment'
            index={i}
          />
        ))}
        {handles.map(({ value, active, getHandleProps }) => (
          <button
            {...getHandleProps({
              style: {
                appearance: 'none',
                border: 'none',
                background: 'transparent',
                outline: 'none',
              },
            })}
          >
            <div
              style={{
                color: disabled ? disabledHandleTextColor : handleTextColor,
                background: disabled ? disabledHandleBackgroundColor : handleBackgroundColor,
              }}
              className={active ? 'ranger-handle active' : 'ranger-handle'}
              active={active ? 'true' : 'false'}
            >
              {value}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

Ranger.propTypes = {
  handleBackgroundColor: PropTypes.string,
  handleTextColor: PropTypes.string,
  trackBackgroundColor: PropTypes.string,
  trackHighlightColor: PropTypes.string,
  vals: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
  stepSize: PropTypes.number,
  tickSize: PropTypes.number,
  setRange: PropTypes.func,
  disabled: PropTypes.bool,
};

Ranger.defaultProps = {
  handleBackgroundColor: '#40afff',
  handleTextColor: '#ffffff',
  trackBackgroundColor: '#1A2430',
  trackHighlightColor: '#40afff',
  vals: [],
  min: 1,
  max: 1,
  stepSize: 1,
  tickSize: 1,
  setRange: () => {},
  disabled: false,
};

export default Ranger;
