import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
// components
import Loader from '../global/Loader';
import ManageAdminRoles from './ManageAdminRoles';

export default function UserManagement() {
  // loading flag
  const [loading, setLoading] = useState(true);

  /**
   * Doesn't really do anything other than set loading to false
   * Runs each time the Roster component is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    console.log('roster loaded');
    setLoading(false);
  }, []);

  return (
    <div className='pt-4 pb-4'>
      <p className='lead mb-3'>User Management</p>
      <hr />
      <p>
        With great power comes great responsibiilty... or something like that, ya dont F%$K it up!
      </p>
      {loading === true && (
        <Card className='mt-5'>
          <Loader />
        </Card>
      )}
      {loading === false && (
        <div className='mt-5'>
          <div className='search-n-add mb-5'>
            <ManageAdminRoles />
          </div>
        </div>
      )}
    </div>
  );
}
