import React, { useCallback, useEffect, useGlobal, useState } from 'reactn';
import { Button, Dropdown, DropdownButton, OverlayTrigger, Popover } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CovarianceMatrix from '../visuals/CovarianceMatrix';
// components
import PortfolioTypeToggle from './PortfolioTypeToggle';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
import { getCovarianceMatrix } from '../../helpers/DataServices';
import { updateUser } from '../../helpers/Firebase';
// hooks
import useSettings from '../../hooks/UseSettings';
// media
import covarianceMatrixIcon from '../../media/covariance-matrix-icon.svg';
import useCanViewList from '../../hooks/useCanViewList';

const PortfolioToolbar = ({
  portfolio = {},
  onSetPortfolioWeighting,
  totalInvested,
  stockList,
  startingDate,
  hideModeToggle,
  hideAllocationDropdown,
  onSetAssetViewMode,
  viewMode,
}) => {
  // get the app level settings context
  const { weighting, setWeight } = useSettings();
  const [currentUser, setCurrentUser] = useGlobal('currentUser');
  const [allocationMethod, setAllocationMethod] = useState(weighting);
  const [covarianceMatrixData, setCovarianceMatrixData] = useState(null);
  const {
    portfolioViewMode = 'grid',
    uid: userId,
  } = currentUser || {};
  const canViewList = useCanViewList();

  useEffect(async () => {
    const symbols = stockList.map((stock) => stock.symbol);
    const { data } = await getCovarianceMatrix(symbols);
    setCovarianceMatrixData(data);
  }, [stockList]);

  // UI dropdown label
  let allocationMethodLabel;
  switch (allocationMethod) {
    case 'equal':
      allocationMethodLabel = 'Equally Weighted';
      break;

    case 'volatility':
      allocationMethodLabel = 'Volatility Weighted';
      break;

    default:
      allocationMethodLabel = 'No Weighting';
  }

  // handles switching when dropdown is chosen
  const onChangeAllocationMethod = (method) => {
    setWeight(method);
    setAllocationMethod(method);
    onSetPortfolioWeighting(method);
    amplitudeClient.getInstance().logEvent('action_change-allocation-method', {
      method,
      portfolioId: portfolio.id,
    });
    window.Appcues?.track('Change allocation method', { method });
  };

  // tracks the button
  const onTrackCovarianceOverlay = (bol) => {
    amplitudeClient.getInstance().logEvent('action_toggle-covariance-matrix', {
      toggle: bol ? 'open' : 'close',
      portfolioId: portfolio.id,
    });
    window.Appcues?.track('Toggle covariance matrix');
  };

  // handles switching view mode
  const onChangeViewMode = useCallback((mode) => {
    if (userId) {
      updateUser(userId, { portfolioViewMode: mode });
    } else {
      onSetAssetViewMode(mode);
    }
  }, [userId]);

  const CovariancePopout = (
    <Popover
      id='popover-basic'
      arrowProps={{
        style: {
          borderColor: 'blue',
        },
      }}
    >
      <Popover.Body>
        <div className='d-flex align-items-center mb-2'>
          <FontAwesomeIcon icon={['fas', 'info-circle']} size={'2x'} fixedWidth />
          <small className='ms-2'>
            The <strong>Covariance Matrix</strong> shows how correlated each of your assets are to
            one another.
            <br />
            Darker is less correlated, which is a good goal.
          </small>
        </div>
        <CovarianceMatrix data={covarianceMatrixData} />
      </Popover.Body>
    </Popover>
  );

  const weightButtonClasses = classNames({
    'ms-auto': !covarianceMatrixData || stockList.length < 2,
  });

  const portfolioMode = userId ? portfolioViewMode : viewMode;

  return (
    <div className='portfolio-toolbar'>
      <div className='d-flex align-content-center flex-wrap'>
        {!hideModeToggle && (
          <div className='mb-1'>
            <PortfolioTypeToggle
              portfolio={portfolio}
              amountInvested={totalInvested}
              startingDate={startingDate}
            />
          </div>
        )}
        {canViewList && (
          <div className='ms-auto me-3 d-flex align-items-center'>
            <FontAwesomeIcon
              icon={['far', 'grid-2']}
              fixedWidth
              className={classNames('view-mode-toggle me-2', { 'text-muted': portfolioMode !== 'grid' })}
              onClick={() => onChangeViewMode('grid')}
            />
            <FontAwesomeIcon
              icon={['far', 'list-ul']}
              fixedWidth
              className={classNames('view-mode-toggle', { 'text-muted': portfolioMode !== 'list' })}
              onClick={() => onChangeViewMode('list')}
            />
          </div>
        )}
        {covarianceMatrixData && stockList.length > 1 && (
          <div className='me-3 portfolio-button'>
            <OverlayTrigger
              trigger='click'
              onToggle={onTrackCovarianceOverlay}
              placement='auto'
              overlay={CovariancePopout}
              rootClose
            >
              <Button variant='dark' className='d-flex align-items-center'>
                <img src={covarianceMatrixIcon} alt='covariance matrix' className='me-2' />
                Covariance Matrix
              </Button>
            </OverlayTrigger>
          </div>
        )}
        {!hideAllocationDropdown && (
          <div className='portfolio-button'>
            <DropdownButton
              onSelect={onChangeAllocationMethod}
              title={allocationMethodLabel}
              align='end'
              disabled={portfolio.isReal}
              variant='dark'
            >
              <Dropdown.Item eventKey='volatility'>Volatility Weighted</Dropdown.Item>
              <Dropdown.Item eventKey='equal'>Equally Weighted</Dropdown.Item>
            </DropdownButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default PortfolioToolbar;
