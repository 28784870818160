import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function CollectionToolbarSearchItemStat({ label, stat, disableColoring, showAsPercent }) {
  const statClasses = classNames({
    'stat-positive': stat > 0,
    'stat-negative': stat < 0,
  });

  // if (stat === 'NaN') {
  //   return;
  // }

  return (
    <span>
      {`${label}: `}
      <span className={!disableColoring ? statClasses : ''}>
        {showAsPercent === true ? `${stat}%` : stat}
      </span>
    </span>
  );
}

CollectionToolbarSearchItemStat.propTypes = {
  label: PropTypes.string,
  stat: PropTypes.string,
  disableColoring: PropTypes.bool,
  showAsPercent: PropTypes.bool,
};

CollectionToolbarSearchItemStat.defaultProps = {
  label: '',
  stat: '',
  disableColoring: false,
  showAsPercent: false,
};

export default CollectionToolbarSearchItemStat;
