import { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import classNames from 'classnames';
import contentful from '../../helpers/Contentful';
import { trackTipOpen } from '../../helpers/Analytics';

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => <img src={node.data.target.fields.file.url} alt={node.data.target.fields.title} className="content-image mb-3" />,
  },
};

export default function Tip({ contentfulId, className, prefixContent, icon, iconClassName, trigger = 'click' }) {
  const [tip, setTip] = useState(null);
  const [tipName, setTipName] = useState(null);
  const [tipOpen, setTipOpen] = useState(false);

  useEffect(() => {
    contentful.getEntry(contentfulId).then((entry) => {
      setTip(documentToReactComponents(entry.fields.tipContent, renderOptions));
      setTipName(entry.fields.title);
    });
  }, [contentfulId]);

  const logTipClick = () => {
    if (!tipOpen) {
      setTipOpen(true);
      trackTipOpen(tipName);
    }
    if (tipOpen) {
      setTipOpen(false);
    }
  };

  const tipPopover = (
    <Popover id="tip-popover">
      <Popover.Body>
        <>
          { prefixContent &&
            <div className='mb-2'>
              <strong>
                { prefixContent }
              </strong>
            </div>
          }
        </>
        { tip }
      </Popover.Body>
    </Popover>
  );

  const tipIcon = icon || ['fal', 'info-circle'];
  const iconClasses = classNames(
    'me-2',
    'text-muted',
    { iconClassName }
  );

  return (
    <OverlayTrigger trigger={trigger} placement="bottom" overlay={tipPopover}>
      <span className={className}>
        <FontAwesomeIcon
          icon={tipIcon}
          className={iconClasses}
          onClick={logTipClick}
        />
      </span>
    </OverlayTrigger>
  );
}
