/* eslint-disable indent */
import React from 'react';
import { Col, Card } from 'react-bootstrap';
import { addInvestorToBullpen, removeInvestorFromBullpen } from '../../helpers/Firebase';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// components
import InvestorCardBody from './InvestorCardBody';
import InvestorCardFooter from './InvestorCardFooter';

export default function InvestorCard({ investor, isFavorite, followCount, followLimit, userID }) {
  const { filerCIK } = investor;
  // track the follow count
  const atFollowLimit = followCount >= followLimit;

  // add the investor to the user's follow list
  const onFollow = () => {
    if (!atFollowLimit) {
      addInvestorToBullpen(filerCIK, userID);
      amplitudeClient.getInstance().logEvent('action_follow-investor', {
        investor: investor.name,
        filerCIK,
      });
    }
  };

  // remove the investor from the user's follow list
  const onUnfollow = () => {
    removeInvestorFromBullpen(filerCIK, userID);
    amplitudeClient.getInstance().logEvent('action_unfollow-investor', {
      investor: investor.name,
      filerCIK,
    });
  };

  return (
    <Col className='mb-4'>
      {investor ? (
        <Card id={investor.id} className='investor loaded'>
          <InvestorCardBody investor={investor} isFavorite={isFavorite} />
          <InvestorCardFooter
            investorID={filerCIK}
            isFavorite={isFavorite}
            atFollowLimit={atFollowLimit}
            selectInvestor={onFollow}
            deleteInvestor={onUnfollow}
          />
        </Card>
      ) : (
        <Card className='investor no-data'>
          <Card.Body>
            <Card.Text className='text-danger'>🧟 no data for {symbol}</Card.Text>
          </Card.Body>
        </Card>
      )}
    </Col>
  );
}
