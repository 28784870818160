import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// Hooks
import useProfile from '../../hooks/UseProfile';
import { updatePortfolioShareSettings } from '../../helpers/Firebase';
import useToast from '../../hooks/UseToast';

export default function PublicToggle({ portfolio }) {
  const { userID } = useProfile();
  const { isPublic } = portfolio?.public || {};
  const [switchLabel, setSwitchLabel] = useState(null);
  // add toast API for display to the user
  const { addToast } = useToast();

  const onTogglePublic = () => {
    updatePortfolioShareSettings(portfolio.id, !isPublic);
    amplitudeClient.getInstance().logEvent('action_toggle-shared-portfolio', {
      toggle: !isPublic === true ? 'public' : 'private',
      portfolioId: portfolio.id,
    });
    window.Appcues?.track('Toggle portfolio share', { public: isPublic });
    if (!isPublic === true) {
      const shareLink = `${window.location.origin}/p/${portfolio.id}`;
      navigator.clipboard.writeText(shareLink);
      amplitudeClient.getInstance().logEvent('system_copy-public-share-link-to-clipboard', {
        link: shareLink,
      });
      addToast('Share Link Copied to Clipboard', '', 'info');
    }
  };

  useEffect(() => {
    setSwitchLabel(isPublic ? 'Public' : 'Private');
  }, [isPublic]);

  const subText = isPublic ? 'URL will go here' : 'Only you can see this';

  return (
    <div className='d-flex align-items-center'>
      <Switch
        type='switch'
        id='portfolio-mode-switch'
        checked={isPublic}
        onChange={onTogglePublic}
        uncheckedIcon={<FontAwesomeIcon icon={['fad', 'eye-slash']} className='mx-2 mt-2' />}
        checkedIcon={<FontAwesomeIcon icon={['fad', 'eye']} className='mx-2 mt-2' />}
        height={33}
        width={63}
        handleDiameter={33}
        offColor='#2b333e'
        onColor='#2b333e'
        offHandleColor='#3EA6FF'
        onHandleColor='#1FBD60'
        // boxShadow='0px 0px 1px 1px #3EA6FF'
        // activeBoxShadow='0px 0px 1px 1px #1FBD60'
      />
      <span className='switch-label d-flex flex-column ms-2'>
        <div>{switchLabel}</div>
      </span>
    </div>
  );
}
