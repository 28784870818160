import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import UAParser from 'ua-parser-js';
import { db } from '../config/firebase';
// import useToast from '../hooks/UseToast';
import { setLocalStorageValue } from './LocalStorage';

/**
 * Returns whether or not the user owns a given portfolio
 * @param {String} portfolioId - the portfolioId
 * @param {String} userId - the userId
 * @returns {Boolean} - true if the user owns the portfolio
 */

export const verifyPortfolioOwner = (portfolioId, userId) =>
  getDoc(doc(db, 'portfolios', portfolioId))
    .then((d) => {
      if (d.exists) {
        const data = d.data();
        if (data.owner === userId) {
          return true;
        }
      }
      return false;
    })
    .catch((e) => {
      console.log(e);
      return new Error(e);
    });

/**
 * Builds a human readable installation name from the user agent string.
 */
export const getInstallationName = () => {
  const incrementerRE = /#(\d+)$/;
  const ua = new UAParser(window.navigator.userAgent).getResult();
  const nameParts = [];

  if (ua?.os?.name) {
    nameParts.push(ua.os.name);
  }

  if (ua?.browser?.name) {
    nameParts.push(ua.browser.name);
  }

  return nameParts.join(' ');
};

// add toast API for displaying error toasts to users
const { addToast } = { addToast: () => {} }; // useToast();

// utility to update history collection within a portfolio
export const updatePortfolioHistory = (portfolioID, action) => {
  console.log('update', portfolioID, 'history with ', action);
};

/**
 * Set check in date local storage
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} key - local storage key
 * @param {String} ttl - time to life in milliseconds
 * @returns - none
 */
export const setCheckInExpiry = (key, ttl) => {
  const now = new Date();
  // the time when it's supposed to expire
  const expires = now.getTime() + ttl;
  // set data to local storage
  setLocalStorageValue(key, expires);
};

/**
 * updates the profile name for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} userName - the users name
 * @returns - none
 */
export const updateUserName = (userID, userName) => {
  // make sure we have a userName
  // if (userName === '') {
  //   return addToast('ERROR SAVING USER', 'User name cannot be empty...', 'error');
  // }
  // save user name
  const userDocRef = doc(db, 'users', userID);
  setDoc(
    userDocRef,
    {
      name: userName,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('user name updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR WRITING USER NAME', err.message, 'error');
    });
};

/**
 * updates the phone number for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} userPhone - the users phone number
 * @returns - none
 */
export const updateUserPhone = (userID, userPhone) => {
  // make sure we have a userName
  // if (userPhone === '') {
  //   return addToast('ERROR SAVING USER PHONE', 'User phone number cannot be empty...', );
  // }
  // save user name
  const userDocRef = doc(db, 'users', userID);
  setDoc(
    userDocRef,
    {
      phone: userPhone,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('user phone number updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR WRITING USER PHONE', err.message, 'error');
    });
};

export const updateUser = (userId, changes) => setDoc(
  doc(db, 'users', userId),
  {
    ...changes,
    updatedAt: new Date(),
  },
  { merge: true }
);

/**
 * Gets a portfolio by ID
 * @param {String} id - the portfolio ID
 * @returns {Object} portfolio - the portfolio object
 */
export const getPortfolio = (id) =>
  getDoc(doc(db, 'portfolios', id))
    .then((d) => {
      if (!d.exists) {
        return null;
      }
      return {
        ...doc.data(),
        id: doc.id,
      };
    })
    .catch((err) => {
      console.log('Error getting roster stock', err);
    });

/**
 * adds an anonymous Portfolio for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID (anonymous user login)
 * @returns {String} newPortfolioRef.id - id of the created portfolio
 */
export const addBlankPortfolio = async (userID) => {
  // add the portfolio to firestore
  const newPortfolioRef = doc(collection(db, 'portfolios'));
  return setDoc(
    newPortfolioRef,
    {
      claimed: true,
      owner: userID,
      name: 'Untitled Portfolio',
      investorBullpen: [],
      isReal: false,
      public: {
        isPublic: false,
      },
      goal: '',
      totalAmountInvested: 10000,
      riskHorizon: '',
      riskTolerance: '',
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    // merge data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('successfully added blank portfolio', newPortfolioRef.id);
      // snoozing initial portfolio check-in expiry for 1 hr (600,000)
      setCheckInExpiry(`M12S-${newPortfolioRef.id}`, 600000);
      // dont return this until it's actually created
      return newPortfolioRef.id;
    })
    .catch((err) => {
      addToast('ERROR WRITING BLANK PORTFOLIO', err.message, 'error');
    });
};

/**
 * adds a Portfolio for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} portfolioName - the nickname for the portfolio
 * @param {String} portfolioDescription - the description of the portfolio
 * @returns {String} newPortfolioRef.id - id of the created portfolio
 */
export const addOwnedPortfolio = async (userID, portfolioName, portfolioDescription) => {
  // make sure we have a portfolioName
  if (portfolioName === '') {
    return addToast('ERROR SAVING PORTFOLIO', 'Portfolio name cannot be empty...', 'error');
  }
  // make sure we have a portfolioDescription
  if (portfolioDescription === '') {
    return addToast('ERROR SAVING NOTE', 'Portfolio description cannot be empty...', 'error');
  }

  // add the portfolio to firestore
  const newPortfolioRef = doc(db, 'portfolios');
  setDoc(
    newPortfolioRef,
    {
      claimed: true,
      owner: userID,
      name: portfolioName,
      investorBullpen: [],
      description: portfolioDescription,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    // merge data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('successfully added owned portfolio', newPortfolioRef.id);
    })
    .catch((err) => {
      addToast('ERROR WRITING PORTFOLIO', err.message, 'error');
    });

  return newPortfolioRef.id;
};

/**
 * removes a portfolio
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} portfolioID - the portfolio ID
 * @returns - none
 */
export const removePortfolio = (portfolioID) => {
  updateDoc(
    doc(db, 'portfolios', portfolioID),
    {
      claimed: false,
      updatedAt: new Date(),
    },
    // merge data vs overwrite it
    { merge: true }
  )
    .then(() => {
      // BEWARE this will silently fail if path is wrong in ANY way
      console.log('successfully deactivated portfolio');
    })
    .catch((error) => {
      console.error('ERROR DEACTIVATING PORTFOLIO: ', error);
    });
};

/**
 * updates the portfolio
 * @param {String} id - the id of the portfolio
 * @param {String} data - the data to update
 */
export const updatePortfolio = async (id, data) => setDoc(
  doc(db, 'portfolios', id),
  {
    updatedAt: new Date(),
    ...data,
  },
  { merge: true }
);

/**
 * updates the portfolio name for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} userName - the users name
 * @returns - none
 */
export const updatePortfolioName = (portfolioID, portfolioName) => {
  // make sure we have a portfolioID
  if (portfolioID === '') {
    return addToast('ERROR UPDATING PORTFOLIO', 'Portfolio ID cannot be empty...', 'error');
  }
  // save user name
  setDoc(
    doc(db, 'portfolios', portfolioID),
    {
      name: portfolioName,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Portfolio name updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR UPDATING PORTFOLIO NAME', err.message, 'error');
    });
};

/**
 * updates the portfolio goal for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} portfolioID - the portfolio ID
 * @param {String} userGoal - the portfolio goal
 * @returns - none
 */
export const updatePortfolioGoal = (portfolioID, portfolioGoal) => {
  // make sure we have a portfolioID
  if (portfolioID === '') {
    return addToast('ERROR UPDATING PORTFOLIO', 'Portfolio ID cannot be empty...', 'error');
  }
  // save user name
  setDoc(
    doc(db, 'portfolios', portfolioID),
    {
      goal: portfolioGoal,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Portfolio goal updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR UPDATING PORTFOLIO GOAL', err.message, 'error');
    });
};

/**
 * updates the portfolio investment amount for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} portfolioID - the portfolio ID
 * @param {String} portfolioInvestment - the portfolio investment
 * @returns - none
 */
export const updatePortfolioInvestmentAmount = (portfolioID, portfolioInvestment) => {
  // make sure we have a portfolioID
  if (portfolioID === '') {
    return addToast('ERROR UPDATING PORTFOLIO', 'Portfolio ID cannot be empty...', 'error');
  }

  // convert to number and remove $ and commas
  const portfolioInvestmentNum = Number(portfolioInvestment.replace(/\$|,/g, ''));

  // save value
  setDoc(
    doc(db, 'portfolios', portfolioID),
    {
      totalAmountInvested: portfolioInvestmentNum,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Portfolio investment updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR UPDATING PORTFOLIO INVESTMENT', err.message, 'error');
    });
};

/**
 * updates the risk tolerance for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} portfolioID - the portfolio ID
 * @param {String} riskTolerance - the portfolio risk tolerance
 * @returns - none
 */
export const updatePortfolioRiskTolerance = (portfolioID, riskTolerance) => {
  // make sure we have a portfolioID
  if (portfolioID === '') {
    return addToast('ERROR UPDATING PORTFOLIO', 'Portfolio ID cannot be empty...', 'error');
  }

  // save value
  setDoc(
    doc(db, 'portfolios', portfolioID),
    {
      riskTolerance,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Portfolio risk tolerance updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR WRITING PORTFOLIO RISK TOLERANCE', err.message, 'error');
    });
};

/**
 * updates the risk horizon for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} portfolioID - the portfolio ID
 * @param {String} riskHorizon - the portfolio risk horizon
 * @returns - none
 */
export const updatePortfolioRiskHorizon = (portfolioID, riskHorizon) => {
  // make sure we have a portfolioID
  if (portfolioID === '') {
    return addToast('ERROR UPDATING PORTFOLIO', 'Portfolio ID cannot be empty...', 'error');
  }

  // save value
  setDoc(
    doc(db, 'portfolios', portfolioID),
    {
      riskHorizon,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Portfolio risk horizon updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR WRITING PORTFOLIO RISK HORIZON', err.message, 'error');
    });
};

/**
 * Updates the portfolio's share settings (public settings)
 * @param {String} portfolioID - the portfolio ID
 * @param {Boolean} isPublic - whether the portfolio is public or not
 * @param {Object} options - share options:
 * - {Boolean} showName - whether to show the person's name or not
 * - {String} allocationMethod - what allocation method to display publicly (equal, weighted, custom)
 */
export const updatePortfolioShareSettings = (portfolioID, isPublic, options) => {
  let update = { isPublic };
  if (options) {
    update = { ...update, ...options };
  }

  setDoc(
    doc(db, 'portfolios', portfolioID),
    {
      public: update,
    },
    { merge: true }
  )
    .then(() => {
      console.log('Share settings updated successfully!');
    })
    .catch((err) => {
      addToast('Error updating share settings', err.message, 'error');
    });
};

export const setPortfolioMode = (userID, portfolioID, isReal) => {
  // make sure we have a portfolioID
  if (portfolioID === '') {
    return addToast('ERROR UPDATING PORTFOLIO', 'Portfolio ID cannot be empty...', 'error');
  }

  // save value
  setDoc(
    doc(db, 'portfolios', portfolioID),
    {
      isReal,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Portfolio mode updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR WRITING PORTFOLIO MODE', err.message, 'error');
    });
};

/**
 * adds a stock to a specified portfolio for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} portfolioID - the portfolio ID
 * @param {String} stockSymbol - stock symbol
 * @param {Array} investors - list of investors
 * @param {String} exitStrategy - whatever represents an exit strategy
 * @returns - none
 */
export const addPortfolioStock = async (userID, portfolioID, stockSymbol) => {
  // make sure we have stock content
  if (stockSymbol === '') {
    return addToast('ERROR SAVING STOCK', 'Stock symbol cannot be empty...', 'error');
  }
  // save the stock to the db
  const newPortfolioStockRef = doc(collection(doc(db, 'portfolios', portfolioID), 'stocks'));
  setDoc(
    newPortfolioStockRef,
    {
      symbol: stockSymbol,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('stock', stockSymbol, 'added successfully!');
    })
    .catch((err) => {
      addToast('ERROR WRITING STOCK', err.message, 'error');
    });

  return newPortfolioStockRef.id;
};

/**
 * updates a stock within a specified portfolio for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} portfolioID - the portfolio ID
 * @param {String} stockID - the stock ID
 * @returns - none
 */
export const updatePortfolioStockShares = (userID, portfolioID, stockID, shareCount) => {
  // make sure we have a portfolioID
  if (portfolioID === '') {
    return addToast('ERROR UPDATING PORTFOLIO', 'Portfolio ID cannot be empty...', 'error');
  }

  // convert string to number for backwards compat and remove $ and commas
  let shareCountNum = 0;
  if (typeof shareCount === 'string') {
    shareCountNum = Number(shareCount.replace(/\$|,/g, ''));
  } else {
    shareCountNum = shareCount;
  }

  // save value
  setDoc(
    doc(db, 'portfolios', portfolioID, 'stocks', stockID),
    {
      shares: shareCountNum,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Stock share count updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR UPDATING STOCK SHARES', err.message, 'error');
    });
};

/**
 * removes a stock within a specified portfolio for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} portfolioID - the portfolio ID
 * @param {String} stockID - the stock ID
 * @returns - none
 */
export const removePortfolioStock = (userID, portfolioID, stockID) => deleteDoc(
  doc(db, 'portfolios', portfolioID, 'stocks', stockID),
)
  .then(() => {
    // BEWARE this will silently fail if path is wrong in ANY way
    console.log('successfully deleted stock');
  })
  .catch((error) => {
    console.error('ERROR DELETING STOCK: ', error);
  });

/**
 * removes a stock within a specified portfolio for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} portfolioID - the portfolio ID
 * @param {String} stockID - the stock ID
 * @returns - none
 */
export const removePortfolioStockByTicker = async (portfolioId, ticker) => {
  const snapshot = await getDocs(collection(db, 'portfolios', portfolioId, 'stocks'));
  const assets = [];
  let stockId = null;
  snapshot.forEach((ref) => {
    if (ref.data().symbol === ticker) {
      stockId = ref.id;
    }
  });

  if (stockId) {
    return removePortfolioStock(null, portfolioId, stockId);
  }
};

/**
 * adds an institution for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} accessToken - the accessToken from Plaid
 * @param {String} institutionName - the institution name
 * @returns - none
 */
export const addPlaidInstitution = async (userID, accessToken, institutionName) => {
  // make sure we have a user
  if (userID === '') {
    return addToast('ERROR SAVING INSTITUTION', 'User ID cannot be empty...', 'error');
  }

  // make sure we have stock content
  if (accessToken === '') {
    return addToast('ERROR SAVING INSTITUTION', 'Access token cannot be empty...', 'error');
  }

  // save the institution data
  const newInstitutionRef = doc(db, 'institutions');
  return setDoc(
    newInstitutionRef,
    {
      name: institutionName,
      accessToken,
      owner: userID,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    // merge data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('institution added successfully!', newInstitutionRef.id);
      // dont return this until it's actually created
      return newInstitutionRef.id;
    })
    .catch((err) => {
      addToast('ERROR WRITING INSTITUTION DOC', err.message, 'error');
    });
};

/**
 * remove an institution for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} institutionID - the institution ID
 * @returns - none
 */
export const removePlaidInstitution = (userID, institutionID) => {
  // make sure we have a user
  if (userID === '') {
    return addToast('ERROR REMOVING INSTITUTION', 'User ID cannot be empty...', 'error');
  }

  // make sure we have an institution ID
  if (institutionID === '') {
    return addToast('ERROR REMOVING INSTITUTION', 'Institution ID cannot be empty...', 'error');
  }

  // remove the institution data
  deleteDoc(
    doc(db, 'institutions', institutionID)
  )
    .then(() => {
      // BEWARE this will silently fail if path is wrong
      console.log('Institution successfully deleted!');
    })
    .catch((error) => {
      console.error('Error removing institution: ', error);
    });
};

/**
 * adds a stock to the roster
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} stockSymbol - stock symbol
 * @returns - none
 */
export const addRosterStock = (stockSymbol) => {
  // make sure we have stock content
  if (stockSymbol === '') {
    return addToast('ERROR SAVING STOCK', 'Stock symbol cannot be empty...', 'error');
  }
  // make sure symbol is uppercase
  const ticker = stockSymbol.toUpperCase();
  // save the stock to the db
  const newRosterStockRef = doc(db, 'roster', ticker);
  setDoc(
    newRosterStockRef,
    {
      symbol: ticker,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log(ticker, 'stock added successfully!');
      return ticker;
    })
    .catch((err) => {
      addToast('ERROR WRITING STOCK', err.message, 'error');
    });
};

/**
 * updates a stock within a specified portfolio for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} portfolioID - the portfolio ID
 * @param {String} stockID - the stock ID
 * @returns - none
 */
export const updateRosterStock = (stockID) => {
  console.log('update', stockID);
};

/**
 * removes a stock within a specified portfolio for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @param {String} portfolioID - the portfolio ID
 * @param {String} stockID - the stock ID
 * @returns - none
 */
export const removeRosterStock = (stockID) => {
  // remove the stock from firestore
  deleteDoc(
    doc(db, 'roster', stockID),
  )
    .then(() => {
      console.log('successfully deleted stock');
    })
    .catch((error) => {
      console.error('ERROR DELETING STOCK: ', error);
    });
};

/**
 * Investors
 */

export const getInvestors = (riskTolerance, timeHorizon) => getDocs(collection(db, 'investors'))
  .then((querySnapshot) => {
    const investors = [];
    querySnapshot.forEach((d) => investors.push(d.data()));
    return investors;
  })
  .catch((e) => console.error('Error getting investors', e));

export const addInvestorToBullpen = (investorCIK, userId) => {
  setDoc(
    doc(db, 'users', userId),
    {
      investorBullpen: arrayUnion(investorCIK),
    },
    {
      merge: true,
    }
  )
    .then(() => '')
    .catch((e) => console.log('Error', e));
};

export const removeInvestorFromBullpen = (investorCIK, userId) => {
  setDoc(
    doc(db, 'users', userId),
    {
      investorBullpen: arrayRemove(investorCIK),
    },
    {
      merge: true,
    }
  )
    .then(() => '')
    .catch((e) => console.log('Error', e));
};

export const removeAllInvestorsFromBullpen = (userId) => {
  setDoc(
    doc(db, 'users', userId),
    {
      investorBullpen: [],
    },
    {
      merge: true,
    }
  )
    .then(() => '')
    .catch((e) => console.log('Error', e));
};

// Returns an array of all symbols in your investor bullpen
export const getInvestorBullpen = async (userId) => {
  const tickers = [];
  const userSnap = await getDoc(doc(db, 'users', userId));
  const { investorBullpen } = userSnap.data();
  const querySnap = await getDocs(query(
    collection(db, 'investors'),
    where('filerCIK', 'in', [...investorBullpen, '']),
  ));
  querySnap.forEach((d) => {
    tickers.push(d.data().holdings[0].symbols);
  });
  return [...new Set(tickers.flat())];
};

/**
 * Roster
 */
export const getRoster = () => {
  console.log('getRoster');
  return getDocs(collection(db, 'roster'))
    .then((rosterSnapshot) => {
      if (rosterSnapshot.docs === undefined) {
        console.log('no roster stocks yet');
      } else {
        const roster = [];
        rosterSnapshot.forEach((d) => roster.push(d.data()));
        // console.log('Roster data:', roster);
        return roster;
      }
    })
    .catch((e) => console.error('Error getting roster', e));
};

export const getRosterStock = (symbol) =>
  getDoc(doc(db, 'roster', symbol))
    .then((d) => {
      if (!d.exists) {
        console.log('No roster stock for', symbol, '!');
      } else {
        // console.log('Document data:', doc.data());
        return d.data();
      }
    })
    .catch((err) => {
      console.log('Error getting roster stock', err);
    });

/**
 * Collections
 */

/**
 * updates the collections
 * @param {String} id - the id of the collection
 * @param {String} data - the data to update
 */
export const updateCollection = async (id, data) =>
  setDoc(doc(db, 'collections', id), data, { merge: true });

/**
 * Returns whether or not the user owns a given collection
 * @param {String} collectionId - the collectionId
 * @param {String} userId - the userId
 * @returns {Boolean} - true if the user owns the collection
 */

export const verifyCollectionOwner = (collectionId, userId) => doc(db, 'collections', collectionId)
  .then((d) => {
    if (d.exists) {
      const data = d.data();
      if (data.owner === userId) {
        return true;
      }
    }
    return false;
  })
  .catch((e) => {
    console.log(e);
    return new Error(e);
  });

/**
 * adds an collection for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} userID - the users ID
 * @returns {String} newCollectionRef.id - id of the created collection
 */
export const addBlankCollection = async (userID) => {
  // add the collection to firestore
  const newCollectionRef = doc(collection(db, 'collections'));
  return setDoc(
    newCollectionRef,
    {
      claimed: true,
      owner: userID,
      name: 'Untitled Collection',
      note: '',
      public: {
        isPublic: false,
      },
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    // merge data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('successfully added blank collection', newCollectionRef.id);
      // dont return this until it's actually created
      return newCollectionRef.id;
    })
    .catch((err) => {
      addToast('ERROR WRITING BLANK COLLECTION', err.message, 'error');
    });
};

/**
 * removes a collection
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} collectionID - the collection ID
 * @returns - none
 */
export const removeCollection = (collectionID) => {
  // remove the collection from firestore
  // NOT ACTUALLY REMOVING FROM THE DB, FIRESTORE DOES NOT LIKE THAT
  updateDoc(
    doc(db, 'collections', collectionID),
    {
      claimed: false,
      updatedAt: new Date(),
    },
    // merge data vs overwrite it
    { merge: true }
  )
    .then(() => {
      // BEWARE this will silently fail if path is wrong in ANY way
      console.log('successfully deactivated collection');
    })
    .catch((error) => {
      console.error('ERROR DEACTIVATING COLLECTION: ', error);
    });
};

/**
 * updates the collection name for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} collectionID - collection ID
 * @param {String} collectionName - collection name
 * @returns - none
 */
export const updateCollectionName = (collectionID, collectionName) => {
  // make sure we have a collectionID
  if (collectionID === '') {
    return addToast('ERROR UPDATING COLLECTION', 'Collection ID cannot be empty...', 'error');
  }
  // save user name
  const collectionDocRef = doc(db, 'collections', collectionID);
  setDoc(
    collectionDocRef,
    {
      name: collectionName,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Collection name updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR UPDATING COLLECTION NAME', err.message, 'error');
    });
};

/**
 * updates the collection note for specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} collectionID - collection ID
 * @param {String} collectionNote - note text
 * @returns - none
 */
export const updateCollectionNote = (collectionID, collectionNote) => {
  // make sure we have a collectionID
  if (collectionID === '') {
    return addToast('ERROR UPDATING COLLECTION', 'Collection ID cannot be empty...', 'error');
  }
  // save user name
  const collectionDocRef = doc(db, 'collections', collectionID);
  setDoc(
    collectionDocRef,
    {
      note: collectionNote,
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('Collection name updated successfully!');
    })
    .catch((err) => {
      addToast('ERROR UPDATING COLLECTION NAME', err.message, 'error');
    });
};

/**
 * adds an asset to a specified collection
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} collectionID - the collection ID
 * @param {String} stockSymbol - stock symbol
 * @returns - none
 */
export const addCollectionAsset = async (collectionID, assetSymbol) => {
  // make sure we have stock content
  if (assetSymbol === '') {
    return addToast('ERROR SAVING STOCK', 'Stock symbol cannot be empty...', 'error');
  }
  // save the stock to the db
  const newCollectionAssetRef = doc(collection(doc(db, 'collections', collectionID), 'assets'));

  setDoc(
    newCollectionAssetRef,
    {
      symbol: assetSymbol,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    // merge the data vs overwrite it
    { merge: true }
  )
    .then(() => {
      console.log('asset', assetSymbol, 'added successfully!');
    })
    .catch((err) => {
      addToast('ERROR WRITING ASSET', err.message, 'error');
    });

  return newCollectionAssetRef.id;
};

/**
 * removes an asset within a specified collection for a specified user
 * @author Ryan Srofe <rsrofe@gmail.com>
 * @param {String} collectionID - the collection ID
 * @param {String} assetID - the asset ID
 * @returns - none
 */
export const removeCollectionAsset = (collectionID, assetID) => {
  // remove the asset from firestore
  const noteRef = deleteDoc(
    doc(db, 'collections', collectionID, 'assets', assetID)
  )
    .then(() => {
      // BEWARE this will silently fail if path is wrong in ANY way
      console.log('successfully deleted asset');
    })
    .catch((error) => {
      console.error('ERROR DELETING ASSET: ', error);
    });
};

export const removeCollectionAssetByTicker = async (collectionId, ticker) => {
  const snapshot = await getDocs(collection(db, 'collections', collectionId, 'assets'));
  const assets = [];
  let assetId = null;
  snapshot.forEach((ref) => {
    if (ref.data().symbol === ticker) {
      assetId = ref.id;
    }
  });

  if (assetId) {
    return removeCollectionAsset(collectionId, assetId);
  }
};

/**
 * Adds a push notification token to the user's record
 * @param {string} userId
 * @param {string} token
 */
export const ensurePushNotificationToken = (userId, token) => {
  if (!token) {
    return;
  }

  const data = {
    name: getInstallationName(),
    status: 'valid',
    updatedAt: new Date(),
  };

  return updateDoc(
    doc(db, 'users', userId),
    {
      [`pushInstallations.${token}`]: data,
    },
    {
      merge: true,
    }
  );
};

/**
 * Returns a list of available notification settings
 */
export const getNotificationSettings = async () => {
  const snapshot = await getDocs(collection(db, 'notification-settings'));
  const notificationSettings = [];
  snapshot.forEach((ref) => notificationSettings.push({ id: ref.id, ...ref.data() }));

  return notificationSettings;
};

/**
 * Update user's notification channel preferences
 */
export const setNotificationChannelPreferences = async (userID, notificationChannelPreferences) => {
  await setDoc(
    doc(db, 'users', userID),
    {
      notificationChannelPreferences,
      updatedAt: new Date(),
    },
    { merge: true }
  );
};

/**
 * Create a notification subscription
 */
export const addNotificationSubscription = async (owner, topic, data) => {
  const newSubscriptionRef = doc(collection(db, 'notification-subscriptions'), topic);
  await setDoc(
    newSubscriptionRef,
    {
      ...data,
      topic,
      owner,
    },
    { merge: true }
  );
};

/**
 * Delete a subscription by id
 */
export const removeNotificationSubscription = async (id) => deleteDoc(
  doc(db, 'notification-subscriptions', id),
);

/**
 * Returns a list of user subscriptions
 */
export const getNotificationSubscriptions = async (userId) => {
  const snapshot = await getDocs(
    query(
      collection(db, 'notification-subscriptions'),
      where('owner', '==', userId)
    ),
  );
  const notificationSubscriptions = [];
  snapshot.forEach((ref) => notificationSubscriptions.push({ id: ref.id, ...ref.data() }));

  return notificationSubscriptions;
};
