import amplitude from 'amplitude-js';
import { logEvent } from 'firebase/analytics';

// analytics
import { analytics } from '../config/firebase';

/*
 * Amplitude Analytics
 */
amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
  includeFbclid: true,
  includeGclid: true,
  includeReferrer: true,
  includeUtm: true,
  saveParamsReferrerOncePerSession: false,
});

export const amplitudeClient = amplitude;

/*
 * GA4 Custom Events
 * Custom events are events that are not already automatically collected or
 * recommended events. Only use custom events if the existing events don't
 * handle your use case. Custom events won't show up in most standard reports
 * so you will likely need to set up custom reporting for meaningful analysis.
 * https://support.google.com/analytics/answer/9267735?hl=en&ref_topic=9756175
 */

/*
 * Tracks that a user has opened a tooltip on a page
 * @param {method} string, tip
 */
export const trackTipOpen = (tip) => {
  logEvent(analytics, 'tip_open', { tip });
};

/*
 * Tracks that a user has signed up for an account.
 * @param {method} string, method used to login
 */
export const trackSignup = (method, source) => {
  logEvent(analytics, 'signup', { method, source });
};

/*
 * Tracks that a user has logged in.
 * @param {method} string, method used to login
 */
export const trackLogin = (method) => {
  logEvent(analytics, 'login', { method });
};

/*
 * Tracks what a user has searched for.
 * @param {term} string, term that was searched for
 */
export const trackSearch = (term) => {
  logEvent(analytics, 'search', { search_term: term });
};

/*
 * Tracks when a user has shared content.
 * @param {method} string, the method in which the content is shared
 * @param {type} string, the type of shared content
 * @param {id} string, an identifier for the shared content
 */
export const trackShare = (method, type, id) => {
  logEvent(analytics, 'share', {
    method,
    content_type: type,
    item_id: id,
  });
};

/*
 * Tracks that a user has selected some content of a certain type.
 * @param {type} string, the type of selected content
 * @param {id} string, an identifier for the item that was selected
 */
export const trackSelectedContent = (type, id) => {
  logEvent(analytics, 'select_content', {
    content_type: type,
    content_id: id,
  });
};
