import {
  useCallback,
  useGlobal,
  useRef,
  useEffect,
  useState,
} from 'reactn';
import { Container, Form, Button, Col } from 'react-bootstrap';
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom';
import {
  GoogleLoginButton,
  TwitterLoginButton,
  FacebookLoginButton,
} from 'react-social-login-buttons';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import {
  login,
  loginWithGoogle,
  loginWithTwitter,
  loginWithFacebook,
} from '../helpers/Auth';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
import { updateUser } from '../helpers/Firebase';
// hooks
import useToast from '../hooks/UseToast';
import useQueryString from '../hooks/UseQueryString';
// components
import Seo from '../components/global/Seo';
import { auth } from '../config/firebase';

// media
import finiacLogo from '../media/finiac-logo-on-dark.png';

// meta data for SEO
const metaData = {
  title: 'Login to Finiac',
  description:
    'Login to Finiac, check your portfolio, evaluate your risk and make better decisions about your next investment.',
};

export default function Login() {
  // add toast API
  const { addToast } = useToast();
  // reference to email input
  const emailRef = useRef();
  // reference to password input
  const passwordRef = useRef();
  // loader
  const [loading, setLoading] = useState(false);
  // router
  const history = useHistory();
  // router location
  const location = useLocation();
  // current path from location
  const { pathname } = location;
  // query params
  const queryParams = useQueryString();
  // body element class list
  const bodyClassList = document.body.classList;
  const [currentUser] = useGlobal('currentUser');

  const attemptEmailLinkSignIn = useCallback(async () => {
    const referralEmail = queryParams.get('referralEmail');
    const referralSourceId = queryParams.get('referralSourceId');
    const referralEntityId = queryParams.get('referralEntityId');
    const isLocalAndOOB = window.location.href.includes('localhost') && window.location.href.includes('oobCode');

    if (isSignInWithEmailLink(auth, window.location.href)) {
      try {
        const userCred = await signInWithEmailLink(auth, referralEmail, window.location.href);

        if (userCred && referralSourceId && referralEntityId) {
          await updateUser(userCred.user.uid, { referralSourceId, referralEntityId });
        }
      } catch (e) {
        if (emailRef.current) {
          emailRef.current.value = referralEmail;
        }
      }
    }
  }, [queryParams]);

  /**
   * Sets and removes the class on body element per route path
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // split the path into an array [0] seems to always =''
    const pathArray = pathname.split('/');
    const pathArrayLength = pathArray.length;
    const newBodyClass = pathArray[pathArrayLength - 1];

    // set className
    bodyClassList.add(newBodyClass);

    // build amplitude query string tracking payload
    // ** NOTE: filters out UTMs and only adds anything extra
    const amplitudePayload = {};
    queryParams.forEach((value, key) => {
      if (!key.includes('utm_')) {
        amplitudePayload[key] = value;
      }
    });

    attemptEmailLinkSignIn();

    // track asset in Amplitude
    amplitudeClient.getInstance().logEvent('view_login-page', amplitudePayload);
    window.Appcues?.track('View login');

    return () => {
      // remove className
      bodyClassList.remove(newBodyClass);
    };
  }, []);

  /**
   * Handle login form submit
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} e - onClick event
   * @returns - none
   */
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      amplitudeClient.getInstance().logEvent('action_login', { method: 'email' });
      window.Appcues?.track('Log in', { method: 'userpass' });
      setLoading(false);
      history.push('/profile');
    } catch (err) {
      addToast('SIGN IN ERROR', err.message, 'error');
      setLoading(false);
    }
  }

  async function handleGoogleLogin() {
    try {
      await loginWithGoogle();
      amplitudeClient.getInstance().logEvent('action_login', { method: 'google' });
      window.Appcues?.track('Log in', { method: 'google' });
      history.push('/profile');
    } catch (err) {
      addToast('GOOGLE LOGIN ERROR', err.message, 'error');
    }
  }

  async function handleTwitterLogin() {
    try {
      await loginWithTwitter();
      amplitudeClient.getInstance().logEvent('action_login', { method: 'twitter' });
      window.Appcues?.track('Log in', { method: 'twitter' });
      history.push('/profile');
    } catch (err) {
      addToast('TWITTER LOGIN ERROR', err.message, 'error');
    }
  }

  async function handleFacebookLogin() {
    try {
      await loginWithFacebook();
      amplitudeClient.getInstance().logEvent('action_login', { method: 'facebook' });
      window.Appcues?.track('Log in', { method: 'facebook' });
      history.push('/profile');
    } catch (err) {
      addToast('FACEBOOK LOGIN ERROR', err.message, 'error');
    }
  }

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO />
      {currentUser !== null && <Redirect to='/profile' />}
      <Container fluid className="d-flex align-items-center justify-content-center h-100">
        <Col md={6} className='px-5'>
          <img src={finiacLogo} alt='Finiac logo' className='auth-logo' />
          <h4 className='my-5'>Welcome back to Finiac!</h4>
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' id='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email' ref={emailRef} required />
            </Form.Group>
            <Form.Group className='mb-3' id='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                ref={passwordRef}
                aria-describedby='passwordHelpBlock'
                required
              />
              <Form.Text id='passwordHelpBlock' muted>
                a minimum of 6 characters required
              </Form.Text>
            </Form.Group>
            <Button disabled={loading} type='submit' size='lg' className='my-3'>
              Log In
            </Button>
          </Form>
          <hr />
          <GoogleLoginButton onClick={handleGoogleLogin} className='GoogleLoginButton'>
            <span>Log In w/ Google</span>
          </GoogleLoginButton>
          <TwitterLoginButton onClick={handleTwitterLogin} className='TwitterLoginButton'>
            <span>Log In w/ Twitter</span>
          </TwitterLoginButton>
          <FacebookLoginButton onClick={handleFacebookLogin} className='FacebookLoginButton'>
            <span>Log In w/ Facebook</span>
          </FacebookLoginButton>
          <div className='mt-5 d-flex'>
            <span>
              <Link to='/signup'>Sign up now for free!</Link>
            </span>
            <span className='ms-5'>
              <Link to='/forgot-password'>Forgot your password?</Link>
            </span>
          </div>
        </Col>
        <Col
          md={6}
          className='d-none d-md-flex h-100 auth-sidebar justify-content-center flex-column px-5'
        >
          <h3 className='sidebar-quote mb-4'>
            The common thing all great investors share is a commitment to risk management.
          </h3>
          <p>
            Jack Schwager, author of <em>Market Wizards</em>
          </p>
        </Col>
      </Container>
    </>
  );
}
