import React, { useCallback } from 'react';
import Carousel from 'react-gallery-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// components
import Histogram from './Histogram';
import LineChart from './LineChart';
// helpers
import { D3_VOLATILITY_OVERRIDES } from '../../helpers/Constants';
import { amplitudeClient } from '../../helpers/Analytics';

const marginOveride = {
  top: 0,
  right: 25,
  bottom: 0,
  left: 25,
};

const leftIcon = <FontAwesomeIcon className='fa-fw' icon={['fas', 'chevron-left']} />;
const rightIcon = <FontAwesomeIcon className='fa-fw' icon={['fas', 'chevron-right']} />;

export default function VisualCarousel({ asset }) {
  const handleIndexChange = useCallback((e) => {
    let slideName;
    switch (e.curIndexForDisplay) {
      case 1:
        slideName = 'histogram';
        break;

      case 2:
        slideName = 'volatility';
        break;

      case 3:
        slideName = 'growth';
        break;

      default:
        slideName = null;
        break;
    }
    amplitudeClient.getInstance().logEvent('action_slide-visual-carousel', {
      symbol: asset.symbol,
      item: slideName,
    });
    window.Appcues?.track('Slide visual carousel');
  }, []);

  if (!asset.history) return null;

  return (
    <Carousel
      hasIndexBoard={false}
      hasMediaButton={false}
      hasMediaButtonAtMax={false}
      hasSizeButton={false}
      hasDotButtons={false}
      hasThumbnails={false}
      shouldLazyLoad={false}
      shouldMinimizeOnSwipeDown={false} // for vertical overflow scrolling
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      // style={{ backgroundColor: 'transparent' }}
      className='chart-carousel'
      onIndexChange={handleIndexChange}
      isLoop
      shouldSwipeOnMouse
    >
      <div className='d3-chart'>
        <Histogram dailyHistory={asset} hideAxis />
        <p>Return Distribution</p>
      </div>
      <div className='carousel-line-chart px-4'>
        <LineChart
          dailyHistory={asset}
          yValue='changePercent'
          margin={marginOveride}
          yOverrides={D3_VOLATILITY_OVERRIDES}
        />
        <p>Volatility</p>
      </div>
      <div className='carousel-line-chart'>
        <LineChart dailyHistory={asset} yValue='closePrice' margin={marginOveride} />
        <p>Growth</p>
      </div>
    </Carousel>
  );
}
