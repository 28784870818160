import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { motion } from 'framer-motion';
// helpers
import { amplitudeClient } from '../helpers/Analytics';
import contentful from '../helpers/Contentful';
// components
import Seo from '../components/global/Seo';
import GridOfSquares from '../components/visuals/GridOfSquares';
// media
import risksmithMark from '../media/risksmith-mark.svg';

const onboardingSteps = [
  '42cba3YcUPSWaUn0LxYUw5',
  '5135vRBuzzATvbRIopNl8I',
  'Ksw98BFPioQf2CZFoqBy6',
  '6nWa9Wqf8bvoRnl72RRdZN',
  '65BpHku6uPitfk9GLbP41S',
];

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <img
        src={node.data.target.fields.file.url}
        alt={node.data.target.fields.title}
        className='content-image mb-3'
      />
    ),
  },
};

const PaginationIndicators = ({ currentStep }) =>
  onboardingSteps.map((step, index) => (
    <div
      key={step}
      className={`mx-1 pagination-indicator ${index === currentStep ? 'active' : ''}`}
    />
  ));

const AnimatedImage = ({ src }) => (
  <motion.img src={src} animate={{ opacity: 1 }} className='content-image' />
);

export default function Onboarding() {
  const [step, setStep] = useState(0);
  const [onboardingContent, setOnboardingContent] = useState(null);

  const history = useHistory();
  // router location
  const location = useLocation();

  // meta data for SEO
  const metaData = {
    title: 'Onboarding',
    description:
      "Onboarding -- Creating a great portfolio takes work. We'll help you get started, by guiding you through creating a team of stocks that's optimized to win, and then giving you the tools to manage it for the long haul.",
  };

  useEffect(() => {
    // aplitude log
    amplitudeClient.getInstance().logEvent('view_onboarding-page', { step });
    // contentful
    contentful
      .getEntries({
        content_type: 'appOnboardingScreen',
      })
      .then(({ items }) => {
        console.log(items);
        setOnboardingContent(items);
      })
      .catch((err) => console.warn('Cannot fetch content', err));
  }, []);

  const advanceStep = () => {
    if (step >= onboardingSteps.length - 1) return history.push('/profile');
    amplitudeClient.getInstance().logEvent('view_onboarding-page', { step: step + 1 });
    setStep(step + 1);
  };

  if (!onboardingContent) return null;

  const {
    fields: { screenTitle, screenCopy, screencap },
  } = onboardingContent.find((content) => content.sys.id === onboardingSteps[step]);

  const buttonCopy = step !== onboardingSteps.length - 1 ? 'Next' : "Got it. Let's go!";

  return (
    <>
      <Seo metaData={metaData} pagePath={location.pathname} pageSEO noIndex />
      <div className='onboarding container-fluid text-center text-md-start h-md-100'>
        <img src={risksmithMark} alt='Risksmith' width='40' className='mb-md-5 d-md-none' />
        <div className='row mt-5 h-100'>
          {screencap && (
            <div className='col-12 col-md-7 img order-md-2 h-md-100'>
              <AnimatedImage src={screencap.fields.file.url} />
            </div>
          )}
          <div className='col-12 col-md-5 order-md-1 copy'>
            <img
              src={risksmithMark}
              alt='Risksmith'
              width='40'
              className='mb-5 d-none d-md-block rs-mark'
            />
            <h1 className='mb-4 mt-4 mt-md-0'>{screenTitle}</h1>
            {documentToReactComponents(screenCopy, renderOptions)}
            <Button onClick={advanceStep} size='lg' className='mt-4'>
              {buttonCopy}
            </Button>
          </div>
        </div>
        <div className='row fixed-bottom'>
          <div className='d-flex justify-content-center'>
            <PaginationIndicators currentStep={step} />
          </div>
          <span className='mb-3 ms-3 text-muted'>
            If you want, you can{' '}
            <a href='/profile' className='text-muted'>
              skip this walkthrough
            </a>
            .
          </span>
          <GridOfSquares rowCount='1' baseColor='#1FBD60' />
        </div>
      </div>
    </>
  );
}
