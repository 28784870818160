import { Col, Row } from 'react-bootstrap';
// components
import AssetStat from './AssetStat';

const AssetStats = ({ dScore, riskEfficiency, growth, growthStartDate }) => (
  <Row className='asset-stats'>
    <Col sm={4}>
      <AssetStat
        label='Risk Efficiency'
        description='How well this asset converts risk to reward. Higher is more efficient, usually ranges between -1 and 4.'
        stat={riskEfficiency}
      />
    </Col>
    <Col sm={4}>
      <AssetStat
        label='dScore'
        description='How much this asset may drop, randomly, a couple times a month. Not an absolute limit,
        represents the amount of volatility you need to be OK with.'
        stat={dScore}
        formattedStat={`${dScore}%`}
        disableColoring
      />
    </Col>
    <Col sm={4}>
      <AssetStat
        label={'Growth'}
        description={`Growth over the same time period as the rest of the stats shown here. Currently since ${growthStartDate}.`}
        stat={growth}
        formattedStat={`${growth}%`}
      />
    </Col>
  </Row>
);

export default AssetStats;
