import react, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

import useWindowSize from '../../hooks/UseWindowSize';

export default function GridOfSquares({ rowCount, width, baseColor }) {
  const windowSize = useWindowSize();
  const squareSize = 90; // default is 90px square
  const dimensions = {
    width: width || windowSize.width,
  };

  const gridRow = () => {
    const row = [];
    const numSquares = Math.ceil(windowSize.width / squareSize);
    for (let i = 0; i < numSquares; i += 1) {
      row.push(<rect
        key={i}
        width={squareSize}
        height={squareSize}
        x={i * squareSize}
        y="0"
        fill={`rgba(31, 189, 96, ${Math.random()})`}
      />);
    }
    return row;
  };

  return (
    <svg viewBox={`0 0 ${dimensions.width} 90`}>
      <g>
        {gridRow()}
      </g>
    </svg>
  );
}
