import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EmptyState = ({ height, iconName, message, submessage }) => (
  <div
    className='d-flex flex-column justify-content-center align-items-center'
  style={{ minHeight: height || '185px' }}
  >
    <div className='text-muted'>
      <FontAwesomeIcon icon={iconName} size={'lg'} />
    </div>
    <span className='mt-3'>
      {message}
    </span>
    <span className='text-muted'>
      {submessage}
    </span>
  </div>
);

export default EmptyState;
