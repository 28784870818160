import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CollectionHeader({
  style,
  name,
  sort,
  subname,
  subsort,
  currentSort,
  order,
  sortable,
  handleSorting,
}) {
  const [showSortIcon, setShowSortIcon] = useState(false);

  /**
   * Handle sort button click
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {string} newSort - new sort type
   * @param {string} currentOrder - current sort order
   * @returns - none
   */
  const handleSortingChange = (newSort, currentOrder) => {
    // if we are changing sort types start over
    const oo = currentSort !== newSort ? 'off' : currentOrder;
    // get next sort order
    let newOrder;
    switch (oo) {
      case 'off':
        newOrder = 'asc';
        break;

      case 'asc':
        newOrder = 'desc';
        break;

      case 'desc':
        newOrder = 'off';
        break;

      default:
        newOrder = 'off';
        break;
    }
    // dont show the icon when off
    if (newOrder === 'asc' || newOrder === 'desc') {
      setShowSortIcon(true);
    }
    if (newOrder === 'off') {
      setShowSortIcon(false);
    }
    // update sorting method
    handleSorting(newSort, newOrder);
  };

  return (
    <th className={style}>
      {sortable && (
        <>
          <Button size={'sm'} variant={'link'} onClick={() => handleSortingChange(sort, order)}>
            {name}{' '}
            {showSortIcon && currentSort === sort && (
              <FontAwesomeIcon
                icon={order === 'asc' ? ['fad', 'sort-up'] : ['fad', 'sort-down']}
                size={'sm'}
                fixedWidth
              />
            )}
          </Button>
          {subname && subsort && (
            <>
              {' '}
              |{' '}
              <Button
                size={'sm'}
                variant={'link'}
                onClick={() => handleSortingChange(subsort, order)}
              >
                {subname}{' '}
                {showSortIcon && currentSort === subsort && (
                  <FontAwesomeIcon
                    icon={order === 'asc' ? ['fad', 'sort-up'] : ['fad', 'sort-down']}
                    size={'sm'}
                    fixedWidth
                  />
                )}
              </Button>
            </>
          )}
        </>
      )}
      {!sortable && (
        <>
          {name}
          {subname ? ` | ${subname}` : ''}
        </>
      )}
    </th>
  );
}

CollectionHeader.propTypes = {
  style: PropTypes.string,
  name: PropTypes.string,
  sort: PropTypes.string,
  subname: PropTypes.string,
  subsort: PropTypes.string,
  currentSort: PropTypes.string,
  order: PropTypes.string,
  sortable: PropTypes.bool,
  handleSorting: PropTypes.func,
};

CollectionHeader.defaultProps = {
  style: '',
  name: '',
  sort: '',
  subname: '',
  subsort: '',
  currentSort: '',
  order: '',
  sortable: false,
  handleSorting: () => {},
};

export default CollectionHeader;
