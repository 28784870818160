import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrop } from 'react-dnd';
import { get } from 'lodash';
// constants
import { ItemTypes } from '../../helpers/Constants';
// helpers
import { amplitudeClient } from '../../helpers/Analytics';
// hooks
import useProfile from '../../hooks/UseProfile';
import {
  addPortfolioStock,
  removePortfolioStock,
  updatePortfolioStockShares,
} from '../../helpers/Firebase';
// components
import StockCard from './StockCard';
import useCanViewList from '../../hooks/useCanViewList';

// AG Grid Styles
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import StockList from './StockList';

export default function StockCards({
  allocations,
  cpid,
  openInfoSidebar,
  isWeighted,
  shares = {},
  portfolio,
  readOnly,
  hideShares,
  viewMode,
}) {
  const [portfolioAssets, setPortfolioAssets] = useState([]);
  const canViewList = useCanViewList();
  const { userID } = useProfile();

  /**
   * Handle adding stock to database using react dnd
   * @author Ryan Srofe <rsrofe@gmail.com>
   */
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.PLAYER,
    drop: (item, monitor) => {
      amplitudeClient.getInstance().logEvent('action_add-asset-to-portfolio-drag-n-drop', {
        symbol: item.symbol,
        portfolioId: cpid,
      });
      window.Appcues?.track('Add asset to portfolio', { method: 'drag and drop', asset: item.symbol.toUpperCase() });
      addPortfolioStock(userID, cpid, item.symbol);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // highlight dropzone
  const dragHoverTeamBG = isOver ? 'dropzone' : '';

  const ifNoStocks = portfolio.stocks.length !== 0 ? '' : 'dropzone';

  /**
   * Update the shares for a stock
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {Event} value - onClick event
   * @returns - none
   */
  const updateStockShares = useCallback((stockID, shareCount) => {
    updatePortfolioStockShares(userID, cpid, stockID, shareCount);
  }, [cpid, updatePortfolioStockShares, userID]);

  /**
   * Handle deleting stock from database
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @param {String} stockID - ID of the stock to delete
   * @returns - none
   */
  const deleteStock = useCallback((stockID, symbol) => {
    removePortfolioStock(userID, cpid, stockID);
    amplitudeClient.getInstance().logEvent('action_remove-asset-from-portfolio', {
      symbol,
      portfolioId: cpid,
    });
    window.Appcues?.track('Remove asset from portfolio', { asset: symbol.toUpperCase() });
  }, [cpid, removePortfolioStock, userID]);

  if (viewMode === 'list' && canViewList) {
    return (
      <StockList
        allocations={allocations}
        portfolioAssets={portfolio.stocks}
        dropArea={drop}
        isRealPortfolio={portfolio.isReal}
        isWeighted={isWeighted}
        deleteStock={deleteStock}
      />
    );
  }

  return (
    <div ref={drop} className={`portfolio-cards ${dragHoverTeamBG} ${ifNoStocks}`}>
      <Row xs={1} sm={1} md={2} lg={3} xl={3}>
        <div className='drop-message mb-4 col'>
          <div className='drop-here'>
            <>
              {portfolio.stocks.length === 0 && (
                <p style={{ textAlign: 'center', marginTop: '20px' }}>Add Your First Stock!</p>
              )}
              <FontAwesomeIcon className='m-2 fa-fw' icon={['fal', 'plus']} size={'4x'} />
            </>
          </div>
        </div>
        {portfolio.stocks.length !== 0 &&
          portfolio.stocks.map((stock) => {
            const allocation = get(allocations, stock.symbol);

            let assetShares = 1;
            if (stock.shares) {
              assetShares = stock.shares; // doesn't matter which one we use for custom alloc
            }
            if (shares[stock.symbol] && !portfolio.isReal) {
              assetShares = shares[stock.symbol].equal;
            }
            if (shares[stock.symbol] && !portfolio.isReal && isWeighted) {
              assetShares = shares[stock.symbol].weighted;
            }

            return (
              <StockCard
                key={stock.id}
                id={stock.id}
                symbol={stock.symbol}
                openInfoSidebar={openInfoSidebar}
                deleteStock={deleteStock}
                updateStockShares={updateStockShares}
                playerType={ItemTypes.TEAM}
                allocation={allocation}
                shares={assetShares}
                isRealPortfolio={portfolio.isReal}
                readOnly={readOnly}
                hideShares={hideShares}
              />
            );
          })}
      </Row>
    </div>
  );
}
