/* eslint-disable no-confusing-arrow */
import React, {
  useCallback,
  useContext,
  useEffect,
  useGlobal,
  useMemo,
  useState,
} from 'reactn';
import {
  fill,
  filter,
  get,
  keyBy,
  mapValues,
  zipObject,
} from 'lodash';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
// data config
import { db } from '../../config/firebase';
// helpers
// hooks
import useProfile from '../../hooks/UseProfile';
// context
import CollectionContext from '../../contexts/CollectionContext';
// components
import CollectionAsset from './CollectionAsset';
import CollectionHeader from './CollectionHeader';
import { sortAssetsByCorrelation } from '../../helpers/DataServices';

function CollectionAssets({ collectionID, collectionSymbols }) {
  const [portfolioWeighting, setPortfolioWeighting] = useState('equal');
  const [sortOrder, setSortOrder] = useState('off');
  const [sortCurrent, setSortCurrent] = useState('off');
  const [assetList, setAssetList] = useGlobal('collectionAssetList');
  const { weighting } = useProfile();
  const { portfolioPL, actions } = useContext(CollectionContext);
  const [correlationsBySymbol, setCorrelationsBySymbol] = useState({});
  const [currentPortfolioId] = useGlobal('currentPortfolioId');
  const [metaBySymbol, setMetaBySymbol] = useState({});
  const sortedAssetList = useMemo(() => {
    const augmentedAssetList = assetList.map((asset) => {
      const nextAsset = { ...asset };
      const meta = metaBySymbol[asset.symbol];

      if (meta) {
        Object.assign(nextAsset, meta);
      }

      if (asset.symbol in correlationsBySymbol) {
        nextAsset.correlation = correlationsBySymbol[asset.symbol];
      }

      return nextAsset;
    });
    const nextSortedAssetList = [...augmentedAssetList];

    switch (sortCurrent) {
      case 'alpha-symbol':
        if (sortOrder === 'off') {
          nextSortedAssetList.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        }
        if (sortOrder === 'asc') {
          nextSortedAssetList.sort((a, b) =>
            a.symbol.toLowerCase() > b.symbol.toLowerCase() ? 1 : -1
          );
        }
        if (sortOrder === 'desc') {
          nextSortedAssetList.sort((a, b) =>
            a.symbol.toLowerCase() < b.symbol.toLowerCase() ? 1 : -1
          );
        }
        break;

      case 'alpha-name':
        if (sortOrder === 'off') {
          nextSortedAssetList.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        }
        if (sortOrder === 'asc') {
          nextSortedAssetList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        }
        if (sortOrder === 'desc') {
          nextSortedAssetList.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1));
        }
        break;

      case 'risk':
        if (sortOrder === 'off') {
          nextSortedAssetList.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        }
        if (sortOrder === 'asc') {
          nextSortedAssetList.sort(
            (a, b) =>
              (get(b, 'yesterday.stats.adjustedSortino', 0) || 0) -
              (get(a, 'yesterday.stats.adjustedSortino', 0) || 0)
          );
        }
        if (sortOrder === 'desc') {
          nextSortedAssetList.sort(
            (a, b) =>
              (get(a, 'yesterday.stats.adjustedSortino', 0) || 0) -
              (get(b, 'yesterday.stats.adjustedSortino', 0) || 0)
          );
        }
        break;

      case 'dScore':
        if (sortOrder === 'off') {
          nextSortedAssetList.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        }
        if (sortOrder === 'asc') {
          nextSortedAssetList.sort(
            (a, b) =>
              (get(a, 'yesterday.stats.dScore', 0) * 100) -
              (get(b, 'yesterday.stats.dScore', 0) * 100)
          );
        }
        if (sortOrder === 'desc') {
          nextSortedAssetList.sort(
            (a, b) =>
              (get(b, 'yesterday.stats.dScore', 0) * 100) -
              (get(a, 'yesterday.stats.dScore', 0) * 100)
          );
        }
        break;

      case 'growth':
        if (sortOrder === 'off') {
          nextSortedAssetList.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        }
        if (sortOrder === 'asc') {
          nextSortedAssetList.sort((a, b) => b.growth - a.growth);
        }
        if (sortOrder === 'desc') {
          nextSortedAssetList.sort((a, b) => a.growth - b.growth);
        }
        break;

      case 'correlation':
        if (sortOrder === 'off') {
          nextSortedAssetList.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        }
        if (sortOrder === 'asc') {
          nextSortedAssetList.sort(
            (a, b) => (a.correlation * 100) - (b.correlation * 100)
          );
        }
        if (sortOrder === 'desc') {
          nextSortedAssetList.sort(
            (a, b) => (b.correlation * 100) - (a.correlation * 100)
          );
        }
        break;

      default:
        nextSortedAssetList.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        break;
    }

    return nextSortedAssetList;
  }, [assetList, correlationsBySymbol, metaBySymbol, sortCurrent, sortOrder]);

  // TODO: filter out stuff that's in the correlated assets, but not in the collection

  /**
   * Handles table sorting for name, symbol, re, dscore, and growth
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  const handleChangeSort = (nextSortCurrent, nextSortOrder) => {
    setSortOrder(nextSortOrder);
    setSortCurrent(nextSortCurrent);
  };

  const symbolsWithoutCorrelation = filter(collectionSymbols, (symbol) => !correlationsBySymbol[symbol]);

  /**
   * Updates the correlations for the collection's assets
   */
  const updateCorrelations = useCallback(async (controller) => {
    if (symbolsWithoutCorrelation.length === 0) {
      return;
    }

    try {
      const result = await sortAssetsByCorrelation(
        portfolioPL,
        symbolsWithoutCorrelation,
        portfolioWeighting,
        controller
      );
      const resultsBySymbol = keyBy(result, 'symbol');
      const defaultCorrelationsBySymbol = zipObject(
        collectionSymbols,
        fill(Array(collectionSymbols.length), null),
      );
      const fetchedCorrelationsBySymbol = mapValues(resultsBySymbol, 'correlation');

      setCorrelationsBySymbol((prevCorrelationsBySymbol) => ({
        ...defaultCorrelationsBySymbol,
        ...prevCorrelationsBySymbol,
        ...fetchedCorrelationsBySymbol,
      }));
    } catch (e) {
      // noop
    }
  }, [collectionSymbols, portfolioPL, portfolioWeighting, symbolsWithoutCorrelation]);

  /**
   * Resets the correlations if the user changes the compared portfolio
   */
  useEffect(() => {
    setCorrelationsBySymbol({});
  }, [currentPortfolioId]);

  /**
   * Sets portfolio weighting when portfolio changes
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    setPortfolioWeighting(weighting);
  }, [weighting]);

  /**
   * Kicks correlation work after getting portfolioPL and collectionsSymbols
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    if (portfolioPL.length > 0 && collectionSymbols.length > 0) {
      const controller = new AbortController();
      updateCorrelations(controller);

      return () => {
        controller.abort();
      };
    }
  }, [portfolioPL, collectionSymbols, updateCorrelations]);

  /**
   * When a collection changes
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  useEffect(() => {
    // console.log('COLLECTION', collectionID);
    if (collectionID !== '') {
      // console.log('GETTING COLLECTION ASSETS 📦');
      const unsubscribeCollectionAssets = onSnapshot(
        query(
          collection(doc(db, 'collections', collectionID), 'assets'),
          orderBy('createdAt'),
        ),
        (snapshot) => {
          const assets = snapshot.docs.map((d) => ({
            ...d.data(),
            id: d.id,
          }));
          setAssetList(assets);

          // FIXME: this needs to exist, but disabling for now because the state is getting out of whack for some reason
          // if (!isEqual(assets, assetList)) {
          //   console.log('next assetList', assets);
          // }
        },
        (err) => {
          console.log('collection assets snapshot listen error', err.message);
        }
      );
      return () => {
        unsubscribeCollectionAssets();
      };
    }
  }, [collectionID, setAssetList]);

  /**
   * When the sortableAssetList array is updated
   * @author Ryan Srofe <rsrofe@gmail.com>
   * @returns - none
   */
  // useEffect(() => {
  //   // console.log('SORTABLE LIST', sortableAssetList);
  //   // if sortable list is equal to asset list everything is loaded
  //   if (sortableAssetList.length > 0 && sortableAssetList.length === assetList.length) {
  //     // console.log('ASSETS', sortableAssetList);
  //     // sets the collection symbols for correlation
  //
  //     if (collectionSymbols.length === 0 || collectionSymbols.length !== sortableAssetList.length) {
  //       // initial sorting
  //       handleChangeSort(sortCurrent, sortOrder);
  //     }
  //   }
  //
  //   return () => {};
  // }, [sortableAssetList]);
  const handleUpdate = useCallback((symbol, meta) => {
    if (!(symbol in metaBySymbol)) {
      metaBySymbol[symbol] = meta;
    }
  }, [metaBySymbol]);

  return (
    <>
      {sortedAssetList.length === 0 && (
        <div className='mt-3 mb-3'>Add assets using the search above 👆</div>
      )}
      <Table responsive striped={false} borderless hover variant='dark'>
        {sortedAssetList.length >= 1 && (
          <>
            <thead>
              <tr className='asset'>
                <CollectionHeader
                  style={'asset__symbol'}
                  name={'Symbol'}
                  subname={'Name'}
                  sort={'alpha-symbol'}
                  subsort={'alpha-name'}
                  currentSort={sortCurrent}
                  order={sortOrder}
                  sortable
                  handleSorting={handleChangeSort}
                />
                <CollectionHeader
                  style={'asset__stat'}
                  name={'RE'}
                  sort={'risk'}
                  currentSort={sortCurrent}
                  order={sortOrder}
                  sortable
                  handleSorting={handleChangeSort}
                />
                <CollectionHeader
                  style={'asset__stat'}
                  name={'dScore'}
                  sort={'dScore'}
                  currentSort={sortCurrent}
                  order={sortOrder}
                  sortable
                  handleSorting={handleChangeSort}
                />
                <CollectionHeader
                  style={'asset__stat'}
                  name={'Growth (1yr)'}
                  sort={'growth'}
                  currentSort={sortCurrent}
                  order={sortOrder}
                  sortable
                  handleSorting={handleChangeSort}
                />
                <CollectionHeader
                  style={'asset__stat'}
                  name={'Correlation'}
                  sort={'correlation'}
                  currentSort={sortCurrent}
                  order={sortOrder}
                  sortable
                  handleSorting={handleChangeSort}
                />
                <CollectionHeader style={'asset__histo'} name={'Histogram'} />
                <CollectionHeader style={'asset__delete'} name={''} />
              </tr>
            </thead>
            <tbody>
              {sortedAssetList.map((asset) => (
                <CollectionAsset
                  key={asset.id}
                  collectionID={collectionID}
                  asset={{
                    ...asset,
                    correlation: correlationsBySymbol[asset.symbol],
                  }}
                  onUpdate={handleUpdate}
                />
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  );
}

CollectionAssets.propTypes = {
  collectionID: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  collectionSymbols: PropTypes.array,
};

CollectionAssets.defaultProps = {
  collectionID: '',
  collectionSymbols: [],
};

export default CollectionAssets;
