import React, { useEffect, useState } from 'react';
// components
import { getAssetHistory } from '../../helpers/DataServices';
import BenchmarkBadge from '../global/BenchmarkBadge';
// helpers
import { BENCHMARKS } from '../../helpers/Constants';

const withBenchmarks = (MultilineChart) => (props) => {
  const [selectedBenchmarks, setSelectedBenchmarks] = useState(new Set());
  const [benchmarksWithHistory, setBenchmarksWithHistory] = useState([]);

  useEffect(async () => {
    const controller = new AbortController();
    const { data: benchmarkHistories } = await getAssetHistory({
      symbol: BENCHMARKS.map((b) => b.symbol),
      includeStats: true,
      controller,
    });

    const mergedBenchmarkData = BENCHMARKS.map((benchmark) => ({
      ...benchmark,
      history: benchmarkHistories
        .find((bH) => bH.symbol === benchmark.symbol)
        .history.map((h) => ({
          ...h,
          ...h.stats,
        })),
    }));
    setBenchmarksWithHistory(mergedBenchmarkData);

    return () => {
      controller.abort();
    };
  }, []);

  const onClickBenchmark = (benchmark) => {
    const isCurrentlyActive = selectedBenchmarks.has(benchmark.symbol);
    if (!isCurrentlyActive) {
      setSelectedBenchmarks(new Set(selectedBenchmarks.add(benchmark.symbol)));
    }

    if (isCurrentlyActive) {
      const newBenchmarks = new Set([...selectedBenchmarks]);
      newBenchmarks.delete(benchmark.symbol);
      setSelectedBenchmarks(newBenchmarks);
    }
  };

  return (
    <div>
      <MultilineChart
        {...props}
        benchmarks={benchmarksWithHistory.filter((b) => selectedBenchmarks.has(b.symbol))}
      />
      <div className='d-flex align-items-center mt-2'>
        <span
          className='me-2'
          style={{
            fontSize: '.875em',
            color: '#636363',
            fontWeight: 600,
          }}
        >
          Compare to:
        </span>
        {BENCHMARKS.map((benchmark) => (
          <BenchmarkBadge
            key={benchmark.symbol}
            benchmark={benchmark}
            onClickBenchmark={() => onClickBenchmark(benchmark)}
            isActive={selectedBenchmarks.has(benchmark.symbol)}
          />
        ))}
      </div>
    </div>
  );
};

export default withBenchmarks;
